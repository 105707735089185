<template>
    <div class="wrapper">

        <div class="heading heading-overlap-custom-create">
            <div class="container">
                <div class="grid">
                    <div class="col-6 col-md-12">
                        <div class="heading-back">
                            <a v-if="urlParams.get('first_time') != 1" href="/dashboard"><i class="icon icon-arrow-left"></i><span>Моят Pop Up офис</span></a>
                        </div>
                        <header class="heading-content">
                            <h1 v-if="this.$store.state.createLink.step == 1" class="heading-title">Добави продукт</h1>
                            <p v-if="this.$store.state.createLink.step == 1">
                                Започни с описание, цена и снимки.<br>
                                Покажи всички предимства на продукта.<br>
                                Не забравяй да споменеш размер, цвят и<br>
                                състояние.<br>
                            </p>
                            <h1 v-if="this.$store.state.createLink.step == 2" class="heading-title">Избери начален адрес</h1>
                            <p v-if="this.$store.state.createLink.step == 2">
                                Тук избираш дали ще изпращаш продукта от офис <br>
                                <!-- на Еконт, адрес или еконтомат.<br> -->
                                на Еконт или адрес.<br>
                            </p>
                        </header>
                    </div>
                    <div class="col-6 col-md-12">

                        <div class="wizard">
                            <i class="progress" role="progressbar" style="width: 27%"></i>
                            <ul class="nums flex text-center">
                                <li class="active" v-if="this.$store.state.createLink.step == 1">
                                    <a href="#">
                                        <span>
                                            <i>1</i>
                                            <div class="tooltip">0%</div>
                                        </span>
                                    </a>
                                </li>
                                <li class="active" v-if="this.$store.state.createLink.step == 2">
                                    <a href="#">
                                        <span>
                                            <i>1</i>
                                        </span>
                                    </a>
                                </li>
                                
                                <li v-if="this.$store.state.createLink.step == 1">
                                    <a href="#">
                                        <span><i>2</i></span>
                                    </a>
                                </li>
                                <li class="active" v-if="this.$store.state.createLink.step == 2">
                                    <a href="#">
                                        <span>
                                            <i>2</i>
                                            <div class="tooltip">50%</div>
                                        </span>
                                    </a>
                                </li>

                                <li>
                                    <a href="#">
                                        <span><i>3</i></span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="text flex text-center">
                                <li class="active">Добави<br>продукт</li>
                                <li>Избери<br>адрес</li>
                                <li>Генерирай<br>линк</li>
                            </ul>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>

        <form class="form" method="post" enctype="multipart/form-data" autocomplete="off">

           <step-one v-if="this.$store.state.createLink.step === 1" :shops="shops"></step-one>
           <step-two v-if="this.$store.state.createLink.step === 2" :profile="profile" :addresses="addresses" :cities="cities"></step-two>

        </form>

    </div>
</template>

<script>
import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'

    export default {
        props: ['shops', 'addresses', 'cities', 'profile'],
        components: {
            StepOne,
            StepTwo,
        },
        data() {
            return {
                product: this.$store.state.createLink.product,
                urlParams: ''
            }
        },
        created() {
            let uri = window.location.search.substring(1); 
            this.urlParams = new URLSearchParams(uri);
        },
        mounted() {
            this.$store.state.createLink.step = 1
        }
    }

</script>

<style>

</style>
