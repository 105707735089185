<template>
  <div class="modal modal-delete modal-show" data-id="delete">
    <a href="#" class="close-modal" @click="close()"><i class="icon icon-close-circle"></i></a>
    <slot></slot>
    <div class="text-right mt-3">
        <slot name="link"></slot>
        <a href="#" class="button small" @click="close()">Отказ</a>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
    methods: {
        close() {
            this.gsapCloseModal();
            this.$emit('close')
        }
    },
    mounted() {
        let div = document.createElement('div');
        div.setAttribute('class', 'modal-overlay');
        document.body.appendChild(div)
        let modal = document.querySelector('.modal');
        gsap.to(div, .3, { opacity: .9 })
        gsap.fromTo(modal, .3, { opacity: 0, visibility: 'visible' }, { opacity: 1 })
    }
}
</script>

<style scoped>
.modal-show {
    visibility: visible; 
    opacity: 1;
}
</style>