<template>
  <div class="modal modal-delete video-modal" data-id="video">
    <a href="#" class="close-modal" @click="$emit('close')">
      <i class="icon icon-close-circle"></i>
    </a>
    <div class="text-right mt-3">
      <video id="player" playsinline controls>
        <source :src="video.url" type="video/mp4" style="width:800px;" />
      </video>
    </div>
  </div>
</template>

<script>
import Plyr from 'plyr'

export default {
  props: ['video'],
  mounted() {
    const player = new Plyr('#player')
  },
}
</script>
<style scoped>
@import url('~plyr/dist/plyr.css');
.modal {
  width: auto;
  z-index: 9999999;
  background: transparent;
  box-shadow: unset;
}
.modal .close-modal {
  color: white;
}

</style>
