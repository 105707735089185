<template>
    <header class="heading-content">
        <!-- <h1 class="heading-title">Намери продукт</h1>
        <p>Можеш да търсиш по ключови думи из всички обяви в сайта.</p> -->
        <div class="form">
            <div class="search-container">
                <div class="search-content">
                    <label for="">
                        <span class="hide-more-for-ever">Търси</span> 
                        <input
                            @input="fetchOptions"
                            v-model="search"
                            type="text"
                            name="search"
                            autocomplete="off"
                            class="search-input"
                            id="search-pop"
                        />
                    </label>
                    <div class="loading-div" v-if="loading">
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                             x="0px" y="0px" viewBox="0 0 507.2 478.5"
                             xml:space="preserve">
                            <g>
                                <path d="M463.8,280.3c-24.1-0.1-43.1-19.4-43-43.5c0.1-23.7,19.6-42.8,43.5-42.6c23.6,0.1,42.9,19.6,42.8,43.2
                                    C507.1,261.1,487.6,280.4,463.8,280.3z"/>
                                <path d="M476.8,144.1c0,22.2-17.9,40-40.1,40c-22.2,0-40-17.9-39.9-40.1c0-22,17.9-39.8,39.9-39.9
                                    C458.9,104.1,476.8,121.9,476.8,144.1z"/>
                                <path d="M373.3,109.1c-20.6,0-37-16.4-37-36.9c0-20.3,16.5-36.8,36.8-36.9c20.4,0,37.1,16.6,37,37
                                    C410.1,92.6,393.6,109,373.3,109.1z"/>
                                <path d="M284.6,67.7c-18.7-0.1-33.6-15.2-33.6-34C251.1,15.2,266.4,0,285,0c18.8,0,34,15.4,33.9,34.4
                                    C318.7,52.9,303.4,67.8,284.6,67.7z"/>
                                <path d="M219.5,36.5c0.1,17-13.4,30.6-30.5,30.8c-17,0.2-30.8-13.5-31-30.4c-0.1-17,13.7-31,30.7-31
                                    C205.7,5.8,219.5,19.5,219.5,36.5z"/>
                                <path d="M130.6,79.7c0,15.3-12.2,27.7-27.5,27.8C87.8,107.6,75.3,95.3,75.2,80c-0.1-15.4,12.3-27.9,27.7-27.9
                                    C118.2,52.1,130.5,64.4,130.6,79.7z"/>
                                <path d="M18.8,155.3c-0.1-13.6,10.9-24.7,24.5-24.7c13.6-0.1,24.7,10.8,24.8,24.4c0.1,13.5-10.9,24.6-24.5,24.8
                                    C30,179.9,18.9,168.8,18.8,155.3z"/>
                                <path d="M43.1,248.6c0,12-9.5,21.6-21.5,21.6C9.7,270.2,0,260.5,0,248.7c0-11.9,9.6-21.5,21.5-21.6
                                    C33.5,227.1,43.1,236.6,43.1,248.6z"/>
                                <path d="M59.8,342.6c0.1,10.2-8.2,18.5-18.4,18.5c-10.1,0-18.4-8.1-18.5-18.2c-0.1-10.3,8-18.6,18.3-18.7
                                    C51.4,324.2,59.7,332.3,59.8,342.6z"/>
                                <path d="M114.5,419.7c-0.2,8.5-7.5,15.3-15.9,15c-8.5-0.3-15.2-7.5-14.9-16c0.3-8.3,7.4-15,15.8-14.8
                                    C108,404.1,114.7,411.2,114.5,419.7z"/>
                                <path d="M171.7,464c0.1-6.8,5.6-12,12.4-11.9c6.9,0.1,12.3,5.7,12.1,12.6c-0.2,6.8-5.8,12.1-12.4,12
                                    C176.9,476.6,171.6,471,171.7,464z"/>
                                <path d="M280,460c5.1,0,9.1,4,9.2,9.1c0.1,5.2-4.1,9.4-9.2,9.5c-5.1,0-9.3-4.3-9.2-9.4C270.9,464,275,460,280,460z"/>
                                <path d="M375.2,433c0,3.3-2.8,6-6.1,6.1c-3.3,0-6.1-2.7-6.2-6c-0.1-3.4,2.8-6.3,6.2-6.2C372.4,427,375.2,429.7,375.2,433z"/>
                                <path d="M437.7,363.1c-1.1,0.8-2.3,2.4-3.4,2.3c-1.1-0.1-2.1-1.7-3.2-2.7c1-1,1.8-2.8,2.9-3c1-0.2,2.4,1.2,3.6,2
                                    C437.7,362.2,437.7,362.7,437.7,363.1z"/>
                            </g>
                        </svg>
                    </div>
                    <div for="search-pop" v-else>
                        <a :href="search !== null ? `/obqvi?search=${search}` : `/obqvi?search=`" :title="search !== '' ? `Търсене на ${search} - Popup` : `Търсене в Popup`" rel="nofollow">
                            <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                             x="0px" y="0px"viewBox="0 0 197 194.9"
                             xml:space="preserve">
                            <g>
                                <path d="M87.1,174.2c-48,0-87.1-39.1-87.1-87.1S39.1,0,87.1,0c48,0,87.1,39.1,87.1,87.1S135.1,174.2,87.1,174.2z
                                     M87.1,12C45.7,12,12,45.7,12,87.1s33.7,75.1,75.1,75.1c41.4,0,75.1-33.7,75.1-75.1S128.5,12,87.1,12z"/>
                                
                                    <rect x="161" y="135.4" transform="matrix(0.6885 -0.7252 0.7252 0.6885 -68.9016 174.3916)" class="st0" width="15.2" height="64"/>
                            </g>
                            </svg>
                        </a>
                    </div>
                   <!--  <svg v-else version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 445 445" xml:space="preserve">
                    <path d="M164.5,274.7c-17.2-16-30.4-34.2-39.4-55.3c-28.8-67.2-6.3-144.3,54.6-187.8c72.4-51.7,173.4-38.6,230,29.8
                        c59.1,71.5,42,177.5-37.1,229.4c-57.7,37.9-133.7,37.1-190.5-2.2c-1.1-0.8-2.2-1.5-3.6-2.4c-6.7,6.3-13.3,12.6-19.9,18.9
                        c-46.4,44.5-92.8,89.1-139.3,133.6c-1.3,1.3-2.6,2.6-4,3.7c-4.3,3.5-9.6,3.3-13-0.5c-3.3-3.7-3.1-8.4,1-12.4
                        c9.3-9.1,18.8-18,28.1-27c43.3-41.5,86.6-83,129.9-124.5C162.3,277.1,163.2,276.1,164.5,274.7z M287.4,17.3
                        c-34,0.2-57.8,6-80.2,17.8c-79,41.9-100.9,140.8-46.4,210c48.2,61.2,139.5,74.4,204.4,29.4c56.5-39.2,77.8-110.3,49.4-171.1
                        C387.9,46.4,340.4,20.2,287.4,17.3z"></path>
                    </svg> -->
                    <ul class="search-result" v-if="showList == true && products.length > 0">
                        <li class="search-item-counter">
                            <span>{{ products.length }} {{ products.length > 1 ? 'резултата' : 'резултат' }}</span>
                            <span class="close" @click='closeMe()'>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11 fa-7x">
                                    <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
                                </svg>
                            </span>
                        </li>
                        <li v-for="product in products" class="search-result__item">
                            <a class="search-result__link" href="#" @click="redirectTo(product.id)">
                                <img class="search-result__img" :src="image(product)" alt="image" />
                                <div class="search-result__item-content">
                                    <span>{{ product.title }}</span>
                                    <span>Цена: <b>{{ product.price }}лв</b></span>
                                </div>
                            </a>
                        </li>
                         <li class="search-result__item search-result__item-footer">
                            <a class="search-result__link" :href="search !== null ? `/ads?search=${search}` : `/ads?search=`" c>
                                <span class="circle"></span>
                                <span class="circle"></span>
                                <span class="circle"></span>
                            </a>
                        </li>
                    </ul>

                    <!-- <v-select @search="fetchOptions" :filterable="false" :options="products" label="title">
                    <template v-slot:option="products">
                        <div class="search-content__search-field">
                            <img :src="image(products)" alt="image">
                            {{ products.title}}
                         </div>
                    </template>
                    <template slot="selected-option" slot-scope="products">
                        {{ products.title}}
                    </template>
                    <template slot="no-options">
                        type to search...
                    </template>
                </v-select> -->
                </div>
                <!-- <button type="submit" class="button search-button">
                    Търси
                </button> -->
                <a :href="searchUrl" class="button search-button searching-button" rel="nofollow">
                    <span>Търси</span>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import moment from 'moment'
export default {
    props: ['category'],
    data() {
        return {
            products: [],
            search: '',
            showList: false,
            loading:false,
            searchUrl: ''
        }
    },
    created() {
        const params = (new URL(location)).searchParams;
        this.search = params.get('search')
    },
    mounted() {
        if (this.category) {
            this.searchUrl = `/obqvi/${this.category}?search=${this.search || ''}`
        } else {
            this.searchUrl = `/obqvi?search=${this.search || ''}`
        }
    },
    methods: {
        fetchOptions(search, loading) {
            if (this.category) {
                this.searchUrl = `/obqvi/${this.category}?search=${this.search || ''}`
            } else {
                this.searchUrl = `/obqvi?search=${this.search || ''}`
            }
            this.loading = true;
            axios
                .get(`/ads/products`, {
                    params: {
                        q: this.search,
                    },
                })
                .then(response => {
                    // console.log(response.data.products);
                    this.products = response.data.products
                    // loading(false)
                    this.showList = true;
                    this.loading = false;
                })
                .catch(errors => {
                    // loading(false)
                })
        },
        closeMe(){
            this.showList = false;
        },
        image(product) {
            if (!product.product_media[0]) return '/images/default/default.png'

            return `/storage/media/${moment(product.created_at).format('Y')}/${moment(
                product.created_at
            ).format('MM')}/${product.id}/${product.product_media[0].name}`
        },
        redirectTo(id) {
            location = '/' + id
        },
    },
}
</script>
