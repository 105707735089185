<template>
        <form class="form col-4 col-md-12 form-order" method="post" enctype="multipart/form-data" autocomplete="off" @submit.prevent="submit">
            <div class="form-order-close-m" @click="closeModal">
                <i class="icon icon-close-circle"></i>
            </div>
            <div id="formItem">
                <div ref="formItemOK" :class="product.user_profile ? 'content-sticky' : 'content-sticky content-no-top'">
                    <div class="product-single">
                        <div class="user-cart-m-header">
                        Поръчай с доставка
                    </div>
                        <p class="product-single-tiny product-for-sure">Всички полета обозначени със знак (<span class="color-red">*</span>) са задължителни.</p>
                    </div>
                    <div class="v-b-col v-b-form-group">
                        <label for="">Избери количество</label>
                        <div class="v-b-numbers-container">
                            <div class="v-b-btn v-b-less" @click="changeQuantity(false)">
                                <svg version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                 x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                                    <rect x="6.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 14.9688 1.776357e-15)" width="2" height="15"/>
                                </svg>
                            </div>
                            <div class="v-b-data">
                                <input type="number" name="quantity" min="1" :max="maxQuantity" v-model="receiver.quantity"  @change="changedQuantity()">
                            </div>
                            <div class="v-b-btn v-b-more" @click="changeQuantity(true)">
                            <svg version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                 x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                                <rect x="6.5" width="2" height="15"/>
                                <rect x="6.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 14.9688 1.776357e-15)" width="2" height="15"/>
                            </svg>
                            </div>
                        </div>
                        <div class="v-b-error" v-if="offer ? (offer.quantity > product.quantity) : false">
                            * В момента продукта има по-малко бройки, от колкото сте заявили. Свържете се с продавача или поръчайте останалите налични бройки.
                        </div>

                        <label for="" class="office-offers-label" v-if="offer">Поръчай:</label>
                        <div class="radio-stack no-select col-12 office-offers" v-if="offer">
                            <div class="md-radio-fieldset">
                                <label class="md-radio">
                                    <input type="radio" name="radio_offer" @click="changeOffer(0);"
                                    :checked="receiver.withOffer == true ? true : false">
                                    <i class="md-transition"></i>
                                    <span>С оферта ({{ offer.offer_price }}лв.)</span>
                                </label>
                                <label class="md-radio offices">
                                    <input type="radio" name="radio_offer" @click="changeOffer(1);" :checked="receiver.withOffer == true ? false : true">
                                    <i class="md-transition"></i>
                                    <span>Без оферта ({{ product.price }}лв.)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                     <div class="grid">
                        <div class="col-12 mt-3 mb-2">
                            <div class="v-b-form-title">
                                Данни за поръчка
                            </div>
                        </div>
                    </div>
                    <div class="grid" @change="canBuy = false">
                        <div class="col-12 mt-3 mb-2">
                            <div class="md-radio-fieldset">
                                <label class="md-radio">
                                    <input type="radio" name="isCompany" :checked="isCompany ? false : true" @click="isCompany = false;receiver.representative = ''">
                                    <i class="md-transition"></i>
                                    <span>Физическо лице</span>
                                </label>
                                <label class="md-radio offices">
                                    <input type="radio" name="isCompany" @click="isCompany = true;" :checked="!isCompany ? false : true">
                                    <i class="md-transition"></i>
                                    <span>Фирма</span>
                                </label>
                            </div>
                        </div>

                        <div class="col-12 grid">
                            <div
                                class="form-group col-12"
                                :class="errors && errors.name ? 'is-error' : ''"
                            >
                                <label v-if="!isCompany" for="name">Име и Фамилия<sub class="is-required">*</sub></label>
                                <label v-else for="name">Име на фирма<sub class="is-required">*</sub></label>
                                <div class="price-wrapper">
                                    <input id="name" @keyup="errors.name = null" type="text" v-model="receiver.name">
                                </div>
                                <form-error v-if="errors && errors.name">
                                    <template v-for="name in errors.name">{{ name }}</template>
                                </form-error>
                            </div>

                            <div
                                v-if="isCompany"
                                class="form-group col-12"
                                :class="errors && errors.representative ? 'is-error' : ''">
                                <label for="name">Име и фамилия на упълномощено лице<sub class="is-required">*</sub></label>
                                <div class="price-wrapper">
                                    <input id="mol" type="text" name="company" v-model="receiver.representative">
                                </div>
                                <form-error v-if="errors && errors.representative">
                                    <template v-for="representative in errors.representative">{{ representative }}</template>
                                </form-error>
                            </div>

                            <div class="form-group col-6 col-md-6 col-m-inside" :class="errors && errors.tel ? 'is-error' : ''">
                                <label for="tel">Телефон за връзка<sub class="is-required">*</sub></label>
                                <!-- <MazPhoneNumberInput
                                    v-model="tel"
                                    default-country-code="BG"
                                    size="sm"
                                    no-validator-state
                                    hint
                                    @update="updateTel"
                                    :translations="{
                                        countrySelectorLabel: 'Изберете държава',
                                        phoneNumberLabel: '',
                                        example: 'Пример :'
                                    }"
                                /> -->
                                <div class="price-wrapper form__tel-wrap">
                                    <input
                                        class="form__tel"
                                        id="tel"
                                        type="text"
                                        name="tel"
                                        v-model="receiver.tel"
                                        @keyup="errors.tel = null"
                                    >
                                </div>
                                <form-error v-if="errors && errors.tel">
                                    <template v-for="tel in errors.tel">{{ tel }}</template>
                                </form-error>
                            </div>

                            <div class="form-group col-6 col-md-6 col-m-inside" :class="errors && errors.email ? 'is-error' : ''">
                                <label for="email">Имейл<sub class="is-required">*</sub></label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <input type="text" id="email" v-model="receiver.email" @keyup="errors.email = null">
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left" data-text="На този имейл ще получиш информация за поръчката си." title="Email info"><i class="icon icon-info"></i></a>
                                    </div>
                                </div>
                                <form-error v-if="errors && errors.email">
                                    <template v-for="email in errors.email">{{ email }}</template>
                                </form-error>
                            </div>

                            <div class="form-group col-12" :class="errors && errors.comment ? 'is-error' : ''">
                                <label for="comment">Коментар по поръчка</label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <textarea v-model="receiver.comment" @keyup="errors.comment = null"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-2 col-12" @click="errors.city = null">
                                <infinite-scroll-select v-model="receiver.city"
                                                        :options="cities"
                                                        :filter="citiesFilter"
                                                        :errors="errors && errors.city"
                                                        option-label-name="name"
                                                        @input="setCity"
                                                        field-label="Населено място"
                                                        :required="true">
                                    <template v-slot:option="{option}">
                                        [{{ option.post_code}}] <b>{{ option.name }}</b>, <i>{{option.region_name}}</i>
                                    </template>
                                    <template v-slot:selected-option>
                                        [{{ receiver.post_code }}] <b>{{ receiver.city }}</b>,
                                        <i>{{ receiver.region_name }}</i>
                                    </template>
                                    <template v-slot:no-options>
                                        Няма такова населено място
                                    </template>
                                </infinite-scroll-select>
                            </div>

                            <div class="radio-stack no-select col-12 office-adress">
                                <div class="md-radio-fieldset">
                                    <label v-if="product.properties.deliveryTo.address" class="md-radio">
                                        <input type="radio" name="radio_some" @click="changeAddressType(0)"
                                        :checked="!receiver.is_office">
                                        <i class="md-transition"></i>
                                        <span>Адрес</span>
                                    </label>
                                    <label v-if="product.properties.deliveryTo.office" class="md-radio offices">
                                        <input type="radio" name="radio_some" @click="changeAddressType(1);receiver.priorityTime = false" :checked="receiver.is_office">
                                        <i class="md-transition"></i>
                                        <span>Офис на Еконт</span>
                                    </label>
                                </div>
                            </div>
                           <div v-if="product.properties.priorityTime" class="form-group col-12 col-md-12 exact-hour">
                                <div class="no-select">
                                    <label class='md-checkbox' title="Избери час, в който куриерът да достави пратката на посочен адрес.">
                                        <input :disabled="receiver.is_office == 1" v-model="receiver.priorityTime" type='checkbox'>
                                        <span class='md-transition'></span><em>Точен час за получаване на пратка</em>
                                    </label>
                                </div>

                                <div class="pickup--hours">
                                    <div class="pickup-cell">
                                        <div class="select-wrapper choose-between-select">
                                            <select v-model="receiver.timeInterval" :disabled="!receiver.priorityTime">
                                                <option value="between">между</option>
                                                <option value="in">в</option>
                                            </select>
                                            <div class="custom-select-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pickup-cell">
                                        <div class="select-wrapper select-wrapper-no-bullet">
                                            <vue-timepicker :disabled="!receiver.priorityTime" :hour-range="[[8, 22]]" v-model="receiver.startTime" hide-disabled-hours :minute-interval="10" placeholder="Изберете" hide-clear-button lazy></vue-timepicker>
                                        </div>
                                    </div>
                                    <div class="pickup-cell" v-if="receiver.timeInterval == 'between'">
                                        <div class="select-wrapper select-wrapper-no-bullet">
                                            <vue-timepicker :disabled="!receiver.priorityTime" :hour-range="[[8, 22]]" v-model="receiver.endTime" hide-disabled-hours
                                            :minute-interval="10" placeholder="Изберете" hide-clear-button lazy></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-else class="form-group col-6 col-md-12">

                            </div> -->
                            <div v-if="!receiver.is_office" class="form-group col-12 col-md-12 custom-order-adress" :class="errors && errors.full_address ? 'is-error' : ''">
                                <label for="address">Адрес<sub class="is-required">*</sub></label>
                                <input id="address" type="text" v-model="receiver.full_address" @input="isBuyable = false" @keyup="errors.full_address = null">
                                <form-error v-if="errors && errors.full_address">
                                    <template v-for="full_address in errors.full_address">{{ full_address }}</template>
                                </form-error>
                            </div>

                            <div v-if="receiver.is_office" class="form-group col-12 col-md-12 custom-order-adress" @click="errors.full_address = null">
                                <label for="office">Офис на Еконт<sub class="is-required">*</sub></label>
                                <div class="toggle-field">
                                    <infinite-scroll-select v-model="receiver.full_address"
                                                            :options="offices"
                                                            :filter="officesFilter"
                                                            :errors="errors && errors.full_address"
                                                            option-label-name="full_address"
                                                            @input="setFullAddress"
                                                            class="toggle-field-left">
                                        <template v-slot:option="{option}">
                                            <b>{{ option.name }}</b> <span>{{ option.full_address }}</span>
                                        </template>
                                        <template v-slot:selected-option>
                                            {{ receiver.full_address }}
                                        </template>
                                        <template v-slot:no-options>
                                            Няма такъв офис на Еконт
                                        </template>
                                        <template v-slot:after>
                                        </template>
                                    </infinite-scroll-select>
                                    <div class="toggle-field-right office-locator-button" style="margin-top: 10px">
                                        <office-locator-button @office-choice="onLocatorOfficeChange"
                                                               :city="receiver.city"
                                                               :address="receiver.full_address">
                                        </office-locator-button>
                                    </div>
                                </div>
                                <form-error v-if="errors && errors.full_address">
                                    <template v-for="full_address in errors.full_address">{{ full_address }}</template>
                                </form-error>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-in-order">
                        <div class="col-12 col-info" @change="canBuy = false">
                            <div class="summary-noafter mt-3" v-if="!receiver.is_sender_paying && receiver.delivery_price">
                                <table>
                                    <tbody>
                                        <tr v-if="receiver.delivery_price">
                                            <td class="summary-detail">Цена на доставка:</td>
                                            <td>{{ receiver.delivery_price }}лв.</td>
                                        </tr>
                                        <tr v-if="receiver.delivery_price">
                                            <td class="summary-detail">Наложен платеж:</td>
                                            <td>{{ receiver.price }}лв.</td>
                                        </tr>
                                        <tr class="summary-price">
                                            <td class="summary-detail">Общо за плащане:</td>
                                            <td>{{ finalPrice }}лв.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 col-info" v-if="receiver.is_sender_paying">
                            <div class="summary-noafter mt-3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="summary-detail">Доставката е за сметка на продавача</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="form-group form-group-radio col-12" :class="errors && errors.payment_method ? 'is-error text-error' : ''" @change="canBuy = false">
                            <label for="">
                                Начин на плащане<sub class="is-required">*</sub>
                            </label>

                            <div class="radio-stack no-select col-6 col-md-12 payment-radios">
                                <div class="md-radio-fieldset toggle-field toggle-field-payment">
                                    <div class="toggle-field-left">
                                        <label class="md-radio" v-if="product.properties.cdAmount">
                                            <input type="radio" name="payment_method" @click="receiver.payOnline = false; receiver.payment_method = true;" :checked="!product.properties.payOnline ? true : false">
                                            <i class="md-transition" :class="errors && errors.payment_method ? 'is-error border-error' : ''"></i>
                                            <div class="payment-data-container">
                                                    <svg version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                                        x="0px" y="0px" viewBox="0 0 54.1 27" style="overflow:auto;enable-background:new 0 0 54.1 27;"
                                                        xml:space="preserve">
                                                    <g transform="translate(28.128 581.907)">
                                                        <g>
                                                            <path d="M-27.6-558.2c-0.1,1.5,1,2.7,2.4,2.8H23c1.5-0.1,2.5-1.4,2.4-2.8v-20.3
                                                                c0.1-1.5-1-2.7-2.4-2.8h-48.2c-1.5,0.1-2.5,1.4-2.4,2.8V-558.2z"/>
                                                            <path d="M25.4-571.4c-4.6,0-9-4.9-9-10"/>
                                                            <path d="M-7.7-581.4c-4.5,2.6-7.4,7.4-7.4,12.6c0,5.4,3.9,11,9,13.4"/>
                                                            <path d="M4.7-581.4c4.5,2.6,7.4,7.4,7.4,12.6c0,5.4-3.9,11-9,13.4"/>
                                                            <path d="M-0.8-561.4v-14l-4,3.5"/>
                                                            <path d="M25.4-565.4c-4.6,0-9,4.9-9,10"/>
                                                            <path d="M-27.6-571.4c4.6,0,9-4.9,9-10"/>
                                                            <path d="M-27.6-565.4c4.6,0,9,4.9,9,10"/>
                                                        </g>
                                                    </g>
                                                    </svg>
                                                <span>Наложен платеж</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="radio-stack no-select col-6 col-md-12 payment-radios" v-if="product.properties.payOnline">
                                <div class="md-radio-fieldset toggle-field toggle-field-payment">
                                    <div class="toggle-field-left form__payonline">
                                        <label class="md-radio offices"  v-if="product.properties.payOnline">
                                            <input type="radio" name="payment_method" @click="receiver.payOnline = true; receiver.payment_method = true;" :checked="!product.properties.cdAmount ? true : false">
                                            <i class="md-transition" :class="errors && errors.payment_method ? 'is-error border-error' : ''"></i>
                                            <img src="/images/GOE_logo.png" alt="" class='goe_logo'>
                                            <img src="/images/Visa.svg" alt="" class='goe_sub_logo'>
                                            <img src="/images/Mastercard.svg" alt="" class='goe_sub_logo'>
                                        </label>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                        data-text="Плащане с банкова карта, при което EcontPay резервира сума, равна на стойността на поръчаната стока + сумата за доставка. Когато приемете пратката, общата сума се тегли от сметката Ви. Ако откажете пратката, сумата за поръчаната стока се възстановява по сметката Ви. Приспадат се само куриерските услуги за доставка и връщане."
                                        title="Email info"><i class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-buttons">
                            <div class="pay-buttons-container">
                                <div class="form-checkbox-container">
                                    <label class="md-checkbox">
                                        <input type="checkbox" v-model="receiver.privacy" id="terms">
                                        <span class="md-transition"></span>
                                        <em><label for="terms"><a href="./home/terms" target="_blank">Съгласен съм с Условията за ползване на услугите на Еконт</a></label></em>
                                    </label>
                                    <form-error v-if="errors && errors.privacy">
                                        <template v-for="privacy in errors.privacy">{{ privacy }}</template>
                                    </form-error>
                                </div>

                                <div class="form__button-buy">
                                    <template v-if="!canBuy">
                                        <div class="text-right" v-if="!receiver.is_sender_paying">
                                            <button @click="getDeliveryPrice" type="button" href="#" :disabled="priceButtonStatus" class="button">Изчисли доставка</button>
                                        </div>
                                        <div class="text-right" v-else>
                                            <p class="userMessage"></p>
                                            <button :disabled="!receiver.privacy || loader"
                                                    type="submit" class="button">Купи</button>
                                        </div>
                                    </template>
                                    <div class="summary-button buy-button-check" v-if="canBuy">
                                            <button :disabled="!isBuyable || !receiver.privacy || loader"
                                                type="submit" class="button">Купи</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
</template>

<script>

import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import FormError from '../../components/FormError.vue'
import moment from 'moment'
import ContactModal from './ContactModal'
import OfficeLocatorButton from "../../components/OfficeLocatorButton";
import InfiniteScrollSelect from "../../components/InfiniteScrollSelect.vue";

export default {
    props: {
        product: Object,
        cities: Array,
        additional: Array,
        // user: Object,
        breadcrumb: Array,
        // profile:Object,
        address:Object,
        offer:Object,
    },
    components: {
        InfiniteScrollSelect,
        FormError,
        VueTimepicker,
        ContactModal,
        OfficeLocatorButton
    },
    data() {
            return {
                errors: [],
                receiver: {
                    product_id: this.product.id,
                    is_office: 0,
                    is_sender_paying: this.product.is_sender_paying,
                    price: this.offer ? this.offer.offer_price*(this.offer.quantity > this.product.quantity ? this.product.quantity : this.offer.quantity) : this.product.price,
                    full_address: null,
                    representative: null,
                    delivery_price: null,
                    name: null,
                    priorityTime: false,
                    startTime: {"HH": "08", "mm": "00"},
                    endTime: {"HH": "09", "mm": "00"},
                    timeInterval: 'between',
                    buyed_from: 0,
                    payOnline: false,
                    payment_method: undefined,
                    quantity: this.offer ? (this.offer.quantity > this.product.quantity ? this.product.quantity : this.offer.quantity) : 1,
                    withOffer: this.offer ? true : false,
                },
                curr_offer: this.offer,
                isBuyable: false,
                offices: [],
                priceButtonStatus: false,
                contact:false,
                flag:true,
                additionalProducts:[...this.additional],
                // setUser: this.user,
                loader: false,
                url: '/order',
                showForm: false,
                canBuy:false,
                isCompany: false,
                tel: '',
                user_product: this.product,
                maxQuantity: this.offer ? (this.offer.quantity > this.product.quantity ? this.product.quantity : this.offer.quantity) : this.product.quantity,
            }
    },
    created() {
        if(this.product.properties.payOnline){
            this.receiver.payOnline = true;
        }
        if (!this.product.properties.deliveryTo.address) {
            this.receiver.is_office = 1
        }
        this.setPropsData();
        this.checkPayments();
    },
    computed: {
        validTo() {
            return moment(this.product.valid_to).format('DD.MM.Y')
        },
        finalPrice() {
            let price = this.receiver.price + this.receiver.delivery_price
            return price.toFixed(2)
        }
    },
    methods: {
        changeOffer(flag){
            if(flag){
                //Regular order
                this.maxQuantity = this.user_product.quantity;
                this.receiver.quantity = 1;
                this.receiver.price = this.user_product.price * this.receiver.quantity;
                this.receiver.withOffer = false;
            }else{
                //Order with offer
                this.maxQuantity = this.curr_offer.quantity;
                this.receiver.quantity = this.curr_offer.quantity > this.user_product.quantity ? this.user_product.quantity : this.curr_offer.quantity;
                this.receiver.price = this.curr_offer.offer_price * this.receiver.quantity;
                this.receiver.withOffer = true;
            }
            this.canBuy = false;
            this.receiver.delivery_price = false;
        },
        checkPayments(){
            if(!this.product.properties.payOnline && this.product.properties.cdAmount){
                this.receiver.payOnline = false;
                this.receiver.payment_method = true;
            }else if(this.product.properties.payOnline && !this.product.properties.cdAmount){
                this.receiver.payOnline = true;
                this.receiver.payment_method = true;
            }
        },
        setPropsData(){
                if(this.address){
                    if(this.address.representative){
                        this.receiver.name = this.address.name;
                        this.receiver.representative = this.address.representative;
                        this.isCompany = true;
                    }else{
                        this.receiver.name = this.address.name;
                    }

                    this.receiver.tel = this.address.tel;
                    this.receiver.full_address = this.address.full_address;
                    this.receiver.email = this.address.email;
                    this.receiver.post_code = this.address.city.post_code
                    this.receiver.city = this.address.city.name
                    this.receiver.city_id = this.address.city.id
                    this.receiver.region_name = this.address.city.region_name
                }
        },
        updateTel(event) {
            if(event.e164) {
                this.receiver.tel = event.e164
            }
        },
        acceptNumber() {
            var x = this.receiver.tel.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
            this.receiver.tel = !x[2] ? x[1] : x[1] + ' ' + x[2] +  (x[3] ? ' ' + x[3] : '');
        },
        handleShowForm(){
            this.showForm = true
            // this.showForm = !this.showForm
            if(window.innerWidth < 650){
                let target = document.querySelector("#formItem");
                if(target){
                    target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                }else{
                    document.querySelector("#formItem").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                }
            }
        },
        getDate(date){
            return moment(date).format('DD.MM.Y');
        },
        changeQuantity(flag){
            this.receiver.quantity = parseInt(this.receiver.quantity)

            if(this.curr_offer && this.receiver.withOffer){
                this.receiver.quantity = this.maxQuantity
            }else{
                if(flag){
                    if(this.receiver.quantity + 1 <= this.maxQuantity){
                     this.receiver.quantity =  this.receiver.quantity + 1;
                    }
                }else{
                    if(this.receiver.quantity - 1 > 0){
                     this.receiver.quantity =  this.receiver.quantity - 1;
                    }
                }
                this.changedQuantity();
            }
        },
        changedQuantity(){
            if(this.curr_offer && this.receiver.withOffer){
                this.receiver.quantity = this.maxQuantity
            }else{
                if(this.receiver.quantity > this.maxQuantity){
                    this.notifyError('Избраното от теб количество не е налично.');
                    this.receiver.quantity = this.maxQuantity
                }
                this.canBuy = false;
                this.receiver.delivery_price = false;
                if(this.curr_offer && this.receiver.withOffer){
                    this.receiver.price = this.offer.offer_price * this.receiver.quantity;
                }else{
                    this.receiver.price = this.product.price * this.receiver.quantity;
                }
            }
        },
        showContactModal(){
            this.contact = true;
        },
        mediaPath(name) {
            return '/storage/media/' + moment(this.product.created_at).format('Y') + '/' + moment(this.product.created_at).format('MM') + '/' + this.product.id + '/' + name
        },
        submit() {
            const query = window.location.href;
            const url = new URL(query);
            const mediaParam = url.searchParams.get("media");
            // TODO check if in_array / array.includes(mediaParam) / array = [0,1,2,3,4,5,6,7];
            if(mediaParam) {
                this.receiver.buyed_from = mediaParam
            }

            this.loader = true;
            if(this.receiver.payOnline){
                this.url = '/order-request';
            }

            this.$snotify.async('Изпраща се поръчката', 'Success async', () => new Promise((resolve, reject) => {
                axios.post(this.url, this.receiver)
                .then((response) => {
                    if (response?.data?.success) {
                        gtag('event', 'purchase', {
                            currency: 'BGN',
                            transaction_id: this?.receiver?.payOnline
                                ? 'OR-' + response.data?.order_request_id
                                : response.data?.order_id,
                            value: this?.finalPrice,
                            shipping: this?.receiver?.delivery_price,
                            items: [
                                {
                                    item_id: this?.product?.id,
                                    item_name: this?.product?.title,
                                    item_category: this?.product?.category?.name,
                                    quantity: this?.receiver?.quantity,
                                    price: this?.receiver?.withOffer ? this?.offer?.price ?? this?.product?.price : this?.product?.price,
                                    location_id: this?.product?.product_address?.city,
                                }
                            ]
                        });

                        window.location = response.data.redirect
                        resolve({
                            title: 'Success',
                            body: response.message,
                            config: {
                                timeout: 10000,
                                closeOnClick: true
                            }
                        })
                    } else {
                        reject({
                            title: 'Error',
                            body: response.data.error,
                            config: {
                                timeout: 0,
                                closeOnClick: true
                            }
                        })
                        this.loader = false;
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                    this.notifyError('Възникна грешка - заредете страницата отново');
                    this.loader = false
                })
            }));
        },
        getDeliveryPrice() {
            this.priceButtonStatus = true
            this.$snotify.async('Цената за доставка се изчислява', 'Success async', () => new Promise((resolve, reject) => {
                axios.post(`/shipment/get-delivery-price`, this.receiver)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            this.receiver.delivery_price = response.data.price
                            this.isBuyable = true
                            resolve({
                                title: 'Success',
                                body: 'Цената за доставка е изчислена',
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            })
                            this.priceButtonStatus = false
                            this.canBuy = true;
                        } else {
                            this.priceButtonStatus = false
                            reject({
                                title: 'Error',
                                body: response.data.error,
                                config: {
                                    timeout: 10000,
                                    closeOnClick: true,
                                    pauseOnHover: true
                                }
                            })

                        }
                    })
                    .catch((errors) => {
                        this.$snotify.clear()
                        this.$snotify.error("Невалидна информация",
                            "Error",{timeout: 10000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                        );

                        this.priceButtonStatus = false
                        this.errors = errors.response.data.errors
                    })
            }));
        },
        setCity(city) {
            this.receiver.post_code = city.post_code
            this.receiver.city = city.name
            this.receiver.city_id = city.id
            this.receiver.region_name = city.region_name

            if (this.receiver.is_office) {
                this.receiver.full_address = ''
                this.getOfficesById();
            }

            this.isBuyable = false
        },
        getOfficesById() {
            axios.get(`/office/get`, {
                params: {
                    city_id: this.receiver.city_id,
                }
            })
            .then((response) => {
                this.offices = response.data.offices;
                if(!this.offices.length) {
                    this.notifyError('В избрания град няма офис на Еконт')
                }
            });
        },
        changeAddressType(value) {
            this.receiver.is_office = value
            this.receiver.full_address = '';

            this.isBuyable = false
            if(value){
                this.getOfficesById();
            }
        },
        setFullAddress(office) {
            this.receiver.full_address = office.full_address
            this.receiver.office_code = office.code
            this.receiver.office_id = office.id

            this.isBuyable = false

        },
        onLocatorOfficeChange(office) {
            if (typeof office?.address !== 'object') {
                return;
            }
            let localCityObj = this.cities.find((val) => {
                return val.city_id === office.address.city.id;
            });
            this.setCity(localCityObj);
            let localOfficeObj = this.offices.find((val) => {
                return val.code === office.code;
            });
            this.setFullAddress(localOfficeObj);
        },
        closeModal(){
            document.querySelector('.form-order').classList.remove('form-order--active');
            document.querySelector('.wrapper-product-preview').classList.remove('wrapper-product-preview--active');
            document.querySelector('body').classList.remove('freeze');
        },
        citiesFilter(city, search) {
            return city.name.toLowerCase().includes(search.toLowerCase());
        },
        officesFilter(office, search) {
            let searchLc = search.toLowerCase();

            return office.name.toLowerCase().includes(searchLc) ||
                office.full_address.toLowerCase().includes(searchLc);
        },
    },
    mounted() {
        gtag('event', 'view_item', {
            value: this?.product?.price,
            currency: 'BGN',
            items: [
                {
                    item_id: this?.product?.id,
                    item_name: this?.product?.title,
                    item_category: this?.product?.category?.name,
                    quantity: 1,
                    price: this?.product?.price,
                    location_id: this?.product?.product_address?.city,
                }
            ]
        });
    }
}
</script>

<style scoped>
    .v-b-form-title{
        font-size:13px;
        font-weight: 600;
        margin-top:3px;
    }
    .office-offers-label{
        margin-top: 15px;
    }
    .goe_sub_logo{
        max-width:40px;
        margin-left: 10px;
    }
    @media only screen and (max-width:768px){
        .goe_logo{
            width:140px;
        }
    }
</style>
