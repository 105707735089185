<script>
export default {
    data() {
        return {
            showModal: false
        }
    },
    watch: {
        showModal: (val) => {
            if (!val) {
                document.querySelector('.modal-overlay').remove();
            }
        },
    }
}
</script>
