<template>
      <div class="choises">
          <div class="links links-no-border" v-if="!choise">
              <section class="section-table-container">
                  <div class="section-table section-table-info">
                      <div class="info-header">
                          Избери, каква е операционната система, която използваш?<br>
                          (има значение за настройките на <span>.CSV файла</span>)
                      </div>
                      <div class="grid grid-double">
                          <div class="grid-item" @click="setChoise('windows')">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="100" width="100" viewBox="0 0 88 88">
                              <path style="fill:#1f70b2;" d="m0,12.402,35.687-4.8602,0.0156,34.423-35.67,0.20313zm35.67,33.529,0.0277,34.453-35.67-4.9041-0.002-29.78zm4.3261-39.025,47.318-6.906,0,41.527-47.318,0.37565zm47.329,39.349-0.0111,41.34-47.318-6.6784-0.0663-34.739z"/>
                              </svg>
                              <div class="grid-text">
                                  Windows
                              </div>
                          </div>
                          <div class="grid-item" @click="setChoise('mac')">
                              <!-- <img src="/images/apple.svg" alt=""> -->
                              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 842 1e3"><path d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z"/></svg>
                              <div class="grid-text">
                                  Mac OS
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>

          <div class="links links-no-border" v-else>
              <section-info-component :curr_choise="choise" :temp_cats="categories"></section-info-component>
          </div>
      </div>
</template>

<script>
import SectionInfoComponent from './SectionInfoComponent.vue'
export default {
  components: {SectionInfoComponent},
  props: ['temp_cats'],
  data() {
    return {
      choise:false,
      category_slug : '',
      categories: this.temp_cats
    }
  },
  created(){
    this.getCat();
    console.log(this.categories)
  },
  methods: {
    getCat(){
      this.categories.filter((item)=>{
        if(item.name == 'Електроника'){
          this.category_slug = `https://${window.location.host}/ads/${item.slug}`;
        }
      })
    },
    setChoise(data){
      this.choise = data;
    },
  },
}
</script>
<style scoped>
  .s-t-list-item > div{
    display:flex;
    flex-direction: row;
  }
  .s-t-list-item >div.s-t-list-inner{
    flex-direction: column;
  }
  .s-t-list-table .s-t-list-item > div{
    display:table-cell;
  }
  .imp-list-padding .s-t-l-i-content{
    padding-top: 1rem;
  }
  .s-t-list-item .circle{
    margin-top:0 !important;
  }
  .grid-item svg{
      margin-bottom:2rem;
  }
  @media only screen and (max-width:1000px){
      .grid.grid-double{
          flex-direction:column;
      }
      .grid.grid-double > *{
          margin-left: auto !important;
          margin-right:auto !important;
          padding-left:0 !important;
          padding-right:0 !important;
      }
      .grid-double .grid-item:first-child:after{
          display:none;
      }
  }
</style>
