<template>
    <div class="offer-container">
        <div class="modal-btn button" @click="openModal();">
            Предложи цена
        </div>
        <div :class="activeModal ? 'modal-contact-container modal-contact-container-active' : 'modal-contact-container'">
            <div :class="activeModal ? `modal modal-delete modal-contact modal-active` : 'modal modal-delete modal-contact'" data-id="contact">
                <h3>
                    <span>Предложи цена</span>
                    <div class="close-modal" @click="closeModal" id="closeSend">
                        <i class="icon icon-close-circle"></i>
                    </div>
                </h3>
                <p>Тук можеш да предложиш към продавача ценова оферта за закупуването на един или повече продукта.</p>
                <form class="form" @submit.prevent="submit">
                    <div class="grid">
                        <div class="col-12 delimiter-text">
                            <p>Моля, посочи колко бройки от продукта желаеш да закупиш</p>
                        </div>
                        <div class="col-12">
                            <div class="form-group" :class="errors && errors.quantity ? 'is-error' : ''">
                                <div class="price-wrapper">
                                    <div class="v-b-numbers-container">
                                        <div class="v-b-btn v-b-less" @click="changeQuantity(false)">
                                            <svg version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                             x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                                                <rect x="6.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 14.9688 1.776357e-15)" width="2" height="15"/>
                                            </svg>
                                        </div>
                                        <div class="v-b-data">
                                            <input type="number" name="quantity" min="1" :max="maxQuantity" v-model="chosenQuantity"  @change="changedQuantity()">
                                        </div>
                                        <div class="v-b-btn v-b-more" @click="changeQuantity(true)">
                                        <svg version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                             x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                                            <rect x="6.5" width="2" height="15"/>
                                            <rect x="6.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 14.9688 1.776357e-15)" width="2" height="15"/>
                                        </svg>
                                        </div>
                                    </div>
                                    <form-error v-if="errors && errors.quantity">
                                        <template v-for="quantity in errors.quantity">{{ quantity }}</template>
                                    </form-error>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 delimiter-text">
                            <p>Моля, предложи цена за един брой продукт</p>
                        </div>
                        <div class="col-12">
                            <div class="form-group" :class="errors && errors.price ? 'is-error' : ''">
                                <div class="price-wrapper">
                                    <!-- <input type="text" name="tel" v-model="addr.tel" tabindex="4"> -->
                                    <input ref="startModal" type="text" name="price" tabindex="2" v-model="choosenPrice" @change="inputChange">
                                    <!-- :class="data.message.price!=null ? 'dissabledSelect' : ''"> -->
                                    <form-error v-if="errors && errors.price">
                                        <template v-for="price in errors.price">{{ price }}</template>
                                    </form-error>
                                </div>
                            </div>
                        </div>


                        <div class="col-12">
                            <button class="button" type="submit" id="submitSend">Изпрати</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-overlay modal-overlay-active" @click="closeModal"></div>
        </div>
    </div>
</template>

<script>

export default {
  props: ['product','profile'],
  data() {
    return {
        errors:[],
        curr_product: this.product,
        curr_profile : this.profile,
        activeModal:false,
        requestQuantity:1,
        choosenPrice:this.product.price,
        maxQuantity:this.product.quantity,
        chosenQuantity:1,
    }
  },
  created(){
    // console.log(this.curr_profile)
    // console.log(this.curr_product)
  },
  methods: {
    changeQuantity(flag){
        this.chosenQuantity = parseInt(this.chosenQuantity)
        // console.log(this.chosenQuantity)
        if(flag){
            if(this.chosenQuantity + 1 <= this.maxQuantity){
             this.chosenQuantity =  this.chosenQuantity + 1;
            }
        }else{
            if(this.chosenQuantity - 1 > 0){
             this.chosenQuantity =  this.chosenQuantity - 1;
            }
        }
        this.changedQuantity();
    },
     changedQuantity(){
        if(this.chosenQuantity > this.maxQuantity){
            this.notifyError('Избраното от теб количество не е налично.');
            this.chosenQuantity = this.maxQuantity
        }
        // this.receiver.price = this.product.price * this.chosenQuantity;
    },
    openModal(){
        this.activeModal = true;
        document.querySelector('body').classList.add('freeze');
    },
    inputChange(event){
        let val = event.target.value;
        if(val.includes(',')){
            this.$refs.startModal.value = val.replace(',','.');
            this.choosenPrice = val.replace(',','.');
        }
    },
    focusInput(){
         this.$refs.startModal.focus();
    },
     submit() {
        if(parseFloat(this.choosenPrice).toFixed(2) !== 'NaN'){
            this.choosenPrice = parseFloat(this.choosenPrice).toFixed(2)
            let data = new FormData();
            data.append("price", this.choosenPrice);
            data.append("quantity", this.chosenQuantity);
            data.append('product_id', this.curr_product.id);
            data.append('sender_id', this.curr_profile.id);
            axios.post(`offer/store`, data)
                .then((response) => {
                    if (response.data.type == 'success') {
                       this.closeModal();
                       this.notifySuccess(response.data.message,{
                           closeOnClick: true
                       });
                    }
                })
                .catch((errors) => {
                    // this.errors = errors.response.data.errors
                })
        }else{
            this.choosenPrice = this.product.price;
            this.notifyError('Невалидна стойност за цена, моля въведете само цифри.');
        }
    },
    closeModal(){
        this.activeModal = false;
        // document.querySelector('.modal-delete.modal-contact').classList.remove('modal-active');
        // document.querySelector('.modal-contact-container').classList.remove('modal-contact-container-active');
        document.querySelector('body').classList.remove('freeze');
    },
  },
  mounted() {
    this.focusInput();
  },
}
</script>
<style type="text/css" scoped>
    .offer-container{
        margin-left: auto;
    }
    /*.offer-container .modal-btn{
        padding: 15px;
        background-color: #913f98;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top:0px;
        display: block;
        width:20rem;
        cursor: pointer;
    }*/
    @media only screen and (max-width:1000px){
        .offer-container{
            margin-left: 0;
        }
    }
    @media only screen and (max-width:500px){
        body .offer-container{
            margin-left: -20px !important;
            margin-top: 15px;
        }
    }
</style>
