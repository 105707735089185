<template>
    <section class="vb-modal-container v-modal-filters">
        <div class="vb-modal">
            <div class="modal-header">
                <div class="m-h-title">
                    Филтри
                </div>
                <div class="m-h-close" @click="close()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.264 29.264">
                      <g transform="translate(1.132 1.132)">
                        <g>
                          <path d="M9674.438,1010.545a13.5,13.5,0,1,1,0-19.091A13.5,13.5,0,0,1,9674.438,1010.545Z" transform="translate(-9651.391 -987.5)" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          <g transform="translate(8.583 8.582)">
                            <line y1="9.833" x2="9.832" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                            <line x2="9.837" y2="9.835" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                </div>
            </div>
            <div class="modal-list">
                <div class="m-l-item m-l-item--input">
                    <div class="m-l-i-header">
                        <div class="m-l-i-h-icon">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 440.2 437.3" xml:space="preserve">
                            <path d="M194,0C86.9,0,0,86.9,0,194s86.9,194,194,194s194-86.9,194-194S301.1,0,194,0z M193,359
                                c-91.7,0-166-74.3-166-166S101.3,27,193,27s166,74.3,166,166S284.7,359,193,359z"/>
                            <rect x="362.6" y="308.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.2685 378.8583)" width="34.2" height="136.9"/>
                            </svg>
                        </div>
                        <div class="m-l-i-h-input">
                            <input type="text" name="search" placeholder="Търси продукт..." v-model="currentSearch">
                        </div>
                    </div>
                </div>

                <div class="m-l-item" ref="categories">
                    <div class="m-l-i-header">
                        <div class="m-l-i-h-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.708 16.033">
                              <path d="M5816,690.032a3.238,3.238,0,1,1,3.237-3.24A3.238,3.238,0,0,1,5816,690.032Zm0-5.476a2.238,2.238,0,1,0,2.236,2.236,2.237,2.237,0,0,0-2.236-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <path d="M5826.228,699.589a3.237,3.237,0,1,1,3.241-3.239,3.237,3.237,0,0,1-3.241,3.239Zm0-5.475a2.237,2.237,0,1,0,2.241,2.236,2.237,2.237,0,0,0-2.241-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <path d="M5816.021,699.589a3.237,3.237,0,1,1,3.236-3.239,3.237,3.237,0,0,1-3.236,3.239Zm0-5.475a2.237,2.237,0,1,0,2.235,2.236,2.237,2.237,0,0,0-2.235-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <g transform="translate(10.339 0.063)">
                                <path d="M5826.265,689.943a.5.5,0,0,1-.5-.5v-5.324a.5.5,0,0,1,1,0v5.324A.5.5,0,0,1,5826.265,689.943Z" transform="translate(-5823.1 -683.619)"/>
                                <path d="M5828.927,687.281H5823.6a.5.5,0,0,1,0-1h5.326a.5.5,0,0,1,0,1Z" transform="translate(-5823.1 -683.619)"/>
                              </g>
                            </svg>
                        </div>
                        <div class="m-i-h-label">
                            Категории {{ this.currentCategory ? `: ${this.currentCategory.name}` : '' }}
                        </div>
                        <div class="m-i-h-drop">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407">
                              <path id="Path_2952" data-name="Path 2952" d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="m-l-i-content">
                        <div class="m-l-c-item" @click="changeCategory(false)">
                            <div class="item-label">Всички</div>
                        </div>
                        <template v-for="category in categories">
                            <div class="m-l-c-item">
                                <div class="item-label" @click="changeCategory(category)">{{ category.name }}</div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="m-l-item" ref="sections">
                    <div class="m-l-i-header">
                        <div class="m-l-i-h-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.708 16.033">
                              <path d="M5816,690.032a3.238,3.238,0,1,1,3.237-3.24A3.238,3.238,0,0,1,5816,690.032Zm0-5.476a2.238,2.238,0,1,0,2.236,2.236,2.237,2.237,0,0,0-2.236-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <path d="M5826.228,699.589a3.237,3.237,0,1,1,3.241-3.239,3.237,3.237,0,0,1-3.241,3.239Zm0-5.475a2.237,2.237,0,1,0,2.241,2.236,2.237,2.237,0,0,0-2.241-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <path d="M5816.021,699.589a3.237,3.237,0,1,1,3.236-3.239,3.237,3.237,0,0,1-3.236,3.239Zm0-5.475a2.237,2.237,0,1,0,2.235,2.236,2.237,2.237,0,0,0-2.235-2.236Z" transform="translate(-5812.761 -683.556)"/>
                              <g transform="translate(10.339 0.063)">
                                <path d="M5826.265,689.943a.5.5,0,0,1-.5-.5v-5.324a.5.5,0,0,1,1,0v5.324A.5.5,0,0,1,5826.265,689.943Z" transform="translate(-5823.1 -683.619)"/>
                                <path d="M5828.927,687.281H5823.6a.5.5,0,0,1,0-1h5.326a.5.5,0,0,1,0,1Z" transform="translate(-5823.1 -683.619)"/>
                              </g>
                            </svg>
                        </div>
                        <div class="m-i-h-label">
                            Секции {{ this.currentSection ? `: ${this.currentSection.title}` : '' }}
                        </div>
                        <div class="m-i-h-drop">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407">
                              <path id="Path_2952" data-name="Path 2952" d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="m-l-i-content">
                        <div class="m-l-c-item" @click="changeSection(false)">
                            <div class="item-label">Всички</div>
                        </div>
                        <template v-for="shop in shops">
                            <div class="m-l-c-item" @click="changeSection(shop)">
                                <div class="item-label">{{ shop.title }}</div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="m-l-item" ref="statuses">
                    <div class="m-l-i-header">
                        <div class="m-l-i-h-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.987 18.206">
                              <g transform="translate(0.1 0.1)">
                                <path d="M6337.52,708.34h-11.977a1.44,1.44,0,0,1-1.406-1.469v-.279a1.483,1.483,0,0,1,.893-1.375c-.009-1.668.16-7.473,2.806-10.348a4.984,4.984,0,0,1,7.389,0c2.648,2.875,2.816,8.683,2.809,10.349a1.481,1.481,0,0,1,.891,1.375v.279A1.438,1.438,0,0,1,6337.52,708.34Zm-5.988-14.051a3.837,3.837,0,0,0-2.963,1.313c-2.748,2.982-2.522,9.953-2.52,10.023a.54.54,0,0,1-.141.388.5.5,0,0,1-.366.162.414.414,0,0,0-.393.417v.279a.4.4,0,0,0,.393.411h11.977a.407.407,0,0,0,.392-.411v-.279a.409.409,0,0,0-.392-.417.5.5,0,0,1-.365-.162.546.546,0,0,1-.141-.388c0-.07.228-7.042-2.519-10.023a3.842,3.842,0,0,0-2.963-1.313Z" transform="translate(-6324.137 -691.895)" stroke-width="0.2"/>
                                <path d="M6331.667,709.188a2.107,2.107,0,0,1-2.107-2.107.555.555,0,0,1,1.109,0,1,1,0,0,0,2,0,.554.554,0,0,1,1.108,0A2.113,2.113,0,0,1,6331.667,709.188Z" transform="translate(-6324.251 -691.182)" stroke-width="0.2"/>
                                <path d="M6331.514,694.34a.555.555,0,0,1-.555-.555v-1.2a.555.555,0,0,1,1.109,0v1.2A.555.555,0,0,1,6331.514,694.34Z" transform="translate(-6324.097 -692.026)" stroke-width="0.2"/>
                              </g>
                            </svg>
                        </div>
                        <div class="m-i-h-label">
                            Статус {{ this.currentStatus ? `: ${this.currentStatus}` : '' }}
                        </div>
                        <div class="m-i-h-drop">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407">
                              <path id="Path_2952" data-name="Path 2952" d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="m-l-i-content">
                        <div class="m-l-c-item" @click="changeStatus(false)">
                            <div class="item-role role-purple"></div>
                            <div class="item-label">Всички</div>
                        </div>
                        <div class="m-l-c-item" @click="changeStatus('Активни')">
                            <div class="item-role role-green"></div>
                            <div class="item-label">Активни</div>
                        </div>
                        <div class="m-l-c-item" @click="changeStatus('Изтичащи')">
                            <div class="item-role role-orange"></div>
                            <div class="item-label">Изтичащи</div>
                        </div>
                        <div class="m-l-c-item" @click="changeStatus('Изтекли')">
                            <div class="item-role role-red"></div>
                            <div class="item-label">Изтекли</div>
                        </div>
                        <div class="m-l-c-item" @click="changeStatus('Скрити')">
                            <div class="item-role role-gray"></div>
                            <div class="item-label">Скрит</div>
                        </div>
                        <div class="m-l-c-item" @click="changeStatus('Stopped')">
                            <div class="item-role role-red"></div>
                            <div class="item-label">Спряни</div>
                        </div>
                    </div>
                </div>

                <div class="m-l-item" ref="dates">
                    <div class="m-l-i-header">
                        <div class="m-l-i-h-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 376.3 354.1" xml:space="preserve">
                            <path d="M149.9,104.6c-3.3,3.5-8.4,3.7-13.1,0c-2.4-1.9-4.5-4.4-6.4-6.9C117.1,80.1,103.9,62.6,90.7,45c-0.7-0.9-1.5-1.8-3.3-3.8V48
                                c0,97,0,194,0,291c0,2,0.1,4-0.1,6c-0.7,5.5-4.8,9-10.4,9.1c-5.6,0-9.7-3.5-10.4-9.1c-0.3-2-0.1-4-0.1-6c0-97,0-194,0-291v-6.8
                                c-1.8,2.1-2.6,2.9-3.3,3.8C49.7,62.6,36.5,80.1,23.2,97.7c-1.9,2.5-3.9,5-6.4,6.9c-4.7,3.7-9.8,3.5-13.1,0
                                c-4.4-4.7-4.9-9.9-1.3-14.7c12.2-16,24.3-32.1,36.6-48.1c9.7-12.7,19.5-25.3,29.3-37.9c2.3-3,5.3-4,8.5-3.9C80-0.1,83,0.9,85.3,3.9
                                c9.8,12.6,19.6,25.2,29.3,37.9c12.3,16,24.4,32,36.6,48.1C154.8,94.6,154.3,99.8,149.9,104.6z"/>
                            <path d="M275,331c28.8,0,57.6,0,86.4,0c2.2,0,4.4-0.1,6.5,0.2c5,0.7,8.5,5,8.5,10.2c0,4.9-3.8,9.7-8.4,10.3c-2,0.3-4,0.2-6,0.2
                                c-57.8,0-115.6,0-173.4,0c-1.8,0-3.7,0-5.5-0.2c-5.2-0.6-8.7-4.8-8.7-10.4c0-5.8,3.2-9.7,8.7-10.3c2-0.2,4-0.2,6-0.2
                                C217.7,331,246.3,331,275,331z"/>
                            <path d="M240.8,268c-18.5,0-36.9,0.1-55.4,0c-7,0-10.6-3.3-10.9-9.6c-0.4-6.9,3.2-11.2,10.2-11.2c37.6-0.1,75.2-0.1,112.8,0
                                c6.6,0,10.2,3.9,10.3,10.3c0.1,6.6-3.6,10.5-10.5,10.5C278.4,268.1,259.6,268,240.8,268C240.8,268,240.8,268,240.8,268z"/>
                            <path d="M217.8,165.2c10.5,0,21-0.1,31.5,0c4.6,0.1,9.7,0.8,11.1,5.8c0.9,3.5,0.7,8.3-1.1,11.1c-1.7,2.6-6.3,4.3-9.7,4.4
                                c-21.1,0.4-42.3,0.3-63.4,0.1c-6.7,0-10.6-3.1-11.4-8.2c-1-6.3,1-10.7,5.8-12.3c2.3-0.8,4.9-1,7.4-1.1
                                C197.8,165.2,207.8,165.2,217.8,165.2z"/>
                            </svg>
                        </div>
                        <div class="m-i-h-label">
                            Дата {{ this.currentData ? `: ${this.currentData}` : '' }}
                        </div>
                        <div class="m-i-h-drop">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407">
                              <path id="Path_2952" data-name="Path 2952" d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="m-l-i-content">
                        <div class="m-l-c-item" @click="changeDate('Най-нови')">
                            <div class="item-label">Най-нови</div>
                        </div>
                        <div class="m-l-c-item" @click="changeDate('Най-стари')">
                            <div class="item-label">Най-стари</div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="m-l-btn-container">
                <div class="m-l-btn" @click="subbmitFilters();">
                    Търси
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import gsap from 'gsap'
import $ from 'jquery';

export default {
    props: ["categories", "shops"],

    data() {
        return {
            currentCategory:false,
            currentSection:false,
            currentStatus:false,
            currentData:false,
            currentSearch:'',          
        }
    },
    created(){
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        let tempSection = this.shops.filter((item)=>{
            if(item.id == params.get('shop')){
                return item;
            }
        })[0];
        let tempCategory = this.categories.filter((item)=>{
            if(item.id == params.get('category')){
                return item;
            }
        })[0];

        this.currentSearch = params.get("product_search");
        this.currentSection = tempSection ? tempSection : false;
        this.currentCategory = tempCategory ? tempCategory : false;
        this.currentStatus = this.switchStatus(params.get("product_status"));
        this.currentData = this.switchData(params.get("order"));
        
    },
    methods: {
        close() {
            this.gsapCloseModal();
            this.$emit('close');
            $('.modal-overlay').detach();
        },
        changeCategory(data){
            this.currentCategory = data;
            this.$refs.categories.classList.remove("m-l-item--active");
            // console.log(this.currentCategory);
        },
        changeSection(data){
            this.currentSection = data;
            this.$refs.sections.classList.remove("m-l-item--active");
        },
        changeStatus(data){
            this.currentStatus = data;
            this.$refs.statuses.classList.remove("m-l-item--active");
        },
        changeDate(data){
            this.currentData = data;
            this.$refs.dates.classList.remove("m-l-item--active");
        },
        changeSearch(data){
            this.currentSearch = data;
        },
        fixStatus(data){
            switch(data){
                case 'Актвини':
                    return 1;
                    break;
                case 'Изтичащи':
                    return 2;
                    break;
                case 'Изтекли':
                    return 3;
                    break;
                case 'Скрити':
                    return 'hidden';
                    break;
                case 'Stopped':
                    return 'stopped';
                    break;
                default:
                    return 1;
            }
        },
        fixData(data){
            switch(data){
                case "Най-нови":
                    return 'desc';
                    break;
                case "Най-стари":
                    return 'asc';
                    break;
                default:
                    return 'desc';
            }
        },
        switchStatus(data){
            switch(data){
                case '1':
                    return 'Актвини';
                    break;
                case '2':
                    return 'Изтичащи';
                    break;
                case '3':
                    return 'Изтекли';
                    break;
                case 'hidden':
                    return 'Скрити';
                    break;
                case 'stopped':
                    return 'Спряни';
                    break;
                default:
                    return false;
            }
        },
        switchData(data){
            switch(data){
                case "desc":
                    return 'Най-нови';
                    break;
                case "asc":
                    return 'Най-стари';
                    break;
                default:
                    return false;
            }
        },
        subbmitFilters(){
            // console.log(this.currentCategory)
            // console.log(this.currentSection)
            let params = '?';
            if(this.currentCategory){
                params = params + `category=${this.currentCategory.id}`;
            }
            if(this.currentSection){
                if(params !== '?'){
                    params = params + '&';
                }
                params = params + `shop=${this.currentSection.id}`;
            }
            if(this.currentStatus){
                if(params !== '?'){
                    params = params + '&';
                }
                params = params + `product_status=${this.fixStatus(this.currentStatus)}`;
            }
            if(this.currentData){
                if(params !== '?'){
                    params = params + '&';
                }
                params = params + `order=${this.fixData(this.currentData)}`;
            }
            if(this.currentSearch){
                if(params !== '?'){
                    params = params + '&';
                }
                params = params + `product_search=${this.currentSearch}`;
            }
            let redirectTo = window.location.origin + window.location.pathname + params;
            window.location.href = redirectTo;
            // console.log(redirectTo)
            // window.location.href = url.href;
        }
    },
    mounted() {
        // let div = document.createElement('div');
        // div.setAttribute('class', 'modal-overlay');
        // document.body.appendChild(div)
        // let modal = document.querySelector('.modal');
        // gsap.to(div, .3, { opacity: .9 })
        // gsap.fromTo(modal, .3, { opacity: 0, visibility: 'visible' }, { opacity: 1 })
    }
}
</script>
<style type="text/css">
    .m-l-item.m-l-item--input .m-l-i-header{
        background-color:var(--clr-purple) !important;
        color:var(--clr-black) !important;
    }
</style>