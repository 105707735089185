<template>
    <div>
        <table class="links-table">
            <tr v-if="orders.length < 1">
                <td class="full-row">
                    Няма намерени резултати
                </td>
            </tr>
            <tr v-else v-for="order in orders">
                <td class="link-image" :data-target="order.id">
                    <template v-if="order.product.deleted_at">
                        <a class="open-modal" data-modal-for="product-deleted" @click="showProductDeleteModal = true" href="#"><img :src="getImage(order.product)" alt="image"></a>
                    </template>
                    <template v-else>
                        <a :href="'/product/'+order.product_id+'/edit'"><img :src="getImage(order.product)" alt="image"></a>
                    </template>
                </td>
                <td class="td-title link-buyer">
                    <h4>
                        <template v-if="order.product.deleted_at">
                            <a class="open-modal" data-modal-for="product-deleted" @click="showProductDeleteModal = true" href="#">
                                {{ order.title }}
                            <!-- <i class="icon icon-arrow-right"></i> -->
                            </a>
                        </template>
                        <template v-else>
                            <a :href="'/product/'+order.product_id+'/edit'">
                                {{ order.title }}
                            <!-- <i class="icon icon-arrow-right"></i> -->
                            </a>
                        </template>
                        <p class="mt-2">
                            <div>
                                <span class="status">Купувач:</span>
                                <div>{{ order.order_receiver_address.name }}</div>
                            </div>
                        </p>
                    </h4>
                </td>
                <td class="link-status-table-payment">
                    <span class="status">Платежен метод:</span>
                    <div style="color: #000;" class="font-size--10" v-if="order.payment_token">EcontPay</div>
                    <div style="color: #000;" class="font-size--10" v-else>Наложен платеж</div>
                </td>
                <td class="link-status-table-data">
                    <span class="status">Цена:</span>
                    <div style="color: #000;" class="font-size--10">{{ order.price }} лв.</div>
                    <span class="status mt-1">Тегло:</span>
                    <div style="color: #000;" class="font-size--10">{{ order.weight }} кг.</div>
                </td>
                <td class="link-status-table">
                    <span class="status">Статус:</span>
                   <!--  <div v-if="order.status == 9" style="color:#913f98;">Чакаща онлайн плащане</div>
                    <div v-else-if="order.status == 8" style="color:#25b38c;">Успешно онлайн плащане</div>
                    <div v-else-if="order.status == 7" style="color:#d43d3d;">Неуспешно онлайн плащане</div>
                    <template v-else> -->
                    <div v-if="order.label" style="color: #25b38c;">Създаден документ за пратка</div>
                    <div v-if="!order.label" style="color: #d43d3d;">Не е създаден документ за пратка</div>
                    <!-- </template> -->
                    <span class="status mt-1">Дата на поръчка:</span>
                    <div style="color: #000;" class="font-size--10">{{ orderCreatedAt(order) }}</div>
                </td>
                <td class="link-progress">
                    <span class="status">От:</span>
                    <div class="font-size--10" data-modal-for="office-locator"
                        style="color: #000;">{{ order.order_sender_address.city }}-{{ order.order_sender_address.full_address }}</div>
                    <span class="status mt-1">До:</span>
                    <div class="font-size--10" data-modal-for="office-locator"
                        style="color: #000;">{{ order.order_receiver_address.city }}-{{ order.order_receiver_address.full_address }}</div>
                </td>
                <td class="link-cansel">
                    <a v-if="!order.label" class="fast-button open-modal" data-modal-for="delete" href="#" @click="mShowDeleteModal(order)" title="Откажи поръчка">
                        <i class="icon icon-close-circle-bold"></i>
                    </a>
                </td>
               <td class="link-share">
                    <template v-if="order.label">
                        <span class="status">Товарителница:</span>
                        <div class="tovaritelnica">
                            <a target="_blank" :href="order.label.pdf_url">
                                <em class="font-size--10">{{ order.label.shipment_number }}</em>
                            </a>
                            <a v-if="!order.courier" class="fast-button open-modal" href="#" @click="showDeleteLabelModal = true;setOrder(order)" data-modal-for="delete">
                                <i class="icon icon icon-link-ico-09"></i>
                            </a>
                        </div>
                        <div v-if="!order.courier && !order.order_sender_address.is_office" class="mt-2">
                            <a href="#" @click="showCourierModal = true; setCourierOrder(order)" class="button button-light">Заяви куриер</a>
                        </div>
                    </template>
                    <a v-if="!order.label" href="#" class="button open-modal" data-modal-for="bill-lading" @click="showModal = true;setOrder(order)">Създай документ</a>
                </td>
                <div class="link-divider"></div>

            </tr>

    </table>

    <request-courier-modal v-if="showCourierModal" @close="showCourierModal = false" :order="order" :cities="cities"></request-courier-modal>

    <bill-modal @update="updateOrder" v-if="showModal" @close="showModal = false" :p-order="order" :cities="cities"></bill-modal>

    <delete-modal v-if="showDeleteModal" @close="showDeleteModal = false" :order="order"></delete-modal>
    <list-component-delete-label v-if="showDeleteLabelModal" @close="showDeleteLabelModal = false" :order="order"></list-component-delete-label>
    <list-component-deleted-product v-if="showProductDeleteModal" @close="showProductDeleteModal = false"></list-component-deleted-product>
    </div>
</template>

<script>
import BillModal from "./BillModal.vue";
import RequestCourierModal from "./RequestCourierModal.vue";
import moment from "moment";
import DeleteModal from "./DeleteModal.vue";
import ListComponentDeleteLabel from "./ListComponentDeleteLabel.vue";
import ListComponentDeletedProduct from "./ListComponentDeletedProduct.vue";
import $ from 'jquery';

export default {
    props: ["orders", "cities"],
    components: {
        BillModal,
        RequestCourierModal,
        DeleteModal,
        ListComponentDeleteLabel,
        ListComponentDeletedProduct
    },
    data() {
        return {
            showModal: false,
            showDeleteModal: false,
            showDeleteLabelModal: false,
            showProductDeleteModal: false,
            showCourierModal: false,
            order: ""
        };
    },
    methods: {
        checkDate(date){
            let now = moment();
            now = now.subtract(5, "days");
            if(now.format('DD/MM/Y') < moment(date).format('DD/MM/Y')){
                return true;
            }else{
                return false;
            }
        },
        modalOverview(){
            setTimeout(function(){
                $('.modal-overlay').click(function(e){
                    $('.modal').find('.close-modal').trigger('click');
                });
            }, 500);
        },
        setOrder(order) {
            this.order = order;
            this.modalOverview();
        },
        updateOrder(order) {
            var index = this.orders.findIndex(e => e.id == order.id);
            this.orders[index] = order;
        },
        setCourierOrder(order) {
            this.order = order;
            this.showCourierModal = true;
            this.modalOverview();
        },
        mShowDeleteModal(order) {
            this.order = order;
            this.showDeleteModal = true;
            this.modalOverview();
        },
        getImage(product) {
            let image = product.product_media[0];
            if (image) {
                var extension = image.name.split(".").pop();
                if (extension == 'mp4' || extension == 'mov') return '/images/default/video-thumb.jpg';

                return '/storage/media/' + moment(product.created_at).format('Y')
                + '/' + moment(product.created_at).format('MM')
                + '/' + product.id + '/' + image.name
            }
        },
        addZeroes(num) {
            return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 3})
        },
        orderCreatedAt(order) {
            return moment(order.created_at).format('DD/MM/Y')
        },
    },
    // computed: {
    //     orderCreatedAt() {
    //         return moment(this.order.created_at).format('DD/MM/Y')
    //     },
    // },
};
</script>
