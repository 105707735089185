<template>
    <div class="modal modal-request-courier" :data-id="`request-courier-${order.id}`">
        <a href="#" class="close-modal" @click="$emit('close')"><i class="icon icon-close-circle"></i></a>
        <div class="modal-courier-number"><i class="icon icon-box"></i><span>272470531</span></div>
        <h3>Заяви куриер</h3>
        <p>Избери пратките, които ще изпращаш:</p>
        <div class="grid">
            <div v-for="orderCheck in orders" class="col-3 col-lg-6 col-sm-12">
                <label class='md-checkbox'>
                    <input type='checkbox' v-model="data.orderId" :value="orderCheck.id" :checked="orderCheck.id == order.id" name="orderId">
                    <span class='md-transition'></span><em>{{ orderCheck.product.title }}</em>
                </label>
                <div class="inline-courier-number"><i class="icon icon-box"></i><span>{{ orderCheck.label.shipment_number }}</span></div>
            </div>

        </div>
        <form class="form">
            <!-- <p class="modal-text">Къде да дойде куриерът?</p> -->
            <div class="grid">
            <!-- <div class="col-6 col-sm-12"> -->

                <div class="form-group col-6 col-sm-12" :class="errors && errors.email ? 'is-error' : ''">
                    <label for="">E-mail<sub class="is-required">*</sub></label>
                    <input type="text" name="email" v-model="data.email" :tabindex="3">
                    <form-error v-if="errors && errors.email">
                        <template v-for="email in errors.email">{{ email }}</template>
                    </form-error>
                </div>

                <div class="form-group col-6 col-sm-12" :class="errors && errors.city ? 'is-error' : ''" @click="errors.city = null">
                    <label for="">Населено място:<sub class="is-required">*</sub></label>
                    <v-select :options="cities" label="cities" v-model="data.city" @input="setCity" :tabindex="4">
                        <template v-slot:option="cities">
                            [{{ cities.post_code}}] {{ cities.name }}
                        </template>
                        <template slot="selected-option" slot-scope="cities">
                            [{{ data.post_code}}] {{ data.city }}
                        </template>
                        <template slot="no-options">
                            Няма такова населено място
                        </template>
                    </v-select>
                    <form-error v-if="errors && errors.city">
                        <template v-for="city in errors.city">{{ city }}</template>
                    </form-error>
                </div>

                <div class="form-group col-6 col-sm-12" :class="errors && errors.tel ? 'is-error' : ''">
                    <label for="">Телефон<sub class="is-required">*</sub></label>
                    <input type="text" name="tel" v-model="data.tel" :tabindex="5">
                    <form-error v-if="errors && errors.tel">
                        <template v-for="tel in errors.tel">{{ tel }}</template>
                    </form-error>
                </div>

            <!-- </div> -->
            <!-- <div class="col-6 col-sm-12"> -->

                <div class="form-group col-6 col-sm-12" :class="errors && errors.full_address ? 'is-error' : ''">
                    <label for="">Адрес<sub class="is-required">*</sub></label>
                    <input type="text" name="full_address" v-model="data.full_address" :tabindex="6">
                    <form-error v-if="errors && errors.full_address">
                        <template v-for="full_address in errors.full_address">{{ full_address }}</template>
                    </form-error>
                </div>
            <!-- </div> -->
        </div>

            <p class="modal-text mt-3">Кога да дойде куриерът?</p>
            <div class="grid timing-grid">
                <div class="col-2 col-md-12">
                    <div class="form-group">
                        <div class="calendar-wrapper">
                            <input class="datepicker" type="text" name="date"
                                autocomplete="off" v-model="data.date" :tabindex="6">
                            <i class="icon icon-calendar"></i>
                        </div>
                    </div>
                </div>

                <div class="col-2 col-md-12">
                    <div class="form-group">
                        <div class="select-wrapper">
                            <select name="time_interval" v-model="data.timeInterval" :tabindex="7">
                                <option value="between">между</option>
                                <option value="in">в</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-3 col-md-12">
                    <div class="grid">
                        <div class="col-5 col-lg-5 pr-0">
                            <div class="form-group">
                                <div class="select-wrapper">
                                    <select name="time_from_hour" v-model="data.time_from_hour" :tabindex="8">
                                        <option v-for="index in 15" :value="getHour(index)" :key="getHour(index)">
                                            {{ getHour(index) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 col-sm-2 p-0">
                            <div class="form-group">
                                <div class="ab-hour-label">:</div>
                            </div>
                        </div>
                        <div class="col-5 col-lg-5 pl-0">
                            <div class="form-group">
                                <div class="select-wrapper">
                                    <select name="time_from_minute" v-model="data.time_from_minute" :tabindex="9">
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="col-1 col-md-12 pl-0 custom-label purged" v-if="data.timeInterval == 'between'">
                    <div class="grid">
                        <div class="col-12 col-sm-12 ab-hour-label">
                            и
                        </div>
                    </div>
                </div>

                <div class="col-3 col-md-12" v-if="data.timeInterval == 'between'">
                    <div class="grid">
                        <div class="col-5 col-lg-5 pr-0">
                            <div class="form-group">
                                <div class="select-wrapper">
                                    <select name="time_to_hour" v-model="data.time_to_hour" :tabindex="10">
                                        <option v-for="index in 15" :value="getHour(index)" :key="getHour(index)">
                                            {{ getHour(index) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 col-sm-2 p-0">
                            <div class="form-group">
                                <div class="ab-hour-label">:</div>
                            </div>
                        </div>
                        <div class="col-5 col-lg-5 pl-0">
                            <div class="form-group">
                                <div class="select-wrapper">
                                    <select name="time_to_minute" v-model="data.time_to_minute" :tabindex="11">
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="text-right">
                <a href="#" class="button" @click="add" :tabindex="12">Заяви куриер</a>
            </div>
        </form>
    </div>

</template>

<script>
    import FormError from '../FormError.vue'
    import moment from 'moment'

    export default {
        props: ['order', 'cities'],
        components: { FormError },
        data() {
            return {
                errors: [],
                orders: {},
                addressChecked: this.order.sender_address_type,
                data: {
                    orderId: [this.order.id],
                    email: this.order.order_sender_address.email,
                    tel: this.order.order_sender_address.tel,
                    city: this.order.order_sender_address.city,
                    post_code: this.order.order_sender_address.post_code,
                    full_address: this.order.order_sender_address.full_address,
                    date: moment().format('DD/MM/Y'),
                    time_from_hour: 8,
                    time_from_minute: '00',
                    time_to_hour: 8,
                    time_to_minute: '15',
                    timeInterval: 'between',
                },
            }
        },
        created() {
            axios.get(`/order/orders-rdy-for-courier`)
            .then((response) => {
                this.orders = response.data.orders
            })
        },
        methods: {
            add() {
                this.$snotify.async('Заявката за куриер се подава', 'Success async', () => new Promise((resolve, reject) => {
                axios.post(`/courier`, this.data)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            resolve({
                                title: 'Success',
                                body: 'Заявката за куриер е направена успешно',
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            })
                            window.location.reload();
                        } else {
                            reject({
                                title: 'Error',
                                body: response.data.error,
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            })
                        }
                    })
                    .catch((errors) => {
                        this.$snotify.clear()
                        this.errors = errors.response.data.errors
                    })
            }));
            },
            getHour(index) {
                return index + 7;
            },
            setCity(city) {
                this.data.post_code = city.post_code
                this.data.city = city.name
                this.data.city_id = city.id
            }
        },
        mounted() {
            const $ = require('jquery')
            var self = this;

            $('.datepicker').datepicker({
                yearRange: '1920:2034',
                dateFormat : 'dd/mm/yy',
                setDate: moment().format('DD/MM/Y'),
                defaultDate: moment().format('DD/MM/Y'),
                minDate: 0,
                onSelect:function(selectedDate, datePicker) {
                    self.data.date = selectedDate;
                }
            });
            $.datepicker.regional['bg']
        }
    }

</script>

<style>

</style>
