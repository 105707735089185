<template>
  <!-- Modal Cansel order -->
    <section class="vb-modal-container" :data-id="`cansel-order-${orderId}`" ref="modalContainer">
        <div class="vb-modal">
            <div class="modal-header">
                <div class="m-h-title">
                    Отказване на поръчка
                </div>
                <div class="m-h-close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.264 29.264">
                      <g transform="translate(1.132 1.132)">
                        <g>
                          <path d="M9674.438,1010.545a13.5,13.5,0,1,1,0-19.091A13.5,13.5,0,0,1,9674.438,1010.545Z" transform="translate(-9651.391 -987.5)" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          <g transform="translate(8.583 8.582)">
                            <line y1="9.833" x2="9.832" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                            <line x2="9.837" y2="9.835" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                </div>
            </div>
            <div class="modal-header-sub">
                Сигурен ли си, че искаш да откажеш 
                направената от теб поръчка?
                <template v-if="whoIS == 'receiver'">Веднъж отказана
                губиш правото си над нея.</template>
            </div>
            <div class="modal-form">
                <div class="m-f-item">
                    <div class="m-f-label">
                        Моля сподели с нас причината(*)
                    </div>
                    <div class="m-f-select">
                        <div class="m-f-s-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407">
                              <path d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path>
                            </svg>
                        </div>
                        <input type="hidden" id="m-f-s-content-input" name="cansel_reason">
                        <ul :class="error_choise ? 'm-f-s-content m-f-s-content--error' : 'm-f-s-content'">
                            <li class="m-f-s-item m-f-s-item--unactive m-f-s-item--active">
                                Избери опция
                            </li>
                            <template v-for="option in cansel_options">
                                <li class="m-f-s-item" @click="changeOption(option)">
                                    {{ option.name }}
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div v-if="error_choise" class="error"><span>Полето е задължително.</span></div>
                </div>
                <div class="m-f-item">
                    <div class="m-f-label">
                        Кратко описание
                    </div>
                    <div class="m-f-text">
                        <textarea name="cansel_order_message" v-model="cansel_message"></textarea>
                    </div>
                </div>
                <div class="m-f-item m-f-item-check" @click="share_with_owner = !share_with_owner">
                    <div :class="share_with_owner ? 'm-f-item-check item-check item-check--active' : 'm-f-item-check item-check'">
                        <svg data-target="share_with_owner" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 15.2 12.9" xml:space="preserve"><g><rect x="8.9" y="-1" transform="matrix(0.7896 0.6137 -0.6137 0.7896 6.0265 -4.7005)" width="2" height="14.8" class="st0"></rect> <rect x="2.7" y="5.7" transform="matrix(0.6377 -0.7703 0.7703 0.6377 -6.1198 6.299)" width="2" height="7.8" class="st0"></rect></g></svg>
                        <input type="checkbox" name="isChecked">
                    </div>
                    <div class="m-f-label" v-if="whoIS == 'receiver'">
                        Сподели с продавача причината за отказването на поръчката
                    </div>
                    <div class="m-f-label" v-else>
                        Сподели с купувача причината за отказването на поръчката
                    </div>
                </div>
                 <div class="m-l-btn-container m-l-btn-container-duo">
                    <div class="m-l-btn" @click="submit()">
                        Потвърди
                    </div>
                    <div class="m-l-btn m-l-btn--green" @click="closeModal()">
                        Откажи
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Modal Cansel order -->
</template>

<script>
import gsap from 'gsap'

export default {
    props: ["order_reasons","order_id","who"],

    data() {
        return {
            cansel_options: this.order_reasons,
            orderId: this.order_id,
            choosen_option:null,
            share_with_owner:false,
            cansel_message:'',
            delete_url : `/dashboard/orders/user/${this.order_id}/destroy`,
            errors:[],
            error_choise:false,
            valid_data:false,
            whoIS: this.who,
        }
    },
    methods: {
        closeModal() {
            this.$refs.modalContainer.classList.remove('modal-container--active');
        },
        changeOption(option){
            this.choosen_option = this.cansel_options.filter((item)=>{
                if(item.value == option.value){
                    return item;
                }
            })[0].value;
            this.validateData();
        },
        submit(){
            this.validateData();
            if(this.valid_data){
                axios.delete(this.delete_url, { params:{
                    'cansel_reason':this.choosen_option,
                    'cansel_order_message':this.cansel_message,
                    'share_with_owner':this.share_with_owner ? 1 : 0,
                    'from':this.whoIS,
                    }}).then((response) => {
                        this.errors = false;
                        // console.log(response.data)
                        if (response.data.success) {
                            this.$snotify.success(response.data.success);
                            setTimeout(function(){
                                location.reload();
                            }, 1000);
                        } else {
                            this.$snotify.error('Възникна проблем, моля опитайте отново.');
                        }
                    });
            }
        },
        validateData(){
            if(this.choosen_option == null){
                this.error_choise = true;
                this.valid_data = false;
            }else{
                this.valid_data = true;
                this.error_choise = false;
            }
            // if(this.cansel_message == '' || this.cansel_message == ' '){
            //     return false;
            // }
        },
    },
    mounted() {
        // console.log(this.whoIS)
    }
}
</script>
<style scoped>
    .vb-modal-container{
        z-index:999 !important;
    }
    .m-f-s-content.m-f-s-content--error{
        border-color:#f53f5d;
    }
    .error{
        display: inline-block;
        line-height: 16px;
        padding: 5px 10px;
        background-color: #f53f5d;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        font-size:12px;
    }
</style>