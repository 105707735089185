<template>
    <div class="modal-contact-container">
        <div class="modal modal-delete modal-contact" data-id="contact">
            <h3>
                <span>Напиши съобщение</span>
                <div class="close-modal" @click="closeModal" id="closeSend">
                    <i class="icon icon-close-circle"></i>
                </div>
            </h3>
            <p>Тук можеш да зададеш своите въпроси към продавача.</p>
            <p>Всички полета обозначени със знак (<sub class="is-required color-red">*</sub>) са задължителни.</p>
            <form class="form" @submit.prevent="submit">
                <div class="grid">
                     <div class="col-12">
                        <div class="form-group" :class="errors && errors.name ? 'is-error' : ''">
                            <label for="name">Име/Фамилия<sub class="is-required">*</sub></label>
                            <div class="price-wrapper">
                                <!-- <input type="text" name="name" v-model="addr.name" tabindex="2"> -->
                                <input type="text" name="name" tabindex="2" v-model="data.message.name"
                                v-if="data.message.name == null" disabled>
                                <input type="text" name="name" tabindex="2" ref="startModal" disabled v-model="data.message.name" v-else>
                                <!-- :class="data.message.name!=null ? 'dissabledSelect' : ''"> -->
                                <form-error v-if="errors && errors.name">
                                    <template v-for="name in errors.name">{{ name }}</template>
                                </form-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 delimiter-text">
                        <!-- <p>Избери поне един от начините за обратна връзка<sub class="is-required color-red">*</sub></p> -->
                        <p>Остави свои контакти, за да се свърже продавачът с теб.</p>
                    </div>
                    <div class="col-5 col-md-6">
                        <div class="form-group" :class="errors && errors.email ? 'is-error' : ''">
                            <label for="email">E-mail</label>
                            <div class="price-wrapper">
                                <!-- <input type="text" name="tel" v-model="addr.tel" tabindex="4"> -->
                                <input type="text" name="email" tabindex="4" v-model="data.message.email">
                                <!-- :class="data.message.email!=null ? 'dissabledSelect' : ''"> -->
                                <form-error v-if="errors && errors.email">
                                    <template v-for="email in errors.email">{{ email }}</template>
                                </form-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 del-text">
                         <div class="form-group">
                            <p>и/или</p>
                         </div>
                    </div>
                    <div class="col-5 col-md-6">
                        <div class="form-group" :class="errors && errors.phone ? 'is-error' : ''">
                            <label for="tel">Телефон</label>
                            <div class="price-wrapper">
                                <!-- <input type="text" name="tel" v-model="addr.tel" tabindex="4"> -->
                                <input type="text" name="tel" tabindex="5" v-model="data.message.phone">
                                <form-error v-if="errors && errors.phone">
                                    <template v-for="phone in errors.phone">{{ phone }}</template>
                                </form-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group" :class="errors && errors.content ? 'is-error' : ''">
                            <label for="description">Съобщение<sub class="is-required">*</sub></label>
                            <div class="price-wrapper">
                                <!-- <input type="text" name="tel" v-model="addr.tel" tabindex="4"> -->
                                <!-- <input type="textarea" name="description" tabindex="6"> -->
                                <textarea type="textarea" name="description" tabindex="6" v-model="data.message.content"
                                v-if="data.message.name"  ref="startModal"></textarea>
                                <textarea type="textarea" ref="startModal" name="description" tabindex="6" v-model="data.message.content"
                                v-else></textarea>
                                <form-error v-if="errors && errors.content">
                                    <template v-for="description in errors.content">{{ description }}</template>
                                </form-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <button class="button" type="submit" id="submitSend">Изпрати</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-overlay modal-overlay-active" @click="closeModal"></div>
    </div>
</template>

<script>
import FormError from '../../components/FormError.vue'

export default {
  props: ['product','user','type'],
   components: {
        FormError,
    },
  data() {
    return {
        errors:[],

        data:{
            messageThread:{
                type:this.type,
            },
            message:{
                content:'',
                phone: '',
                // email:'',
                email:this.user.email,
                // name:'',
                name:this.user.name,
            },
            productID: this.product ? this.product.id : '',
        }
    }
  },
  methods: {
    pressEnter(){
        // console.log('hello')
        var input = document.getElementById("submitSend");

        input.addEventListener("keyup", function(event) {
          // Number 13 is the "Enter" key on the keyboard
          if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            this.submit();
          }
        });
    },
    focusInput(){
         this.$refs.startModal.focus();
    },
     submit() {
        axios.post(`/message/store`, {
            'data':this.data,
            'mode':'start'
        }).then((response) => {
                if (response.data.type == 'success') {
                    let address = response.data.address
                    this.notifySuccess('Успешно изпратено съобщение')
                    this.$emit('success', this.message)
                    // this.$emit('close')
                    // document.querySelector('.modal-overlay').remove()
                    this.closeModal();
                    dataLayer.push({'event': 'contact_seller','product_id' : `product-${productID}`});
                }
            })
            .catch((errors) => {
                this.errors = errors.response.data.errors
            })
    },
    closeModal(){
        //document.querySelector('.modal-delete.modal-contact').classList.remove('modal-active');
        //document.querySelector('.modal-contact-container').classList.remove('modal-contact-container-active');
        document.querySelectorAll('.modal-contact-container').forEach((item)=>{
            item.classList.remove('modal-contact-container-active')
        });
        document.querySelectorAll('.modal-delete.modal-contact').forEach((item)=>{
            item.classList.remove('modal-active')
        });
        document.querySelector('body').classList.remove('freeze');
    },
  },
  mounted() {
    this.pressEnter();
    this.focusInput();
  },
}
</script>
