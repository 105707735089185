<template>
    <section class="section-dashboard">
        <div class="container extend--upload">
            <div class="grid grid-break-xl bg-white">

                <step-one-media :errors="errors" :product="product"></step-one-media>
                
                <div class="col-6 col-lg-12">
                    <div class="grid">
                        <div class="col-6 col-md-12">
                            <div class="form-group" :class="errors && errors.title ? 'is-error' : ''" @keyup="errors.title = null">
                                <label for="">Име на продукт<sub class="is-required">*</sub></label>
                                <input type="text" name="product_name" v-model="product.title" tabindex="2" ref="startPage" placeholder="Текст за заглавие">
                            </div>
                            <div class="form-group" :class="errors && errors.description ? 'is-error' : ''" @keyup="errors.description = null">
                                <label for="">Описание на продукт<sub class="is-required">*</sub></label>
                                <textarea type="text" name="product_description" v-model="product.description"
                                    placeholder="Опиши какво продаваш и дай възможно най-подробна информация." tabindex="5"></textarea>
                            </div>
                        </div>
                        <div class="col-6 col-md-12">
                            <div tabindex="-1" class="toggle-field">
                                <div tabindex="-1" class="toggle-field-left">
                                    <div class="grid">
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.price ? 'is-error' : ''" @keyup="errors.price = null">
                                                <label for="">Цена<sub class="is-required">*</sub></label>
                                                <div class="price-wrapper">
                                                    <input type="number" name="price" v-model="product.price" tabindex="2">
                                                    <span class="number-sub">лв.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.quantity ? 'is-error' : ''" @keyup="errors.quantity = null">
                                                <label for="">Брой<sub class="is-required">*</sub></label>
                                                <div class="number-wrapper">
                                                    <input type="text" name="amount" v-model="product.quantity" tabindex="3">
                                                    <a href="#" class="number-arrow number-arrow-up" @click="numberArrowUp" tabindex="-1"><i
                                                            class="icon icon-arrow-up"></i></a>
                                                    <a href="#" class="number-arrow number-arrow-down" @click="numberArrowDown" tabindex="-1"><i
                                                            class="icon icon-arrow-down"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.weight ? 'is-error' : ''" @keyup="errors.weight = null">
                                                <label for="">Тегло<sub class="is-required">*</sub></label>
                                                <div class="price-wrapper">
                                                    <input type="text" name="weight" v-model="product.weight" tabindex="4">
                                                    <span class="number-sub">кг.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-field-right mobile-toggle-down">
                                    <a href="#" class="toggle toggle-top ml-0 mr-0 toggle-bottom-left"
                                        data-text="Необходимо е да въведеш тегло, за да пресметнем куриерската услуга. Ако не си сигурен, въведи приблизително, а Еконт ще го коригира при изпращането." tabindex="-1"><i
                                            class="icon icon-info"></i></a>
                                </div>
                            </div>
                            <div class="form-group" :class="errors && errors.valid_to ? 'is-error' : ''" @click="errors.valid_to = null">
                                <label for="">Валидно до</label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="calendar-wrapper">
                                            <input class="datepicker" type="text" name="expiration"
                                                v-model="product.valid_to" autocomplete="off" tabindex="6">
                                            <i class="icon icon-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери срок, в който твоят продукт да е валиден. Можеш да го промениш по всяко време или да го оставиш 40 дни по подразбиране." tabindex="-1"><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" :class="errors && errors.shop_id ? 'is-error' : ''" @click="errors.shop_id = null">
                                <label for="">Моите секции</label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="select-wrapper">
                                            <select id="shop_id" name="shop_id" v-model="product.shop_id" tabindex="7">
                                                <option v-if="shops.length == 0" value="0" selected>Моята първа секция</option>
                                                <option v-for="(shop, index) in shops" :value="shop.id">
                                                    {{ shop.title }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери къде да се запамети продуктът." tabindex="-1"><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" :class="errors && errors.transport_pay ? 'is-error' : ''">
                                <label for="">Куриерската услуга е за сметка на<sub class="is-required">*</sub></label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="radio-wrapper">
                                            <div class="radio-stack no-select">
                                                <div class="md-radio-fieldset">
                                                    <label class="md-radio" tabindex="8">
                                                        <input type="radio" value="0" v-model="product.is_sender_paying">
                                                        <i class="md-transition"></i>
                                                        <span>Купувач</span>
                                                    </label>
                                                    <label class="md-radio" tabindex="9">
                                                        <input type="radio" value="1" v-model="product.is_sender_paying">
                                                        <i class="md-transition"></i>
                                                        <span>Продавач</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери за чия сметка ще бъде доставката."><i
                                                class="icon icon-info"  tabindex="-1"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" :class="errors && errors.condition ? 'is-error' : ''" @click="errors.condition = null">
                                <label for="">Състояние<sub class="is-required">*</sub></label>
                                <div tabindex="-1" class="toggle-field">
                                    <div tabindex="-1" class="toggle-field-left">
                                        <div class="select-wrapper">
                                            <select id="condition" name="condition" v-model="product.condition" tabindex="10">
                                                <option value="1" selected disabled>Не е посочено</option>
                                                <option value="0">Втора употреба</option>
                                                <option value="2">Нов продукт</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div tabindex="-1" class="toggle-field-right">
                                        <a href="#" tabindex="-1" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери дали продукта ти е нов или втора употреба."><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="form--extend-butons form--extend-butons-1 justify-end mb-2">
                                <!-- <div v-if="!product.status">
                                    <a @click.prevent="draft()" href="#" class="button button-light">Запази чернова</a>
                                </div> -->
                                <div>
                                    <input @click.prevent="next()" class="button" type="submit" value="Напред"  tabindex="10">
                                </div>
                                <p class="mutted text-right faq--extend extend--faq mr-0">
                                    <span class="vertical-middle">Често задавани въпроси</span>
                                    <a href="/faq/questions" class="toggle mr-0 toggle-bottom-left"
                                       target="_blank" tabindex="-1"><i
                                            class="icon icon-questionmark-circle"></i></a>
                                </p>
                            </div>



                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import FormError from '../../FormError.vue'
    import moment from 'moment'
    import StepOneMedia from '../StepOneMedia.vue'

    import draggable from 'vuedraggable'

    export default {
        props: ['shops'],
        components: { FormError,draggable, StepOneMedia },
        data() {
            return {
                product: this.$store.state.createLink.product,
                errors: [],
            }
        },
        methods: {
            focusInput(){
                this.$refs.startPage.focus();
            },
            next() {
                let element = document.getElementsByClassName('footer-video-c');
                element[0].style.display = "none";
                if(this.product.condition == 1){
                    this.product.condition = null;
                }
                axios.post(`/product/validate-step-one`, this.product)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            this.$store.state.createLink.step++;
                        }
                    })
                    .catch((errors) => {
                        this.errors = errors.response.data.errors
                        //  if(this.errors.description){
                        //     this.$snotify.error('Описанието трябва да бъде по-малко от 200 знака',
                        //             "Default",{timeout: 3000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                        //         );
                        // }
                    })

            },
            numberArrowUp() {
                this.product.quantity++;
            },
            numberArrowDown() {
                if (this.product.quantity > 1) {
                    this.product.quantity--;
                }
            },
        },
        created(){
            this.product.condition = 1;
        },
        mounted() {
            this.focusInput();

            const $ = require('jquery')
            var self = this;
            $('.datepicker').datepicker({
                yearRange: '1920:2025',
                dateFormat : 'dd/mm/yy',
                minDate: 0,
                onSelect:function(selectedDate, datePicker) {
                    self.product.valid_to = selectedDate;
                }
            });
            $.datepicker.regional['bg']
        }
    }

</script>
