<template>
  <section class="section-table-container">
      <div class="s-t-header s-t-header--import-category">
          <div class="s-t-h-title">
              Категоризация
          </div>
          <div class="tab-list tab-list-select">
            <div class="select-label">
              <span>Продуктов лист:</span>
            </div>
            <select @change="changeShop($event)">
                <option class="default-option" value="false" selected disabled>Избор на лист..</option>
                <template v-for="c in current_shops">
                    <option :value="c.id" v-if="c.id == shop.id" selected>
                        {{ c.source.split('/').slice(0,3).join('/') }}
                    </option>
                    <option :value="c.id" v-else>
                        {{ c.source.split('/').slice(0,3).join('/') }}
                    </option>
                </template>
            </select>
          </div>
     </div>
     <div class="section-table" v-if="current_products.length > 0" >
          <template v-for="product in current_products">
            <category-item :product="product" :categories="current_categories"></category-item>
          </template>
      </div>
     <div class="section-table" v-else>
          <div class="s-t-item s-t-item--active" style="padding-left:25px; padding-bottom:25px; padding-top:25px;">
              В момента нямаш качени продукти без категория{{ shop ? ` в секция ${shop.title}.` : '.' }}
          </div>
     </div>
  </section>
</template>

<script>
import moment from "moment";
import CategoryItem from './CategoryItem.vue'

export default {
  props: ['categories', 'shops','products','currfeed'],
  components: { CategoryItem },
  data() {
    return {
      current_products:this.products.data,
      current_categories:this.categories,
      marked: [],
      current_shops: this.shops,
      shop:false,
      currentFeedID: this.currfeed,
    }
  },
  created(){
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    // if(params.shop){
      this.getShop()
    // }
  },
  methods: {
    changeShop(event){
      // this.getShop(event.target.value)
      let url = new URL(document.location)
      url.searchParams.set('shop', event.target.value)
      url.searchParams.set('page', 1)
      // console.log(url)
      // location.href = url.href
      location.href = `/dashboard/feed/${event.target.value}/categories`
    },
    getShop(){
      this.current_shops.map((item)=>{
        if(item.id == this.currentFeedID){
          this.shop = item;
        }
        console.log('this.currentFeedID')
        console.log(this.currentFeedID)
        console.log('shop')
        console.log(this.shop)
      });
    },
    getImage(product){
      let image = product.product_media[0];
      if (image) {
          var extension = image.name.split(".").pop();
          if (extension == 'mp4' || extension == 'mov') return '/images/default/video-thumb.jpg';

          return '/storage/media/' + moment(product.created_at).format('Y')
          + '/' + moment(product.created_at).format('MM')
          + '/' + product.id + '/' + image.name
      }else{
        return 'images/default/default.png';
      }
    },
  },
}
</script>

<style scoped>
  .default-option{
    opacity: .6;
  }
  .tab-list-select{
    background-color:#913f98;
    color:#fff;
    display: flex;
    padding:.5rem 1rem;
  }
  .tab-list-select select{
    background-color:#913f98;
    color:#fff;
    font-weight: 600;
  }
  .tab-list-select .select-label{
    margin-right: .5rem;
    display:flex;
  }
  .tab-list-select .select-label span{
    margin:auto;
  }
  .section-table{
    overflow-x: hidden;
  }
  .i-d-i-info-category{
    grid-column: 1 / span 6;
    grid-row: span 2;
  }
  .s-t-i-d-category{
    margin-left: 0;
    width:100%;
  }
  .i-d-item-big--button-container{
    grid-row: 1 / span 2;
    grid-column: span 3;
    display: flex;
    width:100%;
  }
  .i-d-item-big--button-container .i-d-item-big{
    background-color:var(--clr-blue);
    color:#fff;
    width: 18rem;
    font-size: 1.4rem;
    padding: .75rem 1.5rem;
    margin: auto;
    text-align: center;
    border-radius: 5rem;
  }
  .tab-list{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    height: 4.5rem;
    font-size:1.4rem;
  }
  .tab-list .tab-list-item.tab-list-active{
    background-color:#ade8e2;
    pointer-events: none;
  }
  .tab-list .tab-list-item.tab-list-active span{
    color:#000;
  }
  .tab-list .tab-list-item.tab-list-active svg{
    fill:#000;
  }
  .tab-list-item{
    background-color:#913f98;
    display: flex;
    padding-left:1rem;
    padding-right: 1rem; 
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  .tab-list-item span{
    color:#fff;
    margin:auto;
    font-size:1.2rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  .tab-list-icon{
    width:1.6rem;
    height: 1.6rem;
    margin-right: 1.1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  .tab-list-icon svg{
    fill:#fff;
    width:100%;
  }
  @media only screen and (max-width:768px){
    .s-t-header--import-category{
      flex-direction: column;
    }
    .tab-list-select{
      width:100%;
    }
    .tab-list-select select{
      width:100%;
      font-size:1.6rem;
    }
  }
</style>