import moment from 'moment'

export default {
    namespaced: true,
    state: {
        product: {
            title: null,
            description: null,
            category_id: null,
            price: null,
            quantity: 0,
            weight: 1,
            valid_to: moment().add(+90, 'days').format('DD/MM/Y'),
            shop_id: null,
            is_sender_paying: "0",
            anonymous_email: null,
            productAddress: {
                name: null,
                representative: null,
                email: null,
                tel: null,
                city: null,
                post_code: null,
                full_address: null,
                office_code: null,
                is_office: 0,
                is_company: 0,
            },
            media: [],
        },
        step: 1
    }
};