<template>
    <section class="section-table-container">
        <div class="s-t-header">
            <div class="s-t-h-title s-t-h-title--bigger">
                Как да кача продукти чрез CSV?
            </div>
        </div>
        <div class="section-table section-table-info">
            <div class="info-text">
                Полетата отбелязани с <span>*</span> са задължителни.
            </div>
            <div class="s-t-list" style="line-height:3rem;">
                <div class="s-t-list-item imp-list-padding">
                    <div><b class="circle"><span>1</span></b> <div class="s-t-l-i-content"> <span>Изтегли примерния темплейт от</span> <a class="underline" href="/dashboard/import/downloadTemplate">тук</a>.</div></div>
                </div>
                <div class="s-t-list-item imp-list-padding" v-if="choise == 'mac'">
                    <div><b class="circle"><span>2</span></b> <div class="s-t-l-i-content"> <span>Заради кодирането на текстове на Mac OS устройства, трябва да имаш Microsoft Office пакет, за да го отвориш с Excel.
                    След като го отвориш и попълниш трябва да го запишеш в разширение CSV с UTF-8 Encoding.</span></div></div>
                </div>
                <div class="s-t-list-item imp-list-padding">
                    <div><b class="circle"><span>{{ choise == 'mac' ? '3' : '2' }}</span></b> <div class="s-t-l-i-content"> <span>Попълни колоните от файла, като всеки продукт трябва да е на нов ред.</span> <span class="weight-strong">(не изтривай колони от файла и не променяй тяхната последователност)</span></div></div>
                    <div class="s-t-list-inner">
                        <div class="s-t-list-item">
                            <span><b>*Name</b> <span class="low-opacity">- Име на продукта</span> <i>// Tекст до 100 символа</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>*Description</b> <span class="low-opacity">- Описание на продукта</span> <i>// Текст до 2500 символа</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>*Price</b> <span class="low-opacity">- Цена на продукта</span> <i>// Число</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>*Weight</b> <span class="low-opacity">- Тегло на продукта</span> <i>// Число с минимална стойност 0.2 и максимална стойност 999</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>*Quantity</b> <span class="low-opacity">- Количество на продукта</span> <i>// Число с минимална стойност 1 и максимална стойност 999</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>*Main Image</b> <span class="low-opacity">- Главна снимка за продукта</span> <i>// Линк към снимка</i> <span  class="weight-strong">(използвайте линк, който да води към актуална снимка)</span></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>Image_2</b> <span class="low-opacity">- Снимка за продукта</span> <i>// Линк към снимка</i></span>
                        </div>
                        <div class="s-t-list-item">
                            <span><b>Image_3</b> <span class="low-opacity">- Снимка за продукта</span> <i>// Линк към снимка</i></span>
                        </div>

                        <div class="s-t-list-item">
                            <span><b>Image_4</b> <span class="low-opacity">- Снимка за продукта</span> <i>// Линк към снимка</i></span>
                        </div>

                        <div class="s-t-list-item">
                            <span><b>Image_5</b> <span class="low-opacity">- Снимка за продукта</span> <i>// Линк към снимка</i></span>
                        </div>

                        <div class="s-t-list-item">
                            <span><b>Category</b> <span class="low-opacity">- Категория на продукта</span> <i>// Слъг на категория</i></span>
                        </div>

                        <div class="s-t-list-item">
                            <span><b>Condition</b> <span class="low-opacity">- Състояние на продукта</span> <i>// Нов или Втора употреба</i></span>
                        </div>
                    </div>
                </div>
                <div class="s-t-list-item imp-list-padding">
                    <div><b class="circle"><span>{{ choise == 'mac' ? '4' : '3' }}</span></b> <div class="s-t-l-i-content"> <span>Избери секция</span> <span>"Импорт на продукти"</span> <span>или от</span> <a class="underline" href="/dashboard/import">тук</a></div></div>
                </div>
                <div class="s-t-list-item imp-list-padding">
                    <div>
                        <b class="circle"><span>{{ choise == 'mac' ? '5' : '4' }}</span></b>
                        <div class="s-t-l-i-content">
                        <span>Избери категория за всеки импортнат от теб продукт<br> (подредени по секция), който няма въведена стойност за <span  class="weight-strong">"Category"</span> и я запази от секция</span> <span class="weight-strong">"Категоризация"</span> <span>или от</span> <a class="underline" href="/dashboard/import/category">тук</a></span>
                    </div>
                    </div>
                </div>

                <div class="s-t-list-item">
                    <i><div>
                        <b class="m-inner"><span class="weight-strong">** Слъг</span> - начинът, по който се изписва текстът на подкатегорията в URL адресът на страницата - например слъг на електроника е <span>{{ category_slug }}</span>.</b>
                    </div></i>
                </div>
            </div>

            <div class=" s-t-list-table">
                <div class="section-table-info-header s-t-h-title--bigger s-t-h-title--p-b">
                    Слъгове на категории
                </div>
                <div class="s-t-list s-t-list-table">
                    <div class="s-t-list-row s-t-list-row-head">
                        <div class="s-t-list-col">
                            Име
                        </div>
                        <div class="s-t-list-col">
                            Слъг
                        </div>
                    </div>
                    <template v-for="(category,index) in categories">
                        <div class="s-t-list-item s-t-list-row">
                        <div class="s-t-list-col">
                            <span class="clr-purple">{{ index + 1 }}) {{ category.name }}</span>
                        </div>
                        <div class="s-t-list-col">
                            <b>{{ category.children.length > 0 ? '...' : category.slug }}</b>
                        </div>
                    </div>
                    <template v-if="category.children">
                        <template v-for="ch in category.children">
                            <div class="s-t-list-item s-t-list-row">
                                    <div class="s-t-list-col">
                                        <span class="low-opacity">- {{ ch.name }}</span>
                                    </div>
                                    <div class="s-t-list-col">
                                        <b>{{ ch.children.length > 0 ? '...' : ch.slug }}</b>
                                    </div>
                                </div>
                                <template v-if="ch.children">
                                    <template v-for="child in ch.children">
                                        <div class="s-t-list-item s-t-list-row">
                                        <div class="s-t-list-col">
                                                <span class="low-opacity">-- {{ child.name }}</span>
                                            </div>
                                        <div class="s-t-list-col">
                                            <b>{{ child.slug }}</b>
                                        </div>
                                        </div>
                                    </template>
                                </template>
                        </template>
                    </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
  props: ['temp_cats','curr_choise'],
  data() {
    return {
    choise:this.curr_choise,
      category_slug : '',
      categories: this.temp_cats
    }
  },
  created(){
    // this.getCat();
  },
  methods: {
    getCat(){
      this.categories.filter((item)=>{
        if(item.name == 'Електроника'){
          this.category_slug = `https://${window.location.host}/ads/${item.slug}`;
        }
      })
    },
  },
}
</script>
<style scoped>
.weight-strong{
    font-weight:bold;
    display:inline-block !important;
}
  .s-t-list-item > div{
    display:flex;
    flex-direction: row;
  }
  .s-t-list-item >div.s-t-list-inner{
    flex-direction: column;
  }
  .s-t-list-table .s-t-list-item > div{
    display:table-cell;
  }
  .imp-list-padding .s-t-l-i-content{
    padding-top: 1rem;
  }
  .s-t-list-item .circle{
    margin-top:0 !important;
  }
  @media only screen and (max-width:1000px){
      .grid.grid-double{
          flex-direction:column;
      }
      .grid.grid-double > *{
          margin-left: auto !important;
          margin-right:auto !important;
          padding-left:0 !important;
          padding-right:0 !important;
      }
      .grid-double .grid-item:first-child:after{
          display:none;
      }
      .imp-list-padding .s-t-l-i-content{
          padding-top:.5rem;
      }
      .s-t-list .s-t-list-inner{
          padding-left:5rem;
      }
  }
</style>
