<template>
    <div>
        <div class="search-confurm-box search-confurm-box-icons" :class="freeze ? 'frozen_in_time' : ''"  @click="openModal = !openModal">
            <div class="form-checkbox-container">
                <div class="md-checkbox" :title="this.setSearchable ? 'Скрии от търсачката' : 'Покажи в търсачката'">
                    <input type="checkbox" v-model="this.setSearchable">
                    <div class="search-item-icon" :class="this.setSearchable == false ? 'not-searchable' : ''">
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                             x="0px" y="0px" viewBox="0 0 980 762"
                             xml:space="preserve">
                        <g>
                            <path d="M938,762c-13-2.9-21.3-11.7-29-21.9c-49.5-65.3-99.3-130.3-149-195.5c-0.4-0.5-0.9-1-1.4-1.5c-10.2,4.8-20.3,10-30.6,14.3
                                c-39.5,16.7-80.9,24.1-123.7,22.3c-132-5.4-245.3-100.2-271-230.3C311.2,238.2,344.7,144,429.6,68.9c40.9-36.1,89.4-57,143.2-65.6
                                c9-1.4,18.1-2.2,27.2-3.3c11,0,22,0,33,0c1.3,0.3,2.6,0.7,3.9,0.8C647.7,2.2,658.7,3,669.5,5c67.4,12.6,123.8,45,167.9,97.4
                                c56.4,67.1,78.2,144.7,65.7,231.6c-7.3,50.7-27.7,95.8-59.5,135.8c-8.3,10.4-17.5,20-26.4,30.2c0.3,0.5,0.8,1.2,1.3,1.8
                                c46.4,60.7,92.8,121.4,139.1,182.2c8.6,11.3,19.1,21.4,22.6,35.9c0,4,0,8,0,12c-2.5,7.3-4.8,15-11.3,19.7
                                c-5.7,4.1-12.4,6.9-18.7,10.3C946,762,942,762,938,762z M616.6,72.6c-119.7,0-217.3,97.4-217.5,217.1
                                c-0.3,119.8,97.2,217.4,217.3,217.8C736.1,507.8,834,410.1,834,290.1C834.1,170,736.7,72.6,616.6,72.6z"/>
                            <path d="M0,646c1.3-3.5,2.3-7.3,4-10.6c7.4-14.2,19.6-20.1,35.2-20.1c58.8,0,117.6,0,176.4,0c72.1,0,144.3,0,216.4,0
                                c17.8,0,31,8.4,36.8,23.1c9.4,24.1-8.3,49.5-34.6,49.5c-132.3,0-264.6,0-396.9,0c-2.2,0-4.4,0-6.5-0.4c-15.9-3.1-26.1-12.4-30-28.3
                                c-0.2-0.8-0.6-1.5-0.9-2.3C0,653.3,0,649.7,0,646z"/>
                            <path d="M0,102c0.7-2.2,1.4-4.4,2.2-6.5C7.4,81,20.5,71.7,36.8,71.7c72.1-0.1,144.3-0.1,216.4,0c19.7,0,35.2,14,36.7,32.4
                                c1.6,19.2-10.8,35.6-29.9,39.4c-3.1,0.6-6.3,0.5-9.4,0.5c-70.3,0-140.6,0-210.9,0c-20.9,0-33-9.1-39-29.3c-0.1-0.3-0.4-0.5-0.7-0.7
                                C0,110,0,106,0,102z"/>
                            <path d="M0,392c2.3-5.1,3.9-10.7,7-15.2c7.6-10.9,18.6-15.2,31.9-15.2c44.5,0.2,88.9,0.1,133.4,0.1c3,0,6-0.1,9,0
                                c20.1,0.6,34.3,14,36.2,34.1c1.7,19-14.4,38-33.5,38.1c-50.1,0.3-100.3,0.2-150.4,0c-14.7-0.1-28.3-11.8-32.4-26.8
                                C0.9,406.1,0.4,405,0,404C0,400,0,396,0,392z"/>
                            <path class="st0" d="M950,762c6.3-3.4,12.9-6.2,18.7-10.3c6.5-4.7,8.8-12.4,11.3-19.7c0,10,0,20,0,30C970,762,960,762,950,762z"/>
                        </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-delete" v-if="openModal" style="visibility: visible; opacity: 1;">
            <a href="#" class="close-modal" @click="openModal = false"><i class="icon icon-close-circle"></i></a>
            <template  v-if="this.setSearchable">
                <h3>Скриване от търсачката</h3>
                <h4>Сигурен ли си, че искаш да скриеш този продукт от търсачката?</h4>
                <p>От тук можеш да забраниш на търсачката да показва този продукт.</p>
                <div class="text-right mt-3">
                    <button type="submit" class="button small" @click="changeState()">Скрии</button>
                    <a href="#" class="button small" @click="openModal = false">Отказ</a>
                </div>
            </template>
            <template v-else>
                <h3>Показване в търсачката</h3>
                <h4>Сигурен ли си, че искаш да покажеш този продукт в търсачката?</h4>
                <p>От тук можеш да позволиш на търсачката да показва този продукт.</p>
                <div class="text-right mt-3">
                    <button type="submit" class="button small" @click="changeState()">Покажи</button>
                    <a href="#" class="button small" @click="openModal = false">Отказ</a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    props: ['product'],
    data() {
        return {
            setSearchable: this.product.is_searchable,
            setProduct : this.product,
            freeze: false,
            openModal: false,
        }
    },
    methods: {
        changeState() {
            this.openModal = !this.openModal;
            // loading(true)
            if(this.setSearchable == 0){
                this.setSearchable = 1;
            }else{
                this.setSearchable = 0;
            }
            this.freeze = true;
            axios.patch(`/product/${this.setProduct.id}/searchable`)
                .then(response => {
                    this.freeze = false;

                    this.notifySuccess('Успешно променен статус');
                    this.$emit('success', this.message)
                    // loading(false)
                })
                .catch(errors => {
                    // loading(false)
                })
        }
    }
}
</script>

