<template>
    <div :class="!showMessageThread ? 'vb-modal' : 'vb-modal vb-modal--show-messages vb-modal--showed-messages'">
        <div :class="!showMessageThread ? 'vb-modal-item vb-m-i--contacts vb-modal-item--active' : 'vb-modal-item vb-m-i--contacts'">
            <div class="modal-header">
                <div class="m-h-title">
                    Съобщения
                </div>
                <div class="m-h-close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.264 29.264">
                      <g transform="translate(1.132 1.132)">
                        <g>
                          <path d="M9674.438,1010.545a13.5,13.5,0,1,1,0-19.091A13.5,13.5,0,0,1,9674.438,1010.545Z" transform="translate(-9651.391 -987.5)" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          <g transform="translate(8.583 8.582)">
                            <line y1="9.833" x2="9.832" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                            <line x2="9.837" y2="9.835" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                </div>
            </div>
            <div class="m-h-chat container-messages">
                <div class="container-list" v-if="chats.length > 0">
                    <template v-for="chat in chats">
                        <div :key="chat.id" :class="chat.un_seen_profile_notifications.length < 1 ? 'list-item' : 'list-item list-item--unread'">
                            <div class="l-i-content" @click="showMessages(chat)">
                                <div class="l-i-title">{{ chat.sender ? chat.sender.name : chat.guest.name }}</div>
                                <div class="l-i-message" v-if="chat.last_message">
                                    <div class="l-i-label">{{ chat.last_message.sender ? chat.last_message.sender.name : "Анонимен" }}</div>
                                    <div class="l-i-m-data">
                                        {{ chat.last_message.message }}
                                    </div>
                                </div>
                                <div class="l-i-icon">
                                    <svg class=" icon-closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 17.625">
                                      <path d="M21.3,64H2.2A2.2,2.2,0,0,0,0,66.2V79.422a2.2,2.2,0,0,0,2.2,2.2H21.3a2.2,2.2,0,0,0,2.2-2.2V66.2A2.2,2.2,0,0,0,21.3,64ZM2.2,65.469H21.3a.737.737,0,0,1,.734.734v1.9c-1.005.849-2.442,2.02-6.912,5.567-.776.615-2.3,2.1-3.369,2.079-1.065.018-2.6-1.464-3.369-2.079C3.911,70.123,2.474,68.952,1.469,68.1V66.2A.737.737,0,0,1,2.2,65.469ZM21.3,80.156H2.2a.737.737,0,0,1-.734-.734V70.013c1.046.858,2.7,2.185,6,4.806.941.753,2.6,2.41,4.282,2.4,1.671.014,3.318-1.629,4.282-2.4,3.3-2.621,4.952-3.947,6-4.806v9.409A.737.737,0,0,1,21.3,80.156Z" transform="translate(0 -64)"/>
                                    </svg>
                                    <svg class=" icon-open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.848 23.848">
                                      <path d="M16.27,2.434C15.29,1.653,13.644,0,11.924,0S8.6,1.619,7.578,2.434C5.967,3.713,2.476,6.528.819,7.884A2.235,2.235,0,0,0,0,9.614v12a2.236,2.236,0,0,0,2.236,2.236H21.612a2.236,2.236,0,0,0,2.236-2.236v-12a2.235,2.235,0,0,0-.82-1.73C21.371,6.528,17.881,3.713,16.27,2.434Zm5.342,19.923H2.236a.745.745,0,0,1-.745-.745V9.6a.745.745,0,0,1,.274-.577c1.224-1,5.672-4.576,6.74-5.424.821-.654,2.316-2.111,3.419-2.111s2.6,1.456,3.419,2.111c1.068.848,5.516,4.425,6.74,5.425a.745.745,0,0,1,.274.577V21.612a.745.745,0,0,1-.745.745ZM21.2,12.282a.559.559,0,0,1-.083.787c-1.063.858-2.568,2.06-4.851,3.873-.98.782-2.649,2.446-4.347,2.434-1.7.012-3.392-1.673-4.347-2.434-2.283-1.812-3.788-3.014-4.851-3.873a.559.559,0,0,1-.083-.787l.235-.289a.559.559,0,0,1,.785-.083c1.06.856,2.562,2.056,4.841,3.865.785.626,2.338,2.128,3.419,2.111,1.081.017,2.635-1.485,3.419-2.111,2.279-1.809,3.781-3.009,4.841-3.865a.559.559,0,0,1,.785.083Z" fill="#ade8e2"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="l-i-footer" @click="showMessages(chat)">
                                <div class="l-i-time">
                                    <div class="l-i-date">{{ getDate(chat.last_message.created_at) }} -</div>
                                    <div class="l-i-hour">{{ getHours(chat.last_message.created_at) }}ч.</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="container-list" v-else>
                    Няма налични съобщения за този продукт.
                </div>
            </div>
        </div>
        <div :class="!showMessageThread ? 'vb-modal-item vb-m-i--messages' : 'vb-modal-item vb-m-i--messages vb-modal-item--active'">
            <div class="vb-modal-item-inner-container">
                <div class="modal-header" v-if="messageThread">
                    <div class="m-h-back" @click="hideMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.423 7.407"><path d="M.7,13.423A.7.7,0,0,1,.2,12.236L5.727,6.711.2,1.188A.7.7,0,0,1,1.188.2L7.2,6.219a.7.7,0,0,1,0,.984L1.188,13.219A.69.69,0,0,1,.7,13.423Z" transform="translate(13.423) rotate(90)"></path></svg>
                    </div>
                    <div class="m-h-title">
                        {{ messageThread.sender ? messageThread.sender.name : messageThread.guest.name }}
                    </div>
                    <div class="m-h-close" @click="hideMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.264 29.264">
                          <g transform="translate(1.132 1.132)">
                            <g>
                              <path d="M9674.438,1010.545a13.5,13.5,0,1,1,0-19.091A13.5,13.5,0,0,1,9674.438,1010.545Z" transform="translate(-9651.391 -987.5)" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                              <g transform="translate(8.583 8.582)">
                                <line y1="9.833" x2="9.832" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                                <line x2="9.837" y2="9.835" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                              </g>
                            </g>
                          </g>
                        </svg>
                    </div>
                </div>
                <div class="m-h-chat" v-if="messageThread">
                    <template v-for="message in messageThread.messages">
                        <div :key="getDateShort(message.created_at)" class="chat-date" v-if="dateIDS.includes(message.id)">
                            {{ getDateShort(message.created_at) }}
                        </div>
                        <div :key="message.id" :class="message.sender_id !== profileId ? 'chat-message message-sender' : 'chat-message message-receiver'">
                            <div class="c-m-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.25 35.274">
                                  <g transform="translate(-20.83 -263.683)">
                                    <circle cx="17.5" cy="17.5" r="17.5" transform="translate(20.955 263.958)" fill="none"/>
                                    <path d="M35.25,17.616A17.625,17.625,0,1,1,17.669,0,17.608,17.608,0,0,1,35.25,17.616ZM29.259,28.833a16.185,16.185,0,1,0-27.3-15.359,15.83,15.83,0,0,0,.551,10.008,15.672,15.672,0,0,0,3.449,5.386,13.278,13.278,0,0,1,8.3-6.826,6.685,6.685,0,0,1-3.306-5.084,6.539,6.539,0,0,1,2.044-5.635,6.708,6.708,0,0,1,9.475.213,6.538,6.538,0,0,1,1.849,4.977,6.755,6.755,0,0,1-3.324,5.528,13.4,13.4,0,0,1,4.871,2.524A13.266,13.266,0,0,1,29.259,28.833Zm-11.27,5c.444-.036,1.244-.053,2.044-.178a16.089,16.089,0,0,0,7.75-3.413c.427-.338.427-.338.178-.835a11.558,11.558,0,0,0-12.07-6.239,11.436,11.436,0,0,0-8.675,6.346c-.178.338-.089.5.178.711A16.608,16.608,0,0,0,17.989,33.828Zm-.356-12.319a5.315,5.315,0,1,0-5.333-5.3A5.341,5.341,0,0,0,17.634,21.509Z" transform="translate(20.83 263.684)"/>
                                  </g>
                                </svg>
                            </div>
                            <div class="c-m-message">
                                <div class="c-m-time">
                                    {{ getHours(message.created_at) }}
                                </div>
                                <div class="c-m-m-content">
                                    {{ message.message }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="lastMessage" ref="lastMessage" id="lastMessage"></div>
                </div>
                <div class="m-h-write-container">
                    <div class="m-h-write">
                        <input type="text" name="message" class="m-h-message-input" placeholder="Напишете съобщението си тук..." v-model="newMessage" autocomplete="off">
                        <div class="m-h-icon" @click="sendMessage()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.485 21.492">
                              <path d="M19.986.1.534,11.327A1.008,1.008,0,0,0,.627,13.14l4.461,1.872L17.145,4.386a.252.252,0,0,1,.361.348L7.4,17.051V20.43a1.007,1.007,0,0,0,1.784.663l2.665-3.244,5.229,2.191a1.01,1.01,0,0,0,1.385-.764L21.48,1.146A1.007,1.007,0,0,0,19.986.1Z" transform="translate(-0.01 0.031)"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div :class="loading ? 'modal-loader' :  'modal-loader loading-div'">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 507.2 478.5" xml:space="preserve">
                    <g>
                        <path d="M463.8,280.3c-24.1-0.1-43.1-19.4-43-43.5c0.1-23.7,19.6-42.8,43.5-42.6c23.6,0.1,42.9,19.6,42.8,43.2
                            C507.1,261.1,487.6,280.4,463.8,280.3z"/>
                        <path d="M476.8,144.1c0,22.2-17.9,40-40.1,40c-22.2,0-40-17.9-39.9-40.1c0-22,17.9-39.8,39.9-39.9
                            C458.9,104.1,476.8,121.9,476.8,144.1z"/>
                        <path d="M373.3,109.1c-20.6,0-37-16.4-37-36.9c0-20.3,16.5-36.8,36.8-36.9c20.4,0,37.1,16.6,37,37
                            C410.1,92.6,393.6,109,373.3,109.1z"/>
                        <path d="M284.6,67.7c-18.7-0.1-33.6-15.2-33.6-34C251.1,15.2,266.4,0,285,0c18.8,0,34,15.4,33.9,34.4
                            C318.7,52.9,303.4,67.8,284.6,67.7z"/>
                        <path d="M219.5,36.5c0.1,17-13.4,30.6-30.5,30.8c-17,0.2-30.8-13.5-31-30.4c-0.1-17,13.7-31,30.7-31
                            C205.7,5.8,219.5,19.5,219.5,36.5z"/>
                        <path d="M130.6,79.7c0,15.3-12.2,27.7-27.5,27.8C87.8,107.6,75.3,95.3,75.2,80c-0.1-15.4,12.3-27.9,27.7-27.9
                            C118.2,52.1,130.5,64.4,130.6,79.7z"/>
                        <path d="M18.8,155.3c-0.1-13.6,10.9-24.7,24.5-24.7c13.6-0.1,24.7,10.8,24.8,24.4c0.1,13.5-10.9,24.6-24.5,24.8
                            C30,179.9,18.9,168.8,18.8,155.3z"/>
                        <path d="M43.1,248.6c0,12-9.5,21.6-21.5,21.6C9.7,270.2,0,260.5,0,248.7c0-11.9,9.6-21.5,21.5-21.6
                            C33.5,227.1,43.1,236.6,43.1,248.6z"/>
                        <path d="M59.8,342.6c0.1,10.2-8.2,18.5-18.4,18.5c-10.1,0-18.4-8.1-18.5-18.2c-0.1-10.3,8-18.6,18.3-18.7
                            C51.4,324.2,59.7,332.3,59.8,342.6z"/>
                        <path d="M114.5,419.7c-0.2,8.5-7.5,15.3-15.9,15c-8.5-0.3-15.2-7.5-14.9-16c0.3-8.3,7.4-15,15.8-14.8
                            C108,404.1,114.7,411.2,114.5,419.7z"/>
                        <path d="M171.7,464c0.1-6.8,5.6-12,12.4-11.9c6.9,0.1,12.3,5.7,12.1,12.6c-0.2,6.8-5.8,12.1-12.4,12
                            C176.9,476.6,171.6,471,171.7,464z"/>
                        <path d="M280,460c5.1,0,9.1,4,9.2,9.1c0.1,5.2-4.1,9.4-9.2,9.5c-5.1,0-9.3-4.3-9.2-9.4C270.9,464,275,460,280,460z"/>
                        <path d="M375.2,433c0,3.3-2.8,6-6.1,6.1c-3.3,0-6.1-2.7-6.2-6c-0.1-3.4,2.8-6.3,6.2-6.2C372.4,427,375.2,429.7,375.2,433z"/>
                        <path d="M437.7,363.1c-1.1,0.8-2.3,2.4-3.4,2.3c-1.1-0.1-2.1-1.7-3.2-2.7c1-1,1.8-2.8,2.9-3c1-0.2,2.4,1.2,3.6,2
                            C437.7,362.2,437.7,362.7,437.7,363.1z"/>
                    </g>
                </svg>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Message from '../../messages/Message'

export default {

    props: ['profile_id','threads','product_id'],
    components: { 
        Message,
    },
    data() {
        return {
            'activeMessageThread': null,
            'profileId': this.profile_id,
            'chats' : this.threads ? this.threads : [],
            'newCounter': 0,
            'errors' : '',
            'messageThread': false,
            'showMessageThread': false,
            'loading': true,
            'dateBubble': [],
            'dateIDS': [],
            'newMessage': '',
            'productID': this.product_id,
        }
    },
    created(){
        // console.log(this.chats)
        if(this.chats.length > 0){
            this.getNewThreads();
        }
        this.startListeningForNewMessages();
        this.enterKeyEvent();
    },
    methods: {
        // submitMessage(thread){
        //     console.log('inside message index')
        //     console.log('thread');
        //     console.log(thread);
        //     this.chats.map((item)=>{
        //         if(item.id == thread.id){
        //             item = thread;
        //         }
        //     });
        // },
        startListeningForNewMessages() {
            const timer = setInterval(() => {
                if (window.Echo === undefined) {
                    return;
                }
                // if (this.activeMessageThread === null) {
                //     return;
                // }

                clearInterval(timer);


                let channel = window.Echo.channel(`messages.${this.profileId}`).listen('NewMessageEvent', (e) => {
                    // console.log('listening message message table modal')
                    // console.log(e.data);
                    let activeChart = this.chats.filter((item)=>{
                        if(item.id == e.data.messageThread.id){
                            // return item;
                            item.last_message.created_at = e.data.message.created_at;
                            item.last_message.id = e.data.message.id;
                            item.last_message.message = e.data.message.message;
                            item.last_message.message_thread_id = e.data.message.message_thread_id;
                            item.last_message.receiver_id = e.data.message.receiver_id;
                            item.last_message.sender_id = e.data.message.sender_id;
                            // item.last_message.sender_id = e.data.message.sender_id;
                            // item.last_message.sender_id = e.data.message.sender_id;
                            item.messages = [...item.messages,e.data.message];
                            this.messageThread.messages = item.messages;

                            if(e.data.message.sender_id !== this.profileId){
                                item.un_seen_profile_notifications = [...item.un_seen_profile_notifications,['new message']];
                            }

                            // console.log('this is the thread')
                            // console.log(item);
                            this.scrollToLast(true);
                            return item;
                        }
                    });
                    if(activeChart.length == 0 && this.productID == e.data.messageThread.product_id){
                        this.fetchThread(e.data.messageThread.id);
                    }
                });

            }, 300);
        },
        getNewThreads(){
            this.chats.map((item)=>{
                if(item.un_seen_profile_notifications.length > 0){
                    this.newCounter = this.newCounter + 1;
                }
            });
        },
        getDate(date){
            moment.locale('bg');
            return moment(date).format('DD / MMMM / Y');
        },
        getDateShort(date){
            moment.locale('bg');
            return moment(date).format('DD MMM');
        },
        getHours(date){
            return moment(date).format('HH:mm');
        },
        showMessages(chat){
            axios.post(`../../dashboard/message/resetMessageNotificationsByID`, { 
              'id': chat.id,
              }).then((response) => {
                  if (response.data.type == 'success') {
                    //  this.seenCounter = 0;
                    this.messageThread = response.data.thread;
                    this.showMessageThread = true;
                    this.dateBubble = [];
                    this.dateIDS = [];
                    this.messageThread.messages.map((item)=>{
                        let date = this.getDate(item.created_at);
                        let flag = this.dateBubble.includes(date);
                        if(!flag){
                            this.dateBubble.push(date);
                            this.dateIDS.push(item.id);
                        }
                    });
                    this.scrollToLast(false)
                  }else if(response.data.type == 'error'){
                      this.$snotify.error(response.data.message);
                  }
              })
              .catch((errors) => {
                  // this.errors = errors.response.data.errors
              })
            
        },
        scrollToLast(smooth){
           
            if(smooth){
                setTimeout(()=>{
                     let element = document.querySelector('#lastMessage');
            // console.log('top')
            // console.log(element.parentNode.scrollTop)
            // console.log('height')
            // console.log(element.parentNode.scrollHeight)
                    element.parentNode.scrollTop = element.parentNode.scrollHeight;
                },50)
            }else{
                setTimeout(()=>{
                     let element = document.querySelector('#lastMessage');
            // console.log('top')
            // console.log(element.parentNode.scrollTop)
            // console.log('height')
            // console.log(element.parentNode.scrollHeight)
                    this.loading = false;
                    element.parentNode.scrollTop = element.parentNode.scrollHeight;
                },500)
            }
        },
        hideMessage(){
            this.showMessageThread = false;
            this.messageThread = false;
            this.loading = true;
            this.newMessage = '';
        },
        sendMessage(){
            let timeNow = moment().format('Y-MM-DD HH:mm');
            /*Last message update*/
            this.messageThread.last_message.message = this.newMessage;
            this.messageThread.last_message.created_at = timeNow;
            this.messageThread.last_message.sender_id = this.profileId;
            this.messageThread.last_message.receiver_id = this.messageThread.receiver_id == this.profileId ? this.messageThread.sender_id : this.messageThread.receiver_id;
            // console.log('message');
            // console.log(this.messageThread.last_message)
            // console.log('thread');
            // console.log(this.messageThread)

            /*send message request here*/
            axios.post(`message/store`, { 
                'data':this.messageThread.last_message,
                }).then((response) => {
                    if (response.data.type == 'success') {
                        /*Add message to Que*/
                        let tempMessageObject = {
                            'created_at': timeNow,
                            'message': this.newMessage,
                            'message_thread_id': this.messageThread.id,
                            'receiver_id':response.data.thread.receiver_id == this.profileId ? response.data.thread.sender_id : response.data.thread.receiver_id,
                            'sender_id':this.profileId,
                        }
                        this.newMessage = '';
                        if(tempMessageObject.receiver_id != tempMessageObject.sender_id){
                            this.messageThread.messages = [...this.messageThread.messages,tempMessageObject];
                        }

                        this.messageThread.un_seen_profile_notifications = [];
                        // this.$emit('sendMessage',this.messageThread);
                        let date = this.getDate(timeNow);
                        let flag = this.dateBubble.includes(date);
                        if(!flag){
                            this.dateBubble.push(date);
                            this.dateIDS.push(item.id);
                        }
                        // this.showMessages(messageThread)
                        this.scrollToLast(true);
                        // console.log(response.data)
                        this.decreaseCounter(response.data.thread.product_id);
                    }else if(response.data.type == 'error'){
                        this.$snotify.error(response.data.message);
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
        },
        enterKeyEvent(){
            document.addEventListener('keydown', (event) => {
              if (event.keyCode === 13 && event.target.nodeName === 'INPUT') {
                    if(this.newMessage !== '' && this.newMessage !==  ' '){
                        this.sendMessage();
                    }
              }
            });
        },
        fetchThread(id){
            /*send message request here*/
            axios.get(`message/${id}/getThreadByID`)
                .then((response) => {
                    if (response.data.type == 'success') {
                        /*Add message to Que*/
                        this.chats = [response.data.thread,...this.chats];
                        // console.log('response')
                        // console.log(response.data.thread)
                        if(response.data.thread){
                            this.updateCounter(response.data.thread.product_id);
                        }
                    }else if(response.data.type == 'error'){
                        this.$snotify.error(response.data.message);
                    }
                })
                .catch((errors) => {
                    // this.errors = errors.data.errors
                })
        },
        updateCounter(id){
            if(id !== null){
                // console.log('update counter')
                let container = document.querySelector(`*[data-modal="messages-modal-${id}"]`); 
                let parent = document.querySelector(`*[data-modal="messages-modal-${id}"] .i-d-item-icon`);
                let counter = parent.querySelector('.i-d-item-badge');
                if(container.classList.contains('show-modal-unactive')){
                    container.classList.remove('show-modal-unactive')
                }
                // console.log('parent')
                // console.log(parent)
                // console.log('counter')
                // console.log(counter)
                if(counter){
                    counter.textContent = parseInt(counter.textContent) + 1;
                }else{
                    let dummy = `<div class="i-d-item-badge">
                                    1
                                </div>`;
                    parent.innerHTML = parent.innerHTML + dummy;
                }

            }
        },
        decreaseCounter(id){
            if(id !== null){
                // console.log('decrease counter')
                let parent = document.querySelector(`*[data-modal="messages-modal-${id}"] .i-d-item-icon`);
                let counter = parent.querySelector('.i-d-item-badge');
                // console.log('parent')
                // console.log(parent)
                // console.log('counter')
                // console.log(counter)
                if(counter){
                    let temp = parseInt(counter.textContent) - 1;
                    if(temp < 1){
                        parent.removeChild(counter);
                    }else{
                        counter.textContent = parseInt(counter.textContent) - 1;
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
.list-item{
    cursor: pointer;
}
.l-i-message{
    margin-left: auto !important;
    margin-right: 15px;
}
.l-i-icon{
    margin-right: 0;
}
.m-h-title{
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.l-i-date{
    text-transform: capitalize;
   }
   .lastMessage{
    height: 50px;
    display: block;
    width:100%;
   }
   .modal-loader{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    background-color:#fff;
    width:100%;
    height: 100%;
    z-index:9000;
   }
   .modal-loader.loading-div{
    display: none;
   }
   .modal-loader svg{
        fill:#913f98;
        width:50px;
        height: 50px;
        margin:auto;
        animation:spin .5s infinite linear;
   }
   .m-h-chat::-webkit-scrollbar {
      display: none;
    }
    .m-h-chat {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
   @keyframes spin{
        from{
            transform: rotate(0);
        }
        to{
            transform: rotate(360deg);
        }
   }
</style>