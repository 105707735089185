<template>
    <form @submit.prevent="post" class="form" method="post" enctype="multipart/form-data" autocomplete="off">
        <section class="section-dashboard">
            <div class="container">
                <div class="grid bg-white">
                    <div class="col-12">

                        <div class="grid">
                            <div class="col-6 col-lg-12">
                                <div class="extend--dostavki">

                                    <div class="form-title">
                                        Настройки за E-mail известия
                                    </div>

                                    <div class="form-group mb-0">

                                        <!-- <label v-for="notification in notifications" class="md-checkbox">
                                            <input v-model="notify" type="checkbox" value="1" checked="checked">
                                            <span class='md-transition'></span><em>Постъпила нова поръчка</em>
                                            <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a>
                                        </label> -->

                                        <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="1">
                                            <span class='md-transition'></span><em>Постъпила нова поръчка</em>
                                            <!-- <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a> -->
                                        </label>

                                        <!-- <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="2">
                                            <span class='md-transition'></span><em>Известяване при забавяне (над 3
                                                дни) на генериране на товарителница</em>
                                            <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a>
                                        </label> -->

                                        <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="3">
                                            <span class='md-transition'></span><em>Изчерпване на количество по
                                                продукт</em>
                                            <!-- <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a> -->
                                        </label>

                                        <!-- <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="4">
                                            <span class='md-transition'></span><em>Доставена поръчка</em>
                                            <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a>
                                        </label> -->

                                        <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="5">
                                            <span class='md-transition'></span><em>Нови функционалности в
                                                платформата Pop Up от Еконт</em>
                                            <!-- <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a> -->
                                        </label>

                                        <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="6">
                                            <span class='md-transition'></span><em>Рекламни съобщения</em>
                                            <!-- <a href="#" class="toggle toggle-top-left" data-text="Линкът за продажба на този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i class="icon icon-info"></i></a> -->
                                        </label>

                                        <label class="md-checkbox">
                                            <input v-model="notification" type="checkbox" value="8">
                                            <span class='md-transition'></span><em>Грешки при автоматичен импорт на продуктов списък</em>
                                        </label>

                                    </div>

                                </div>
                            </div>

                            <div class="col-6 col-lg-12">
                                <div class="form-title">
                                    Изпращане на известия до:
                                </div>

                                <div v-for="(item, index) in items" class="form-group" >
                                    <label for="">E-mail<sub class="is-required">*</sub></label>
                                    <div class="flex">
                                        <input type="text" name="email[]" v-model="item.email" :class="errors && errors[`emails.${index}.email`] ? 'is-error-media' : ''" @keyup="errors[`emails.${index}.email`] = null">
                                        <a @click="deleteItem(index)" href="#" class="button ml-2 btn-centered">Изтрий</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 custom-buttons-blq">
                            <div class="text-right">
                                <a @click="addItem" href="#" class="button button-border">Добави нов E-mail</a>
                            </div>
                            <div class="text-right mt-3">
                                <button type="submit" class="button">Запази промените</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </form>
</template>

<script>
import FormError from '../FormError.vue'

export default {
    props: ["user-emails", "notification-emails"],
    data() {
        return {
            items: this.userEmails,
            notification: this.notificationEmails,
            errors: [],
        };
    },
    methods: {
        post() {
            var self = this;
            axios.post(`/notification`, {'emails': this.items, 'notification': this.notification})
                .then(function(response) {
                    self.errors = [];
                    if (response.data.success) {
                        window.location = response.data.redirect
                    }
                })
                .catch(function(errors) {
                    self.errors = errors.response.data.errors;
                });
        },
        addItem() {
            this.items.push({
                email: ""
            });
        },
        deleteItem(index) {
            this.errors = [];
            this.items.splice(index, 1);
        }
    },
    mounted() {
        if (!this.userEmails.length) {
            this.addItem();
        }
    }
};
</script>
