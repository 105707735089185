<template>
  <div class="modal modal-delete modal-extend modal-add-product" data-id="add-feed">
        <a href="#" class="close-modal"><i class="icon icon-close-circle"></i></a>
        <div class="text-left">
            <h3>Добавяне на нов product feed</h3>
            <div class="radio-buttons md-radio-fieldset">
              <label class="md-radio">
                  <input type="radio" name="choise" @click="useFeed = !useFeed; productFile = false;" 
                                        :checked="useFeed">
                                        <i class="md-transition"></i>
                                        <span>Използвай линк</span>
              </label>

              <label class="md-radio">
                  <input type="radio" name="choise" @click="useFeed = !useFeed; url = ''" 
                                        :checked="!useFeed">
                                        <i class="md-transition"></i>
                                        <span>Използвай файл</span>
              </label>
            </div>
            <p class="modal-data" v-if="useFeed">Тук може да добавите линк към product feed.<br>Aвтоматично на всеки два дни ще актуализираме линка за нови продукти.</p>
            <p class="modal-data" v-else>Тук може да качите файл с product feed, който еднократно да се качът продукти</p>
            <div class="price-wrapper form-group"  v-if="useFeed">
                    <input type="text" v-model="url" placeholder="Линк към product feed" />
                </div>
                <div class="price-wrapper"  v-else>
                    <input type="file" name="import_file" ref="file" class="imp-input" @change="setFile($event)" accept=".xml">
                </div>
            <div class="text-right mt-3">
                <div type="submit" class="button small s-t-m-l" @click="useFeed ? storeUrl() : validateFile()"> {{ useFeed ? 'Запази' : 'Качи' }} </div>
                <a href="#" class="button small mint close-modal" ref="close">Отказ</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  // props: ['shops'],
  data() {
    return {
      useFeed: true,
      url: '',
      productFile: false,
      is_active: false,
      urlStore:'/dashboard/feed/store',
      fileStore: '/dashboard/feed/file/store',
      fileValidate: '/dashboard/feed/file/validateFile',
    }
  },
  mounted() {
    
  },
  methods: {
  setFile(event){
      // let validate = this.validateFile(event);
      // if(validate){
        this.is_active = true;
        this.productFile = event.target.files[0]; 
      // }
    },
    triggerInput(){
      this.$refs.file.click()
    },
    storeUrl(){
      let data = new FormData();
      data.append("source", this.url);
      axios.post(this.urlStore, data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }).then((response) => {
                  if(response.data.type == 'success'){
                    this.notifySuccess(response.data.message)
                    this.$refs.close.click()
                  }else{
                    this.notifyError(response.data.message)
                  }
                });
    },
    validateFile(){
      this.$snotify.async(`Проверяване на файла.`, 'Success async', () => new Promise((resolve, reject) => {
            if(this.productFile){
              let data = new FormData();
              data.append("file", this.productFile);
              axios.post(this.fileValidate, data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((response) => {
                    if(response.data.type == 'success'){
                      resolve({
                                title: 'Success',
                                body: response.data.message,
                                config: {
                                    // timeout: 3000,
                                    closeOnClick: true
                                }
                            });
                    }else{
                      reject({
                          title: 'Error',
                          body: response.data.message,
                          config: {
                              // timeout: 10000,
                              closeOnClick: true
                          }
                      });
                    }
                });

            }else{
              reject({
                        title: 'Error',
                        body: 'Моля изберете файл.',
                        config: {
                            timeout: 10000,
                            closeOnClick: true
                        }
                    });
            }
      }));
    },
    storeFile(){
      this.$snotify.async(`Качване на ${this.productNumbers} продукта.`, 'Success async', () => new Promise((resolve, reject) => {
            // this.interVal = setInterval(this.getProductNumber(),1000);
            this.$snotify.html(`Качване на <i style="display:inline;" id="updateCounter">${this.pCounter}</i> от ${this.productNumbers}.`,{
              showProgressBar: true,
              timeout:Math.floor(this.productNumbers / 1) * 700,
            });
            this.interVal = setInterval(this.getProductNumber,600);
            let data = new FormData();
            data.append("file", this.file);
           
            data.append('is_sender_paying',this.is_sender_paying);
                axios.post(this.urlStore, data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((response) => {
                    if(response.data){
                        if(response.data.type == 'success'){
                          this.for_category = true;
                          // this.products = response.data.products;
                          this.is_active = false;
                          if(response.data.for_categories){
                            this.active_category = true;
                          }
                          this.file = null;
                          this.chosen_shop = response.data.shop;
                          resolve({
                                title: 'Success',
                                body: response.data.message,
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            });
                          setTimeout(()=>{
                            this.$snotify.clear();
                          },3000);
                        }else if(response.data.type == 'error'){
                          this.$snotify.clear();
                          this.notifyError(response.data.message);
                        }
                    }
                    // this.notifyError(response.data.message);
                    // this.loader = false
                    
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                    reject({
                                title: 'Error',
                                body: 'Възникна грешка. Моля опитайте отново',
                                config: {
                                    timeout: 10000,
                                    closeOnClick: true
                                }
                            });
                    this.loader = false
                })
        }),
        // {
        //       showProgressBar: true,
        //       timeout: Math.floor(this.productNumbers / 2) * 1000,
        //     }
            );
    }
  },
}
</script>
<style scoped>
  .modal-data{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .radio-buttons *:first-child{
    margin-right: 3rem;
  }
  .button.close-modal{
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }
  .text-right{
    flex-direction: row;
  }
</style>