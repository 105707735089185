<template>
    <div v-if="visible" class="modal-contact-container modal-contact-container-active" ref="container">
        <div class="modal modal-choose-how-to-proceed" style="visibility: visible; opacity: 1; z-index: 9994;" ref="modal">
            <span class="close-modal" @click="hideReminder"><i class="icon icon-close-circle"></i></span>
            <h3>Данни на продавач</h3>
            <div class="form">
                <span>
                Препоръчваме да добавиш телефон в публичния си профил.
                Така купувачите ще се свързват с теб по-лесно.
                </span>
            </div>
            <div>
                <div class="grid">
                    <div class="col-12">
                        <div class="form-group mb-0 text-right">
                            <button class="button small" @click="disableReminder">Не показвай за 3 месеца</button>
                            <a rel="nofollow" :href="settingsLink" class="button small">
                                Към Моят Профил
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-overlay" style="z-index: 9993; opacity: 0.9;"></div>
    </div>
</template>

<script>
import {getCookie, setCookie} from "tiny-cookie";

const PHONE_REMINDER_COOKIE_NAME = 'hide-phone-reminder';
const PHONE_REMINDER_COOKIE_MAX_AGE = 3 * 30 * 24 * 60 * 60;

export default {
    name: "PublicPhoneReminderModal",
    props: ['settingsLink'],
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        showReminder() {
            this.visible = true;
        },
        hideReminder() {
            this.visible = false;
        },
        disableReminder() {
            this.hideReminder();
            setCookie(
                PHONE_REMINDER_COOKIE_NAME,
                true,
                {
                    samesite: 'Lax',
                    secure: true,
                    "max-age": PHONE_REMINDER_COOKIE_MAX_AGE,
                }
            );
        },
    },
    created() {
        if (this.settingsLink !== location.origin + location.pathname
            && getCookie(PHONE_REMINDER_COOKIE_NAME) === null
        ) {
            this.showReminder();
        }
    },
}
</script>
