import Vue from 'vue'
import Vuex from 'vuex'
import createLink from './modules/createLink'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    createLink,
  },
})