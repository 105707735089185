'use strict'

import debug from '../../utils/debug'
import $ from 'jquery'
import gsap from 'gsap'
import { Power4 } from "gsap/all"

window.$ = window.jQuery

gsap.registerPlugin( Power4 )

export default class Artefact {

	constructor( $element ) {

		this.$ = $element
		this.$w = $(window)
		this.$background = this.$.find('i')

		this.animation_speed = .3
		this.animation_delay = .135

		this.set()
		this.init()

	}

	init() {

		this.animate( true )
		this.reveal()
		setTimeout(() => {
			this.scroll()
		}, 100 )

	}

    reveal() {
		if( this.$.hasClass('no--reveal') ) {
			return
		}
		gsap.fromTo( this.$, .6, { opacity: 0, marginTop: 200 }, { opacity: 1, marginTop: 0, ease: Power4.easeOut } )
	}

    scroll() {

        this.$w.scroll(() => {
            this.animate()
        });
    }

    animate( prevent ) {

		prevent = prevent || false

		let props = {};
        let viewport = this.viewport()
        let percent = viewport.percent;

		for ( let [ key, value ] of Object.entries( JSON.parse( this.$.attr('props') ) ) ) {
			props[ key ] = value * percent;
		}

        if ( viewport.visible ) {

			// initial
            if( prevent ) {
				gsap.set( this.$, props )
			}
			// animation
			else{

				props['delay'] = this.animation_delay
				gsap.to( this.$, this.animation_speed, props )


				if( this.$background.length ) {
					gsap.to( this.$background, this.animation_speed, {
						opacity: 1 - ( 1 * percent )
					})
				}

			}

        }

    }

    set() {

		this.window_height = this.$w.innerHeight();
        this.element_height = this.$.outerHeight();
        this.top_of_element = this.$.offset().top;
        this.bottom_of_element = this.$.offset().top + this.element_height;

	}

    viewport() {

        let bottom_of_screen = this.$w.scrollTop() + this.window_height + ( 600 );
        let top_of_screen = this.$w.scrollTop();

        return {
            visible: ( bottom_of_screen > this.top_of_element ) && ( top_of_screen < this.bottom_of_element ),
            percent: ( ( this.top_of_element + this.element_height ) - top_of_screen ) / ( this.window_height + this.element_height )
        };

    }

}
