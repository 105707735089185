<template>
    <div class="modal modal-cropper" data-id="media-cropper" v-if="show" style="visibility: visible; opacity: 1;">
        <a href="#" class="close-modal" @click="$emit('close')"><i class="icon icon-close-circle"></i></a>
        <h3 class="mt-3">Избери каква част от снимката да се показва</h3>

        <div class="img-container">
            <img :src="src" ref="image">
        </div>

        <div class="action-buttons">
            <div class="action-buttons__group">
                <button type="button" class="button" title="Zoom In" @click="cropper.zoom(0.1)">
                    <!-- <span class="fa fa-search-plus"></span> -->
                    <span class="fa fa-plus"></span>
                </button>
                <button type="button" class="button" title="Zoom Out" @click="cropper.zoom(-0.1)">
                    <!-- <span class="fa fa-search-minus"></span> -->
                    <span class="fa fa-minus"></span>
                </button>
                <button type="button" class="button" @click="cropper.rotate(90)" title="Rotate Right">
                    <span class="fa fa-redo-alt"></span>
                </button>
                 <button type="button" class="button" @click="cropper.reset()" title="Clear">
                    <span class="fa fa-sync-alt"></span>
                </button>
                 <div class="text-right">
                 <!-- <div class="text-right mt-3"> -->
                    <!-- <a href="#" class="button mint small" @click="$emit('close')">Обратно</a> -->
                    <a @click="submit" href="#" class="button small">Продължи</a>
                </div>
            </div>

            <!-- <div class="action-buttons__group">
                <button type="button" class="button" @click="cropper.rotate(-90)" title="Rotate Left">
                    <span class="fa fa-undo-alt"></span>
                </button>
                <button type="button" class="button" @click="cropper.rotate(90)" title="Rotate Right">
                    <span class="fa fa-redo-alt"></span>
                </button>
            </div>

            <div class="action-buttons__group">
                <button type="button" class="button" @click="cropper.reset()" title="Clear">
                    <span class="fa fa-sync-alt"></span>
                </button>
            </div> -->
        </div>

        <!-- <div class="text-right mt-3">
            <a href="#" class="button mint small" @click="$emit('close')">Обратно</a>
            <a @click="submit" href="#" class="button small">Продължи</a>
        </div> -->
    </div>
</template>

<script>
import Cropper from "cropperjs";

export default {
    props: {
        src: String,
        name: String,
        ext: String,
        video: String,
    },
    data() {
        return {
            cropper: {},
            image: {},
            show: true,
            rotate: 0,
            scaleXX: -1,
            scaleYY: -1
        };
    },
    methods: {
        submit() {
            let canvas = this.cropper.getCroppedCanvas({
                    fillColor: '#fff',
                });
            canvas.toBlob(blob => {
                var data = new FormData();
                data.append("media[]", blob);
                data.append("name", this.name);
                data.append("ext", this.ext);
                if (this.video && this.video != '') {
                    data.append("video", this.video);
                }
                axios.post(`/media`, data).then(response => {
                    let index  = this.$store.state.createLink.product.media.findIndex(e => e.name == response.data.media[0].name);
                    this.$store.state.createLink.product.media[index] = response.data.media[0]

                    this.$emit("setImage");
                    this.gsapCloseModal()
                    document.querySelector('.modal-overlay').remove()
                });
            });
        },
        scaleX() {
            this.cropper.scaleX(this.scaleXX);
            if (this.scaleXX == -1) {
                this.scaleXX = 1;
            } else {
                this.scaleXX = -1;
            }
        },
        scaleY() {
            this.cropper.scaleY(this.scaleYY);
            if (this.scaleYY == -1) {
                this.scaleYY = 1;
            } else {
                this.scaleYY = -1;
            }
        }
    },
    mounted() {
        // let img = this.$refs.image
        // let canvas = document.createElement("canvas"),ctx = canvas.getContext("2d");
        // canvas.width  = img.width  / (img.width / 550);
        // canvas.height = img.height / (img.width / 550);
        // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // img.src = canvas.toDataURL();

        // this.image = img
        this.image = this.$refs.image
        this.cropper = new Cropper(this.image, {
            dragMode: 'move',
            // aspectRatio: 5 / 3,
            aspectRatio: 1.91 / 1,
            autoCropArea: 0.85,
            restore: false,
            guides: false,
            center: false,
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            fillColor: '#fff',
            cropStart() {
                const { buttons, button } = event;
                if (
                    this.disabled
                    || ((
                    event.type === 'mousedown'
                    || (event.type === 'pointerdown' && event.pointerType === 'mouse')
                    ) && (
                    (isNumber(buttons) && buttons !== 1)
                    || (isNumber(button) && button !== 0)
                    || event.ctrlKey
                    ))
                ) {
                    return;
                }
            },
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas({
                    fillColor: '#fff',
                });
                this.destination = canvas.toDataURL("image/png");
            },
        });
    }
};
</script>



<style scoped>
@import url("~cropperjs/dist/cropper.min.css");

.img-container {
    margin: 20px auto;
    max-width: 640px;
}

.img-container img {
    max-width: 100%;
}
.action-buttons {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0px;
    /*margin-bottom: 30px;*/
}
.action-buttons__group{
    display:flex;
    width:100%;
    align-items: center;
}
.action-buttons__group .button{
    margin-right: 13px;
}
.modal-cropper .action-buttons__group > .button{
    width:26px;
    height: 26px;
    display:flex;
    background-color:transparent;
    border:1.5px solid #913f98;
    color:#913f98;
    border-radius: 50%;
    padding:0;
    font-size:12px;
}
.modal-cropper .action-buttons__group > .text-right{
    margin-left: auto;
    margin-top:auto !important;
}
.modal-cropper .button span{
    margin:auto;
}
/* .modal-cropper {
    max-width: 500px;
} */
@media (max-width: 550px) {
    .modal-cropper {
        bottom: unset !important;
        top: 50% !important;
        max-height: 100vh;
    }
    .modal-cropper h3{
        font-size:18px;
        margin-bottom: 14px;
    }
}

</style>