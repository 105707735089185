<template>
    <sub class="badge-counter" v-if="eCounter">{{ eCounter }}</sub>
</template>
<script>
    export default {
    	props: ['link','counter','profile_id'],
        data() {
            return {
                eRoute: this.link,
                eCounter: this.counter,
                profileID: this.profile_id,
            }
        },
        created() {
            this.startListening();
            console.log('listening')
        },
        methods: {
           startListening(){
	           	const timer = setInterval(() => {
	                if (window.Echo === undefined) {
	                    return;
	                }

	                clearInterval(timer);


	                let channel = window.Echo.channel(`messages.${this.profileID}`).listen('NewMessageEvent', (e) => {

	                    if(this.eCounter < 99){
		                    this.eCounter += 1;
		                }
	                });

	            }, 300);
           }
        },

    }
</script>
<style scoped>
    .badge-counter{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    background-color: #ade8e2;
    font-size:13px;
    line-height:21px;
    color: #913f98;
    font-weight: bold;
    text-align: center;
}
</style>