'use strict'

import debug from './utils/debug'
import $ from 'jquery'
import gsap from 'gsap'

window.$ = window.jQuery

export default class Mobile {

	constructor() {

        this.$hamburger = $('.hamburger')
        this.$main = $('.site-main')

		this.mobile()

	}

    mobile() {

        this.$hamburger.on('click', () => {

			this.$hamburger.toggleClass('is-active')

			if( ! this.$hamburger.hasClass('is-active') ) {
				gsap.to( this.$main, .2, { y: 30, opacity: 0, visibility: 'hidden' });
			}else{
				gsap.to( this.$main, .2, { y: 0, opacity: 1, visibility: 'visible' });
			}

		})

    }

}
