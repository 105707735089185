<template>
    <div class="modal modal-delete" :data-id="`delete-order-${order.id}`">
        <a href="#" class="close-modal" @click="$emit('close')"><i class="icon icon-close-circle"></i></a>
        <h3>Изтриване</h3>
        <h4>Сигурен ли си, че искаш да изтриеш поръчката?</h4>
        <p>Всички активни поръчки на този продукт ще бъдат изтрити. Не забравяй да уведомиш изчакващ купувач, че отказваш поръчката му.</p>
        <div class="text-right mt-3 flex">
            <div>
                <form :action="'/order/'+order.id" method="POST">
                    <input type="hidden" name="_method" value="DELETE">
                    <input type="hidden" name="_token" :value="token" />
                    <button type="submit" class="button small mint" tabindex="1">
                        Изтрий
                    </button>
                </form>
            </div>
            <a href="#" class="button small c-m" @click="$emit('close')" tabindex="2">Отказ</a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["order"],
    computed: {
        token() {
            return document.head.querySelector("[name=csrf-token]").content;
        }
    }
};
</script>