'use strict'

require('./app');
require('./bootstrap');


import debug from './utils/debug'
import $ from 'jquery'

import Header from './client/header'
import Animations from './client/animations/animations'
import Mobile from './mobile'
import Cookies from './cookies'
import Modal from './modal'
import Form from './dashboard/form'
import dependency from './dashboard/dependency'
import imagesLoaded from 'imagesloaded'
import flickity from 'flickity'
import gsap from 'gsap'
import { Power4 } from "gsap/all"

window.$ = window.jQuery
window.client = window.client || {}

gsap.registerPlugin( Power4 )

class Client {

	constructor() {

		$(document).ready(() => this.ready())

	}

	ready() {

		// debug.client('DOM Ready')
		this.init()

	}

	init() {
		// this.cookie();
		this.bind()
		this.init_dependency()
		this.kauza_slider()
		this.human_slider()

		this.cookie = new Cookies()
		this.cookie.run()
		this.header = new Header()
		this.animations = new Animations()
		this.mobile = new Mobile()
		this.modal = new Modal()
		this.form = new Form()

		new imagesLoaded( $(window), () => {
			this.cover()
			this.video_slider()
		})

	}

    init_dependency() {

		$('.has-dependency').not('.md-field-ready').each(( index, element ) => {
			new dependency( $( element ) )
        })

	}

	bind() {
		this.setUserProfileMenu();
		if(document.querySelectorAll('.header-w-inner-h') !== null){
            document.querySelectorAll('.header-w-inner-h').forEach(item => {
                item.addEventListener('click', function (event){
                    let target = document.querySelectorAll('.header-w-inner-c');
                    target.forEach(titem => {
                        if(titem.classList.contains('header-w-inner-c-active')){
                            titem.classList.remove('header-w-inner-c-active');
                        }else{
                            titem.classList.add('header-w-inner-c-active');
                        }
                    })
                });
            });
        }

        document.querySelector('.searching-button-mobile-ok').addEventListener('click', function(event){
            let target = document.querySelector('.site-header').querySelector('.site-main');
            // console.log(target);
            if(target.classList.contains('active-mobile-menu')){
                target.classList.remove('active-mobile-menu');
            }else{
                target.classList.add('active-mobile-menu');
                document.querySelector('.search-input').focus();
            }
        });
        
		$(document).on('click', 'a[href="#"], a.disabled', e => { e.preventDefault() })
		$(document).on('click', '.load-more', e => { this.load_more(e) })

		$(document).on('click', '.address:not(.address-add)', (e) => {
			$('.address').removeClass('active')
			$( e.currentTarget ).addClass('active')
		})
		// if($('.faq').length > 0){
  //           // console.log($('.faq').length);
  //           $('.faq').children('.faq-label').click(function(){
  //               if($(this).siblings('.faq-content').css('height') == '0px'){
  //                   $(this).css({'border-color':'transparent'});
  //                   $(this).siblings('.faq-content').css('height','auto');
  //                   $(this).children('.faq-toggle').children("a").css({'transition':'transform .5s','display':'block'});
  //                   $(this).children('.faq-toggle').children("a").css({'transform':'rotate(180deg)'});
  //               }else{
  //                   $(this).css({'border-color':'#ade8e2'});
  //                   $(this).siblings('.faq-content').css('height','0px');
  //                  $(this).children('.faq-toggle').children("a").css({'transform':'rotate(0deg)'});
  //               }
  //           });
  //       }
        if($('.video-slider-auto-play').length>0){
        	let target = $('.video-slider-auto-play').find('.slide');
        	setInterval(function(){

        	},3000);
        	
        }
        	(function(){ 
        	$(".flickity-button").click(function(e){
        		// console.log('clicked');
        		// console.log($('.is-selected'))
        		let src;
        		$('.is-selected').siblings('.slide').each(function(index, element){
        			src = $(element).find('iframe').attr('src');
        			// console.log(index);	
        			// console.log(element);
        			$(element).find('iframe').attr('src',src+'?enablejsapi=1');
        			// console.log($(element).find('iframe'));
        			// $(element).find('iframe').trigger('click');
        		})
        	});
        }, 2000);

	}
	setUserProfileMenu(){
        let targetList = $(".header-inner-item");
        if(targetList !== null){
            targetList.click(function(e){
                if($(this).hasClass('header-inner-item-grid') == false){
                    if($(this).siblings().hasClass('header-inner-item-holded')){
                        $(this).siblings().removeClass('header-inner-item-holded')
                    }else{
                        $(this).siblings().addClass('header-inner-item-holded')
                    }
                    if($(this).hasClass('header-inner-item-active')){
                        e.preventDefault()
                    }
                }
            });
            
        }
    }
	human_slider() {
		let $slider = $('.cover-slider-people');
		// console.log($slider.length);
		if($slider.length){
        	let current;
        	let interval = setInterval(function(){ 
        		current = $('.cover-slider-people').children('.active-slide');
        		if(current.next().length > 0){
        			current.next().addClass('active-slide');
        			current.next().css('opacity','1');
        			current.removeClass('active-slide');
        			current.css('opacity','0');
        		}else{
        			current.removeClass('active-slide');
        			current.css('opacity','0');
        			$('.cover-slider-people').children('.slide:first-child').addClass('active-slide');
        			$('.cover-slider-people').children('.slide:first-child').css('opacity','1');
        		}
        	}, 3000);
        }
	}

	kauza_slider() {

		let $slider = $('.kauza-slider')

		if( $slider.length ) {

				new imagesLoaded( $slider, () => {

				$slider.each(( index, element ) => {

					var flkty = new flickity( '.kauza-slider', {
		                contain: true,
		                wrapAround: false,
		                prevNextButtons: true,
		                pageDots: false,
		                autoPlay: false,
		            })

		        })
	        })
		}
	}

	load_more(e) {

		e.preventDefault()

		let $button = $( e.currentTarget )
		let $container = $button.parent().prev('.load-more-container')
		let $inner = $container.children('.load-more-inner')
		let url = $button.attr('href')

		if( $container.hasClass('ajaxing') ) {
			return
		}

		$.ajax({
			type: 'post',
			dataType: 'html',
			url: $button.attr('href'),
			data: {},
			beforeSend: () => {
				$container.addClass('ajaxing')
			},
			success: ( response ) => {

				gsap.set( $container, { height: $container.outerHeight() } )
				$inner.append( response )

				gsap.to( $container, 1.2, { height: $inner.outerHeight(), ease: Power4.easeOut, onComplete: () => {
					gsap.set( $container, { height: 'auto' } )
					$container.removeClass('ajaxing')
				}})

			}
		})

	}
    cover() {

		let $cover_slider = $('.cover-slider')

		if( $cover_slider.length ) {
			new imagesLoaded( $cover_slider, () => {
				new flickity( '.cover-slider', {
		            contain: true,
		            wrapAround: false,
		            prevNextButtons: false,
		            pageDots: false,
		            autoPlay: 2000,
					pauseAutoPlayOnHover: false,
					on: {
						change: ( index, test ) => {

							let $slider_icon = $('.slider-icon')
							let $icon = $('.cover-slider .slide').eq( index ).find('.slide-icon').html()

							gsap.to( $slider_icon, .15, { scale: 0, ease: Power4.easeOut, onComplete: () => {
								$slider_icon.html( $icon )
								gsap.to( $slider_icon, .15, { scale: 1, ease: Power4.easeOut } )
							}})

						}
					}
		        })
			})
		}
    }

    video_slider() {

		if( $('.video-slider').length ) {

			var Flickity = require('flickity-fade')

			$('.video-slider').each(( index, element ) => {

				//let $slider_icon = $( this ).find('.round-video');
				// console.log(element);
	            new flickity( element, {
	                contain: true,
	                wrapAround: true,
	                prevNextButtons: true,
	                pageDots: false,
	                autoPlay: 3000,
					pauseAutoPlayOnHover: false,
					fade: true,
					on: {
						change: ( index, test ) => {
							let $slider_icon = $('.round-video', element);
							gsap.to( $slider_icon, .45, { scale: 0.96, ease: Power4.easeOut, onComplete: () => {
								gsap.to( $slider_icon, .45, { scale: 1, ease: Power4.easeOut } )
							}})

						}
					}
	            })
	        })
		}

    }

}

window.client = new Client()
