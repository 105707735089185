<template>
    <form @submit.prevent="post" class="form form-group" method="post" enctype="multipart/form-data" autocomplete="off">
        <section class="section-dashboard">
            <div class="container">
                <div class="grid bg-white">
                    <div class="col-12">
                        <div class="grid">
                            <div class="col-12">
                                <div class="form-group mb-1">
                                    <div class="grid grid-modified">
                                        <div class="col-6">
                                            <div class="grid-image">
                                                <div class="form-title">
                                                    Профилна снимка
                                                </div>
                                                <div class="form-group mt-1">
                                                    <div class="form-image-container">
                                                        <img :src="userInfo.image ? userInfo.image : '/images/default/default_add.png'" alt="Профилна снимка" ref="prof_image"  @click="openUpload()">
                                                        <input class="hidden-upload" type="file" name="image" ref="upload" accept=".png,.jpg,.jpeg" @change="uploadImage">
                                                        <div class="form-image-edit" v-if="userInfo.image !== '/images/default/default_add.png' && userInfo.image"  @click="openUpload()">
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" width="199.8px" height="192.9px" viewBox="0 0 199.8 192.9" xml:space="preserve" class="custom-edit-svg"><path d="M99.7,191.4c-39.7-2-70.2-19-88.9-54.9c-4.9-9.3-7.5-19.4-8.7-29.8c-4.4-38.4,15.8-73.9,47.9-92
                                                            C61.6,8,74,3.9,87.2,2.3C129.4-2.9,170.3,18.1,189,56c4.6,9.4,7.5,19.4,8.7,29.9c5.5,47.9-26.7,89.8-71.1,101.7
                                                            c-3.7,1-7.5,1.9-11.4,2.4C110.2,190.6,105,190.9,99.7,191.4z M98.9,181.1c4.2-0.3,7.4-0.5,10.6-0.8c13.2-1.2,25.6-5.1,36.8-12.2
                                                            c30.6-19.7,44.9-47.6,40.4-84.1c-2.1-17.2-9.8-32-21.8-44.5C146.1,20,123.1,11,96.1,11.9c-9.4,0.3-18.6,2.1-27.4,5.3
                                                            C35.4,29.3,10.4,61.7,12.3,99.9c0.5,11.3,2.9,22,8.1,32C37,164.2,64.3,179.4,98.9,181.1z"></path> <path d="M128,47.1c4.8-0.1,8.6,1.6,11.9,4.8c2.1,2.1,4.3,4,6.4,6.1c6.4,6.4,6.5,15.9,0,22.2
                                                            c-19.9,19.2-39.8,38.4-59.8,57.6c-0.9,0.8-2.1,1.5-3.3,1.8c-10,2.8-19.9,5.5-29.9,8.2c-4.9,1.3-8.3-2.1-6.8-6.9
                                                            c2.8-9.5,5.6-19,8.5-28.4c0.4-1.3,1.3-2.7,2.3-3.7c19.7-19.1,39.5-38.2,59.3-57.2C119.7,48.6,123.6,47,128,47.1z M59.3,135.3
                                                            c0.7-0.1,1.2,0,1.6-0.1c5.9-1.6,11.8-3.2,17.8-4.9c0.7-0.2,1.4-0.7,2-1.2c14.7-14.2,29.4-28.3,44.2-42.5c1-1,1-1.6,0-2.6
                                                            c-4-3.8-8-7.6-11.9-11.4c-1.2-1.1-1.9-1.2-3.1,0c-14.6,14.1-29.2,28.2-43.8,42.3c-0.7,0.7-1.2,1.6-1.5,2.5
                                                            c-1.7,5.4-3.2,10.7-4.8,16.1C59.6,134,59.5,134.5,59.3,135.3z M118.9,63.8c5,4.8,9.8,9.5,14.7,14.2c1.3-1.2,2.3-2.1,3.3-3.1
                                                            c0.7-0.7,1.5-1.4,2.1-2.1c2-2.3,1.9-5.2-0.2-7.4c-2.2-2.3-4.6-4.5-7-6.6c-2-1.8-5-2-7-0.4C122.8,59.9,121,61.8,118.9,63.8z"></path></svg>
                                                        </div>

                                                        <div class="form-image-destroy" @click="removeImage()" v-if="userInfo.image !== '/images/default/default_add.png' && userInfo.image">
                                                            <svg version="1.1"
                                                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                                                 x="0px" y="0px" viewBox="0 0 176 176.1" style="enable-background:new 0 0 176 176.1;"
                                                                 xml:space="preserve">
                                                            <g>
                                                                <path d="M176,88c0.8,48.1-39.5,88-87.7,88C37.7,176.1,0,134.5,0,88.1C0,39.4,40.7-0.1,88.3,0
                                                                    C134.7,0.1,176.8,38.7,176,88z M88.2,163.1c42.3-0.5,74.9-33.9,74.8-75.1c-0.2-43.1-33.7-74.9-75-74.8C44.9,13.4,13.4,47,13.2,87.8
                                                                    C13,129.8,46.4,162.9,88.2,163.1z"/>
                                                                <path d="M51.3,99.1c0-9.2-0.1-18.3,0-27.5c0-2.5-0.5-4-3-5.5c-1.6-0.9-2.8-4.2-2.5-6.1c0.3-1.5,3.3-3.8,4.8-3.6
                                                                    c9.2,1.1,15.8-1.2,19.9-10.3c1.1-2.5,5.9-3.6,9.3-4.6c2.5-0.7,5.3,0.1,8-0.2C98,40,105.7,43,110,53.1c0.7,1.5,3.5,2.7,5.5,3
                                                                    c3.1,0.5,6.4-0.2,9.5,0.3c1.8,0.3,4.6,2,4.6,3.2c0.1,2.2-0.8,5.8-2.5,6.6c-2.9,1.5-3.1,3.3-3.1,5.9c0,17.8,0,35.7,0,53.5
                                                                    c0,8.2-3.4,11.5-11.5,11.5c-16.5,0-33,0-49.5,0c-8.3,0-11.7-3.5-11.7-11.9C51.2,116.4,51.3,107.7,51.3,99.1z M113.2,97.5
                                                                    c0-8.7-0.1-17.3,0.1-26c0.1-3.4-0.9-4.8-4.5-4.7c-14.1,0.2-28.3,0.2-42.4,0c-3.7,0-4.4,1.5-4.4,4.8c0.1,17,0.1,34,0,50.9
                                                                    c0,3.3,0.8,4.8,4.5,4.7c14.1-0.2,28.3-0.2,42.4,0c3.7,0,4.5-1.5,4.4-4.8C113.1,114.1,113.2,105.8,113.2,97.5z M98.3,55.9
                                                                    c-2.5-4.4-4.1-4.8-16.3-3.8c-1.7,0.1-3.2,1.6-4.8,2.5c0.2,0.4,0.4,0.9,0.6,1.3C84.5,55.9,91.2,55.9,98.3,55.9z"/>
                                                                <path d="M103.8,97.1c0,5.6-0.1,11.3,0,16.9c0.1,4-1.8,5.8-5.7,5.8c-3.8,0-5.8-1.6-5.8-5.7c0.1-11.6,0.1-23.3,0-34.9
                                                                    c0-3.9,1.6-5.2,5.4-5.2c3.8,0,6.1,0.7,6.1,5.1C103.7,85.1,103.8,91.1,103.8,97.1z"/>
                                                                <path d="M72.2,96.5c0-6,0.2-12-0.1-18c-0.2-4.2,2.2-4.4,5.4-4.4c3.2,0,5.5,0.4,5.5,4.5c-0.1,12.1-0.1,24.3,0,36.4
                                                                    c0,3.9-2.3,4.8-5.5,4.8c-3.2,0-5.4-1-5.3-4.9C72.3,108.8,72.2,102.6,72.2,96.5z"/>
                                                            </g>
                                                            </svg>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-title-sub form-title-sub--bottom">
                                                    (Препоръчително е снимката да е с резолюция 1:1, до 20 MB. Позволени формати са .png/.jpg/,jpeg)
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="grid-description">
                                                <div class="form-title">
                                                    Име
                                                </div>
                                                <div class="form-title-sub">
                                                    (Име на профила, видим за аудиторията на PopUp)
                                                </div>
                                                <div class="form-group mb-1">
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <div class="form-group mt-1">
                                                                <input type="text" v-model="userInfo.public_name" class="text" name="public-name" placeholder="...">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid-description">
                                                <div class="form-title">
                                                    Описание
                                                </div>
                                                <div class="form-title-sub">
                                                    (Описание на профила, видим за аудиторията на PopUp)
                                                </div>
                                                <div class="form-group mb-1">
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <div class="form-group mt-1">
                                                                <textarea v-model="userInfo.description" class="textarea-long" name="description" placeholder="..."></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid-phone">
                                                <div class="form-title">
                                                    Телефон
                                                </div>
                                                <div class="form-title-sub">
                                                    (Телефон за връзка между 9 и 20 символа)
                                                </div>
                                                <div class="form-group mb-1">
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <div class="form-group form-group-phone mt-1">
                                                                <input v-model="userInfo.phone" name="phone" placeholder="Телефон за връзка" type="text"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 custom-buttons-blq">
                            <div class="text-right mt-3">
                                <button type="submit" class="button">Запази промените</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </form>
</template>

<script>
import FormError from '../FormError.vue'

export default {
    props: ["user_info","profile_id"],
    data() {
        return {
            userInfo: this.user_info ? this.user_info : {
                image : '/images/default/default_add.png',
                phone: '',
                public_name: '',
            },
            defaultImage: this.user_info ? (this.user_info.image ? this.user_info.image : false) : false,
            profileName: this.profile_name,
            profileID: this.profile_id,
        };
    },
    methods: {
        post() {
            var self = this;

            axios.post(`/settings/profile/store`, {'user_info': this.userInfo})
                .then(function(response) {
                    self.errors = [];
                    console.log('response')
                    console.log(response)
                    if (response.data.success) {
                        // window.location = response.data.redirect
                        self.$snotify.success(response.data.message,
                            "Default",{timeout: 3000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                        );
                    }else{
                        self.$snotify.error(response.data.message,
                                "Default",{timeout: 3000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                            );
                    }
                })
                .catch(function(errors) {
                    let err = Object.values(errors.response.data.errors);
                    err.map((item)=>{
                        console.log(err)
                        self.$snotify.error(item,
                            "Default",{timeout: 30000,showProgressBar: false,closeOnClick: true}
                        );
                    });
                });
        },
        openUpload(){
            this.$refs.upload.click();
        },
        removeImage(){
            this.userInfo.image = this.defaultImage ? defaultImage : '/images/default/default_add.png';
        },
        uploadImage($event){
            let data = new FormData();
            if ($event.target.files) {
                data.append("profile_picture", $event.target.files[0]);
            }else{
                this.$snotify.error("Възникна грешка, моля опитайте отново.",
                                    "Default",{timeout: 3000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                                );
            }
            this.$snotify.async("Качване на медията на съвръра.","Success async",() => new Promise((resolve, reject) => {
                axios.post('/settings/profile/upload', data)
                    .then((response) => {
                        console.log(response)
                            if (response.data.success) {
                                this.userInfo.image = response.data.image_data.link_to;
                                resolve({title: "Success", body: "Успешно качена медия",
                                    config: {timeout: 3000, closeOnClick: true}
                                });
                                // console.log(this.userInfo);
                            } else {
                                reject({title: "Error",body: response.data.error,
                                    config: {timeout: 3000,closeOnClick: true}
                                });
                            }
                        });
                    })
            );
        }
    },
    mounted() {

    }
};
</script>
<style>
    .capitalize{
        text-transform: capitalize;
    }
    .dashboard .heading .heading-content{
        padding-top:9rem;
        padding-bottom: 4rem;
    }
    body .wrapper-table .section-dashboard{
        padding:0 0 0 65px !important;
    }
    @media only screen and (max-width:1368px){
        body .wrapper-table .section-dashboard{
            padding:0 0 0 50px !important;
        }
    }
    @media only screen and (max-width: 768px){
        .dashboard .heading .heading-content{
            padding-top:3rem;
            padding-bottom: 1rem;
        }
        .col-6.col-md-12{
            padding-left: 0 !important;
        }
    }
</style>
<style scoped>
    .grid-description{
        max-width: 600px;
    }
    .grid-phone{
        max-width: 300px;
    }
    .form-group-phone input{
        padding-left: 50px;
    }
    .form-group-phone{
        position: relative;
    }
    .form-group-phone:before{
        content:'+359';
        position: absolute;
        left: 0;
        top:0;
        bottom:0;
        background-color:#913f98;
        color:#fff;
        padding:3px 10px;
        font-size:12px;
        align-content: center;
        display: grid;
        z-index: 5;
    }
    .grid-modified{
        display: grid;
        grid-template-columns: 260px auto;
        grid-gap:5px 20px;
    }
    .custom-buttons-blq > div:last-child{
        margin-right: 0 !important;
    }
    .custom-buttons-blq{
        padding-right: 0 !important;
    }
    .grid-modified .form-group textarea{
        height: 170px;
        /*height: 100%;*/
    }
    .grid-modified .grid-image{
        grid-row: span 2;
    }

    .form-image-container{
        width:260px;
        height: 260px;
        display: flex;
        cursor: pointer;
        position: relative;
        margin-top: 37px;
    }
    .form-image-container:hover .form-image-edit,
    .form-image-container:hover .form-image-destroy{
        display: flex;
        opacity: 1;
    }
    .form-image-edit{
        background-color:#913f98;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        left:calc(50% - 20px);
        top:calc(50% - 20px);
        display: none;
        opacity: 0;
        transition:opacity .6s;
        z-index: 5;
    }
    .form-image-destroy{
        background-color:#913f98;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right:-10px;
        top:-10px;
        display: none;
        opacity: 0;
        transition:opacity .6s;
        z-index: 5;
    }
    .form-image-edit svg,
    .form-image-destroy svg{
        width:70%;
        height: 70%;
        margin:auto;
        fill:#fff;
    }
    .form-image-container img{
        max-width: 100%;
        margin-right: auto;
        border:2px solid #ade8e2;
        object-fit: cover;
        object-position: center center;
    }
    .hidden-upload{
        display: none;
    }
    .order-reset{
        order:0 !important;
    }
    .form-title{
        font-size:18px;
    }
    .form-title-sub{
        font-size:14px;
        margin-top: -20px;
        margin-bottom: 10px;
        color:#959595;
    }
    .form-title-sub.form-title-sub--bottom{
        margin-top: 20px;
        margin-bottom: 0;
    }
    @media only screen and (max-width: 1000px){
        .custom-buttons-blq{
            margin-top: 20px;
        }
        .grid-modified{
            grid-template-columns: 100%;
            grid-row-gap:20px;
        }
        body .grid-modified .form-group{
            margin-bottom: 0;
        }
        .form-image-container{
            margin:auto;
        }
        .custom-buttons-blq .text-right{
            width: 175px !important;
            height: 40px;
        }

    }
</style>
