<template>
  <div class="cat-filter">
    <div v-if="!redirect_to">
      <label class="cat-filter__label" for="">
        Населено място
      </label>
      <v-select
        :options="cities"
        label="name"
        v-model="city"
        @search="fetchCities"
        @input="setSelected"
        class="cat-filter__select"
      >
        <template v-slot:option="cities">
          {{ cities.name }}
        </template>
        <template slot="selected-option" slot-scope="cities">
          {{ cities.name }}
        </template>
        <template slot="no-options">
          Въведи населено място
        </template>
      </v-select>
    </div>
    <div>
      <label class="cat-filter__label" for="">
        Главни Категории
      </label>
      <v-select
        :options="categoryFetch"
        label="name"
        v-model="categoryModel"
        @search="fetchCats"
        @input="setCat"
        class="cat-filter__select"
      >
        <template v-slot:option="categoryFetch">
          {{ categoryFetch.name }}
        </template>
        <template slot="selected-option" slot-scope="categoryFetch">
          {{ categoryFetch.name }}
        </template>
        <template slot="no-options">
          Няма такава категория
        </template>
      </v-select>
    </div>

    <template v-if="subCategoryFetch">
      <div v-if="subCategoryFetch.length > 0" class="mobile-subs">
        <label class="cat-filter__label" for="">
          Подкатегории
        </label>
        <v-select
          :options="subCategoryFetch"
          label="name"
          v-model="subCategoryModel"
          @search="fetchCats"
          @input="setCat"
          class="cat-filter__select"
        >
          <template v-slot:option="subCategoryFetch">
            {{ subCategoryFetch.name }}
          </template>
          <template slot="selected-option" slot-scope="subCategoryFetch">
            {{ subCategoryFetch.name }}
          </template>
          <template slot="no-options">
            Няма такава категория
          </template>
        </v-select>
      </div>
      </template>

      <div v-if="searchFieldLabel" class="search-content">
        <label for="seller-search">{{ searchFieldLabel }}</label>
        <div class="toggle-field" style="margin-top: 9px; min-height: 30px;">
            <div class="toggle-field-left">
                <input type="text" id="seller-search" v-model="sellerSearch">
            </div>
            <div>
                <a href="#" @click="setCat({slug: 'all'})">
                  <svg style="top: 0; background-color: transparent;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 197 194.9" xml:space="preserve"><g><path d="M87.1,174.2c-48,0-87.1-39.1-87.1-87.1S39.1,0,87.1,0c48,0,87.1,39.1,87.1,87.1S135.1,174.2,87.1,174.2z
                                M87.1,12C45.7,12,12,45.7,12,87.1s33.7,75.1,75.1,75.1c41.4,0,75.1-33.7,75.1-75.1S128.5,12,87.1,12z"></path> <rect x="161" y="135.4" transform="matrix(0.6885 -0.7252 0.7252 0.6885 -68.9016 174.3916)" width="15.2" height="64" class="st0"></rect></g>
                  </svg>
                </a>
            </div>
        </div>
      </div>
  </div>


</template>

<script>
export default {
  props: [
    'category', 
    'value',
    'allCities',
    'categoryid',
    'redirect_to',
    'searchFieldLabel'
  ],
  data() {
    return {
      cities: [],
      city: '',
      categoryFetch: [],
      subCategoryFetch:[],
      categoryModel: '',
      subCategoryModel: '',
      redirect_url: this.redirect_to ? this.redirect_to : '/obqvi',
      sellerSearch: '',
    }
  },
  mounted() {
    if (this.value) {
      let value = this.value
      axios
        .get(`/api/city/showSlug`, {
          params: {
            slug: value,
          },
        })
        .then(response => {
          this.city = response.data.city
        })
    }else{
      this.city = {
        slug:'all',
        name:'Всички',
      }
    }

    this.fetchCats()
    this.fetchCities('',false);
    this.fetchInitialCities();
    this.sellerSearch = this.getSearchParam();
  },
  methods: {
    fetchCities(search, loading) {
      if (search !== '') {
        axios
        .get(`/api/cities`, {
          params: {
            search: search,
          },
        })
        .then(response => {
          this.cities = response.data.city
          this.cities.unshift({
            name:'Всички',
            slug:'all',
          })
        })
      }else{
        this.cities = this.cities.filter((item)=>{
          if(item.name.includes(search)){
            return item;
          }
        });
      }
      
    },
    setSelected(value) {
      let redirect;
      let searchParam = this.getSearchParam() ? this.getSearchParam() : false;
      let choosenCat = this.categoryFetch.filter((item)=>{
        if(item.slug == this.category){
          return item;
        }
      });
      if (this.category) {
        if(value){
          if(value.slug == 'all'){
            if(this.category == 'all'){
              redirect = `${this.redirect_url}`
            }else{
              redirect = `${this.redirect_url}/${this.category}`
            }
          }else{
            if(choosenCat[0]){
              redirect = `${this.redirect_url}/${choosenCat[0].slug}-cid${choosenCat[0].id}/${value.slug}`
            }else{
              redirect = `${this.redirect_url}/${this.category}/${value.slug}`
            }
          }
        }else{
          redirect = `${this.redirect_url}/${this.category}`
        }
      } else {
        if(value){
          if(value.slug == 'all'){
            redirect = `${this.redirect_url}`
          }else{
            redirect = `${this.redirect_url}/${value.slug}`
          }
        }
      }
      if(redirect){
        let url = new URL(document.location)
        url.pathname = redirect;
        url.search = '';
        if(searchParam){
          url.searchParams.set('search',searchParam);
        }
        window.location = url.href;
      }
    },
    fetchInitialCities() {
      axios
        .get(`/api/city/all`)
        .then(response => {
          this.cities = response.data.city
        })
    },

    fetchCats() {
      axios
        .get(`/api/category`)
        .then(response => {
          this.categoryFetch = response.data.categories
        })
        .then(response => {
          axios
            .get(`/api/category/showSlug`, {
              params: {
                slug: this.category,
                id: this.categoryid,
              },
            })
            .then(response => {
              this.categoryModel = this.categoryFetch.find(
                e => e.id == response.data.category.id
              )
              this.subCategoryFetch = response.data.subs;
            })
        })
    },
    getSearchParam(){
      let url = new URL(document.location)
      let search = url.searchParams.get('search');

      return search;
    },
    setCat(catValue) {
      let redirect
      let searchParam = this.sellerSearch ?? this.getSearchParam() ?? false;
      
      if(this.value){
        if(this.value == 'all'){
          redirect = `${this.redirect_url}/${catValue.slug}`
        }else{
          if(catValue.slug == 'all'){

            redirect = `${this.redirect_url}/${this.value}`
          }else{

            redirect = `${this.redirect_url}/${catValue.slug}-cid${catValue.id}/${this.value}`
          }
        }
      }else{
        if(catValue.slug == 'all'){
          redirect = `${this.redirect_url}`
        }else{
          redirect = `${this.redirect_url}/${catValue.slug}-cid${catValue.id}`
        }
      }
      if(redirect){
        let url = new URL(document.location)
        url.pathname = redirect;
        url.search = '';
        if(searchParam){
          url.searchParams.set('search',searchParam);
        }
        window.location = url.href;
      }
    },
  },
}
</script>
<style scoped>
  .cat-filter.cat-filter-alone{
    max-width:350px;
  }
</style>