<script>
    export default {
        data() {
            return {
                url: null,
            }
        },
        methods: {
            chooseFiles() {
                this.$refs.fileUpload.click();
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);

                this.$refs.previewImage.style.opacity = '1';
                this.$refs.previewImage.style.background = 'url('+this.url+') 50% 50% / cover repeat scroll padding-box border-box rgba(0, 0, 0, 0)';

                this.$refs.check.className = "upload-cover-drag has-main-upload has-check";
                
                let radio = document.querySelectorAll('[type="radio"]');
                radio.forEach((el) => {
                    el.checked = false;
                });
            },
            radioClicked() {
                if (this.$refs.previewImage.style.opacity) {
                    this.$refs.check.className = "upload-cover-drag has-main-upload";
                }
            }
        }
    }
</script>
