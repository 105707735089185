<template>
  <div class="modal modal-bill-lading" ref="modal" :data-id="`bill-lading-${order.id}`">
    <a href="#" class="close-modal" @click="$emit('close')"><i class="icon icon-close-circle"></i></a>
    <h3>Създаване на документ за доставка</h3>
    <form class="form">
        <div class="grid mt-4">
            <div class="col-6 col-md-12">
                <div class="bill-lading bill-lading--bg-pink">
                    <h3>Подател:</h3>
                    <table>
                        <tr>
                            <td>Имейл:</td>
                            <td>{{ order.order_sender_address.email }}</td>
                        </tr>
                        <tr>
                            <td>Тел:</td>
                            <td>{{ order.order_sender_address.tel }}</td>
                        </tr>
                        <tr>
                            <td>Населено място:</td>
                            <td>{{ order.order_sender_address.city }}</td>
                        </tr>
                        <tr>
                            <td>Адрес:</td>
                            <td>{{ order.order_sender_address.full_address }}</td>
                        </tr>
                    </table>
                    <a href="#" @click="showModal = true;addressType='sender'" class="address-edit bill-lading-edit m-r-30" title="Редактирай адрес">
                        <!-- <i class="icon icon-edit"></i> -->
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                             x="0px" y="0px" width="199.8px" height="192.9px" viewBox="0 0 199.8 192.9" class="custom-edit-svg-modal custom-white"
                             xml:space="preserve">
                        <path d="M99.7,191.4c-39.7-2-70.2-19-88.9-54.9c-4.9-9.3-7.5-19.4-8.7-29.8c-4.4-38.4,15.8-73.9,47.9-92
                            C61.6,8,74,3.9,87.2,2.3C129.4-2.9,170.3,18.1,189,56c4.6,9.4,7.5,19.4,8.7,29.9c5.5,47.9-26.7,89.8-71.1,101.7
                            c-3.7,1-7.5,1.9-11.4,2.4C110.2,190.6,105,190.9,99.7,191.4z M98.9,181.1c4.2-0.3,7.4-0.5,10.6-0.8c13.2-1.2,25.6-5.1,36.8-12.2
                            c30.6-19.7,44.9-47.6,40.4-84.1c-2.1-17.2-9.8-32-21.8-44.5C146.1,20,123.1,11,96.1,11.9c-9.4,0.3-18.6,2.1-27.4,5.3
                            C35.4,29.3,10.4,61.7,12.3,99.9c0.5,11.3,2.9,22,8.1,32C37,164.2,64.3,179.4,98.9,181.1z"/>
                        <path d="M128,47.1c4.8-0.1,8.6,1.6,11.9,4.8c2.1,2.1,4.3,4,6.4,6.1c6.4,6.4,6.5,15.9,0,22.2
                            c-19.9,19.2-39.8,38.4-59.8,57.6c-0.9,0.8-2.1,1.5-3.3,1.8c-10,2.8-19.9,5.5-29.9,8.2c-4.9,1.3-8.3-2.1-6.8-6.9
                            c2.8-9.5,5.6-19,8.5-28.4c0.4-1.3,1.3-2.7,2.3-3.7c19.7-19.1,39.5-38.2,59.3-57.2C119.7,48.6,123.6,47,128,47.1z M59.3,135.3
                            c0.7-0.1,1.2,0,1.6-0.1c5.9-1.6,11.8-3.2,17.8-4.9c0.7-0.2,1.4-0.7,2-1.2c14.7-14.2,29.4-28.3,44.2-42.5c1-1,1-1.6,0-2.6
                            c-4-3.8-8-7.6-11.9-11.4c-1.2-1.1-1.9-1.2-3.1,0c-14.6,14.1-29.2,28.2-43.8,42.3c-0.7,0.7-1.2,1.6-1.5,2.5
                            c-1.7,5.4-3.2,10.7-4.8,16.1C59.6,134,59.5,134.5,59.3,135.3z M118.9,63.8c5,4.8,9.8,9.5,14.7,14.2c1.3-1.2,2.3-2.1,3.3-3.1
                            c0.7-0.7,1.5-1.4,2.1-2.1c2-2.3,1.9-5.2-0.2-7.4c-2.2-2.3-4.6-4.5-7-6.6c-2-1.8-5-2-7-0.4C122.8,59.9,121,61.8,118.9,63.8z"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col-6 col-md-12">
                <div class="bill-lading bill-lading--bg-mint">
                    <h3>Получател:</h3>
                    <table>
                        <tr>
                            <td>Име/Фирма:</td>
                            <td>{{ order.order_receiver_address.name }}</td>
                        </tr>
                        <tr v-if="order.order_receiver_address.representative">
                            <td>МОЛ:</td>
                            <td>{{ order.order_receiver_address.representative }}</td>
                        </tr>
                        <tr>
                            <td>Имейл:</td>
                            <td>{{ order.order_receiver_address.email }}</td>
                        </tr>
                        <tr>
                            <td>Тел:</td>
                            <td>{{ order.order_receiver_address.tel }}</td>
                        </tr>
                        <tr>
                            <td>Населено място:</td>
                            <td>{{ order.order_receiver_address.city }}</td>
                        </tr>
                        <tr>
                            <td>Адрес:</td>
                            <td>{{ order.order_receiver_address.full_address }}</td>
                        </tr>
                    </table>
                    <a href="#" @click="showModal = true;addressType='receiver'" class="address-edit bill-lading-edit m-r-30" title="Редактирай адрес">
                        <!-- <i class="icon icon-edit"></i> -->
                         <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                             x="0px" y="0px" width="199.8px" height="192.9px" viewBox="0 0 199.8 192.9" class="custom-edit-svg-modal"
                             xml:space="preserve">
                        <path d="M99.7,191.4c-39.7-2-70.2-19-88.9-54.9c-4.9-9.3-7.5-19.4-8.7-29.8c-4.4-38.4,15.8-73.9,47.9-92
                            C61.6,8,74,3.9,87.2,2.3C129.4-2.9,170.3,18.1,189,56c4.6,9.4,7.5,19.4,8.7,29.9c5.5,47.9-26.7,89.8-71.1,101.7
                            c-3.7,1-7.5,1.9-11.4,2.4C110.2,190.6,105,190.9,99.7,191.4z M98.9,181.1c4.2-0.3,7.4-0.5,10.6-0.8c13.2-1.2,25.6-5.1,36.8-12.2
                            c30.6-19.7,44.9-47.6,40.4-84.1c-2.1-17.2-9.8-32-21.8-44.5C146.1,20,123.1,11,96.1,11.9c-9.4,0.3-18.6,2.1-27.4,5.3
                            C35.4,29.3,10.4,61.7,12.3,99.9c0.5,11.3,2.9,22,8.1,32C37,164.2,64.3,179.4,98.9,181.1z"/>
                        <path d="M128,47.1c4.8-0.1,8.6,1.6,11.9,4.8c2.1,2.1,4.3,4,6.4,6.1c6.4,6.4,6.5,15.9,0,22.2
                            c-19.9,19.2-39.8,38.4-59.8,57.6c-0.9,0.8-2.1,1.5-3.3,1.8c-10,2.8-19.9,5.5-29.9,8.2c-4.9,1.3-8.3-2.1-6.8-6.9
                            c2.8-9.5,5.6-19,8.5-28.4c0.4-1.3,1.3-2.7,2.3-3.7c19.7-19.1,39.5-38.2,59.3-57.2C119.7,48.6,123.6,47,128,47.1z M59.3,135.3
                            c0.7-0.1,1.2,0,1.6-0.1c5.9-1.6,11.8-3.2,17.8-4.9c0.7-0.2,1.4-0.7,2-1.2c14.7-14.2,29.4-28.3,44.2-42.5c1-1,1-1.6,0-2.6
                            c-4-3.8-8-7.6-11.9-11.4c-1.2-1.1-1.9-1.2-3.1,0c-14.6,14.1-29.2,28.2-43.8,42.3c-0.7,0.7-1.2,1.6-1.5,2.5
                            c-1.7,5.4-3.2,10.7-4.8,16.1C59.6,134,59.5,134.5,59.3,135.3z M118.9,63.8c5,4.8,9.8,9.5,14.7,14.2c1.3-1.2,2.3-2.1,3.3-3.1
                            c0.7-0.7,1.5-1.4,2.1-2.1c2-2.3,1.9-5.2-0.2-7.4c-2.2-2.3-4.6-4.5-7-6.6c-2-1.8-5-2-7-0.4C122.8,59.9,121,61.8,118.9,63.8z"/>
                        </svg>
                    </a>

                </div>
            </div>
        </div>
        <div v-if="order.payment_token && daysSinceOrderCreation >= 5" class="grid mt-4">
            <div class="col-12">
                <p style="background-color: #913f98; color: white; padding: 8px;">
                    Поръчката е създадена преди 5 или повече дена и е платена с EcontPay. Моля, свържи се с Еконт и потвърди,
                    че плащането не е възстановено по сметката на купувача. Ако това е така и все пак искаш да изпълниш поръчката,
                    увери се, че опцията за наложен платеж е маркирана.
                </p>
            </div>
        </div>
        <div v-else-if="order.payment_token" class="grid mt-4">
            <div class="col-12">
                <p style="background-color: #913f98; color: white; padding: 8px;">
                    Моля, обърни внимание, че поръчката е платена електронно с EcontPay. След като веднъж създадеш товарителница,
                    при последващо отказване, парите за плащането ще бъдат възстановени по сметката на купувача. Ако решиш все
                    пак да изпълниш поръчката с нова товарителница, ще трябва да активираш наложен платеж
                    при създаването ѝ.
                </p>
            </div>
        </div>
        <p class="modal-text mt-3"><span>Допълнителни услуги</span> <a href="#" data-text="Избери какви допълнителни услуги да бъдат включени" class="toggle toggle-bottom-right toggle-large"><i class="icon icon-info"></i></a></p>

        <settings @input="(val) => {order.properties = val}" :order="order"></settings>

        <div class="text-right mt-2">
            <a href="#" @click="createLabel" class="button" tabindex="17">Създай</a>
        </div>
    </form>

    <bill-modal-edit-address v-if="showModal" @close="showModal = false" :order="order"
    @update="update" :cities="cities" :address-type="addressType"></bill-modal-edit-address>
</div>

</template>

<script>
import BillModalEditAddress from './BillModalEditAddress.vue';
import Settings from './Settings.vue';
import FormError from '../FormError.vue'
import moment from 'moment';

export default {
    props: ['p-order', 'cities'],
    components: {
        BillModalEditAddress,
        FormError,
        Settings,
    },
    data() {
        return {
            errors: [],
            showModal: false,
            order: { ...this.pOrder },
        }
    },
    methods: {
        createLabel() {
            this.$snotify.async('Документът се обработва', 'Success async', () => new Promise((resolve, reject) => {
                axios.post(`/label`, this.order)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            resolve({
                                title: 'Success',
                                body: 'Документът е подаден успешно',
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            })
                            this.$refs.modal.style.visibility = "hidden";
                            this.$refs.modal.style.opacity = "0";
                            document.querySelector('.modal-overlay').remove();
                            location.reload();
                        } else {
                            reject({
                                title: 'Error',
                                body: response.data.error,
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                            })
                        }
                    })
                    .catch((errors) => {
                        this.$snotify.clear()
                        if(errors){
                            this.errors = errors.response.data.errors
                        }
                    })
            }));

        },
        update(order) {
            this.order = { ...this.order, ...order }
        },
    },
    computed: {
        daysSinceOrderCreation() {
            return moment().diff(moment(this.order.created_at), 'days');
        },
    },
}
</script>
