require('./bootstrap');
import Vue from 'vue'
import store from './store/';

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VuejsClipper from 'vuejs-clipper'
Vue.use(VuejsClipper)

import gsap from 'gsap'

import Snotify from './snotify';

import ProductAddComponent from './components/product/ProductAddComponent.vue';
import ProductEditComponent from './components/product/ProductEditComponent.vue';
import AddressComponent from './components/address/Address.vue';
import ListComponent from './components/order/ListComponent.vue';
import PreviewShow from './pages/preview/PreviewShow.vue';
import ShopCreate from './pages/shop/Create.vue';
import DashboardDelete from './pages/dashboard/Delete.vue';
import DashboardShare from './pages/dashboard/Share.vue';
import PreviewIndex from './pages/preview/PreviewIndex.vue';
import ContactModal from './pages/preview/ContactModal.vue';
import Offer from './pages/preview/Offer.vue';

import CreateLink from './components/link/Create.vue';
import EditLink from './components/link/edit/Edit.vue';
import DraftLink from './components/link/draft/Edit.vue';

import NotificationIndex from './components/notification/Index.vue';
import SettingsIndex from './components/settings/Index.vue';

import PayingIndex from './components/paying/Index.vue';
import Search from './components/search/Search.vue';
import Searchable from './components/search/Searchable.vue';
import Searchableicon from './components/search/SearchableIcon.vue';

import NewFeatures from './components/messages/NewFeatures.vue';
import Filters from './components/filters/index.vue';
import BillModal from "./components/order/BillModal.vue";
import RequestCourierModal from "./components/order/RequestCourierModal.vue";
import DeleteModal from "./components/order/DeleteModal.vue";
import CanselOrder from "./components/modal/CanselOrder.vue";
import ListComponentDeleteLabel from "./components/order/ListComponentDeleteLabel.vue";
import ListComponentDeletedProduct from "./components/order/ListComponentDeletedProduct";
import SearchComponent from "./components/product/SearchComponent.vue";
import PFilters from "./components/modal/Filters.vue";
import MessagesIndex from "./pages/messages/MessagesIndex.vue";
import MessageIcon from "./components/my-orders/MessageIcon.vue";
import MessageTableModal from "./pages/dashboard/products/MessageTableModal.vue";
import HeaderCounter from "./components/messages/HeaderCounter.vue";
import SortComponent from "./components/filters/SortComponent.vue";

import ImportProducts from "./components/import/ImportProducts.vue";
import CategoryIndex from "./components/import/CategoryIndex.vue";
import CategoryFeed from "./components/import/CategoryFeed.vue";
import InfoComponent from "./components/import/InfoComponent.vue";
import ImportFeed from "./components/feed/ImportFeed.vue";
import Profile from './components/profile/Index.vue';
import BadgeCounter from "./components/messages/BadgeCounter.vue";
import SellerPhoneComponent from "./components/product/SellerPhoneComponent.vue";

import PublicPhoneReminderModal from "./components/modal/PublicPhoneReminderModal.vue";

Vue.component('v-select', vSelect)
Vue.prototype.$window = window;

Vue.mixin({
    methods: {
        // warning
        notifySuccess(body) {
            this.$snotify.success(body, 'Default', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        notifyError(body) {
            this.$snotify.error(body, 'Default', {
                timeout: 0,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        notifyAsync(body) {
            this.$snotify.async(body, 'Default', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        notifyInfo(body) {
            this.$snotify.info(body, 'Default', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        notifyWarning(body) {
            this.$snotify.warning(body, 'Default', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        gsapCloseModal() {
            const $ = require('jquery')
            // gsap.to($('.modal-overlay'), .2, {
            //     opacity: 0,
            //     onComplete: () => {
            //         $('.modal-overlay').remove()
            //     }
            // })
        },
        showSubCategory(id) {
            let categorySub = document.querySelectorAll('.catogories__sub')
            categorySub.forEach((e) => {
                if(e.id != `category-${id}`) {
                    e.style.display = 'none'
                }
            })

            let categorySubId = document.getElementById(`category-${id}`)
            if (categorySubId.style.display === "none" || categorySubId.style.display == '') {
                categorySubId.style.display = 'block'
            } else {
                categorySubId.style.display = 'none'
            }
        },
        closeSubCategory(id) {
           let categorySub = document.querySelectorAll('.catogories__sub')

           categorySub.forEach((e) => {
               if(e.id != `category-${id}`) {
                   e.style.display = 'none'
                   e.classList.remove('active-category')
               }
           })

           let categorySubId = document.getElementById(`category-${id}`)
           categorySubId.style.display = 'none'
           categorySubId.classList.remove('active-category')

         },
         showCategoriesSearch(){
            let header = document.querySelector('.button-category-header');
            let inner = document.querySelector('.search-categories__list');
            if(header.classList.contains('unactive-button')){
                header.classList.remove('unactive-button')
            }else{
                header.classList.add('unactive-button')
            }

            if(inner.classList.contains('search-categories__list-active')){
                inner.classList.remove('search-categories__list-active')
            }else{
                inner.classList.add('search-categories__list-active')
            }
         },
         closeCategoriesSearch(){
            document.querySelector('.button-category-header').classList.remove('unactive-button');
            document.querySelector('.search-categories__list').classList.remove('search-categories__list-active');

         }
    }
})

const app = new Vue({
    el: '#app',
    store,
    components: {
        ProductAddComponent,
        ProductEditComponent,
        AddressComponent,
        PreviewShow,
        ListComponent,
        ShopCreate,
        DashboardDelete,
        CreateLink,
        EditLink,
        DashboardShare,
        PreviewIndex,
        NotificationIndex,
        SettingsIndex,
        PayingIndex,
        DraftLink,
        Search,
        Searchable,
        Searchableicon,
        NewFeatures,
        Filters,
        ContactModal,
        BillModal,
        RequestCourierModal,
        DeleteModal,
        ListComponentDeleteLabel,
        ListComponentDeletedProduct,
        SearchComponent,
        PFilters,
        CanselOrder,
        MessagesIndex,
        MessageIcon,
        MessageTableModal,
        HeaderCounter,
        SortComponent,
        ImportProducts,
        CategoryIndex,
        CategoryFeed,
        InfoComponent,
        Offer,
        ImportFeed,
        Profile,
        BadgeCounter,
        SellerPhoneComponent,
        PublicPhoneReminderModal,
    },
    data: function() {
        return {
            productDownloadQrCode: '',
            productDownloadPDF: '',
            linkForShare: '',
            imageForShare: '',
            descriptionForShare: '',
            productID: '',
        }
    },
    created(){
        const terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';

        addEventListener(terminationEvent, (event) => {
          //


        }, {capture: true});
        setTimeout(()=>{
            this.previewPageSliders();
            this.dashboardMore();
        },500)

        window.addEventListener('resize',() => {
            this.trackViewPort();
        });
        this.trackViewPort();
    },
    methods: {
        trackViewPort(){
            let vh = window.innerHeight;
            document.documentElement.style.setProperty('--modal-vh',`${vh}px`)
        },

        productDownload(product_id) {
            this.productDownloadQrCode = `/qrcode/${product_id}/png`;
            this.productDownloadPDF = `/qrcode/${product_id}`;
        },

        shareLink(link, image, description) {
            this.linkForShare = link;
            this.imageForShare = image;
            this.descriptionForShare = description;
        },
        showCategories() {
            let categories = document.querySelector('.section-categories');
            let catButton = document.querySelector('.button-category');
            if(categories.style.display == '' || categories.style.display == 'none') {
                categories.style.display = 'block';
                catButton.style.backgroundColor = 'grey';

            } else {
                categories.style.display = 'none';
                catButton.style.backgroundColor = '#913f98';
            }

        },
        dashboardMore(){
            const $ = require('jquery');
            if($('.h-i-info-descriotion-more')){
                $('.h-i-info-descriotion-more').click(function(e){
                    // e.preventDefault();
                    if($(this).hasClass('h-i-info-descriotion-more--reverce')){
                        $(this).removeClass('h-i-info-descriotion-more--reverce');
                        $('.h-i-info-description').removeClass('h-i-info-description--active')
                    }else{
                        $(this).addClass('h-i-info-descriotion-more--reverce');
                        $('.h-i-info-description').addClass('h-i-info-description--active')
                    }
                });
            }
        },
        previewPageSliders(){

          if($){
            $(".gallery-slider-slick-container").find('.gallery-slider-slick').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
              dots:true,
              lazyLoaded:true,
              prevArrow: $(".gallery-slider-slick-container").find('.slick-arrow.prev-arrow'),
              nextArrow: $(".gallery-slider-slick-container").find('.slick-arrow.next-arrow'),
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                },
              ],
            });
            $('.gallery-slider-slick-container').on('setPosition', function(event, slick){
                // event subscriber goes here
                $(this).addClass('gallery-slider-slick-container-active')
            });
            $(".additional-slider").slick({
              slidesToShow: 2,
              slidesToScroll: 1,
              speed: 300,
              lazyLoaded:true,
              prevArrow: $(".additional-slider-container").find('.slick-arrow.prev-arrow'),
              nextArrow: $(".additional-slider-container").find('.slick-arrow.next-arrow'),
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
              ],
              centerPadding: '20px',
              variableWidth: true,
              adaptiveHeight: false
            });
            $('.additional-slider-container').on('setPosition', function(event, slick){
                // event subscriber goes here
                $(this).addClass('additional-slider-container-active')
            });
            $(".gallery-popup-container").find('.gallery-popup').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
              thumbs:true,
              prevArrow: $(".gallery-popup-container").find('.slick-arrow.prev-arrow'),
              nextArrow: $(".gallery-popup-container").find('.slick-arrow.next-arrow'),
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
              ],
            });
            if($(".gallery-popup-thumbs")){
                let thumbs = $(".gallery-popup-thumbs").slick({
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  speed: 300,
                  asNavFor:".gallery-popup",
                  arrows:false,
                  infinite:false,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                      }
                    },
                  ],
                  centerPadding: '20px',
                });
            }
            $('.gallery-slider-slick').find('.gallery-slide').on('click',function(){
                $('.preview-show-container').addClass('preview-show-container--active');
                $(body).addClass('freeze');
            });
            $('.gallery-popup-thumbs').find('.gallery-slide').on('click',function(){
                let current = $(this).children('img').data('index');
                $('.gallery-popup').slick('slickGoTo',current);
                $(this).siblings().removeClass('slick-active');
                $(this).addClass('slick-active');
            })
            $('.gallery-popup-close').on('click',function(){
                $('.gallery-popup-container').removeClass('gallery-popup-container--active');
                let videos = $(".gallery-popup").find('video');
                if(videos){
                    videos.each(function(){
                        // console.log('video');
                        // console.log($(this));
                        // let source = $(this).children('source').attr('src')
                        // console.log('source')
                        // console.log(source)
                        // $(this).children('source').attr('src',source);
                        $(this).trigger('pause');
                    })
                }
                $('.preview-show-container').removeClass('preview-show-container--active')
                $('body').removeClass('freeze');
            })

            $('.gallery-dots').find('.gallery-slide').on('click',function(){
                let current = $(this).find('img').data('slide');
                // console.log(current)
                $('.gallery-popup').slick('slickGoTo',current);
                $('.gallery-popup-container').addClass('gallery-popup-container--active');
            });
            $(".additional-button").on('click',function(){
                // console.log('hello')
                // console.log($('.modal-contact'));
                $('.modal-contact').addClass('modal-active')
                if(!$('.modal-contact').find('.modal-overlay')){
                    $('.modal-contact').append('<div class="modal-overlay modal-overlay-active"></div>');
                }
                $('.modal-contact-container').addClass('modal-contact-container-active')
                $('body').addClass('freeze');
            });
            $(".video-show").on('click',function(){
                $(this).siblings('img').addClass('image--hide');
                $(this).siblings('video').addClass('video--show')
                $(this).addClass('video-show--hide')
            })
            $(".video-show-slider").on('click',function(){
                let slide = $(this).siblings('img').data('slide');
                let img = $('.gallery-popup').find(`.gallery-slide[data-slick-index="${slide}"]`).find('img');
                let video = $('.gallery-popup').find(`.gallery-slide[data-slick-index="${slide}"]`).find('video')
                let icon = $('.gallery-popup').find(`.gallery-slide[data-slick-index="${slide}"]`).find('.video-show');
                img.addClass('image--hide');
                video.addClass('video--show')
                setTimeout(function(){
                    video.trigger('play');
                },500)
                icon.addClass('video-show--hide')
            })
            $(".buy-button").on('click',function(){
                $('.form-order').addClass('form-order--active');
                $('.wrapper-product-preview').addClass('wrapper-product-preview--active');
                $('body').addClass('freeze');
            });
            $('.gallery-popup-container').find('.slick-arrow').on('click',function(){
                let video = $(".gallery-popup").find('video');
                if(video){
                    video.trigger('pause');
                }
            });

            let rows = jQuery('.vc_row.wpb_row.vc_inner');
            let tempRow = false;
            rows.each(function(index,item){
                if(index > 3){
                    if(tempRow !== false){
                        jQuery(item).prepend(tempRow);
                        console.log('moving');
                        console.log(tempRow.find('h3').children('a').text());
                        console.log('to row')
                        console.log(index)
                        // console.log(tempRow)
                    }
                    tempRow = jQuery(item).find('.wpb_column:last-child');

                    tempRow.detach();
                }
            });

            }
         }
    },
});
