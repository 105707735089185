<template>
    <section>
        <div class="container">
            <div class="form-group mb-0 p-0">

                <div class="grid mb-1">
                    <div class="col-4 col-md-12">
                        <label class="md-checkbox m-0" tabindex="1">
                            <input disabled type="checkbox" name="properties[cdAmount]" v-model="properties.cdAmount">
                            <span class='md-transition'></span><em>Наложен платеж</em>
                        </label>
                        <label class="md-checkbox m-0" tabindex="2">
                            <input type="checkbox" name="properties[smsNotification]" v-model="properties.smsNotification"
                                   @input="emitInput">
                            <span class='md-transition'></span><em>SMS известяване</em>
                        </label>
                    </div>

                    <div class="col-4 col-md-12">
                        <label class="md-checkbox m-0" tabindex="5">
                            <input @change="cPayAfterAccept" ref="payAfterAccept" type="checkbox" name="properties[payAfterAccept]" v-model="properties.payAfterAccept" :checked="properties.payAfterAccept"
                                   @input="emitInput">
                            <span class='md-transition'></span><em>Преглед</em>
                             <a href="#" data-text="Получателят има право да прегледа пратката в рамките на 15 минути и да плати наложения платеж само ако приеме стоката ми" class="toggle toggle-bottom-right"><i class="icon icon-info"></i></a>
                        </label>
                        <label class="md-checkbox mt-0" tabindex="6">
                            <input @click="payAfter" type="checkbox" name="properties[payAfterTest]" v-model="properties.payAfterTest" :checked="properties.payAfterTest"
                                   @input="emitInput">
                            <span class='md-transition'></span><em>Тест</em>
                            <a href="#" data-text="Получателят има право да тества стоката след прегледа в рамките на 15 минути" class="toggle toggle-bottom-right"><i class="icon icon-info"></i></a>
                        </label>
                        <label class="md-checkbox mt-0" tabindex="7">
                            <input @click="payAfter" type="checkbox" name="properties[partialDelivery]" v-model="properties.partialDelivery" :checked="properties.partialDelivery"
                                   @input="emitInput">
                            <span class='md-transition'></span><em>Избор</em>
                        </label>
                    </div>


                    <div class="col-4 col-md-12">

                        <label class="md-checkbox m-0" tabindex="3">
                            <input
                                type="checkbox"
                                name="properties[declaredValue]"
                                v-model="properties.declaredValue"
                                @click="!properties.declaredValue"
                                @input="emitInput"
                            >
                            <span class='md-transition'></span><em>Обявена стойност</em><a href="#" data-text="Това е платена услуга. Това е стойността на пратката, до която носим отговорност в случаите на повреда, загуба или кражба. Тя се посочва от подателя при изпращане на пратката. Лимит 10 000 лв. С маркирането на този чек бокс, се дава възможност да се настрои и праг за стойността на поръчката, от който услугата Обявена стойност да се активира. Ако не е въведен праг, но е маркиран чек бокса за услугата по всички пратки, ще се активира услуга Обявена стойност. Към сумата за доставка ще се добави допълнителна стойност за услугата Обявена стойност." class="toggle toggle-bottom-left"><i class="icon icon-info"></i></a>
                        </label>
                        <input v-model="properties.declaredValueAmount" :disabled="!properties.declaredValue" type="text"
                               @input="emitInput">

                        <label class="md-checkbox mt-2" tabindex="8">
                            <em class="mb-1 pl-0">Брой части на пратка</em>
                            <a href="#" class="toggle toggle-bottom-left"
                               data-text="Разделя пратката на няколко части, например, ако се състои от няколко кашона. Документът за доставка също се разделя на няколко страници - по една за всяка част от пратката. Не променя цената на услугата.">
                                <i class="icon icon-info"></i>
                            </a>
                        </label>
                        <input v-model="properties.packCount" type="number" min="1" max="10" @input="emitInput">

                        <template v-if="properties.priorityTime">
                            <label class="md-checkbox mt-2" tabindex="9">
                                <input type="checkbox"
                                       v-model="properties.priorityTimeEnabled"
                                       :disabled="!!order.order_receiver_address.is_office"
                                       @input="emitInput">
                                <span class='md-transition'></span>
                                <em>Точен час за получаване на пратка</em>
                            </label>
                            <div class="pickup--hours">
                                <div class="pickup-cell">
                                    <div class="select-wrapper">
                                        <select v-model="properties.priorityTime.timeInterval"
                                                :disabled="!!order.order_receiver_address.is_office"
                                                @input="emitInput"
                                                tabindex="10">
                                            <option value="between" selected>между</option>
                                            <option value="in">в</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="pickup-cell">
                                    <div class="select-wrapper select-wrapper-no-bullet">
                                        <vue-timepicker v-model="properties.priorityTime.startTime"
                                                        :disabled="!!order.order_receiver_address.is_office"
                                                        :hour-range="[[8, 22]]"
                                                        :minute-interval="10"
                                                        hide-disabled-hours hide-clear-button lazy
                                                        @input="emitInput"
                                                        placeholder="Изберете"
                                                        tabindex="11">
                                        </vue-timepicker>
                                    </div>
                                </div>
                                <div class="pickup-cell">
                                    <div class="select-wrapper select-wrapper-no-bullet">
                                        <vue-timepicker v-model="properties.priorityTime.endTime"
                                                        :disabled="!!order.order_receiver_address.is_office || properties.priorityTime.timeInterval === 'in'"
                                                        :hour-range="[[8, 22]]"
                                                        :minute-interval="10"
                                                        hide-disabled-hours hide-clear-button lazy
                                                        @input="emitInput"
                                                        placeholder="Изберете"
                                                        tabindex="12">
                                        </vue-timepicker>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>

                </div> <!-- grid -->

            </div>

        </div>
    </section>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'

export default {
    props: ["order"],
    components: {
        VueTimepicker
    },
    data() {
        return {
            properties: {
                priorityTimeEnabled: !!this.order.properties.priorityTime,
                packCount: '1',
                ...this.order.properties,
            },
        };
    },
    methods: {
        post() {
            this.afterAcceptDisabled = false;
        },
        payAfter($event) {
            if ($event.target.checked) {
                this.properties.payAfterAccept = true;
                this.$refs.payAfterAccept.checked = true;
                this.emitInput();
            }
        },
        cPayAfterAccept($event) {
            if (
                this.properties.payAfterTest == true ||
                this.properties.payAfterTest == "on" ||
                this.properties.partialDelivery == true ||
                this.properties.partialDelivery == "on"
            ) {
                this.properties.payAfterAccept = true;
                $event.target.checked = true;
                this.emitInput();
            }
        },
        address($event) {
            if (!this.properties.deliveryTo.office) {
                this.properties.deliveryTo.address = true;
                $event.target.checked = true;
            }
        },
        office($event) {
            if (!this.properties.deliveryTo.address) {
                this.properties.deliveryTo.office = true;
                $event.target.checked = true;
            }
        },
        emitInput() {
            this.$emit('input', this.properties);
        },
    },
    watch:{
        'order.order_receiver_address.is_office': function (newVal, oldVal) {
            if(newVal) {
                this.properties.priorityTimeEnabled = false;
                return;
            }
            this.properties.priorityTimeEnabled = !!this.order.properties.priorityTime;
        },
    },
    mounted() {
        this.emitInput();
    },
}
</script>
<style>
.vue__time-picker .dropdown {
    z-index: 99999 !important;
}
</style>
