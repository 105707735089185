'use strict'

import debug from '../utils/debug'
import $ from 'jquery'
import 'jquery-ui-bundle'
import datepickerFactory from 'jquery-datepicker';
import datepickerBGFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-bg';
// import select2 from 'select2'
// import 'select2/dist/css/select2.css'
// import gsap from 'gsap'
// import dependency from './dependency'

// window.$ = window.jQuery

// datepickerFactory($)
// datepickerBGFactory($)

export default class Form {

    constructor() {

        $(document).ready(() => this.ready())

    }

    ready() {

        // debug.dashboard('Form Init')

        this.init()

    }

    init() {

        // this.calendar()
        // this.number()
        this.toggle()
            // this.gallery()
            // this.init_dependency()
            // this.address()
            // this.payment()
            // this.bill_lading_edit()
            // this.autocomplete()
            // this.faq()

    }

    // faq() {

    // 	$('.faq-label').on('click', e => {

    // 		let $faq = $( e.currentTarget ).parent()
    // 		let $content = $( '.faq-content', $faq )
    // 		let $inner = $( '.faq-content-inner', $faq )

    // 		// close other faq items
    // 		$('.faq.faq-expanded .faq-label').not( e.currentTarget ).trigger('click')

    // 		$faq.toggleClass('faq-expanded')

    // 		if( $faq.hasClass('faq-expanded') ) {
    // 			gsap.to( $content, .3, { height: $inner.innerHeight(), onComplete: () => {
    // 				gsap.set( $content, { height: 'auto' })
    // 			}})
    // 		}else{
    // 			gsap.to( $content, .3, { height: 0 })
    // 		}

    // 	})

    // }

    // autocomplete() {

    // 	let $autocomplete = $('.select2-autocomplete')

    // 	if( $autocomplete.length ) {

    // 		window.jQuery = $

    // 		require('select2/dist/js/i18n/bg.js')

    // 		$autocomplete.select2({
    // 			language: 'bg',
    // 			ajax: {
    // 			url: $autocomplete.data('request'),
    // 			minimumInputLength: 3,
    // 			dataType: 'json'
    // 				// Additional AJAX parameters go here; see the end of this chapter for the full code of this example
    // 			}
    // 		});
    // 	}

    // }

    // bill_lading_edit() {

    // 	$('.bill-lading-edit').on('click', e => this.open_bill_lading(e))
    // 	$('.bill-lading-close').on('click', e => this.close_bill_lading(e))

    // }

    // open_bill_lading() {

    // 	$('.bill-lading-details').addClass('bill-lading-details-show')

    // }

    // close_bill_lading() {

    // 	$('.bill-lading-details').removeClass('bill-lading-details-show')

    // }

    // payment() {

    // 	$('.select-payment > a').on('click', e => this.payment_open(e))
    // 	$('.payment-close-button').on('click', e => this.payment_close(e))

    // }

    // address() {

    // 	$('.select-address').on('change', e => this.address_change(e))
    // 	$('.address-close-button').on('click', e => this.address_close(e))

    // }

    // payment_open(e) {

    // 	$('.payment-details').addClass('payment-show')

    // }

    // payment_close() {

    // 	$('.payment-details').removeClass('payment-show')

    // }

    // address_change(e) {

    // 	if( e.target.value ) {
    // 		$('.address-details').addClass('address-show')
    // 	}

    // }

    // address_close() {

    // 	$('.address-details').removeClass('address-show')

    // }

    // init_dependency() {

    // 	$('.has-dependency').not('.md-field-ready').each(( index, element ) => {
    // 		new dependency( $( element ) )
    //     })

    // }

    // calendar() {

    // 	// let date = new Date()
    // 	// date.setDate( date.getDate() + 40 )

    // 	// $('.datepicker').datepicker({
    // 	// 	yearRange: '1920:2025',
    //     //     dateFormat : 'dd/mm/yy',
    //     //     setDate: +40,
    //     //     defaultDate: +40,
    // 	// })
    // 	// $.datepicker.regional['bg']

    // }

    // // number() {

    // // 	$('.number-arrow').on('click', function() {

    // // 		let $button = $(this)
    // // 		let old_val = $button.parent().find('input').val() || 0
    // // 		let new_val = 0

    // // 		if ( $button.hasClass('number-arrow-up') ) {
    // // 			new_val = parseFloat( old_val ) + 1
    // // 		}else{
    // // 			if ( old_val > 0 ) {
    // // 				new_val = parseFloat( old_val ) - 1
    // // 			}else{
    // // 				new_val = 0
    // // 			}
    // // 		}

    // // 		$button.parent().find('input').val( new_val )

    // // 	});

    // // }

    toggle() {

        $(document).on('click', '.toggle', e => this.exapnd_toggle(e))

    }

    exapnd_toggle(e) {

        let $toggle = $(e.currentTarget)
        let text = $toggle.attr('data-text')
        let $content

        if (!$toggle.find('.toggle-content').length) {
            $content = `<div class="toggle-content">${text}</div>`
            $toggle.append($content)
        } else {
            $content = $toggle.find('.toggle-content')
        }

        $(document).off('click.close_toggle')

        setTimeout(() => {
            $toggle.toggleClass('expanded')

            if ($toggle.hasClass('expanded')) {

                $('.toggle').not($toggle).removeClass('expanded')

                $(document).on('click.close_toggle', (e) => {

                    if (!$(e.target).hasClass('toggle') && !$(e.target).closest('.toggle').length) {
                        $('.toggle').removeClass('expanded')
                    }
                })
            }

        }, 30)

    }

    // gallery() {

    // 	this.$upload = $('.upload')

    // 	if( ! this.$upload.length ) {
    // 		return
    // 	}

    // 	this.is_single = this.$upload.hasClass('upload-single')
    //     this.$gallery_input = $('#input-upload')
    //     this.$input = $('.input-ids')
    //     this.$cover = $('.upload-cover-drag')
    // 	this.$ubgs = $('.upload-background')
    // 	this.upload_ids = []
    // 	this.image_types = [
    // 		'image/jpeg',
    // 		'image/jpg',
    // 		'image/png',
    // 	]
    // 	this.allowed_types = [
    // 		'image/jpeg',
    // 		'image/jpg',
    // 		'image/png',
    // 		'video/mp4'
    // 	]

    //     // this.$gallery_input.on('change', e => this.gallery_uploaded(e))

    // 	let drag_area = this.$cover.get(0);

    // 	// prevent
    // 	drag_area.addEventListener('dragenter', e => this.gallery_drag_prevent(e), false)
    // 	drag_area.addEventListener('dragleave', e => this.gallery_drag_prevent(e), false)
    // 	drag_area.addEventListener('dragover', e => this.gallery_drag_prevent(e), false)
    // 	drag_area.addEventListener('drop', e => this.gallery_drag_prevent(e), false)

    // 	// highlight
    // 	drag_area.addEventListener('dragenter', e => this.gallery_drag_highlight(e), false)
    // 	drag_area.addEventListener('dragover', e => this.gallery_drag_highlight(e), false)

    // 	// unhighlight
    // 	drag_area.addEventListener('dragleave', e => this.gallery_drag_unhighlight(e), false)
    // 	drag_area.addEventListener('drop', e => this.gallery_drag_unhighlight(e), false)

    // 	// handle upload
    // 	drag_area.addEventListener('drop', e => this.gallery_handle_drop(e), false)

    // 	if( ! this.is_single ) {

    // 		$(document).on('click', '.upload-remove', e => this.upload_remove(e))
    // 		$(document).on('click', '.edit-upload-remove', e => this.edit_upload_remove(e))
    // 		$(document).on('click', '.upload-nav', e => this.upload_nav(e))

    // 		this.gallery_sortable()

    // 	}else{

    // 		$('.md-radio-image-fieldset').on('click', (e) => {
    // 			$('.upload-cover-drag').removeClass('has-check')
    // 			$('[name="is_main_cover_selected"]').prop('checked', false)
    // 		})

    // 	}

    // 	$('.upload-cover').on('click', (e) => {

    // 		if( ! this.is_single ) {

    // 			$('.input-upload-label').click()

    // 		}else{

    // 			if( ! $('.upload-cover-drag.has-main-upload').length || $( e.target ).hasClass('button') ) {

    // 				$('.input-upload-label').click()
    // 				$('[name="is_main_cover_selected"]').prop('checked', true)

    // 			}else{

    // 				$('.upload-cover-drag').addClass('has-check')
    // 				$('[type="radio"]').prop('checked', false)

    // 			}

    // 		}
    // 	})

    // }

    // gallery_sortable() {

    // 	$('.upload-thumbs ul').sortable({
    // 		placeholder: 'highlight',
    // 		items: 'li',
    // 		distance: 10,
    // 		update: ( event, ui ) => {
    // 			this.$ubgs = $('.upload-background')
    // 			this.parse_uploads()

    // 			let inputIds = document.querySelector('.input-ids');
    // 			var tempUrl = window.location.href;
    // 			var url = tempUrl.replace("create", "image-drag");

    // 			fetch(url, {
    // 				headers: {
    // 					"Content-Type": "application/json",
    // 					"Accept": "application/json, text-plain, */*",
    // 					"X-Requested-With": "XMLHttpRequest",
    // 					"X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
    // 					},
    // 				method: 'post',
    // 				credentials: "same-origin",
    // 				body: JSON.stringify({
    // 					media: inputIds.value,
    // 				})
    // 			})
    // 			.then((response) => {
    // 				return response.json();
    // 			})
    // 			.then((data) => {
    // 				// console.log(data);
    // 			})
    // 			.catch(function(error) {
    // 				console.log(error);
    // 			});
    // 		}
    // 	}).disableSelection()

    // }

    // gallery_drag_highlight(e) {
    // 	this.$cover.addClass('highlight')
    // }

    // gallery_drag_unhighlight(e) {
    // 	this.$cover.removeClass('highlight')
    // }

    // gallery_drag_prevent(e) {
    // 	e.preventDefault()
    // 	e.stopPropagation()
    // }

    // gallery_handle_drop(e) {

    // 	let dt = e.dataTransfer
    // 	let files = dt.files

    // 	// this.gallery_send_files( files )

    // }

    // // gallery_uploaded(e) {
    //     // this.gallery_send_files( e.target.files )
    // // }

    // // gallery_send_files( files ) {

    // // 	let files_queued = files.length
    // //     let files_uploaded = 0
    // // 	let $upload_backgrounds

    // // 	this.upload_is_ready = false

    // //     if( files_queued <= 0 ) {
    // //         return
    // //     }

    // // 	if( ! this.is_single ) {
    // // 		if( files_queued > this.$ubgs.not('.ready').length ) {
    // // 			this.upload_error( 'Може да качите до 5 файла' )
    // // 			return
    // // 		}
    // // 	}else{
    // // 		this.clear_upload( this.$ubgs )
    // // 		this.upload_ids = [];
    // // 	}

    // //     this.$upload.addClass('ajaxing')

    // //     $.each( files, ( index, file ) => {

    // // 		// file type not allowed
    // // 		if( ! this.allowed_types.includes( file.type ) ) {
    // // 			files_uploaded++
    // // 			if( files_uploaded == files_queued ) {
    // // 				this.$upload.removeClass('ajaxing')
    // // 			}
    // // 			this.upload_error( 'Този файл тип не е разрешен' )
    // // 			return true
    // // 		}

    // //         var data = new FormData();
    // //         data.append( 'md_file_upload', file )
    // // 		// data.append( 'X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'))
    // // 		let dataId = this.$upload.attr('data-id');
    // // 		if(dataId) {
    // // 			data.append('product_id', dataId)
    // // 		}

    // // 		$.ajaxSetup({
    // // 			headers: {
    // // 				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    // // 			}
    // // 		});

    // //         $.ajax({
    // //             type: 'POST',
    // //             url: this.$upload.data('href'),
    // //             data: data,
    // //             cache: false,
    // //             dataType: 'json',
    // //             processData: false, // don't process the files
    // //             contentType: false, // set content type to false as jquery will tell the server its a query string request
    // //             success: response => {


    // // 				if(response.errors) {
    // // 					let errors = response.errors;
    // // 					this.upload_error( errors['md_file_upload'][0] )

    // // 					return;
    // // 				}
    // // 				files_uploaded++;
    // // 				// success upload
    // //                 if( response.success ) {

    // // 					let reader = new FileReader()
    // // 					$upload_backgrounds = this.$ubgs.not('.ready')

    // // 		            reader.onload = event => {

    // // 						// image
    // // 						if( this.image_types.includes( file.type ) ) {
    // // 							$upload_backgrounds.not('.ready').first()
    // // 								.css('background-image', 'url("' + reader.result + '")')
    // // 						}
    // // 						// video
    // // 						else{
    // // 							$upload_backgrounds.not('.ready').first().parent()
    // // 								.addClass('is-video')
    // // 									.closest('li')
    // // 									.addClass('has-upload')
    // // 						}

    // // 						$upload_backgrounds.not('.ready').first()
    // // 							.attr('data-id', response.image_id )
    // // 							.addClass('ready')
    // // 								.closest('li')
    // // 								.addClass('has-upload')

    // // 						if( this.upload_is_ready ) {
    // // 							this.gallery_cover_preview()
    // // 						}

    // // 		            }
    // // 		            reader.readAsDataURL( file )

    // // 					this.upload_ids.push( String( response.image_id ) )
    // //                 }

    // // 				// handle image error
    // // 				else{
    // // 					this.upload_error( response.error )
    // //                 }

    // // 				// last image from stack
    // //                 if( files_uploaded == files_queued ) {

    // //                     this.$upload.removeClass('ajaxing')
    // // 					this.$input.val( JSON.stringify( this.upload_ids ) ).trigger('input') // set the image ids into json string
    // // 					this.$gallery_input.val('') // clear the input file

    // // 					this.upload_is_ready = true

    // //                 }

    // //             }
    // //         })

    // //     })

    // // }

    // upload_error( str ) {

    // 	let $error = $(`<p class="upload-error">${str}</p>`)
    // 	this.$cover.append( $error )

    // 	gsap.fromTo( $error, .3, { opacity: 0, scale: .9 }, { opacity: 1, scale: 1 })

    // 	setTimeout(() => {
    // 		gsap.to( $error, .3, { opacity: 0, scale: .9, onComplete: () => {
    // 			$error.remove()
    // 		}})
    // 	}, 5500 )

    // }

    // upload_remove( e ) {
    // 	let $e = $( e.currentTarget ).parent()
    // 	let id = $e.attr('data-id')

    // 	var tempUrl = window.location.href;
    // 	var url = tempUrl.replace("product/create", "product-media/create-destroy");

    // 	console.log(url);

    // 	fetch(url, {
    // 		headers: {
    // 			"Content-Type": "application/json",
    // 			"Accept": "application/json, text-plain, */*",
    // 			"X-Requested-With": "XMLHttpRequest",
    // 			"X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
    // 			},
    // 		method: 'post',
    // 		credentials: "same-origin",
    // 		body: JSON.stringify({
    // 			name: id,
    // 		})
    // 	})
    // 	.then((response) => {
    // 		return response.json();
    // 	})
    // 	.then((data) => {
    // 		console.log(data);
    // 	})
    // 	.catch(function(error) {
    // 		console.log(error);
    // 	});

    // 	this.clear_upload( $e )

    // 	let index = this.upload_ids.indexOf( id )
    // 	if ( index > -1 ) {
    // 		this.upload_ids.splice( index, 1 )
    // 	}

    // 	this.parse_uploads()

    // }

    // edit_upload_remove( e ) {
    // 	let $e = $( e.currentTarget ).parent()
    // 	let id = $e.attr('data-id')
    // 	let product_id = $e.attr('data-product-id')

    // 	var tempUrl = window.location.href;
    // 	var url = tempUrl.replace("product/edit", "product-media/image-destroy");

    // 	fetch(url, {
    // 		headers: {
    // 			"Content-Type": "application/json",
    // 			"Accept": "application/json, text-plain, */*",
    // 			"X-Requested-With": "XMLHttpRequest",
    // 			"X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
    // 			},
    // 		method: 'post',
    // 		credentials: "same-origin",
    // 		body: JSON.stringify({
    // 			name: id,
    // 			product_id: product_id,
    // 		})
    // 	})
    // 	.then((response) => {
    // 		return response.json();
    // 	})
    // 	.then((data) => {
    // 		console.log(data);
    // 	})
    // 	.catch(function(error) {
    // 		console.log(error);
    // 	});

    // 	this.clear_upload( $e )

    // 	let index = this.upload_ids.indexOf( id )
    // 	if ( index > -1 ) {
    // 		this.upload_ids.splice( index, 1 )
    // 	}

    // 	this.parse_uploads()

    // }

    // clear_upload( $upload_backgrounds ) {
    // 	$upload_backgrounds
    // 		.attr('data-id', '')
    // 		.removeClass('ready')
    // 		.css('background-image', '')
    // 			.parent()
    // 			.removeClass('is-video')

    // 	$upload_backgrounds
    // 		.closest('li')
    // 		.removeClass('has-upload')
    // }

    // parse_uploads() {

    // 	let $e
    // 	let gallery = []
    // 	let gallery_ids = []
    // 	let is_video = false

    // 	this.$ubgs.each(( index, element ) => {
    // 		$e = $( element )
    // 		if( $e.hasClass('ready') ) {

    // 			is_video = $e.parent().hasClass('is-video')

    // 			gallery.push({
    // 				id: $e.attr('data-id'),
    // 				image: ! is_video ? $e.css('background-image'): null,
    // 				is_video: is_video
    // 			})

    // 			gallery_ids.push( $e.attr('data-id') )

    // 		}
    // 	})

    // 	this.clear_upload( this.$ubgs )

    // 	if( gallery.length ) {
    // 		$.each( gallery, ( index, item ) => {

    // 			this.$ubgs.eq( index ).attr('data-id', item.id)

    // 			if( item.is_video ) {
    // 				this.$ubgs.eq( index )
    // 					.addClass('ready')
    // 						.parent()
    // 						.addClass('is-video')
    // 			}else{
    // 				this.$ubgs.eq( index )
    // 					.addClass('ready')
    // 					.css('background-image', item.image)
    // 			}

    // 			this.$ubgs.closest('li').addClass('has-upload')

    // 		})
    // 	}

    // 	this.$input.val( JSON.stringify( gallery_ids ) ).trigger('input') // set the image ids into json string

    // 	this.gallery_cover_preview()

    // }

    // upload_nav(e) {

    // 	let $e = $( e.currentTarget )
    // 	let is_next = $e.hasClass('upload-nav-next')

    // 	this.gallery_index += is_next ? +1 : -1;

    // 	if( this.gallery_index < 0  ) {
    // 		this.gallery_index = 0
    // 	}

    // 	if( this.gallery_index + 1 > this.gallery_length ) {
    // 		this.gallery_index = this.gallery_length - 1
    // 	}

    // 	let slide_background = $('.upload-background.ready').eq( this.gallery_index ).css('background')

    // 	$('.cover-background').css( 'background', slide_background )
    // 		.parent()
    // 		.addClass('has-main-upload')

    // }

    // gallery_cover_preview() {

    // 	this.gallery_length = $('.upload-background.ready').length
    // 	this.gallery_index = 0

    // 	if( this.gallery_length > 1 ) {
    // 		$('.upload-cover').addClass('has-nav')
    // 	}

    // 	$('.upload-cover')[ this.gallery_length > 1 ? 'addClass' : 'removeClass' ]('has-nav')

    // 	if( this.gallery_length == 0 ) {

    // 		$('.cover-background')
    // 			.css('opacity', 0)
    // 			.css('background', '#fff')
    // 				.parent()
    // 				.removeClass('has-main-upload')

    // 		$('.drop-file-text').css('opacity', 1)

    // 		return

    // 	}

    // 	let $thumb = $('.upload-background.ready')

    // 	if( $thumb.length ) {

    // 		$('.cover-background')
    // 			.css('opacity', 1)
    // 			.css('background', $thumb.first().css('background'))
    // 				.parent()
    // 				.addClass('has-main-upload')

    // 		$('.drop-file-text').css('opacity', 0)

    // 	}

    // 	if( this.is_single ) {
    // 		$('[type="radio"]').prop('checked', false)
    // 		$('.upload-cover-drag').addClass('has-check')
    // 	}

    // }

}