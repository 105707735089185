<template>
    <div class="error">
        <span><slot></slot></span>
    </div>
</template>

<script>
    export default {
        props: ['errors']
    }
</script>