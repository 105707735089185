<template>
    <div :class="boxClass">
        <svg version="1.1"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
             x="0px" y="0px" width="26px" height="26px" viewBox="0 0 191.1 184.1" :style="`fill: ${shareIconColor}`"
             xml:space="preserve">
            <path d="M154.8,70.3C143.7,70,134.4,66,127,57.8c-0.9-1-1.7-1.2-2.9-0.6c-17,8.2-34,16.4-51,24.6c-1.3,0.6-1.6,1.3-1.2,2.7
            c1.3,5.1,1.3,10.3,0,15.5c-0.3,1.3,0,2,1.2,2.5c17.1,8.2,34.2,16.4,51.2,24.7c1.1,0.5,1.8,0.4,2.6-0.5c6.1-7,13.9-11.2,23-12.3
            c20.9-2.4,37.9,11.2,40.8,29.7c2.9,18.6-10.4,36.5-29.2,39.5c-15.2,2.4-27.7-2.4-36.8-14.8c-6-8.1-7.7-17.4-5.4-27.3
            c0.3-1.5,0-2.1-1.3-2.8c-17-8.1-33.9-16.3-50.9-24.5c-1.3-0.6-2-0.4-2.9,0.6c-8.2,9.1-18.6,13.2-30.8,12.3
            c-16.2-1.2-30.3-12.8-33-29.9c-2.9-18.1,10-36,28.3-39.4c14-2.6,25.9,1.3,35.6,11.8c0.8,0.9,1.5,1.1,2.6,0.5
            C83.9,61.9,101,53.6,118,45.4c1.3-0.6,1.5-1.3,1.2-2.7c-4.6-20,9.1-38.6,28.7-42.1c18.2-3.3,35.1,7,40.9,22.1
            c7.6,19.8-4.6,41.9-25.5,46.4C160.5,69.8,157.6,69.9,154.8,70.3z M59.1,92.1c0.2-10.8-8.7-22-22.7-21.9c-14.2,0.1-22.8,11.4-22.7,22
            c0.1,12.1,10.3,22,22.8,22C48.9,114.2,59.1,104.3,59.1,92.1z M132,35.1c0,12.2,10.1,21.9,22.7,21.9c12.5,0,22.7-9.8,22.7-22
            c0.1-12.8-11.4-22-22.7-21.9C142.9,13.1,131.7,22.9,132,35.1z M154.7,171c11.3,0.5,22.8-9,22.7-21.8c-0.1-12.1-10.2-22-22.7-22
            c-12.6,0-22.6,9.8-22.7,21.9C131.8,161.3,142.9,171.4,154.7,171z"/>
        </svg>
        <template v-if="shared">
            <a v-if="fixed" href="#" class="share-close toggle-share-popup"><i class="icon icon-close-circle-bold"></i></a>
            <p>Сподели продукта онлайн и продавай на няколко места едновременно.</p>
            <ul>
                <li class="hover-fb">
                    <a class="social-button"
                       @click="share('facebook')"
                       id="facebook-share">
                        <i class="icon icon-share-facebook"></i>
                    </a>
                </li>
                <li class="hover-ms">
                    <a class="social-button pad-top-3"
                       @click="share('messenger')"
                       id="messenger-share">
                        <i class="icon icon-share-messenger"></i>
                    </a>
                </li>
                <li class="hover-vb">
                    <a class="social-button pad-top-3"
                       @click="share('viber')"
                       id="viber-share">
                        <i class="icon icon-share-viber"></i>
                    </a>
                </li>
                <li class="hover-vb">
                    <a class="social-button"
                       @click="share('twitter')"
                       id="twitter-share">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="hover-wa">
                    <a class="social-button" id="whatsapp-share"
                       @click="share('whatsapp')">
                        <i class="icon icon-share-whatsapp"></i>
                    </a>
                </li>
                <li class="hover-pi">
                    <a class="social-button"
                       @click="share('pinterest')">
                        <i class="icon icon-share-pinterest"></i>
                    </a>
                </li>
            </ul>
            <div class="share-input">
                <div class="share-input-field">
                    <input type="text" name="copy-code" :value="link">
                </div>
                <div class="share-input-button">
                    <a href="#" class="button copy-code">Копирай</a>
                </div>
                <div class="share-input-info">
                    <a href="#" class="toggle ml-0 mr-0 toggle-middle-left dashboard-share-tooltip"
                       data-text="Този продукт е готов да продава! На колкото повече места го споделиш, толкова повече хора могат да купят твоя продукт."><i
                        class="icon icon-info"></i>
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <h4>Потвърждение за това устройство</h4>
            <p>Тази опция е налична само ако имате инсталирано приложение
                <template v-if="messanger">Messanger</template>
                <template v-else>Viber</template>
                на вашето устройство.
            </p>
            <div class="flex justify-center">
                <div class="">
                    <a :href="info" class="button btn-modal-approve">Потвърди</a>
                    <a @click="returnPrevPop()" class="button btn-purple btn-modal-close">Откажи</a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: ['link', 'image', 'description', 'shareIconColor', 'fixed', 'white', 'productId'],
    data() {
        return {
            shared: true,
            messanger: true,
            info: '',
        }
    },
    computed: {
        facebookLink: function () {
            return `https://www.facebook.com/sharer/sharer.php?u=${this.link}?media=1`;
        },
        messengerLink: function () {
            return `fb-messenger://share/?link=${this.link}?media=2`;
        },
        viberLink: function () {
            return `viber://forward?text=${this.link}?media=3`;
        },
        twitterLink: function () {
            return `https://twitter.com/intent/tweet?url=&text=${this.link}?media=4`;
        },
        whatsappLink: function () {
            return `https://api.whatsapp.com/send?text=${this.link}?media=5`;
        },
        pinterestLink: function () {
            return `https://pinterest.com/pin/create/button/?url=${this.link}?media=7&media=${this.image}&description=${this.description}`;
        },
        boxClass: function () {
            let result = ['share-box'];
            if (this.white === true) {
                result.push('share-box-white');
            }
            if (this.fixed === true) {
                result.push('share-box-fixed');
            }

            return result;
        },
    },
    methods: {
        share(channel) {
            this.info = this[`${channel}Link`];
            if (channel === 'messenger') {
                this.shared = !this.shared;
                this.messanger = true;

                return;
            } else if (channel === 'viber') {
                this.shared = !this.shared;

                return;
            }
            window.open(this.info);
            gtag('event', 'share', {
                method: channel,
                content_type: 'product',
                item_id: this.productId,
            });
        },
        returnPrevPop() {
            this.shared = true;
            this.messanger = false;
        }
    }
}
</script>

<style>

</style>
