// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-11[0].rules[0].use[1]!../../../../node_modules/plyr/dist/plyr.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal[data-v-21295e14]{background:transparent;box-shadow:unset;width:auto;z-index:9999999}.modal .close-modal[data-v-21295e14]{color:#fff}", "",{"version":3,"sources":["webpack://./resources/js/pages/preview/VideoModal.vue"],"names":[],"mappings":"AAyBA,wBAGA,sBAAA,CACA,gBAAA,CAHA,UAAA,CACA,eAGA,CACA,qCACA,UACA","sourcesContent":["<template>\n  <div class=\"modal modal-delete video-modal\" data-id=\"video\">\n    <a href=\"#\" class=\"close-modal\" @click=\"$emit('close')\">\n      <i class=\"icon icon-close-circle\"></i>\n    </a>\n    <div class=\"text-right mt-3\">\n      <video id=\"player\" playsinline controls>\n        <source :src=\"video.url\" type=\"video/mp4\" style=\"width:800px;\" />\n      </video>\n    </div>\n  </div>\n</template>\n\n<script>\nimport Plyr from 'plyr'\n\nexport default {\n  props: ['video'],\n  mounted() {\n    const player = new Plyr('#player')\n  },\n}\n</script>\n<style scoped>\n@import url('~plyr/dist/plyr.css');\n.modal {\n  width: auto;\n  z-index: 9999999;\n  background: transparent;\n  box-shadow: unset;\n}\n.modal .close-modal {\n  color: white;\n}\n\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
