<template>
    <div v-if="phone === null" @click="retrievePhone">
        <span>Покажи телефон</span>
    </div>
    <div v-else>
        <span v-if="phone === false">Няма</span>
        <a v-else :href="'tel:' + phone">{{ phone }}</a>
    </div>
</template>

<script>
export default {
    props: ['productId'],
    data() {
        return {
            phone: null,
        };
    },
    methods: {
        async retrievePhone() {
            const response = await axios
                .get(`product/seller-phone/${this.productId}`);
            if (!response.data?.success) {
                this.phone = false;
                return;
            }

            this.phone = response.data.phone;
        },
    },
}
</script>
