<template>
    <a @click.prevent="openLocator">
        <i class="fa fa-map-marker"></i>
    </a>
</template>

<script>
export default {
    name: 'OfficeLocatorButton',
    props: {
        city: {
            type: String,
            required: false,
            default: '',
        },
        address: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            locatorUrl: 'https://officelocator.econt.com',
            locatorWindow: null,
        };
    },
    methods: {
        emitOfficeChoice: function (event) {
            if (event.origin !== this.locatorUrl
                || typeof event.data?.office !== 'object'
            ) {
                return;
            }
            this.$emit('office-choice', event.data.office);
            this.locatorWindow.close();
            this.locatorWindow = null;
        },
        openLocator: function () {
            const url = new URL(window.origin);
            url.pathname = 'office-locator';
            url.searchParams.append('city', this.$props.city);
            url.searchParams.append('address', this.$props.address);

            this.locatorWindow = window.open(
                url.toString(),
                'officeLocator',
                `height=${window.outerHeight},width=${window.outerWidth},left=0,top=0`
            );

            this.locatorWindow.addEventListener('message', this.emitOfficeChoice);
            this.locatorWindow.addEventListener(
                'beforeunload',
                () => {
                    this.locatorWindow = null;
                }
            );
        },
    },
}
</script>
