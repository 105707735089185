<template>
    <!-- <div :class="this.thread.un_seen_profile_notifications.length < 1 ? 'list-item' : 'list-item list-item--unread'" data-target="message-modal"> -->
    <div class="list-item" v-bind:class="{ 'list-item--unread': thread.un_seen_profile_notifications.length < 1 ? false : true }" data-target="message-modal">
        <div class="l-i-content" @click="showMessageModal()">
            <template v-if="product !== undefined">
                <img :src="product ? getImage() : '/images/default/default.png'" alt="" class="l-i-img">
                <div class="l-i-title">{{ product ? product.title : '' }}</div>
            </template>
            <div class="l-i-message" v-if="latestMessage">
                <div class="l-i-label">{{ latestMessage.sender ? latestMessage.sender.name : messageThread.guest.name }}</div>
                <div class="l-i-m-data">
                    {{ latestMessage.message }}
                </div>
            </div>
            <div class="l-i-icon">
                <svg class=" icon-closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 17.625">
                  <path d="M21.3,64H2.2A2.2,2.2,0,0,0,0,66.2V79.422a2.2,2.2,0,0,0,2.2,2.2H21.3a2.2,2.2,0,0,0,2.2-2.2V66.2A2.2,2.2,0,0,0,21.3,64ZM2.2,65.469H21.3a.737.737,0,0,1,.734.734v1.9c-1.005.849-2.442,2.02-6.912,5.567-.776.615-2.3,2.1-3.369,2.079-1.065.018-2.6-1.464-3.369-2.079C3.911,70.123,2.474,68.952,1.469,68.1V66.2A.737.737,0,0,1,2.2,65.469ZM21.3,80.156H2.2a.737.737,0,0,1-.734-.734V70.013c1.046.858,2.7,2.185,6,4.806.941.753,2.6,2.41,4.282,2.4,1.671.014,3.318-1.629,4.282-2.4,3.3-2.621,4.952-3.947,6-4.806v9.409A.737.737,0,0,1,21.3,80.156Z" transform="translate(0 -64)"/>
                </svg>
                <svg class=" icon-open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.848 23.848">
                  <path d="M16.27,2.434C15.29,1.653,13.644,0,11.924,0S8.6,1.619,7.578,2.434C5.967,3.713,2.476,6.528.819,7.884A2.235,2.235,0,0,0,0,9.614v12a2.236,2.236,0,0,0,2.236,2.236H21.612a2.236,2.236,0,0,0,2.236-2.236v-12a2.235,2.235,0,0,0-.82-1.73C21.371,6.528,17.881,3.713,16.27,2.434Zm5.342,19.923H2.236a.745.745,0,0,1-.745-.745V9.6a.745.745,0,0,1,.274-.577c1.224-1,5.672-4.576,6.74-5.424.821-.654,2.316-2.111,3.419-2.111s2.6,1.456,3.419,2.111c1.068.848,5.516,4.425,6.74,5.425a.745.745,0,0,1,.274.577V21.612a.745.745,0,0,1-.745.745ZM21.2,12.282a.559.559,0,0,1-.083.787c-1.063.858-2.568,2.06-4.851,3.873-.98.782-2.649,2.446-4.347,2.434-1.7.012-3.392-1.673-4.347-2.434-2.283-1.812-3.788-3.014-4.851-3.873a.559.559,0,0,1-.083-.787l.235-.289a.559.559,0,0,1,.785-.083c1.06.856,2.562,2.056,4.841,3.865.785.626,2.338,2.128,3.419,2.111,1.081.017,2.635-1.485,3.419-2.111,2.279-1.809,3.781-3.009,4.841-3.865a.559.559,0,0,1,.785.083Z" fill="#ade8e2"/>
                </svg>
            </div>
        </div>
        <div class="l-i-footer" @click="showMessageModal()">
            <div class="l-i-from">
                <div class="l-i-f-label">От:</div>
                <div class="l-i-f-data">{{ threadSender.name }}</div>
            </div>
            <div class="l-i-time">
                <div class="l-i-date">{{ getDate(latestMessage.created_at) }} -</div>
                <div class="l-i-hour">{{ getHours(latestMessage.created_at) }}ч.</div>
            </div>
        </div>
        <MessageModal v-if="showMessage" 
                        :thread="messageThread" 
                        :profile_id="profileID" 
                        @close="showMessage = false" 
                        @sendMessage="sendMessage"
                        ></MessageModal>
    </div>
</template>

<script>
import moment from 'moment'
import MessageModal from './MessageModal.vue';

export default {

    props: ['thread','profile_id'],
    components: { 
        MessageModal,
    },
    data() {
        return {
            'activeMessageThread': null,
            'messageThread' : this.thread,
            'product': this.thread.product ? this.thread.product : (this.thread.order ? (this.thread.order.product ? this.thread.order.product : false) : false),
            'latestMessage': this.thread.last_message ? this.thread.last_message : false, 
            'threadSender': {
                'name': this.thread.sender ? this.thread.sender.name : this.thread.guest.name,
            },
            'profileID': this.profile_id,
            'showMessage' : false,
            'isUnread': this.thread.unread ? this.thread.unread : (this.thread.un_seen_profile_notifications.length < 1 ? false : true),
        }
    },
    updated(){
        // console.log('this element is updating')
        // console.log(this.messageThread)
        // console.log('unread')
        // console.log(this.messageThread.unread);
    },
    created(){
        // this.startListeningForNewMessages();
        // console.log('inside message')
        // console.log(this.messageThread)
        // console.log(this.messageThread.unread);
        console.log(this.product)
    },
    methods: {
        showMessageModal(){
            axios.post(`./message/resetMessageNotificationsByID`, { 
              'id': this.messageThread.id,
              }).then((response) => {
                  if (response.data.type == 'success') {
                    //  this.seenCounter = 0;
                    this.messageThread = response.data.thread;
                    // this.messageThread.unread = false;
                    this.showMessage = true;
                    this.$emit('updateUnseenMessage', this.messageThread.id);
                  }else if(response.data.type == 'error'){
                      this.$snotify.error(response.data.message);
                  }
              })
              .catch((errors) => {
                  // this.errors = errors.response.data.errors
              })

        },
        sendMessage(thread){
            // console.log('inside message vue')
            this.$emit('submitMessage',thread);
        },
        getDate(date){
            moment.locale('bg');
            return moment(date).format('DD / MMMM / Y');
        },
        getHours(date){
            return moment(date).format('HH:mm');
        },
        getImage(){
            let month = moment(this.product.created_at).format('MM');
            let year = moment(this.product.created_at).format('Y');
            let media = this.product.product_media.filter((item)=>{
                return item.sort_id == 1;
            })[0];

            if(media){
                if (media.name.includes('mp4')) {
                    return '/images/default/video-thumb.jpg';
                } else {
                    return `/storage/media/${year}/${month}/${media.product_id}/${media.name}`;
                }
                return ''
            }else{
                return '/images/default/default.jpg';
            }
        }
    }
}
</script>
<style scoped>
    .l-i-date{
        text-transform: capitalize;
    }
    .list-item{
        cursor: pointer;
    }
</style>