<template>
    <div class="bill-lading-details" style="visibility: visible; opacity: 1;">
        <a href="#" class="bill-lading-details-close bill-lading-close" @click="$emit('close')"><i class="icon icon-link-ico-04"></i></a>
        <p v-if="addressType  == 'receiver'" class="bill-lading-details-description">Получател:</p>
        <p v-else class="bill-lading-details-description">Подател:</p>
        <div class="grid">
            <div class="col-6 col-sm-12">

                <div v-if="addressType === 'receiver'" class="form-group" :class="errors && errors.name ? 'is-error' : ''">
                    <label for="">Име/Фирма<sub class="is-required">*</sub></label>
                    <input type="text" name="name" v-model="address.name" tabindex="1">
                    <form-error v-if="errors && errors.name">
                        <template v-for="name in errors.name">{{ name }}</template>
                    </form-error>
                </div>

                <div class="form-group" :class="errors && errors.email ? 'is-error' : ''">
                    <label for="">E-mail<sub class="is-required">*</sub></label>
                    <input type="text" name="email" v-model="address.email" tabindex="3">
                    <form-error v-if="errors && errors.email">
                        <template v-for="email in errors.email">{{ email }}</template>
                    </form-error>
                </div>

                <div class="form-group":class="errors && errors.tel ? 'is-error' : ''">
                    <label for="">Телефон<sub class="is-required">*</sub></label>
                    <div class="price-wrapper">
                        <input type="text" name="tel" v-model="address.tel" tabindex="5">
                    </div>
                    <form-error v-if="errors && errors.tel">
                        <template v-for="tel in errors.tel">{{ tel }}</template>
                    </form-error>
                </div>

                <div v-if="addressType === 'receiver'" class="form-group" :class="errors && errors.representative ? 'is-error' : ''">
                    <label for="">МОЛ</label>
                    <div class="price-wrapper">
                        <input type="text" name="representative" v-model="address.representative" tabindex="2">
                    </div>
                    <form-error v-if="errors && errors.representative">
                        <template v-for="representative in errors.representative">{{ representative }}</template>
                    </form-error>
                </div>
                <infinite-scroll-select v-model="address.city"
                                        :options="cities"
                                        :filter="citiesFilter"
                                        :errors="errors && errors.city"
                                        option-label-name="name"
                                        @input="setCity"
                                        field-label="Населено място"
                                        :required="true">
                    <template v-slot:option="{ option }">
                        [{{ option.post_code }}] <b>{{ option.name }}</b>, <i>{{ option.region_name }}</i>
                    </template>
                    <template v-slot:selected-option>
                        [{{ address.post_code }}] <b>{{ address.city }}</b>,
                        <i>{{ address.region_name }}</i>
                    </template>
                    <template v-slot:no-options>
                        Няма такова населено място
                    </template>
                </infinite-scroll-select>
            </div>

            <div class="col-6 col-sm-12">

                <div class="radio-stack no-select">
                    <div class="md-radio-fieldset">
                        <label class="md-radio" tabindex="6">
                            <input type="radio" name="radio_some" :checked="!address.is_office" @click="changeAddressType(0)">
                            <i class="md-transition"></i>
                            <span>Адрес</span>
                        </label>

                        <label class="md-radio" tabindex="7">
                            <input type="radio" name="radio_some" :checked="address.is_office" @click="changeAddressType(1)">
                            <i class="md-transition"></i>
                            <span>Офис на Еконт</span>
                        </label>
                    </div>
                </div>

                <div v-if="!address.is_office" class="form-group" :class="errors && errors.full_address ? 'is-error' : ''">
                    <label for="">Адрес<sub class="is-required">*</sub></label>
                    <input type="text" name="full_address" v-model="address.full_address" tabindex="8">
                    <form-error v-if="errors && errors.full_address">
                        <template v-for="full_address in errors.full_address">{{ full_address }}</template>
                    </form-error>
                </div>

                <div v-if="address.is_office" class="form-group"
                     :class="errors && (errors.full_address || errors.is_office) ? 'is-error' : ''">
                    <label for="">
                        Офис на Еконт<sub class="is-required" tabindex="9">*</sub>
                        <a v-if="addressType === 'sender'" href="#" class="toggle toggle-bottom-left"
                           data-text="Можеш да използваш Еконтомат като подателски адрес, само ако имаш сключено споразумение за изплащане и си го избрал в настройките."><i
                            class="icon icon-info"></i></a>
                    </label>
                    <div class="toggle-field">
                        <infinite-scroll-select v-model="address.full_address"
                                                :options="offices"
                                                :filter="officesFilter"
                                                :errors="errors && errors.full_address"
                                                :option-is-selectable="(option) => addressType === 'sender' ? !option.disabled : true"
                                                option-label-name="full_address"
                                                @input="setFullAddress"
                                                class="toggle-field-left">
                            <template v-slot:option="{option}">
                                <b>{{ option.name }}</b> <span>{{ option.full_address }}</span>
                            </template>
                            <template v-slot:selected-option>
                                {{ address.full_address }}
                            </template>
                            <template v-slot:no-options>
                                Няма такъв офис на Еконт
                            </template>
                        </infinite-scroll-select>
                        <div class="toggle-field-right office-locator-button" style="margin-top: 10px">
                            <office-locator-button @office-choice="onLocatorOfficeChange"
                                                   :city="address.city"
                                                   :address="address.full_address">
                            </office-locator-button>
                        </div>
                    </div>
                    <form-error v-if="errors && (errors.full_address || errors.is_office)">
                        <template v-for="error in [...errors.full_address, ...errors.is_office]">{{ error }}</template>
                    </form-error>
                </div>

                <div class="form-group text-right mt-3 mb-0">
                    <a href="#" class="button small bill-lading-close" @click="add" tabindex="10">Запази промените</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormError from "../FormError.vue";
import OfficeLocatorButton from "../OfficeLocatorButton";
import InfiniteScrollSelect from "../InfiniteScrollSelect.vue";

export default {
    props: ["order", "cities", 'addressType'],
    components: { FormError, OfficeLocatorButton, InfiniteScrollSelect },
    data() {
        return {
            errors: [],
            offices: [],
            address: {
                // full_address: null,
            },
        };
    },
    created() {
        if(this.addressType == 'sender') return this.address = { ...this.order.order_sender_address }
        if(this.addressType == 'receiver') return this.address = { ...this.order.order_receiver_address }
    },
    methods: {
        setCity(city) {
            this.address.post_code = city.post_code
            this.address.city = city.name
            this.address.city_id = city.id

            if (this.address.is_office) {
                this.address.full_address = '';
            }

            this.getOfficesById();
        },
        changeAddressType(value) {
            this.address.is_office = value;
            this.address.full_address = "";
        },
        add() {
            axios.post(`/order/update-address`, this.address)
                .then((response) => {
                    this.errors = [];
                    this.notifySuccess('Адреса е редактиран успешно')

                    if(this.addressType == 'sender') this.order.order_sender_address = { ...this.order.order_sender_address, ...this.address }
                    if(this.addressType == 'receiver') this.order.order_receiver_address = { ...this.order.order_receiver_address, ...this.address }
                    this.$emit('update', this.order)
                    this.$emit("close");

                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors;
                });
        },
        getOfficesById() {
            axios.get(`/office/get`, {
                params: {
                    city_id: this.address.city_id,
                    for_sending: this.addressType === 'sender',
                }
            })
            .then((response) => {
                this.offices = response.data.offices;
                if(!this.offices.length) {
                    this.notifyError('В избрания град няма офис на Еконт')
                }
            });
        },
        setFullAddress(office) {
            this.address.full_address = office.full_address
            this.address.office_code = office.code
            this.address.office_id = office.id
        },
        onLocatorOfficeChange(office) {
            if (typeof office?.address !== 'object') {
                return;
            }
            let localCityObj = this.cities.find((val) => {
                return val.city_id === office.address.city.id;
            });
            this.setCity(localCityObj);
            let localOfficeObj = this.offices.find((val) => {
                return val.code === office.code;
            });
            this.setFullAddress(localOfficeObj);
        },
        getByCityNameAndPostCode() {
            axios.get(`office/get`, {
                params: {
                    city_name: this.address.city,
                    city_post_code: this.address.post_code,
                    for_sending: this.addressType === 'sender',
                }
            })
            .then((response) => {
                this.offices = response.data.offices;
                if(!this.offices.length) {
                    this.notifyError('В избрания град няма офис на Еконт')
                }
            });
        },
        citiesFilter(city, search) {
            return city.name.toLowerCase().includes(search.toLowerCase());
        },
        officesFilter(office, search) {
            let searchLc = search.toLowerCase();

            return office.name.toLowerCase().includes(searchLc) ||
                office.full_address.toLowerCase().includes(searchLc);
        },
    },
    mounted() {
        this.getByCityNameAndPostCode();
    }
};
</script>
