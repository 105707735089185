<template>
    <div>
    <div class="gallery">
        <div class="gallery-cls">
            <div class="gallery-cl-left">
                <div class="gallery-slider">
                    <template v-for="media in product.product_media">
                    <div @click="setVideo(media)" :class="{'open-modal': media.video}" class="gallery-slide" data-modal-for="video">
                            <div class="video-icon" v-if="media.video">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-play-circle fa-w-16 fa-3x"><path fill="#913f98" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" class=""></path></svg>
                            </div>
                            <img :src="mediaPath(media.name)" alt="">
                    </div>
                    </template>
                </div>
            </div>
            <div class="gallery-cl-right">
                <div v-for="media in product.product_media" class="gallery-thumbs">
                    <div class="video-thumb-icon" v-if="media.video">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-play-circle fa-w-16 fa-3x"><path fill="#913f98" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" class=""></path></svg>
                    </div>
                    <div class="gallery-thumb" :style="{'background-image': 'url('+ mediaPath(media.name) + ')'}"></div>
                </div>
            </div>
        </div>
    </div>

    <video-modal :video="video" @close="closeVideo" v-if="video.url"></video-modal>
    </div>
</template>

<script>
    import moment from 'moment'
    import flickity from 'flickity'
    import imagesLoaded from 'imagesloaded'
    import VideoModal from './VideoModal.vue'

    export default {
        props: ['product'],
        components: {
            VideoModal
        },
        data() {
            return {
                video: {},
            }
        },
        methods: {
            mediaPath(name) {
                return '/storage/media/' + moment(this.product.created_at).format('Y') + '/' + moment(this.product
                    .created_at).format('MM') + '/' + this.product.id + '/' + name
            },
            checkForVideo(media) {
                var extension = media.split(".").pop();
                if (extension == 'mp4') return true;
                return false;
            },
            setVideo(media) {
                if(media.video) {
                    const extension = media.video.name.split(".").pop();
                    this.video = {
                        url: this.mediaPath(media.video.name),
                        ext: extension,
                    }
                }
            },
            closeVideo() {
                this.video = {
                        url: '',
                        ext: '',
                    }
            }
        },
        mounted() {
            const $ = require('jquery')
            let $gallery = $('.gallery')

            if ($gallery.length) {
                new imagesLoaded($gallery, () => {
                    $gallery.each((index, element) => {

                        var flkty = new flickity($('.gallery-slider', element).get(0), {
                            contain: true,
                            wrapAround: false,
                            prevNextButtons: true,
                            pageDots: false,
                            pauseAutoPlayOnHover: true,
                        })

                        $('.gallery-thumb', $gallery).on('click', e => {
                            let index = $('.gallery-thumb', $gallery).index(e.currentTarget)
                            flkty.select(index).playPlayer()
                        })

                        flkty.on("change", function () {
                            flkty.cells.forEach(function (cell, i) {
                                if (cell.element == flkty.selectedElement) {
                                    var video = cell.element.querySelector("video");
                                    if (video) {
                                        // video.play();
                                    }
                                    return;
                                }
                                var video = cell.element.querySelector("video");
                                if (video) {
                                    video.pause();
                                }
                            });
                        });

                    })
                })
            }
        }
    }

</script>

<style scoped>
    .flickity-slider .gallery-slide {
        width: 100%;
    }
</style>
