<template>
    <div class="col-6 col-lg-12">

        <step-one-media-cropper :src="image.url" :name="image.name" :ext="image.ext" :video="image.video" v-if="show" @close="closeCropper()" @setImage="setImage"></step-one-media-cropper>

        <div class="field upload" data-href="/media/store">
            <label class="input-upload-label none" for="input-upload"></label>
            <div class="upload-cover" for="input-upload">
                <div class="upload-cover-drag" :class="errors && errors.media ? 'is-error-media' : ''">
                    <span class="cover-background" ref="preview"></span>
                    <span class="frame" @click="openUpload" @drop.prevent="uploadMedia" @dragover.prevent>
                        <i></i>
                        <i></i>
                    </span>
                    <div class="video-icon" v-if="image.ext">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="vide-icon-svg"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" class=""></path></svg>
                    </div>
                    <span v-if="image.url" class="edit open-modal modal-cropper" data-modal-for="media-cropper" @click.prevent="show = true">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M6.13 1L6 16a2 2 0 0 0 2 2h15"></path><path d="M1 6.13L16 6a2 2 0 0 1 2 2v15"></path></svg>
                    </span>

                    <span v-if="image.ext" class="play open-modal" data-modal-for="video" @click="setVideo()">
                        <!-- <svg aria-hidden="true" width="24" height="24" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-play-circle fa-w-16 fa-3x"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" class=""></path></svg> -->
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 81.5 81.5" xml:space="preserve">
                            <path class="outher" d="M40.7,81.5C18.4,81.4-0.1,62.9,0,40.7C0.1,18.4,18.7-0.2,40.8,0c22.4,0.2,40.7,18.6,40.7,40.8
                                C81.4,63.1,62.9,81.5,40.7,81.5z M34.9,58.6c6.3-6.7,11.8-12.4,17.5-18.4c-5.8-6.1-11.2-11.8-17.5-18.3
                                C34.9,34.5,34.9,45.9,34.9,58.6z"/>
                            <path class="inner" d="M34.9,58.6c0-12.7,0-24,0-36.7c6.2,6.5,11.7,12.2,17.5,18.3C46.6,46.2,41.2,51.9,34.9,58.6z"/>
                        </svg>
                    </span>

                    <span class="plus" ref="plus">
                        <i class="icon icon-plus"></i>
                    </span>
                    <span class="preloader" @click="openUpload" @drop.prevent="uploadMedia" @dragover.prevent>
                        <i class="fas fa-redo-alt fa-spin"></i>
                    </span>
                    <span class="drop-file-text" ref="fileText">пусни файл тук или</span>
                    <span class="button button-upload" ref="buttonForUpload" tabindex="1" @click="openUpload">Качи снимка или видео</span>
                    <a href="#" tabindex="-1" class="toggle mr-0 toggle-bottom-left" data-text="Можеш да качиш до 5 снимки и видео до 3 минути или 90 mb. <br>Съвет: снимай на дневна светлина и покажи продукта от различни страни."><i class="icon icon-info"></i></a>
                    <a href="#" class="upload-nav upload-nav-previous"><i class="icon icon-arrow-left-circle weight-800"></i></a>
                    <a href="#" class="upload-nav upload-nav-next"><i class="icon icon-arrow-right-circle weight-800"></i></a>
                </div>
            </div>
            <div class="none">
                <input type="file" id="input-upload" ref="inputUpload" multiple="multiple" @change="uploadMedia">
            </div>
            <div class="upload-thumbs">
                <ul>
                    <draggable v-model="medias" ghost-class="ghost" @end="sortbleEnd" handle=".handle">
                        <template v-for="(media,index) in medias">
                        <li @click="focus(media, $event)" :class="{'active': media.name == image.name}" class="ui-sortable-handle has-upload sortable">
                            <span class="upload-background ready" :style="{ 'background-image': `url(${media.url})` }">
                                <a href="#" class="edit-upload-remove" @click="remove(media)">
                                    <i class="icon icon-close-circle-bold remove"></i>
                                </a>
                                <a href="#" class="edit-upload-drag handle">
                                    <i class="fas fa-arrows-alt"></i>
                                </a>
                            </span>
                            <div class="video-thumb-icon" v-if="media.ext">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="vide-icon-svg"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" class=""></path></svg>
                            </div>
                            <div class="media-thumb toggle-right" v-if="index == 0">
                                <span>Придърпай снимка или видео,  за да разместиш реда им.<br>
                                А за да направиш някое от тях основно, го постави най-отгоре.</span>
                                <i class="icon icon-close-circle-bold"></i>
                            </div>
                        </li>
                        </template>
                    </draggable>
                    <li class="ui-sortable-handle has-upload sortable" :class="{'unused':product.media.length > 0}" v-if="Object.keys(product.media).length < 5" v-for="index in (5 - Object.keys(product.media).length)" :key="index">
                        <span class="upload-background"><a href="#" class="edit-upload-remove"><i class="icon icon-close-circle-bold"></i></a></span>
                        <i class="icon icon-image"></i>
                    </li>
                </ul>
            </div>
        </div>
        <p class="help-video-text hide--lg">Помощно видео</p>
        <input type="hidden" name="upload" class="input-ids">

        <video-modal :video="video" @close="closeVideo" v-if="video.url"></video-modal>
    </div>
</template>

<script>
import $ from 'jquery';
import draggable from "vuedraggable";
import StepOneMediaCropper from "./StepOneMediaCropper";
import VideoModal from '../../pages/preview/VideoModal'
import moment from 'moment'

export default {
    props: ["errors", "product"],
    components: { draggable, StepOneMediaCropper, VideoModal },
    data() {
        return {
            medias: [...this.product.media],
            show: false,
            mediaFlag: true,
            image: {
                name: '',
                url: '',
                ext: '',
                video: '',
            },
            video: {},
        }
    },
    mounted() {
        if (this.$store.state.createLink.product.update) {
            this.image.name = this.$store.state.createLink.product.media[0]['name']
            this.image.url = this.$store.state.createLink.product.media[0]['url']
            this.image.ext = this.$store.state.createLink.product.media[0]['ext']
            this.image.video = this.$store.state.createLink.product.media[0]['video']
            
            this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
            this.$refs.preview.style.opacity = 1
            this.$refs.plus.style.opacity = '0';
            this.$refs.fileText.style.opacity = '0';
            if (this.product.media.length == 5) {
                this.$refs.buttonForUpload.style.opacity = 0
            }
        }else if(this.$store.state.createLink.step == 1 && this.$store.state.createLink.product.media[0] != null){
            this.image.url = this.$store.state.createLink.product.media[0]['url']
            this.$refs.plus.style.opacity = '0';
            this.$refs.fileText.style.opacity = '0';
            if (this.product.media.length == 5) {
                this.$refs.buttonForUpload.style.opacity = 0
            }
            this.$refs.preview.style.opacity = 1;
            this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
        }
    },
    methods: {
        openUpload($event) {
            if ($event.target.classList.contains("icon-info")) {
                return;
            }
            if (this.product.media.length < 5) {
                this.$refs.inputUpload.click();
            }
            
        },
        uploadMedia($event) {
            let data = new FormData();
            let dataTransfer = $event.dataTransfer;
            if (dataTransfer) {
                [...$event.dataTransfer.files].forEach( function(el, index) {
                    data.append("media[]", el);
                });
                data.append("count", this.product.media.length);
            }

            if ($event.target.files) {
                [...$event.target.files].forEach( function(el, index) {
                    data.append("media[]", el);
                });
                data.append("count", this.product.media.length);
            }

            this.$snotify.async("Медията се качва на сървъра","Success async",() => new Promise((resolve, reject) => {
                axios.post('/media', data)
                    .then((response) => {
                        delete this.errors.media;
                        if (response.data.success) {
                            let media = response.data.media;
                            media.forEach(element => {
                                this.product.media.push({
                                    name: element.name, 
                                    url: `${element.url}`,
                                    ext: element.ext,
                                });

                                this.medias.push({
                                    name: element.name, 
                                    url: `${element.url}`,
                                    ext: element.ext,
                                });

                                this.image.name = element.name
                                this.image.url = element.url
                                this.image.ext = element.ext
                                this.image.video = ''

                                this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
                                this.$refs.preview.style.opacity = 1;
                            });
                                this.$refs.plus.style.opacity = "0";
                                this.$refs.fileText.style.opacity = "0";
                                if (this.product.media.length == 5) {
                                    this.$refs.buttonForUpload.style.opacity = 0;
                                }
                                resolve({title: "Success", body: "Успешно качена медия",
                                    config: {timeout: 3000, closeOnClick: true}
                                });
                            } else {
                                reject({title: "Error",body: response.data.error,
                                    config: {timeout: 3000,closeOnClick: true}
                                });
                            }
                        }).then(()=>{
                            if(this.mediaFlag == true){
                                if(this.product.media.length == 2){
                                    /*Message*/
                                    $(".upload-thumbs").find('.has-upload:first-child').addClass('active-media-thumb');
                                    $(".active-media-thumb").addClass('active');
                                    setInterval(function(){ 
                                        $(".active-media-thumb").removeClass('active');
                                        $(".active-media-thumb").removeClass('active-media-thumb');
                                    }, 8000);
                                    this.mediaFlag = false;
                                }
                            }
                        })
                        .catch((errors) => {
                            this.$snotify.clear();
                            if(errors.response.data.errors) {
                                this.$snotify.error("Качи снимка или видео - " + errors.response.data.errors['media.0']['0'],
                                    "Default",{timeout: 3000,showProgressBar: false,closeOnClick: true,pauseOnHover: true}
                                );
                            }
                        });
                    })
            );
        },
        remove(media) {
            this.$refs.buttonForUpload.style.opacity = 1
            const index = this.product.media.indexOf(media);
            const element = this.product.media.splice(index, 1);
            this.medias = [...this.product.media]

            const currentElement = this.product.media[this.product.media.length - 1];
            if(currentElement) {
                this.image.url = currentElement.url + '?t=' + new Date().getTime();
                this.image.name = currentElement.name
                this.image.ext = currentElement.ext
                this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
            } else {
                document.querySelector('.ready').remove()
                this.$refs.plus.style.opacity = '1';
                this.$refs.preview.style.opacity = 0
                this.image.url = ''
                this.image.name = ''
            }

            const data = {
                id: this.product.id,
                image: element[0]
            }

            // axios.delete(`/media`, { data })
            //     .then((response) => {
            //         this.notifySuccess('Медиата е изтрита успешно')
            //     })

        },
        sortbleEnd(event) {
            let element = this.product.media[event.newIndex];
            this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
            this.product.media = [...this.medias]
            this.$store.state.createLink.product.media = [...this.medias]
        },
        setImage() {
            let id = this.product.media.findIndex(e => e.name == this.image.name);
            this.setImageObject(this.product.media[id])
            this.medias = [...this.product.media]
            
            this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
            this.show = false
            
        },
        setImageObject(image) {
            this.image.name = image.name
            this.image.url = this.getUrlWithTime(image.url)
            this.image.ext = image.ext
            this.image.video = image.video
        },
        getUrlWithTime(imageUrl) {
            let url = imageUrl.split('?').shift()
            return `${url}?t=${new Date().getTime()}`
        },
        focus(media, event) {
            if (event.target.classList.contains('remove')) {
                return
            }
            let element = document.querySelectorAll('.ui-sortable-handle')
            element.forEach((e) => {
                e.classList.remove('active')
            })
            event.currentTarget.classList.add('active')
            this.image.name = media.name
            this.image.url = media.url
            this.image.ext = media.ext
            if(media.video) {
                this.image.video = media.video
            } else {
                this.image.video = ''
            }

            this.$refs.preview.style.background = `url('${this.image.url}') center / contain no-repeat`;
        },
        mediaPath(name) {
            return '/storage/media/' + moment(this.product.created_at).format('Y') + '/' + moment(this.product
                .created_at).format('MM') + '/' + this.product.id + '/' + name
        },
        setVideo() {
            if (this.image.video && this.image.video != "undefined") {
                this.video = {
                    url: this.image.video,
                    ext: this.image.ext,
                }
            } else {
                let url = this.image.url.split(".").shift();
                this.video = {
                    url: url + '.' + this.image.ext,
                    ext: this.image.ext,
                }
            }
        },
        closeVideo() {
            this.video = {
                    url: '',
                    ext: '',
                }
        },
        closeCropper() {
            this.show = false
            this.gsapCloseModal()
        }
    }
};
</script>

<style scoped>
.edit {
    position: absolute;
    bottom: -17px;
    left: 15%;
    transform: translateX(-50%);
    white-space: nowrap;

    display: inline-block;
    padding: 6px 15px;
    margin: 0;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    background-color: #0f4e8c;
    color: #fff;
    border: 0;
    font-weight: 600;
    border-radius: 100px;
    text-decoration: none !important;
    vertical-align: top;
    cursor: pointer;
    transition: all 0.3s;
}
.ui-sortable-handle{
    position: relative;
}
.media-thumb{
    position: absolute;
    left: 100%;
    top:0;
    display:flex;
    width:490px;
    padding:11px;
    font-size:12px;
    background-color: #8f3f97;
    color:white;
    line-height: 14px;
    margin-left:2px;
    display:none;
    z-index:1;
}
.media-thumb span{
    margin:auto 0;
}
.media-thumb i{
    color:white;
    margin:auto 6px auto auto;
}
.active-media-thumb{
    border-radius: 0px;
}
.active-media-thumb .remove{
    background-color:#b1e0dd;
}
.active-media-thumb .remove:before{
    content:"\e90a";
}
.active-media-thumb .media-thumb{
    display:flex;
}
.edit svg {
    display: block;
}
.upload-cover-drag {
    width: 552px;
}
.upload .upload-background > a {
    padding: unset;
    top: unset;
    right: unset;
}

.upload .upload-background .edit-upload-remove {
    top: -21px;
    right: -18px;
    margin: 10px;
    padding: 0;
    text-decoration: none;
    z-index: 2;
}
.active-media-thumb .upload .upload-background .edit-upload-drag {
    display:none;
}
.upload .upload-background .edit-upload-drag {
    bottom: -21px;
    right: -18px;
    padding: 10px;
    text-decoration: none;
    z-index: 2;
}
.upload .upload-thumbs ul li {
    cursor: pointer;
}
.active {
    border-color: #913f98 !important;
}
.upload .upload-thumbs ul li.has-upload:first-child {
    border-color:  #ade8e2;
}

.play {
    position: absolute;
    bottom: -17px;
    left: 85%;
    transform: translateX(-50%);
    white-space: nowrap;

    display: inline-block;
    padding: 6px 15px;
    margin: 0;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    /*background-color: #0f4e8c;*/
    color: #fff;
    border: 0;
    font-weight: 600;
    border-radius: 100px;
    text-decoration: none !important;
    vertical-align: top;
    cursor: pointer;
    transition: all 0.3s;
}
.play svg {
    display: block;
    width:24px;
    height: 24px;
}
.play svg .outher{
    fill:#0f4e8c;
}
.play svg .inner{
    fill:#fff;
}
.video-icon {
    pointer-events: none;
}
.vide-icon-svg path {
    color: #913f98;
}
</style>