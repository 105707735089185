<template>
    <section class="vb-modal-container modal-container--active">
        <div class="vb-modal">
            <div class="modal-header">
                <div class="m-h-title">
                    Изпрати съобщение
                </div>
                <div class="m-h-close" @click="$emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.264 29.264">
                      <g transform="translate(1.132 1.132)">
                        <g>
                          <path d="M9674.438,1010.545a13.5,13.5,0,1,1,0-19.091A13.5,13.5,0,0,1,9674.438,1010.545Z" transform="translate(-9651.391 -987.5)" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          <g transform="translate(8.583 8.582)">
                            <line y1="9.833" x2="9.832" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                            <line x2="9.837" y2="9.835" fill="none" stroke="#8f3f97" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.264"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                </div>
            </div>
            <div class="m-h-product">
                <div class="list-item">
                    <div class="l-i-content">
                        <img :src="getImage()" alt="" class="l-i-img">
                        <div class="l-i-title">{{ order.title }}</div>
                    </div>
                    <div class="l-i-footer">
                        <div class="l-i-from">
                            <div class="l-i-f-label">От:</div>
                            <div class="l-i-f-data">{{ threadSender.name }}</div>
                        </div>
                        <div class="l-i-time">
                            <div class="l-i-date">{{ getDate(order.created_at) }} -</div>
                            <div class="l-i-hour">{{ getHours(order.created_at) }}ч.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-h-chat">
                <template v-if="messageThread">
                    <template v-for="message in messageThread.messages">
                        <div class="chat-date" v-if="dateIDS.includes(message.id)">
                            {{ getDate(message.created_at) }}
                        </div>
                        <div :class=" message.sender_id !== profileID ? 'chat-message message-sender' : 'chat-message message-receiver'">
                            <div class="c-m-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.25 35.274">
                                  <g transform="translate(-20.83 -263.683)">
                                    <circle cx="17.5" cy="17.5" r="17.5" transform="translate(20.955 263.958)" fill="none"/>
                                    <path d="M35.25,17.616A17.625,17.625,0,1,1,17.669,0,17.608,17.608,0,0,1,35.25,17.616ZM29.259,28.833a16.185,16.185,0,1,0-27.3-15.359,15.83,15.83,0,0,0,.551,10.008,15.672,15.672,0,0,0,3.449,5.386,13.278,13.278,0,0,1,8.3-6.826,6.685,6.685,0,0,1-3.306-5.084,6.539,6.539,0,0,1,2.044-5.635,6.708,6.708,0,0,1,9.475.213,6.538,6.538,0,0,1,1.849,4.977,6.755,6.755,0,0,1-3.324,5.528,13.4,13.4,0,0,1,4.871,2.524A13.266,13.266,0,0,1,29.259,28.833Zm-11.27,5c.444-.036,1.244-.053,2.044-.178a16.089,16.089,0,0,0,7.75-3.413c.427-.338.427-.338.178-.835a11.558,11.558,0,0,0-12.07-6.239,11.436,11.436,0,0,0-8.675,6.346c-.178.338-.089.5.178.711A16.608,16.608,0,0,0,17.989,33.828Zm-.356-12.319a5.315,5.315,0,1,0-5.333-5.3A5.341,5.341,0,0,0,17.634,21.509Z" transform="translate(20.83 263.684)"/>
                                  </g>
                                </svg>
                            </div>
                            <div class="c-m-message">
                                <div class="c-m-time">
                                    {{ getHours(message.created_at) }}
                                </div>
                                <div class="c-m-m-content">
                                    {{ message.message }}
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <div class="lastMessage" ref="lastMessage" id="lastMessage"></div>
                <!-- <div class="chat-message message-sender">
                    <div class="c-m-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.25 35.274">
                          <g transform="translate(-20.83 -263.683)">
                            <circle cx="17.5" cy="17.5" r="17.5" transform="translate(20.955 263.958)" fill="none"/>
                            <path d="M35.25,17.616A17.625,17.625,0,1,1,17.669,0,17.608,17.608,0,0,1,35.25,17.616ZM29.259,28.833a16.185,16.185,0,1,0-27.3-15.359,15.83,15.83,0,0,0,.551,10.008,15.672,15.672,0,0,0,3.449,5.386,13.278,13.278,0,0,1,8.3-6.826,6.685,6.685,0,0,1-3.306-5.084,6.539,6.539,0,0,1,2.044-5.635,6.708,6.708,0,0,1,9.475.213,6.538,6.538,0,0,1,1.849,4.977,6.755,6.755,0,0,1-3.324,5.528,13.4,13.4,0,0,1,4.871,2.524A13.266,13.266,0,0,1,29.259,28.833Zm-11.27,5c.444-.036,1.244-.053,2.044-.178a16.089,16.089,0,0,0,7.75-3.413c.427-.338.427-.338.178-.835a11.558,11.558,0,0,0-12.07-6.239,11.436,11.436,0,0,0-8.675,6.346c-.178.338-.089.5.178.711A16.608,16.608,0,0,0,17.989,33.828Zm-.356-12.319a5.315,5.315,0,1,0-5.333-5.3A5.341,5.341,0,0,0,17.634,21.509Z" transform="translate(20.83 263.684)"/>
                          </g>
                        </svg>
                    </div>
                    <div class="c-m-message">
                        <div class="c-m-time">
                            13:52ч.
                        </div>
                        <div class="c-m-m-content">
                            Кафе машината е подходяща за офиси,заведения или за други места, където има хора на едно място. Машината е под наем. Заплащате само кафето, което си поръчвате, а ние поддържаме машината.
                        </div>
                    </div>
                </div> -->

                <!-- <div class="chat-message message-receiver">
                    <div class="c-m-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.25 35.274">
                          <g transform="translate(-20.83 -263.683)">
                            <circle cx="17.5" cy="17.5" r="17.5" transform="translate(20.955 263.958)" fill="none"/>
                            <path d="M35.25,17.616A17.625,17.625,0,1,1,17.669,0,17.608,17.608,0,0,1,35.25,17.616ZM29.259,28.833a16.185,16.185,0,1,0-27.3-15.359,15.83,15.83,0,0,0,.551,10.008,15.672,15.672,0,0,0,3.449,5.386,13.278,13.278,0,0,1,8.3-6.826,6.685,6.685,0,0,1-3.306-5.084,6.539,6.539,0,0,1,2.044-5.635,6.708,6.708,0,0,1,9.475.213,6.538,6.538,0,0,1,1.849,4.977,6.755,6.755,0,0,1-3.324,5.528,13.4,13.4,0,0,1,4.871,2.524A13.266,13.266,0,0,1,29.259,28.833Zm-11.27,5c.444-.036,1.244-.053,2.044-.178a16.089,16.089,0,0,0,7.75-3.413c.427-.338.427-.338.178-.835a11.558,11.558,0,0,0-12.07-6.239,11.436,11.436,0,0,0-8.675,6.346c-.178.338-.089.5.178.711A16.608,16.608,0,0,0,17.989,33.828Zm-.356-12.319a5.315,5.315,0,1,0-5.333-5.3A5.341,5.341,0,0,0,17.634,21.509Z" transform="translate(20.83 263.684)"/>
                          </g>
                        </svg>
                    </div>
                    <div class="c-m-message">
                        <div class="c-m-time">
                            13:59ч.
                        </div>
                        <div class="c-m-m-content">
                            Супер
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="m-h-write-container">
                <div class="m-h-write">
                    <input type="text" name="message" class="m-h-message-input" placeholder="Напишете съобщението си тук..." v-model="newMessage" autocomplete="off">
                    <div class="m-h-icon" @click="sendMessage()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.485 21.492">
                          <path d="M19.986.1.534,11.327A1.008,1.008,0,0,0,.627,13.14l4.461,1.872L17.145,4.386a.252.252,0,0,1,.361.348L7.4,17.051V20.43a1.007,1.007,0,0,0,1.784.663l2.665-3.244,5.229,2.191a1.01,1.01,0,0,0,1.385-.764L21.48,1.146A1.007,1.007,0,0,0,19.986.1Z" transform="translate(-0.01 0.031)"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div :class="loading ? 'modal-loader' :  'modal-loader loading-div'">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 507.2 478.5" xml:space="preserve">
                    <g>
                        <path d="M463.8,280.3c-24.1-0.1-43.1-19.4-43-43.5c0.1-23.7,19.6-42.8,43.5-42.6c23.6,0.1,42.9,19.6,42.8,43.2
                            C507.1,261.1,487.6,280.4,463.8,280.3z"/>
                        <path d="M476.8,144.1c0,22.2-17.9,40-40.1,40c-22.2,0-40-17.9-39.9-40.1c0-22,17.9-39.8,39.9-39.9
                            C458.9,104.1,476.8,121.9,476.8,144.1z"/>
                        <path d="M373.3,109.1c-20.6,0-37-16.4-37-36.9c0-20.3,16.5-36.8,36.8-36.9c20.4,0,37.1,16.6,37,37
                            C410.1,92.6,393.6,109,373.3,109.1z"/>
                        <path d="M284.6,67.7c-18.7-0.1-33.6-15.2-33.6-34C251.1,15.2,266.4,0,285,0c18.8,0,34,15.4,33.9,34.4
                            C318.7,52.9,303.4,67.8,284.6,67.7z"/>
                        <path d="M219.5,36.5c0.1,17-13.4,30.6-30.5,30.8c-17,0.2-30.8-13.5-31-30.4c-0.1-17,13.7-31,30.7-31
                            C205.7,5.8,219.5,19.5,219.5,36.5z"/>
                        <path d="M130.6,79.7c0,15.3-12.2,27.7-27.5,27.8C87.8,107.6,75.3,95.3,75.2,80c-0.1-15.4,12.3-27.9,27.7-27.9
                            C118.2,52.1,130.5,64.4,130.6,79.7z"/>
                        <path d="M18.8,155.3c-0.1-13.6,10.9-24.7,24.5-24.7c13.6-0.1,24.7,10.8,24.8,24.4c0.1,13.5-10.9,24.6-24.5,24.8
                            C30,179.9,18.9,168.8,18.8,155.3z"/>
                        <path d="M43.1,248.6c0,12-9.5,21.6-21.5,21.6C9.7,270.2,0,260.5,0,248.7c0-11.9,9.6-21.5,21.5-21.6
                            C33.5,227.1,43.1,236.6,43.1,248.6z"/>
                        <path d="M59.8,342.6c0.1,10.2-8.2,18.5-18.4,18.5c-10.1,0-18.4-8.1-18.5-18.2c-0.1-10.3,8-18.6,18.3-18.7
                            C51.4,324.2,59.7,332.3,59.8,342.6z"/>
                        <path d="M114.5,419.7c-0.2,8.5-7.5,15.3-15.9,15c-8.5-0.3-15.2-7.5-14.9-16c0.3-8.3,7.4-15,15.8-14.8
                            C108,404.1,114.7,411.2,114.5,419.7z"/>
                        <path d="M171.7,464c0.1-6.8,5.6-12,12.4-11.9c6.9,0.1,12.3,5.7,12.1,12.6c-0.2,6.8-5.8,12.1-12.4,12
                            C176.9,476.6,171.6,471,171.7,464z"/>
                        <path d="M280,460c5.1,0,9.1,4,9.2,9.1c0.1,5.2-4.1,9.4-9.2,9.5c-5.1,0-9.3-4.3-9.2-9.4C270.9,464,275,460,280,460z"/>
                        <path d="M375.2,433c0,3.3-2.8,6-6.1,6.1c-3.3,0-6.1-2.7-6.2-6c-0.1-3.4,2.8-6.3,6.2-6.2C372.4,427,375.2,429.7,375.2,433z"/>
                        <path d="M437.7,363.1c-1.1,0.8-2.3,2.4-3.4,2.3c-1.1-0.1-2.1-1.7-3.2-2.7c1-1,1.8-2.8,2.9-3c1-0.2,2.4,1.2,3.6,2
                            C437.7,362.2,437.7,362.7,437.7,363.1z"/>
                    </g>
                </svg>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'

export default {
    props: ['order','profile_id'],
    data() {
        return {
            'messageThread': false,
            'currentOrder': this.order,
            // 'latestMessage': this.thread.last_message ? this.thread.last_message : false, 
            'threadSender': {
                'name': this.order.user_profile ? this.order.user_profile.name : ' ',
            },
            'receiverID': this.order.user_profile ? this.order.user_profile.profile_id : false,
            'profileID' : this.profile_id,
            'newMessage': '',
            'loading': true,
            'dateBubble': [],
            'dateIDS': [],
        }
    },
    created(){
        // console.log('inside message modal')
        // console.log(this.messageThread)
        // console.log('created')

        this.getThread();
        this.fillDateArrays();
        this.startListeningForNewMessages();
    },
    mounted(){
        this.enterKeyEvent();
    },
    methods: {
        startListeningForNewMessages() {
            const timer = setInterval(() => {
                if (window.Echo === undefined) {
                    return;
                }
                // if (this.activeMessageThread === null) {
                //     return;
                // }

                clearInterval(timer);


                let channel = window.Echo.channel(`messages.${this.profileID}`).listen('NewMessageEvent', (e) => {
                    // console.log('listening message')
                    // console.log(e.data);
                    if(this.messageThread.id == e.data.messageThread.id){
                        this.messageThread.last_message.created_at = e.data.message.created_at;
                        this.messageThread.last_message.id = e.data.message.id;
                        this.messageThread.last_message.message = e.data.message.message;
                        this.messageThread.last_message.message_thread_id = e.data.message.message_thread_id;
                        this.messageThread.last_message.receiver_id = e.data.message.receiver_id;
                        this.messageThread.last_message.sender_id = e.data.message.sender_id;
                        this.messageThread.last_message.sender_id = e.data.message.sender_id;
                        this.messageThread.last_message.sender_id = e.data.message.sender_id;

                        this.messageThread.messages = [...this.messageThread.messages,e.data.message];
                        this.fillDateArrays();
                        // console.log('this is the thread')
                        console.log('new message')
                        console.log(e.data)
                        this.scrollToLast(false);
                        this.$emit('updateCounter')
                    }

                    // if(activeChart.length == 0){
                    //     this.fetchThread(e.data.messageThread.id);
                    // }
                });

            }, 300);
        },
        getThread(){
            axios.get(`../../dashboard/message/${this.currentOrder.id}/getThread`).then((response) => {
                    if (response.data.type == 'success') {
                        /*Add message to Que*/
                        if(response.data.thread){
                            this.messageThread = response.data.thread;
                            if(response.data.thread.receiver_id == this.profileID){
                                this.threadSender.name = response.data.thread.sender.name
                            }else{
                                this.threadSender.name = response.data.thread.receiver.name
                            }
                            this.fillDateArrays();
                        }
                    }else if(response.data.type == 'error'){
                        this.$snotify.error(response.data.message);
                    }
                }).then(()=>{
                    this.scrollToLast(false);
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
        },
        fillDateArrays(){
            this.dateBubble = [];
            this.dateIDS = [];
            if(this.messageThread){
                this.messageThread.messages.map((item)=>{
                    let date = this.getDate(item.created_at);
                    let flag = this.dateBubble.includes(date);

                    if(!flag){
                        this.dateBubble.push(date);
                        this.dateIDS.push(item.id);
                    }
                });
            }
        },
        sendMessage(){
            let timeNow = moment().format('Y-MM-DD HH:mm');
            /*Last message update*/
            let tempMessage = {};
            // console.log('sending');
            // console.log(this.messageThread)
            if(this.messageThread){
                this.messageThread.last_message.message = this.newMessage;
                this.messageThread.last_message.created_at = timeNow;
                this.messageThread.last_message.sender_id = this.profileID;
                this.messageThread.last_message.receiver_id = this.messageThread ? (this.messageThread.receiver_id == this.profileID ? this.messageThread.sender_id : this.messageThread.receiver_id) : (this.messageThread.receiver_id);
                // console.log('receiver')
                // console.log(this.messageThread.receiver_id);
                // console.log('sender')
                // console.log(this.messageThread.sender_id);
            }else{
                tempMessage.message = this.newMessage;
                tempMessage.created_at = timeNow;
                tempMessage.sender_id = this.profileID;
                tempMessage.receiver_id = this.order.user_profile? this.order.user_profile.profile_id : this.order.buyer_profile.profile_id;
            }
    
            /*send message request here*/
            // console.log(this.messageThread.last_message)
            axios.post(`../../dashboard/message/store`, { 
                'data':this.messageThread ? this.messageThread.last_message : tempMessage,
                'order_id': this.currentOrder.id,
                }).then((response) => {
                    if (response.data.type == 'success') {
                        /*Add message to Que*/
                        let tempMessageObject = {
                            'created_at': timeNow,
                            'message': this.newMessage,
                            'message_thread_id': this.messageThread.id,
                            'receiver_id':this.receiverID,
                            'sender_id':this.profileID,
                        }
                        this.messageThread = response.data.thread;
                        this.newMessage = '';
                        // this.messageThread.messages = [...this.messageThread.messages,tempMessageObject];
                        this.$emit('sendMessage',this.messageThread);
                        this.$emit('setCounterToNull')
                        this.scrollToLast(true);
                        // console.log(response.data)
                        this.fillDateArrays();
                    }else if(response.data.type == 'error'){
                        this.$snotify.error(response.data.message);
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
        },
        getDate(date){
            moment.locale('bg');
            return moment(date).format('DD MMM');
        },
        getHours(date){
            return moment(date).format('HH:mm');
        },
        getImage(){
            if(this.order.product){
                let month = moment(this.order.product.created_at).format('MM');
                let year = moment(this.order.product.created_at).format('Y');
                let media = this.order.product.product_media.filter((item)=>{
                    return item.sort_id == 1;
                })[0];
                if(media){
                    if (media.name.includes('mp4')) {
                        return '/images/default/video-thumb.jpg';
                    } else {
                        return `/storage/media/${year}/${month}/${media.product_id}/${media.name}`;
                    }
                }else{
                    return '/images/default/default.jpg';
                }
            }
            else{
                return '/images/default/default.jpg';
            }
            return ''
        },
        scrollToLast(smooth){
            let element = document.querySelector('#lastMessage');
            if(smooth){
                setTimeout(()=>{
                    element.parentNode.scrollTop = element.parentNode.scrollHeight;
                },200)
            }else{
                setTimeout(()=>{
                    this.loading = false;
                    element.parentNode.scrollTop = element.parentNode.scrollHeight;
                },500)
            }
        },
        enterKeyEvent(){
            document.addEventListener('keydown', (event) => {
              if (event.keyCode === 13 && event.target.nodeName === 'INPUT') {
                    if(this.newMessage !== '' && this.newMessage !==  ' '){
                        this.sendMessage();
                    }
              }
            });
        }
    }
}
</script>
<style scoped>
   .l-i-date{
    text-transform: capitalize;
   }
   .lastMessage{
    height: 50px;
    display: block;
    width:100%;
   }
   .modal-loader{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    background-color:#fff;
    width:100%;
    height: 100%;
    z-index:9000;
   }
   .modal-loader.loading-div{
    display: none;
   }
   .modal-loader svg{
        fill:#913f98;
        width:50px;
        height: 50px;
        margin:auto;
        animation:spin .5s infinite linear;
   }
   .m-h-chat::-webkit-scrollbar {
      display: none;
    }
    .m-h-chat {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
   @keyframes spin{
        from{
            transform: rotate(0);
        }
        to{
            transform: rotate(360deg);
        }
   }
</style>