<template>
  <div class="section-duo-container">
    <section class="section-table-container">
      <div class="s-t-header">
          <div class="s-t-h-title s-t-h-title--bigger">
              Качване на продукти със .CSV
          </div>
      </div>
      <div class="section-table">
        <div class="import-container">
          <div class="import-list">
            <div class="import-list-item">
              <span class="i-l-i-number"><span>1</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Прочети инструкции за импорт на продуктите <a href="/dashboard/import/info" class="clr-purple text-undetrine">ТУК</a>
                </span>
              </span>
            </div>

            <div class="import-list-item">
              <span class="i-l-i-number"><span>2</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Изтегли темплейт от <a href="/dashboard/import/downloadTemplate" class="clr-purple text-undetrine">ТУК</a>
                </span>
                <span class="i-l-i-d-sub-text">
                  (не изтривай колони от файла и не променяй тяхната последователност)
                </span>
              </span>
            </div>

            <div class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>3</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Избери секция
                </span>
                <span class="i-l-i-d-sub-text">
                  (ако не избереш секция POPUP ще създаде такава за теб автоматично)
                </span>
                <div class="toggle-field-left select-wrapper-button">
                    <div class="select-wrapper">
                        <select v-model="chosen_shop" @change="setShop($event)">
                            <template v-for="c in user_shops">
                                <option :value="c.id" v-if="c.id == chosen_shop" selected>
                                    {{ c.title }}
                                </option>
                                <option :value="c.id" v-else>
                                    {{ c.title }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
              </span>
            </div>

            <div class="import-list-item imp-list-padding md-radio-fieldset">
              <span class="i-l-i-number"><span>4</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Избери за чия сметка да е куриерската услуга за продуктите, които качваш
                </span>
                <div class="i-l-i-d-choise">
                  <div class="i-l-i-d-c-item imp-checkbox-container">
                    <label class="md-radio">
                      <input :checked="is_sender_paying === 'no'" type="radio" name="delivery" value="sender" @change="changeIsSenderPaying('no')"> <i class="md-transition"></i> <span>За купувача</span>
                    </label>
                      <!-- <div :class="!is_sender_paying ? 'item-check item-check--active' : 'item-check'">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 15.2 12.9" style="enable-background:new 0 0 15.2 12.9;" xml:space="preserve">
                              <g>
                                  <rect x="8.9" y="-1" transform="matrix(0.7896 0.6137 -0.6137 0.7896 6.0265 -4.7005)" class="st0" width="2" height="14.8"></rect>
                                  <rect x="2.7" y="5.7" transform="matrix(0.6377 -0.7703 0.7703 0.6377 -6.1198 6.299)" class="st0" width="2" height="7.8"></rect>
                              </g>
                          </svg>
                      </div>
                      <label for="is_sender_paying" class="imp-check-label">За копувача</label> -->
                  </div>

                  <div class="i-l-i-d-c-item imp-checkbox-container">
                    <label class="md-radio">
                      <input :checked="is_sender_paying === 'yes'" type="radio" name="delivery" value="receiver"  @change="changeIsSenderPaying('yes')"> <i class="md-transition"></i> <span>За продавача</span>
                    </label>
                      <!-- <div :class="is_sender_paying ? 'item-check item-check--active' : 'item-check'">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 15.2 12.9" style="enable-background:new 0 0 15.2 12.9;" xml:space="preserve">
                              <g>
                                  <rect x="8.9" y="-1" transform="matrix(0.7896 0.6137 -0.6137 0.7896 6.0265 -4.7005)" class="st0" width="2" height="14.8"></rect>
                                  <rect x="2.7" y="5.7" transform="matrix(0.6377 -0.7703 0.7703 0.6377 -6.1198 6.299)" class="st0" width="2" height="7.8"></rect>
                              </g>
                          </svg>
                      </div>
                      <label for="is_sender_paying" class="imp-check-label">За продавача</label> -->
                  </div>
                </div>
              </span>
            </div>

            <div class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>5</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Приложи готов CSV файл <span class="clr-purple text-undetrine cursor-pointer"  @click="triggerInput()">ТУК</span>
                </span>
                <div class="i-c-c-content" @click="triggerInput()">
                      <input type="file" name="import_file" ref="file" class="imp-input imp-input-file" @change="setFile($event)" accept=".csv">
                      <div class="i-file-container">
                        <!-- <input type="text" placeholder="Добави .xml файл" class="i-file-input"> -->
                        <div class="i-file-input" v-if="fileName !== ''">{{ fileName }}</div>
                        <div class="i-file-input i-file-input--unactive" v-else><span>Добави .csv файл</span></div>
                        <div class="i-file-btn"><span>Добави</span></div>
                      </div>
                    </div>
              </span>
            </div>

            <div class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>6</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Стартирай импорт на продуктите
                </span>
                <div :class="is_active ? 'i-l-i-d-button i-l-i-d-button--active' : 'i-l-i-d-button' " @click="sendFile()">
                   <div class="i-l-i-d-b-text">
                     Импортирай
                   </div>
                </div>
              </span>
            </div>

            <div v-if="for_category" class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>7</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Категоризирай качените продукти без категория.
                </span>
                <a :href="chosen_shop ? `/dashboard/import/category?shop=${chosen_shop}` : `/dashboard/import/category`" class="i-l-i-d-button i-l-i-d-button--fill-purple">
                   <div class="i-l-i-d-b-text">
                     Към категоризация
                   </div>
                </a>
              </span>
            </div>
          </div>
          <div class="import-list">

          </div>
        </div>
      </div>
    </section>
    <section class="section-table-container">
      <div class="s-t-header">
          <div class="s-t-h-title s-t-h-title--bigger">
              Качване на продукти с .XML (Facebook/Google стандарт)
          </div>
      </div>
      <div class="section-table">
        <div class="import-container">
          <div class="import-list">
            <div class="import-list-item">
              <span class="i-l-i-number"><span>1</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Създай или ползвай вече създаден от теб Facebook/Google "Product Feed" (продуктов списък) като .XML файл или .XML линк.
                </span>
              </span>
            </div>

            <div class="import-list-item">
              <span class="i-l-i-number"><span>2</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Примерен може да видите тук:
                    <a href="/sitemap/example" target="_blank" class="clr-purple text-undetrine">RSS формат</a>
                    или
                    <a href="/sitemap/exampleAtom" target="_blank" class="clr-purple text-undetrine">Atom формат</a>.
                    <br>
                    Линк към спецификацията на формата, който поддържаме, ще намериш
                    <a href="https://support.google.com/merchants/answer/7052112?hl=bg" target="_blank" class="clr-purple text-undetrine">тук</a>.
                    <br>
                    Линк към актуален файл с пълния списък категории, които поддържаме, слъговете им и съответстващите им Facebook категории ще намериш
                    <a href="/dashboard/feed/categories-list" target="_self" class="clr-purple text-undetrine">тук</a>.
                    Можеш да използваш стойностите от всяка от колоните във файла, за да обозначиш категорията на
                    продукта в твоя XML продуктов списък.
                </span>
              </span>
            </div>

            <div class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>3</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Избери секция
                </span>
                <span class="i-l-i-d-sub-text">
                  (ако не избереш секция POPUP ще създаде такава за теб автоматично)
                </span>
                <div class="toggle-field-left select-wrapper-button">
                    <div class="select-wrapper">
                        <select v-model="chosen_shop_xml" @change="setShopXML($event)">
                            <template v-for="c in user_shops">
                                <option :value="c.id" v-if="c.id == chosen_shop_xml" selected>
                                    {{ c.title }}
                                </option>
                                <option :value="c.id" v-else>
                                    {{ c.title }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
              </span>
            </div>

            <div class="import-list-item imp-list-padding md-radio-fieldset">
              <span class="i-l-i-number"><span>4</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Избери за чия сметка да е куриерската услуга за продуктите, които качваш
                </span>
                <div class="i-l-i-d-choise">
                  <div class="i-l-i-d-c-item imp-checkbox-container">
                    <label class="md-radio">
                      <input :checked="is_sender_paying_xml === 'no'" type="radio" name="delivery-xml" value="sender" @change="changeIsSenderPayingXML('no')"> <i class="md-transition"></i> <span>За купувача</span>
                    </label>
                  </div>

                  <div class="i-l-i-d-c-item imp-checkbox-container">
                    <label class="md-radio">
                      <input :checked="is_sender_paying_xml === 'yes' " type="radio" name="delivery-xml" value="receiver" @change="changeIsSenderPayingXML('yes')"> <i class="md-transition"></i> <span>За продавача</span>
                    </label>
                  </div>
                </div>
              </span>
            </div>

            <div class="import-list-item imp-list-padding md-radio-fieldset">
              <span class="i-l-i-number"><span>5</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Избери начин на добавяне
                </span>
                <div class="i-l-i-d-choise i-l-i-d-choise-vertical">
                  <div class="i-l-i-d-c-item imp-checkbox-container imp-checkbox-container--inner">
                    <label class="md-radio">
                      <input  type="radio" name="importXML" value="file" @change="changeUseXML(true)"> <i class="md-transition"></i> <span>Използвай файл</span>
                    </label>
                    <div class="i-c-c-content"  v-if="useXMLFileFlag">
                      <input type="file" name="import_file" ref="xml_file" class="imp-input imp-input-file" @change="setXMLFile($event)" accept=".xml">
                      <div class="i-file-container">
                        <!-- <input type="text" placeholder="Добави .xml файл" class="i-file-input"> -->
                        <div class="i-file-input" v-if="xmlFileName !== ''" @click="clickXMLFile()">{{ xmlFileName }}</div>
                        <div class="i-file-input i-file-input--unactive" v-else @click="clickXMLFile()"><span>Добави .xml файл</span></div>
                        <div class="i-file-btn" @click="clickXMLFile()"><span>Добави</span></div>
                      </div>
                    </div>
                  </div>

                  <div class="i-l-i-d-c-item imp-checkbox-container imp-checkbox-container--inner">
                    <label class="md-radio">
                      <input type="radio" name="importXML" value="link"  @change="changeUseXML(false)"> <i class="md-transition"></i> <span>Използвай линк</span>
                    </label>
                    <div class="i-c-c-content" v-if="useXMLURLFlag">
                      <div class="i-c-c-info">
                        Тук може да добавите линк към продуктовия списък. <b>Автоматично на всеки два дни</b> ще актуализираме продуктовия списък за нови продукти.
                      </div>
                      <div class="i-file-container">
                        <input class="inp-full" type="text" name="xmlSource" v-model="xmlSource" placeholder="Линк към продуктовия списък" />
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <div :class="lockSources ? 'import-list-item imp-list-padding import-list-item--unactive' :'import-list-item imp-list-padding'">
              <span class="i-l-i-number"><span>6</span></span>
              <span :class="xmlActive ? 'i-l-i-data' : 'i-l-i-data i-l-i-data--active'" v-if="xml_choise">
                <span class="i-l-i-d-text">
                  Стартирай импорт на продуктите от списъка
                </span>
                <span class="i-l-i-d-sub-text">
                  (Ако липсва тегло, POPUP ще добави тегло от 1кг.<br/> Цената на продуктите трябва да е в BGN)
                </span>
                <template  v-if="xmlActive">
                  <div class="i-l-i-d-button i-l-i-d-button--active" @click="sendXMLFile()">
                     <div class="i-l-i-d-b-text">
                       Импортирай
                     </div>
                  </div>
                  <a href="/dashboard/feed" target="_blank" class="i-l-i-d-button i-l-i-d-button--active" v-if="showFeedBtn">
                    <div class="i-l-i-d-b-text">
                       Продуктови списъци
                     </div>
                  </a>
                </template>
              </span>

              <span :class="xmlActive ? 'i-l-i-data' : 'i-l-i-data i-l-i-data--active'" v-else>
                <span class="i-l-i-d-text">
                  <!-- Добави продуктов списък -->
                  Стартирай импорт на продуктите от списъка
                </span>
                <span class="i-l-i-d-sub-text">
                  (ако липсва тегло, POPUP ще добави тегло от 1кг)
                </span>
                  <div :class="xmlActive ? 'i-l-i-d-button i-l-i-d-button--active' : 'i-l-i-d-button i-l-i-d-button--xml' " @click="sendXMLSource()">
                     <div class="i-l-i-d-b-text">
                       Импортирай
                     </div>
                  </div>
                  <a href="/dashboard/feed" target="_blank" class="i-l-i-d-button i-l-i-d-button--active" v-if="showFeedBtn">
                    <div class="i-l-i-d-b-text">
                       Продуктови списъци
                     </div>
                  </a>
              </span>
            </div>

            <div v-if="xml_for_category" class="import-list-item imp-list-padding">
              <span class="i-l-i-number"><span>7</span></span>
              <span class="i-l-i-data">
                <span class="i-l-i-d-text">
                  Категоризирай качените продукти без категория.
                </span>
                <a :href="chosen_shop_xml ? `/dashboard/import/category?shop=${chosen_shop_xml}` : `/dashboard/import/category`" class="i-l-i-d-button i-l-i-d-button--fill-purple">
                   <div class="i-l-i-d-b-text">
                     Към категоризация
                   </div>
                </a>
              </span>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['shops'],
  data() {
    return {
      urlStore: '/dashboard/import/store',
      urlValidate: '/dashboard/import/validateStore',
      file: null,
      fileName:'',
      is_active:false,
      for_category:false,
      is_sender_paying:'no',
      is_sender_paying_xml:'no',
      xml_choise:false,
      products:[],
      user_shops:this.shops,
      chosen_shop:false,
      active_category:false,
      productNumbers:0,
      pCounter:0,
      interVal:null,
      showLoader:false,
      xmlSource: '',
      xmlFile: false,
      xmlFileName:'',
      showFeedBtn:false,
      xmlActive: false,
      xml_for_category:false,
      chosen_shop_xml:false,
      useXMLFileFlag:false,
      useXMLURLFlag:false,
      urlStoreFeed:'/dashboard/feed/store',
      fileValidate: '/dashboard/feed/file/validateFile',
      lockSources:false,
    }
  },
  mounted() {

  },
  methods: {
    changeUseXML(flag){
      this.xml_choise = flag;

      this.xmlFileName = '';
      this.productFile = false;
      this.xmlSource = '';
      this.showFeedBtn = false;

      this.xmlActive = true;

      // console.log(this.xml_choise);
      if(flag){
        this.useXMLFileFlag = true;
        this.useXMLURLFlag = false;
      }else{
        this.useXMLFileFlag = false;
        this.useXMLURLFlag = true;
      }
    },
    changeIsSenderPayingXML(flag){
      this.is_sender_paying_xml = flag;
    },
    setXMLFile(event){
        // this.is_active = true;
        this.xmlFileName = event.target.files[0].name;
        this.xmlFile = event.target.files[0];
    },
    setShop(event){
      this.chosen_shop = event.target.value;
    },
    setShopXML(event){
      this.chosen_shop_xml = event.target.value;
    },
    changeIsSenderPaying(flag){
      this.is_sender_paying = flag;
      // this.is_sender_paying = !this.is_sender_paying;
    },
    setFile(event){
      let validate = this.validateFile(event);
      this.for_category = false;
      if(validate){
        this.is_active = true;
        this.file = event.target.files[0];
        this.fileName = event.target.files[0].name;
      }
    },
    validateFile(event){
      this.active_category = false;
      if(!event.target.files[0].name.match(/.(csv)$/i)) {
            // alert('Разрешено е качването само на снимки!');
            this.notifyError('Разрешено е качването само на CSV.');
            return false;
        }
        return true
    },
    clickXMLFile(){
      this.$refs.xml_file.click()
    },
    triggerInput(){
      this.$refs.file.click()
    },
    sendXMLFile(){
      this.xml_for_category = false;
      this.$snotify.async(`Проверяване на файла и качване на продукти.`, 'Success async', () => new Promise((resolve, reject) => {
            if(this.xmlFile){
              this.lockSources = true;
              let data = new FormData();
              data.append("file", this.xmlFile);
              data.append("is_sender_paying", this.is_sender_paying_xml);
              if(this.chosen_shop_xml){
                data.append("shop", this.chosen_shop_xml);
              }
              axios.post(this.fileValidate, data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((response) => {
                    if(response.data.type == 'success'){
                      resolve({
                                title: 'Success',
                                body: response.data.message,
                                config: {
                                    timeout: 10000,
                                    closeOnClick: true
                                }
                            });
                      this.xml_for_category = true;
                    }else{
                      reject({
                          title: 'Error',
                          body: response.data.message,
                          config: {
                              timeout: 0,
                              closeOnClick: true
                          }
                      });
                    }
                  this.lockSources = false;
                });

            }else{
              reject({
                        title: 'Error',
                        body: 'Моля изберете файл.',
                        config: {
                            timeout: 0,
                            closeOnClick: true
                        }
                    });
            }
      }));
    },
    sendXMLSource(){
      this.xml_for_category = false;
      this.$snotify.async(`Проверяване на файла и качване на продукти.`, 'Success async', () => new Promise((resolve, reject) => {
            if(this.xmlSource){
              let data = new FormData();
              data.append("source", this.xmlSource);
              data.append("is_sender_paying", this.is_sender_paying_xml);
              if(this.chosen_shop_xml){
                data.append("shop", this.chosen_shop_xml);
              }
              this.lockSources = true;
              axios.post(this.urlStoreFeed, data, {
                          headers: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }).then((response) => {
                          if(response.data.type == 'success'){
                            resolve({
                                  title: 'Success',
                                  body: response.data.message,
                                  config: {
                                      timeout: 10000,
                                      closeOnClick: true
                                  }
                              });
                            this.showFeedBtn = true;
                            this.xml_for_category = true;
                          }else{
                            if(response.data.btn){
                              this.showFeedBtn = true;
                            }else{
                              this.showFeedBtn = false;
                            }
                            reject({
                                title: 'Error',
                                body: response.data.message,
                                config: {
                                    timeout: 0,
                                    closeOnClick: true
                                }
                            });
                          }
                          this.lockSources = false;
                        });
            }else{
              reject({
                        title: 'Error',
                        body: 'Моля поставете линк.',
                        config: {
                            timeout: 0,
                            closeOnClick: true
                        }
                    });
            }
      }));
    },
      sendFile() {
          this.$snotify.async('Сканиране на файла.', 'Success async', () => new Promise((resolve, reject) => {
              let data = new FormData();
              data.append("file", this.file);
              if (this.chosen_shop) {
                  data.append("shop", this.chosen_shop);
              }
              data.append('is_sender_paying', this.is_sender_paying);
              axios.post(this.urlValidate, data, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })
                  .then((response) => {
                      if (response.data) {
                          if (response.data.type === 'success') {
                              this.productNumbers = response.data.counter;
                              resolve({
                                  title: 'Success',
                                  body: response.data.message,
                                  config: {
                                      timeout: 10000,
                                      closeOnClick: true
                                  }
                              });
                              return;
                          } else if (response.data.type === 'error') {
                              reject({
                                  title: 'Error',
                                  body: response.data.message,
                                  config: {
                                      timeout: 0,
                                      closeOnClick: true
                                  }
                              });
                              throw new Error();
                          }
                      }
                      throw new Error();
                  })
                  .then((response) => {
                      this.storeFile();
                  })
                  .catch((errors) => {
                      this.errors = errors.response?.data?.errors ?? [];
                      reject({
                          title: 'Error',
                          body: 'Възникна грешка. Моля опитайте отново',
                          config: {
                              timeout: 0,
                              closeOnClick: true
                          }
                      });
                      this.loader = false
                  })
          }));
      },
    getProductNumber(){
      let step = 1;
      let counterChange = document.querySelector('#updateCounter');
      if(this.productNumbers > 0 && this.pCounter < this.productNumbers){
        this.pCounter = this.pCounter + step;
        if(counterChange){
          while( counterChange.firstChild ) {
            counterChange.removeChild( counterChange.firstChild );
          }
          counterChange.appendChild( document.createTextNode(this.pCounter) );
        }else{
          clearInterval(this.interVal);
        }
      }else{
        clearInterval(this.interVal);
      }
    },
    storeFile(){
      if(this.productNumbers > 0){
        this.$snotify.async(`Качване на ${this.productNumbers} продукта.`, 'Success async', () => new Promise((resolve, reject) => {
              // this.interVal = setInterval(this.getProductNumber(),1000);
              this.$snotify.html(`Качване на <i style="display:inline;" id="updateCounter">${this.pCounter}</i> от ${this.productNumbers}.`,{
                showProgressBar: true,
                timeout:Math.floor(this.productNumbers / 1) * 700,
              });
              this.interVal = setInterval(this.getProductNumber,600);
              let data = new FormData();
              this.showLoader = true;
              data.append("file", this.file);
              if(this.chosen_shop){
                data.append("shop", this.chosen_shop);
              }
              data.append('is_sender_paying',this.is_sender_paying);
                  axios.post(this.urlStore, data, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                  .then((response) => {
                      if(response.data){
                          if(response.data.type == 'success'){
                            this.for_category = true;
                            // this.products = response.data.products;
                            this.is_active = false;
                            if(response.data.for_categories){
                              this.active_category = true;
                            }
                            this.file = null;
                            this.chosen_shop = response.data.shop;
                            resolve({
                                  title: 'Success',
                                  body: response.data.message,
                                  config: {
                                      timeout: 10000,
                                      closeOnClick: true
                                  }
                              });
                            setTimeout(()=>{
                              this.$snotify.clear();
                            },3000);
                          }else if(response.data.type == 'error'){
                            this.$snotify.clear();
                            this.notifyError(response.data.message);
                          }
                      }
                      // this.notifyError(response.data.message);
                      // this.loader = false

                  })
                  .catch((errors) => {
                      this.errors = errors.response.data.errors
                      reject({
                                  title: 'Error',
                                  body: 'Възникна грешка. Моля опитайте отново',
                                  config: {
                                      timeout: 0,
                                      closeOnClick: true
                                  }
                              });
                      this.loader = false
                  })
          }),
          // {
          //       showProgressBar: true,
          //       timeout: Math.floor(this.productNumbers / 2) * 1000,
          //     }
              );
      }else{
          this.notifyError('Възникна грешка при качването, моля уверете се че този файл не е празен.');
      }
    }
  },
}
</script>
<style type="text/css">
  body .grid.office-action-menu > div a{
    margin-bottom: .3rem;
  }
</style>
<style type="text/css" scoped>
  .i-l-i-d-button{
    transition: opacity .3s;
  }
  .i-l-i-d-button--xml.i-l-i-d-button{
    pointer-events: none;
    opacity: .6;
  }
  .import-list-item--unactive{
    pointer-events: none;
    opacity: .6;
  }
  .import-list-item .i-l-i-data.i-l-i-data--active{
    padding-top:.5rem;
  }
  .imp-checkbox-container.imp-checkbox-container--inner{
    margin:1rem auto;
  }
  .imp-list-padding{
    margin-bottom: 0 !important;
  }
  .i-l-i-d-choise-vertical .i-l-i-d-c-item.imp-checkbox-container{
    margin-right: 0;
  }
  .section-duo-container{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - .75rem));
    /*grid-column-gap: 2.4rem;*/
    grid-column-gap: 1.5rem;
  }
  .i-file-container{
    display: flex;
    flex-direction: row;
    border:.2rem solid #ade8e2;
    font-size:1.3rem;
    height: 4rem;
  }
  .i-file-input.i-file-input--unactive{
    opacity: .3;
  }
  .i-c-c-info{
    padding-bottom: 2rem;
  }
  .i-c-c-content{
    margin-top: 2rem;
    overflow:hidden;
    transition: height .3s;
  }
  .inp-full{
    padding:0 1.5rem;
    width: 100%;
  }
  .imp-input.imp-input-file{
    display: none;
  }
  .i-file-container .i-file-btn{
    background-color:#ade8e2;
    width: 100px;
    color:black;
    margin-left: auto;
    display: flex;
    font-weight: bold;
    cursor: pointer;
  }
  .i-file-btn span{
    margin:auto;
  }
  .i-file-input{
    margin: auto auto auto 0;
  }
  .i-file-input{
    color: black;
    width:100%;
    display: flex;
    padding-left: 1.5rem;
     -webkit-appearance: none;
     -moz-appearance:    none;
     appearance:         none;
  }
  .imp-input-file{
    display: none;
  }
  .clr-purple{
    text-decoration: underline;
  }
  .import-list{
    display: flex;
    flex-direction: column;
    width:100%;
    font-size:1.4rem;
  }
  .import-list-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.2rem;
    transition: opacity .3s;
  }
  .import-list-item .i-l-i-number{
    font-size: 2.7rem;
    font-weight: 600;
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    display: flex;
    /*margin-top: auto;*/
    margin-bottom: auto;
    border-radius: 50%;
    margin-right: 1.4rem;
    align-self: flex-start;
    border:.3rem solid #913f98;
    color: #913f98;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .i-l-i-d-button.i-l-i-d-button--faded{
    opacity: .8;
  }
  .item-check.item-check--active svg{
    stroke:#913f98;
  }
  .import-list-item .i-l-i-data{
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  .i-l-i-d-choise{
    display: flex;
    flex-direction: row;
  }
  .i-l-i-d-choise.i-l-i-d-choise-vertical{
    flex-direction: column;
  }
  .i-l-i-d-choise-vertical .i-l-i-d-c-item{
    flex-direction: column;
  }
  .i-l-i-d-choise-vertical .md-transition{
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-top: -8px;
    border-radius: 50%;
    background-color: #fff;
    vertical-align: middle;
    box-shadow: inset 0 0 0 2px #ade8e2;
  }
  .i-l-i-d-choise .i-l-i-d-c-item.imp-checkbox-container:last-child{
    margin-right: 0;
  }
  .i-l-i-d-c-item.imp-checkbox-container{
    font-weight: normal;
    margin-left: 0;
    margin-right: 5rem;
  }
  .imp-list-padding .i-l-i-data{
    padding-top:1.5rem;
    width: 100%;
    max-width: calc(100% - 5rem);
  }
  .i-l-i-d-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    border:.2rem solid #ade8e2;
    background-color:#ade8e2;
    /* font-size:1.6rem; */
    font-size:1.3rem;
    margin-top: 1.7rem;
    padding:.4rem 2rem;
    /* padding:.7rem 2rem; */
    width:20rem;
    height:4.5rem;
    cursor: pointer;
    transition:color .3s, background-color .3s, transform .3s, opacity .3s;
  }
  .i-l-i-d-button:hover{
    transform:scale(1.05);
  }
  .i-l-i-d-button.i-l-i-d-button--active{
    color:#fff;
    background-color: #913f98;
    border-color:#913f98;
  }
  .i-l-i-d-button.i-l-i-d-button--active svg path{
    stroke:#fff;
  }
  .i-l-i-d-button svg{
    width:1.5rem;
    margin-right: 1rem;
    margin-left: auto;
  }
  .i-l-i-d-button .i-l-i-d-b-text{
    /* margin:auto auto auto 0; */
    margin:auto;
  }
  .import-list-item .select-wrapper select{
    border:.2rem solid #ade8e2;
    padding:.5rem .2rem;
    background-color: transparent;
    margin-top: 2rem;
    max-width: 100%;
  }
  .i-l-i-d-sub-text{
    font-size:1.4rem;
    font-weight: normal
  }
  .import-list-item .i-l-i-number span{
    margin:auto;
  }
  .s-t-h-filters-list--left{
    margin-left: auto;
  }
  .imp-input-f-name{
    display: flex;
  }
  .imp-input-f-name span{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .i-l-i-d-button.i-l-i-d-button--purple{
    border-color:#913f98;
    background-color:#913f98;
    color:#fff;
  }
  .i-l-i-d-button.i-l-i-d-button--purple svg path{
    stroke:#fff;
    fill:#fff;
  }
  .i-l-i-d-button.i-l-i-d-button--fill-purple{
    background-color:#913f98;
    color:#fff;
  }
  .imp-button span{
    margin:auto;
  }
  .imp-button{
    background-color:#913f98;
    color:#fff;
    display: flex;
    font-size:1.6rem;
    /*text-transform: uppercase;*/
    padding:.7rem 0;
    text-align: center;
    width:15rem;
    height: 3.5rem;
    margin:0 auto;
    /*border-radius: .4rem;*/
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: color .3s, background-color .3s;
  }
  .imp-button.imp-button--category{
    background-color:#913f98;
    color:#fff;
    display: block;
    width:auto;
  }
  .imp-button.imp-button--unactive{
    pointer-events: none;
    background-color:gray;
    color:#fff;
  }
  .imp-input-f-name{
    display: flex;
    max-width:50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
    border: .2rem solid #ade8e2;
    padding: 1rem 2rem;
    pointer-events: none;
    margin-bottom: 1rem;
  }
  .item-check svg{
    display: none;
  }
  .item-check.item-check--active svg{
    display: flex;
  }
  .import-container{
    border-right: .1rem solid #ade8e2;
    border-left: .1rem solid #ade8e2;
    border-bottom: .1rem solid #ade8e2;
    padding: 1.5rem 3rem;
  }
  .imp-icon{
    width:20rem;
    height: 20rem;
    display: flex;
    margin:0 auto;
    /*border-radius: 50%;*/
    /*border:.7rem solid;*/
    /*border-color:#913f98;*/
    cursor:pointer;
  }
  .imp-input-container{
    flex-direction: column;
    display: none;
  }
  .imp-icon svg{
    width:80%;
    /*height: 80%;*/
    margin:auto;
    /*fill:#fff;*/
    fill:#913f98;
  }
  .imp-container-header{
    font-size:3rem;
    margin:2rem auto;
    text-align: center;
    font-weight: 600;
    color:#913f98;
  }
 /* .imp-input-container{
    display: flex;
    display: -ms-flex;
    margin-bottom: 1rem;
  }*/
  .imp-input{
    margin:0 auto;
    width:15rem;
    height: 3.5rem;
    /*visibility: hidden;*/
    display: flex;
    position: relative;
  }
  .imp-inpuit::-webkit-file-upload-button{
    visibility: hidden;
  }
  .imp-input:before{
    content:'Избери файл';
    /*width:100%;*/
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    /*background-color: #913f98;*/
    background-color: #ade8e2;
    /*color:#fff;*/
    color:#000;
    font-size:1.4rem;
    padding:1rem 0;
    text-align: center;
    cursor: pointer;
    /*border-radius: .8rem;*/
  }
  .imp-checkbox-container{
    display: flex;
    display:-ms-flex;
    flex-direction: row;
    margin:2rem auto;
  }
  .imp-checkbox-container .item-check{
    margin-right: 1.5rem;
    margin-left: auto;
  }
  .imp-checkbox-container .imp-check-label{
    margin-right: auto;
  }

  /*input[type="file"]{
    display: none;
  }*/
  .toggle-field--import{
    max-width: 100%;
    margin-left:auto;
    margin-right: auto;
    flex-direction: column;
  }
  .toggle-field-left{
    width:100%;
  }
  .toggle-field--import select{
    width:100%;
    border:.2rem solid #ade8e2;
    margin-bottom: 3px;
    padding:6px 6px;
    font-size:1.6rem;
  }
  .toggle-field-label{
    font-size:1.6rem;
    margin-bottom: .8rem;
  }
  .imp-buttons-duo{
    display: flex;
    flex-direction: row;
  }
  .imp-buttons-duo > *:first-child{
    margin-left: auto;
    margin-right: .4rem;
  }
  .imp-buttons-duo > *:last-child{
    margin-right: auto;
    margin-left: .4rem;
  }
  .toggle-message{
    font-size: 1.4rem;
    color: #00000080;
    margin-bottom: .8rem;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .toggle-message span{
    color: #913f98;
  }
  .tab-list{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    height: 4.5rem;
  }
  .tab-list .tab-list-item.tab-list-active{
    background-color:#ade8e2;
    pointer-events: none;
  }
  .tab-list .tab-list-item.tab-list-active span{
    color:#000;
  }
  .tab-list .tab-list-item.tab-list-active svg{
    fill:#000;
  }
  .tab-list-item{
    background-color:#913f98;
    display: flex;
    padding-left:1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  .tab-list-item span{
    color:#fff;
    margin:auto;
    font-size:1.2rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  .tab-list-icon{
    width:1.6rem;
    height: 1.6rem;
    margin-right: 1.1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  .tab-list-icon svg{
    fill:#fff;
    width:100%;
  }
  body .md-radio-fieldset{
    font-size:1.3rem;
    /* font-size:1.6rem; */
  }
  .md-radio-fieldset .md-radio{
    margin-bottom:0;
  }
  .st0{fill:#FFFFFF;}
  .st1{fill:#8E4192;}

  @media only screen and (max-width: 1100px){
    .import-list-item .select-wrapper select{
      min-width: auto !important;
      width:100%;
    }
  }
  @media only screen and (max-width:768px){
    .imp-input-f-name{
      max-width: 90%;
    }
    .imp-icon{
      width:13rem;
      height:13rem;
    }
    .toggle-field--import{
      max-width: 300px;
    }
    .imp-buttons-duo{
      flex-direction: column;
    }
    .imp-buttons-duo > *{
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top:.5rem !important;
    }
    .import-container{
      padding-left:1rem;
      padding-right:1rem;
    }
    .imp-list-padding .i-l-i-data{
      max-width:calc(100% - 7rem);
    }
    .select-wrapper select{
      max-width:100%;
    }
    .i-l-i-d-choise{
      flex-direction:column;
    }
    .imp-checkbox-container{
      margin-top:.7rem;
      margin-bottom:.7rem;
    }
    .import-list-item .i-l-i-number{
      width:3.5rem;
      height:3.5rem;
      min-width:3.5rem;
      font-size:2rem;
    }
    .imp-list-padding .i-l-i-data{
      padding-top:.5rem;
    }
    .section-duo-container{
      grid-template-columns: 100%;
    }
  }
</style>
