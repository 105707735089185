<template>
    <section class="section-dashboard">
        <div class="container extend--upload extend--upload--fix">
            <div class="grid grid-break-xl bg-white grid-fix-me-please">
                <div class="col-6 col-lg-12">
                    <step-one-media :errors="errors" :product="product"></step-one-media>
                    <a href="#" class="no-decoration" data-popup-youtube-id="zBt5ajOYd3w" tabindex="-1"
                       title="Как работи">
                        <div class="how-it-works how-it-works--inside">
                            <div class="how-video">
                                <div class="how-video-box"><i class="icon icon-play"></i></div>
                            </div>
                            <div class="how-text">Как работи Pop Up?</div>
                        </div>
                    </a>
                    <div class="col-grid-green"></div>
                </div>

                <div class="col-6 col-lg-12 bg-white">
                    <div class="grid">
                        <div class="col-6 col-md-12">
                            <div class="form-group" :class="errors && errors.title ? 'is-error' : ''" @keyup="errors.title = null">
                                <label for="">Име на продукт<sub class="is-required">*</sub></label>
                                <input type="text" name="product_name" v-model="product.title" tabindex="2" ref="startPage"  placeholder="Текст за заглавие">
                                <form-error v-if="errors && errors.title">
                                    <template v-for="title in errors.title">{{ title }}</template>
                                </form-error>
                            </div>
                            <div class="form-group" :class="errors && errors.description ? 'is-error' : ''" @keyup="errors.description = null">
                                <label for="">Описание на продукт<sub class="is-required">*</sub></label>
                                <textarea type="text" name="product_description" v-model="product.description"
                                    placeholder="Опиши какво продаваш и дай възможно най-подробна информация." tabindex="5"></textarea>
                                <form-error v-if="errors && errors.description">
                                    <template v-for="description in errors.description">{{ description }}</template>
                                </form-error>
                            </div>
                        </div>
                        <div class="col-6 col-md-12">
                            <div tabindex="-1" class="toggle-field">
                                <div tabindex="-1" class="toggle-field-left">
                                    <div class="grid">
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.price ? 'is-error' : ''" @keyup="errors.price = null">
                                                <label for="">Цена<sub class="is-required">*</sub></label>
                                                <div class="price-wrapper">
                                                    <input type="number" name="price" v-model="product.price" tabindex="2">
                                                    <span class="number-sub">лв.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.quantity ? 'is-error' : ''" @keyup="errors.quantity = null">
                                                <label for="">Брой<sub class="is-required">*</sub></label>
                                                <div class="number-wrapper">
                                                    <input type="text" name="amount" v-model="product.quantity" tabindex="3">
                                                    <a href="#" class="number-arrow number-arrow-up" @click="numberArrowUp" tabindex="-1"><i
                                                            class="icon icon-arrow-up"></i></a>
                                                    <a href="#" class="number-arrow number-arrow-down" @click="numberArrowDown" tabindex="-1"><i
                                                            class="icon icon-arrow-down"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-12">
                                            <div class="form-group" :class="errors && errors.weight ? 'is-error' : ''" @keyup="errors.weight = null">
                                                <label for="">Тегло<sub class="is-required">*</sub></label>
                                                <div class="price-wrapper">
                                                    <input type="text" name="weight" v-model="product.weight" tabindex="4">
                                                    <span class="number-sub">кг.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-field-right mobile-toggle-down">
                                    <a href="#" class="toggle toggle-top ml-0 mr-0 toggle-bottom-left"
                                        data-text="Необходимо е да въведеш тегло, за да пресметнем куриерската услуга. Ако не си сигурен, въведи приблизително, а Еконт ще го коригира при изпращането." tabindex="-1"><i
                                            class="icon icon-info"></i></a>
                                </div>
                            </div>

                            <div class="form-group" :class="errors && errors.valid_to ? 'is-error' : ''" @click="errors.valid_to = null">
                                <label for="">Валидно до</label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="calendar-wrapper">
                                            <input class="datepicker" type="text" name="expiration"
                                                v-model="product.valid_to" autocomplete="off" tabindex="6">
                                            <i class="icon icon-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери срок, в който твоят продукт за продажба да е валиден. Можеш да го промениш по всяко време или да го оставиш 40 дни по подразбиране." tabindex="-1"><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" :class="errors && errors.shop_id ? 'is-error' : ''" @click="errors.shop_id = null">
                                <label for="">Моите секции</label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="select-wrapper">
                                            <select id="shop_id" name="shop_id" v-model="product.shop_id" tabindex="7">
                                                <option v-if="shops.length == 0" value="0" selected>Моята първа секция</option>
                                                <option v-for="(shop, index) in shops" :value="shop.id">
                                                    {{ shop.title }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери къде да се запамети продуктът." tabindex="-1"><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" :class="errors && errors.transport_pay ? 'is-error' : ''">
                                <label for="">Куриерската услуга е за сметка на<sub class="is-required">*</sub></label>
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="radio-wrapper">
                                            <div class="radio-stack no-select">
                                                <div class="md-radio-fieldset">
                                                    <label class="md-radio" tabindex="8">
                                                        <input type="radio" value="0" v-model="product.is_sender_paying">
                                                        <i class="md-transition"></i>
                                                        <span>Купувач</span>
                                                    </label>
                                                    <label class="md-radio" tabindex="9">
                                                        <input type="radio" value="1" v-model="product.is_sender_paying">
                                                        <i class="md-transition"></i>
                                                        <span>Продавач</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери за чия сметка ще бъде доставката."><i
                                                class="icon icon-info"  tabindex="-1"></i></a>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group" :class="errors && errors.condition ? 'is-error' : ''" @click="errors.condition = null">
                                <label for="">Състояние<sub class="is-required">*</sub></label>
                                <div tabindex="-1" class="toggle-field">
                                    <div tabindex="-1" class="toggle-field-left">
                                        <div class="select-wrapper">
                                            <select id="condition" name="condition" v-model="product.condition" tabindex="10">
                                                <option value="1" :selected="product.condition == 1 ? 'true' : 'false'" disabled>Не е посочено</option>
                                                <option value="0" :selected="product.condition == 0 ? 'true' : 'false'">Втора употреба</option>
                                                <option value="2" :selected="product.condition == 2 ? 'true' : 'false'">Нов продукт</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div tabindex="-1" class="toggle-field-right">
                                        <a href="#" tabindex="-1" class="toggle ml-0 mr-0 toggle-bottom-left"
                                            data-text="Избери дали продукта ти е нов или втора употреба."><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form--extend-butons form--extend-butons-1 justify-end mb-2">

                                <div>
                                    <input @click.prevent="next()" class="button" type="submit" value="Напред"  tabindex="10">
                                </div>
                                <p class="mutted text-right faq--extend extend--faq mr-0">
                                    <span class="vertical-middle">Често задавани въпроси</span>
                                    <a href="/faq/questions" class="toggle mr-0 toggle-bottom-left"
                                       target="_blank" tabindex="-1"><i
                                            class="icon icon-questionmark-circle"></i></a>
                                </p>
                            </div> -->



                        </div>

                        <div class="col-12 col-cats">
                            <div class="form-group" :class="errors && errors.category_id ? 'is-error' : ''" @click="errors.category_id = null">
                                <label for="">Категории<sub class="is-required">*</sub></label>
                                <div class="toggle-field" v-for="(item,index) in cat">
                                    <div class="toggle-field-left select-wrapper-button">
                                        <div class="select-wrapper">
                                            <select :id="`category-${index+1}`" :data-id='index' :name="`category-${index+1}`"
                                            v-model="p_cat[index]"
                                            :tabindex="7+index" @change="getChildrens($event)">
                                                <template v-for="c in item">
                                                    <option :value="c.id" v-if="c.id == p_cat" selected>
                                                        {{ c.name }}
                                                    </option>
                                                    <option :value="c.id" v-else>
                                                        {{ c.name }}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                        <div class="select-remove-container">
                                            <div class="select-remove" @click="destroy(index + 1)" v-if="index !== 0">
                                                <i class="icon icon-close-circle-bold"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 col-footer">
                            <div class="form--extend-butons form--extend-butons-1 form--extend-butons-2-extended justify-end mb-2">
                                <div class="form--buttons">
                                    <input @click.prevent="next()" class="button button-left" type="submit" value="Напред"  tabindex="10">
                                </div>
                                <p class="mutted text-right faq--extend extend--faq mr-0">
                                    <span class="vertical-middle">Често задавани въпроси</span>
                                    <a href="/faq/questions" class="toggle mr-0 toggle-bottom-left"
                                       target="_blank" tabindex="-1"><i
                                            class="icon icon-questionmark-circle"></i></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-grid-green"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import FormError from '../../FormError.vue'
    import moment from 'moment'
    import StepOneMedia from '../StepOneMedia.vue'

    import draggable from 'vuedraggable'

    export default {
        props: ['shops','categories','p_category'],
        components: { FormError,draggable, StepOneMedia },
        data() {
            return {
                product: this.$store.state.createLink.product,
                errors: [],
                p_cat: [],
                cat:[],
                n_cat: this.$store.state.createLink.product.category_id,
                bread_mini:[],
                finalCategory:null,
            }
        },
        methods: {
            focusInput(){
                this.$refs.startPage.focus();
            },
            next() {
                this.product.category_id = this.p_cat[this.p_cat.length - 1];
                if(this.product.condition == 1){
                    this.product.condition = null;
                }
                axios.post(`/product/validate-step-one`, this.product)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            this.$store.state.createLink.step++;

                            let catStr = '';
                            for (const catHierarchyLvl in this.p_cat) {
                                if(catHierarchyLvl > 0) {
                                    catStr += ' >> ';
                                }
                                let selectedCat = this.cat[catHierarchyLvl].find((cat) => {
                                    return cat.id == this.p_cat[catHierarchyLvl];
                                });
                                catStr += selectedCat.name;
                            }
                            gtag('event', 'Forms', {
                                'step' : '1',
                                'product_category': catStr,
                                'product_status' : this.product.condition == 2 ? 'new' : 'existing',
                                'product_price' : this.product.price,
                                'product_quantity' : this.product.quantity,
                                'product_weight' : this.product.weight,
                            });
                        }else{
                            this.notifyError(response.data.error);
                        }

                    })
                    .catch((errors) => {
                        this.errors = errors.response.data.errors
                    })

            },
            numberArrowUp() {
                this.product.quantity++;
            },
            numberArrowDown() {
                if (this.product.quantity > 1) {
                    this.product.quantity--;
                }
            },
            getSiblings(event){
                // console.log('get siblings')
                let level = event.target.id.slice(event.target.id.indexOf('-') + 1 , event.target.id.length);
                axios.get(`/categories/${event.target.value}/siblings`)
                .then((response) => {
                    if(response && response.data){
                        // console.log(this.cat)
                        // for(let temp in response.data.categories){
                            this.cat[level-1] = response.data.categories;
                        // }
                        // this.cat[level -1] = response.data.categories;
                        // console.log(this.cat[level-1])
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
            },
            getChildrens(event){
                axios.get(`/api/category/childrens`, {
                    params: {
                        category: this.p_cat,
                        categories: this.cat,
                        level: event.target.id.split('-')[1]
                    }
                })
                .then((response) => {
                    if(response && response.data){
                        this.cat = response.data.categories
                        if (response.data.level) {
                            this.p_cat = this.p_cat.slice(0, response.data.categories.length - 1);
                        } else {
                            this.p_cat = this.p_cat.slice(0, response.data.categories.length);
                        }
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
            },
            getToLevel(event){
                let data = parseInt(event.target.getAttribute('data-id'));
                this.cat = this.cat.filter((item,index) => index < data || index == data)
            },
            arrayHasElements(array,element, value){
                let flag = true;
                for(let temp = 0 ; temp < array.length; temp ++){
                    if(Array.isArray(element)){
                        if(array[temp][0].id == element[0].id){
                            flag = false;
                        }
                        if(element[0].id == value){
                            flag = false;
                        }
                    }
                }
                if(flag){
                    if(!Array.isArray(element)){
                        array.push([element])
                    }else{
                        array.push(element)
                    }
                }
            },
            getCategory(id) {
                axios.get('/api/category/get', {
                    params: {
                        category: id,
                    }
                })
                .then((response) => {
                    this.cat = response.data.categoriesAll
                    this.selectCategory(response.data.categories)
                    console.log(this.cat)
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
            },
            getParents(){
                axios.get(`/api/category/parents`)
                .then((response) => {
                    if(response && response.data){
                        if(response.data.categories.length > 0){
                               this.cat.push(response.data.categories);
                        }
                    }
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors
                })
            },
            selectCategory(categories) {
                categories.forEach((e, index) => {
                    this.p_cat[index] = e[0].id
                })
            },
            destroy(index){
                // axios.get('/api/category/destroy', {
                //     params: {
                //         cat: this.cat,
                //         p_cat: this.p_cat,
                //         index: index,
                //     }
                // })
                // .then((response) => {
                //     this.cat = response.data.cat
                //     this.p_cat = response.data.p_cat
                // })
                // .catch((errors) => {
                //     this.errors = errors.response.data.errors
                // })
                if(this.cat.length > index){
                    for(let i = this.cat.length ; i > 0 ; i --){
                        if(i > index){
                            this.cat.pop();
                        }
                    }
                }else{
                    this.cat.splice(index, 1);

                }
                if(this.p_cat.length > index ){
                    for(let i = this.p_cat.length ; i > -1 ; i --){
                        if(i  > index - 1){
                            this.p_cat.pop();
                        }
                    }
                }else{
                    if(index - 1 !== -1){
                        this.p_cat.splice(index -1, 1);
                    }else{
                        this.p_cat.splice(index,1);
                    }
                }
            }
        },
        mounted() {
            if(this.product.category_id == null){
                this.getParents();
            }else{
                this.getCategory(this.product.category_id);
            }
            this.bread_mini.reverse();
            this.focusInput();
            const $ = require('jquery')
            var self = this;
            $('.datepicker').datepicker({
                yearRange: '1920:2025',
                dateFormat : 'dd/mm/yy',
                minDate: 0,
                onSelect:function(selectedDate, datePicker) {
                    self.product.valid_to = selectedDate;
                }
            });
            $.datepicker.regional['bg']

            console.log('p_cat')
            console.log(this.p_cat)
            console.log('n_cat')
            console.log(this.n_cat)
            console.log('cat')
            console.log(this.cat)
        }
    }

</script>
