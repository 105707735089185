<template>
    <div class="search-confurm-box" :class="freeze ? 'frozen_in_time' : ''"  @click="changeState()">
        <div class="form-checkbox-container">
            <div class="searchable-icon">
                <svg version="1.1"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                     x="0px" y="0px" viewBox="0 0 215.2 164.5" xml:space="preserve">
                <path d="M213.3,150.6l-34.6-43.3c11.9-11.3,19.3-27.3,19.3-45C198,27.9,170.1,0,135.8,0S73.5,27.9,73.5,62.3
                    c0,34.3,27.9,62.3,62.3,62.3c10.5,0,20.4-2.6,29-7.2l35.1,43.9c3,3.7,8.4,4.3,12,1.3C215.7,159.6,216.3,154.3,213.3,150.6z
                     M87.5,62.3c0-26.6,21.7-48.3,48.3-48.3c26.6,0,48.3,21.6,48.3,48.3s-21.6,48.3-48.3,48.3C109.2,110.5,87.5,88.9,87.5,62.3z"/>
                <path d="M56,32.5H9.2c-5.1,0-9.2-4.1-9.2-9.2v0C0,18.1,4.1,14,9.2,14H56c5.1,0,9.2,4.1,9.2,9.2v0
                    C65.3,28.4,61.1,32.5,56,32.5z"/>
                <path d="M94.5,150.3H9.2c-5.1,0-9.2-4.1-9.2-9.2v0c0-5.1,4.1-9.2,9.2-9.2h85.3c5.1,0,9.2,4.1,9.2,9.2v0
                    C103.8,146.1,99.6,150.3,94.5,150.3z"/>
                <path d="M40,95.2H9.2C4.1,95.2,0,91.1,0,86v0c0-5.1,4.1-9.2,9.2-9.2H40c5.1,0,9.2,4.1,9.2,9.2v0
                    C49.3,91.1,45.1,95.2,40,95.2z"/>
                </svg>
            </div>
            <div class="md-checkbox">
                <input type="checkbox" v-model="this.setSearchable">
                <!-- <span class="md-transition"></span> -->
                <em>
                    <label>
                        <span>Продуктът е 
                            <template v-if="this.setSearchable == 1">видим</template>
                            <template v-else>невидим</template>
                             в търсачката</span>
                        <!-- <span>Продуктът да бъде видим в резултатите на търсачката</span> -->
                        <!-- <span>Отбележи тук, за да направиш обявата си видима за всички в търсачката на Pop Up.</span> -->
                        <div class="custom-slider-ball" :class="this.setSearchable ? 'custom-slider-ball' : 'custom-slider-ball-unactive'">
                            <div class="custom-slider-container">
                                <div class="ball-filler"></div>
                                <div class="custom-ball"></div>
                                <!-- <div :class="this.setSearchable ? 'custom-ball custom-ball-active' : 'custom-ball'"></div> -->
                            </div>
                        </div>
                    </label>
                </em>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['product'],
    data() {
        return {
            setSearchable: this.product.is_searchable,
            setProduct : this.product,
            freeze: false,
        }
    },
    methods: {
        changeState() {
            // loading(true)
            this.freeze = true;
            axios.patch(`/product/${this.setProduct.id}/searchable`)
                .then(response => {
                    this.freeze = false;

                     if(this.setSearchable == 0){
                        this.setSearchable = 1;
                    }else{
                        this.setSearchable = 0;
                    }
            
                    this.notifySuccess('Успешно променен статус');
                    this.$emit('success', this.message)
                    // loading(false)
                })
                .catch(errors => {
                    // loading(false)
                })
        }
    }
}
</script>

