import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
    bodyMaxLength: 300
  },
  global: {
    maxOnScreen: 3,
    newOnTop: false,
    // oneAtTime: true,
    // preventDuplicates: true
  }
}

Vue.use(Snotify, options)