<template>
    <div v-if="showModal" class="modal-new-features-container">
        <div class="modal modal-new-features">
            <a href="#" class="close-modal" @click="closeModal()"><i class="icon icon-close-circle"></i></a>
            <h3>{{ data.title }}</h3>
            <!-- <p>Направи обявите си видими за всички в Pop Up с търсачката „Намери продукт“ – в секция „Моите продукти“ кликни върху лупичката срещу всяка обява и готово!</p> -->
            
            <div v-html="data.description">
                
            </div>
            <div class="form" style="padding-bottom:0;">
                <div class="grid">
                    <div class="col-12">
                        <div class="form-group" style="margin-top:0;">
                            <a href="#" class="button small" @click="closeModal()">
                                Разбрах
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-overflowing"></div>
    </div>
</template>

<script>
    export default {
        props: ['feature'],
        data() {
            return {
                showModal: false,
                data : this.feature,
            }
        },
        methods: {
           closeModal(){
            this.showModal = false;
           },
        },
        created() {
            let cookieName = this.feature.cookie;
            /*Checking for cookie new-features*/
            if(document.cookie.includes(`${cookieName}`) == false ){
                    this.showModal = true;

                    // cookieName = `${cookieName}`;

                    /*setting cookie new-features*/
                    var now = new Date();
                    var time = now.getTime();
                    /*30 days*/
                    var expireTime = time + 30 * 24 * 60 * 60 * 1000;
                    now.setTime(expireTime);
                    document.cookie = `${cookieName}=true;expires=${now.toUTCString()}`;
            }
        }
    }
</script>
