<template>
    <div class="wrapper">

        <div class="heading heading-overlap-custom-create">
            <div class="container">
                <div class="grid">
                    <div class="col-6 col-md-12">
                        <div class="heading-back">
                            <a href="/dashboard"><i class="icon icon-arrow-left"></i><span>Моят Pop Up офис</span></a>
                        </div>
                        <header class="heading-content">
                            <h1 v-if="this.$store.state.createLink.step == 1" class="heading-title">Редактирай продукт</h1>
                            <p v-if="this.$store.state.createLink.step == 1">
                                Тук можеш да подобриш описанието или снимките, да<br>
                                актуализираш цена и детайли. Промените автоматично ще<br>
                                се отразят навсякъде, където си споделил продукта.

                            </p>
                            <h1 v-if="this.$store.state.createLink.step == 2" class="heading-title">Редактирай адрес</h1>
                            <p v-if="this.$store.state.createLink.step == 2">
                                Тук можеш да променяш адреса,<br>
                                от който изпращаш.<br>
                            </p>
                        </header>
                    </div>
                    <div class="col-6 col-md-12">
                        <div class="wizard">
                            <i class="progress" role="progressbar" style="width: 27%"></i>
                            <ul class="nums flex text-center">
                                <li class="active" v-if="this.$store.state.createLink.step == 1">
                                    <a href="#">
                                        <span>
                                            <i>1</i>
                                            <div class="tooltip">0%</div>
                                        </span>
                                    </a>
                                </li>
                                <li class="active" v-if="this.$store.state.createLink.step == 2">
                                    <a href="#">
                                        <span>
                                            <i>1</i>
                                        </span>
                                    </a>
                                </li>
                                
                                <li v-if="this.$store.state.createLink.step == 1">
                                    <a href="#">
                                        <span><i>2</i></span>
                                    </a>
                                </li>
                                <li class="active" v-if="this.$store.state.createLink.step == 2">
                                    <a href="#">
                                        <span>
                                            <i>2</i>
                                            <div class="tooltip">50%</div>
                                        </span>
                                    </a>
                                </li>

                                <li>
                                    <a href="#">
                                        <span><i>3</i></span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="text flex text-center">
                                <li class="active">Добави<br>продукт</li>
                                <li>Избери<br>адрес</li>
                                <li>Генерирай<br>линк</li>
                            </ul>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>

        <form class="form" method="post" enctype="multipart/form-data" autocomplete="off">

           <step-one v-if="this.$store.state.createLink.step === 1" :shops="shops"></step-one>
           <step-two v-if="this.$store.state.createLink.step === 2" :addresses="addresses" :cities="cities"></step-two>

        </form>

    </div>
</template>

<script>
import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import moment from 'moment'

    export default {
        props: ['shops', 'addresses', 'cities', 'product'],
        components: {
            StepOne,
            StepTwo,
        },
        data() {
            return {
            }
        },
        methods: {
            prev() {
                this.$store.state.createLink.step--
            },
            next() {
                this.$store.state.createLink.step++
            },
        },
        created() {
            this.$store.state.createLink.step = 1
            this.$store.state.createLink.product = this.product
            this.$store.state.createLink.product.media = []
            this.$store.state.createLink.product.valid_to = moment(this.product.valid_to).format('DD/MM/Y')
            this.$store.state.createLink.product.update = true;
            this.$store.state.createLink.product.media = []
            this.$store.state.createLink.product.productAddress = this.product.product_address

            Object.keys(this.product.product_media).forEach((e) => {
                const name = this.product.product_media[e]['name'];
                let ext = '';
                if (this.product.product_media[e].video) {
                    ext = this.product.product_media[e].video.name.split('.').pop();
                }
                const path = '/storage/media/' + moment(this.product.created_at).format('Y') + '/' + moment(this.product.created_at).format('MM') + '/' + this.product.id + '/' + name
                if (ext) {
                    const nameWithoutExt = name.split('.').shift()
                    let pathArr = path.split('/')
                    let pureUrl = pathArr.splice(-1,1)
                    this.$store.state.createLink.product.media.push({
                        name: name, 
                        url: path,
                        ext: ext,
                        video: pathArr.join('/') + '/' + nameWithoutExt + '.' + ext,
                    })
                } else {
                    this.$store.state.createLink.product.media.push({
                        name: name, 
                        url: path,
                        ext: ext,
                    })
                }


            })
        },
    }
</script>

