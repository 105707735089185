<template>
  <ul class="filters">
    <li class="has-children">
        <div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px"
                viewBox="0 0 417.3 408.3" xml:space="preserve">
                <path
                    d="M235.9,337.9c0,7.9-0.5,15.3,0.1,22.6c0.7,7.5-2.6,12.9-7.4,17.8c-7.1,7.4-16.1,12-25.5,15.7c-19.8,7.9-40.5,11.6-61.6,13.2
                            c-31,2.4-61.7,0.6-91.8-7.5c-13.3-3.6-26.4-8.1-37.2-16.9c-4.5-3.6-8-8.6-11.7-13.2c-0.9-1.2-0.8-3.4-0.8-5.1
                            c0-58.7-0.2-117.4,0.2-176.1c0-5.3,0.4-10.5,0-15.9c-0.6-8.2,4.4-14.1,10.4-19c12.7-10.4,27.8-15.7,43.4-19.3
                            c40.7-9.5,81.7-9.8,122.7-1.2c1.2,0.3,2.5,0.4,4.6,0.8c0-2,0-3.8,0-5.6c0-21.8-0.1-43.5,0.1-65.3c0-2.4,1.1-4.8,1.5-7.3
                            c0.2-1,0.2-2.1-0.1-3c-3.1-8.5-0.7-15.8,5.2-22c10.1-10.8,23.3-16.1,36.9-20.3c27.8-8.6,56.3-11,85.3-10c24.7,0.9,49.1,4,72.2,13.1
                            c8.6,3.4,17,8.2,24.5,13.7c8.5,6.2,13,14.9,8.7,26c-0.2,0.6-0.2,1.5-0.1,2.2c0.5,2.7,1.6,5.5,1.6,8.2c0.1,77.6-0.1,155.2,0.3,232.8
                            c0,9.4-3.4,16.4-10.3,21.3c-7.9,5.5-16.4,10.6-25.4,14.1c-28.1,10.8-57.7,13.4-87.6,13c-18.3-0.2-36.4-2-54.2-6.1
                            C238.7,338.2,237.6,338.2,235.9,337.9z M18.4,198.3c0,10.9,0.3,22.4-0.1,33.9c-0.3,6.7,2.4,11.3,8,14c7,3.4,14.2,6.8,21.7,8.6
                            c44.5,10.8,89.3,10.7,134,1.5c10-2.1,19.5-6.8,28.9-11c4.7-2.1,7-6.5,6.7-12.2c-0.3-9.3-0.1-18.6-0.1-27.8c0-2.6,0-5.2,0-7
                            C150.8,224.7,85,224.7,18.4,198.3z M217.4,261.8c-66.6,26.3-132.6,26.3-199-0.1c0,11.2,0.2,23.2-0.1,35.1c-0.1,5.2,1.7,9.4,6.1,11.6
                            c7.4,3.7,14.8,7.8,22.7,9.7c45.1,11,90.5,11,135.8,1.4c9.5-2,18.7-6.3,27.5-10.6c3.1-1.5,6.5-6.1,6.7-9.5
                            C217.9,286.8,217.4,274,217.4,261.8z M199.8,70.4c0,12.1,0.3,23.8-0.1,35.4c-0.2,6.1,2.2,10.4,7.2,13c6.3,3.3,12.9,6.6,19.7,8.4
                            c45.2,11.6,90.7,11.6,136.3,2.3c9.7-2,19-6.2,28.2-10.2c5.6-2.4,8.4-7.2,8-14c-0.5-8.3-0.1-16.6-0.1-24.9c0-3.2,0-6.5,0-10
                            C333.2,97.9,267.5,97.9,199.8,70.4z M217.4,325.3c-66.4,26.3-132.5,26.4-199-0.1c0,9.9,0.7,20.5-0.2,30.9
                            c-0.9,10.4,4.1,15.8,12.5,19.6c2.5,1.1,5,2.2,7.5,3.1c16.8,6,34.3,8.5,52,9.8c30.6,2.2,61.1,1.8,91-5.2c10.1-2.4,19.8-6.8,29.3-11.1
                            c2.9-1.3,6.3-5.3,6.4-8.2C217.7,351,217.4,337.8,217.4,325.3z M300.8,72.7c6.8-0.3,15.5-0.5,24.1-1.2c18.8-1.5,37.5-3.6,55.2-10.6
                            c5.5-2.2,10.8-5.4,15.5-9.1c4.4-3.4,4.4-8.2,0.8-12.3c-1.9-2.2-4.4-4.5-7-5.6c-7.4-3.2-14.9-6.7-22.8-8.5
                            c-42.5-9.8-85.2-9.6-127.9-1.7c-11.6,2.1-23.1,5.2-33,12.2c-8.2,5.8-8.5,12.9-0.1,18.4c5.5,3.6,11.8,6.4,18.1,8.4
                            C248.3,70.7,273.6,72.1,300.8,72.7z M111.9,199.4c28.3,0,50.3-1.7,72-6.8c10.1-2.4,20.2-5.1,28.5-11.8c6.9-5.5,6.9-11.7-0.2-17
                            c-3.8-2.8-8.1-5.1-12.5-6.8c-14.7-5.8-30.1-8.3-45.7-9.8c-33.1-3.3-66-2.7-98.7,4.1c-11.1,2.3-22.1,5.2-31.4,12.1
                            c-7.5,5.6-7.8,12.3-0.1,17.5c5.4,3.7,11.7,6.7,17.9,8.7C66.5,197.6,92.1,199.2,111.9,199.4z M398.8,134.9
                            c-66.7,26.4-132.7,26.3-198.8,0.1c-1.2,3.2,0.7,4.7,4,6.5c8.2,4.4,16.5,9.1,23.9,14.7c5.6,4.2,9.5,10.9,7.5,18.1
                            c-1.8,6.7,1.5,12.5,0.5,18.7c0.9,0.3,1.3,0.5,1.7,0.6c42.1,8.1,84.2,8.3,126.1-0.8c9.4-2,18.5-6,27.2-10.1c3.4-1.6,7.3-6.1,7.5-9.5
                            C399.3,160.2,398.8,147.2,398.8,134.9z M398.8,261.1c-52.9,24.3-107.4,23.7-162.8,13.7c0,15.1,0,30.1,0,45.3c1.7,0.4,3.2,1,4.8,1.2
                            c10.8,1.4,21.5,3.2,32.3,4c29.9,2.3,59.8,1.6,89.1-5.2c9.8-2.3,19.3-6.4,28.6-10.4c4.2-1.8,7.9-5.2,7.9-10.6
                            C398.9,286.5,398.8,273.9,398.8,261.1z M236,257c8.2,1.3,16.4,2.7,24.6,3.7c22.8,2.7,45.6,2.8,68.5,0.9c18-1.5,35.9-4.1,52.8-10.7
                            c14.8-5.8,16.9-9.1,16.9-24.8c0-9.5,0-19,0-28.6c-53,24.2-107.4,23.7-162.8,13.7C236,226.4,236,241.4,236,257z" />
            </svg>
            Цена
        </div>
        <ul class="is-children">
            <li>
                <div @click="sort('price','asc')">
                    От най-ниска към най-висока
                </div>
            </li>
            <li>
                <div @click="sort('price','desc')">
                    От най-висока към най-ниска
                </div>
            </li>
        </ul>
    </li>
    <li class="has-children">
        <div>
            <i class="icon icon-history"></i>Състояние
        </div>
        <ul class="is-children">
            <li>
                <div @click="sort('condition','all')"><i
                        class="palette" style="background-color: #914098;"></i>Всички</div>
            </li>
            <li>
                <div @click="sort('condition','new')"><i
                        class="palette" style="background-color: #2FC89D;"></i>Нови</div>
            </li>
            <li>
                <div @click="sort('condition','second-hand')"><i
                        class="palette" style="background-color: #ffc000;"></i>Втора употреба</div>
            </li>
        </ul>
    </li>
    <li class="has-children">
        <div>
            <i class="icon icon-sorting"></i>Дата
        </div>
        <ul class="is-children children-align-left">
            <li>
                <div @click="sort('date','desc')">
                    Най-нови
                </div>
            </li>
            <li>
                <div @click="sort('date','asc')">
                    Най-стари
                </div>
            </li>
        </ul>
    </li>
</ul>
</template>

<script>
export default {
  data() {
    return {
      currentLocation: document.location,
      searchWord: '',
    }
  },
  mounted() {
    
  },
  methods: {
    sort(data,value){
      // let url = new URL(this.currentLocation)
      let url = new URL(document.location)
      this.searchWord = url.searchParams.get('search');
      url.search = '';
      if(this.searchWord){
        url.searchParams.set('search',this.searchWord);
      }
      url.searchParams.set(data,value);
      url.searchParams.set('page','1');

      // this.currentLocation = url;
      
      location.href = url.href
    }
  },
}
</script>
<style type="text/css" scoped>
  .section-dashboard--search .filters > li > div{
    padding: 12px 22px;
  }
  body .filters > li > div{
    display: flex;
    align-items: center;
    cursor:pointer;
  }
  .filters > li > div{
    display: block;
    padding: 30px 22px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    vertical-align: middle;
    transition: color 0.5s;
    cursor:pointer;
  }
  .filters > li.has-children > ul > li > div{
      font-weight: 500;
      cursor:pointer;
  }
  .has-children > ul > li > div{
    display: block;
    padding: 8px 0;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    cursor:pointer;
  }
  .has-children > ul > li > div .palette {
    display: inline-block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    vertical-align: middle;
    background-color: red;
    border-radius: 50%;
    margin-right: 17px;
    float: left;
    margin-bottom: 14px;
    margin-top: 1px;
    cursor:pointer;
}
.filters > li > div > i {
    margin-right: 15px;
    font-size: 18px;
    line-height: 20px;
    vertical-align: middle;
}
</style>