'use strict'

import debug from '../../utils/debug'
import $ from 'jquery'
import gsap from 'gsap'
import imagesLoaded from 'imagesloaded'
import flickity from 'flickity'
import { ScrollToPlugin, TweenMax, Power4 } from "gsap/all"
import Artefact from './artefact'

window.$ = window.jQuery

gsap.registerPlugin( ScrollToPlugin, Power4 )

export default class Animations {

	constructor() {

		require('waypoints/lib/noframework.waypoints.min')

		this.init()

	}

	init() {

		// debug.client('Animations init')

		this.bind()
		this.heading()
        this.phone()
		this.phone_slider()
		this.flyout()
		this.hash_scroll()

		new imagesLoaded( $(window), () => {
			this.artefacts()
		})

	}

	hash_scroll() {

		$('.main-nav a').on('click', e => {

			let $link = $( e.currentTarget )
			let url = $link.attr('href')
			let hash = url.split('#')[1];

			if( hash ) {
				this.scroll_page( hash, e )
			}

		})

		if( window.location.hash ) {
			this.scroll_page( window.location.hash.substring(1) )
		}

	}

	scroll_page( hash, e ) {

		let $section = $(`[data-section="${hash}"]`)

		if( $section.length ) {

			if(e) { e.preventDefault() }

			gsap.to( $(window), .6, { scrollTo: { y: $section, offsetY: 0, autoKill: true } });

		}
	}

	flyout() {
		$('.flyout').each(( index, element ) => {
			new Waypoint({
				element: element,
				handler: function( element ) {
					TweenMax.fromTo( this.element, 1.2, { scale: 0 }, { scale: 1, ease: "elastic.out(1, 0.3)" } )
					this.destroy()
				},
				offset: '80%'
			});
		})
	}

	heading() {

		new imagesLoaded( $(document), () => {
			$('.heading').each(( index, element ) => {
				new Waypoint({
					element: element,
					handler: function( element ) {
						TweenMax.staggerFromTo( $( '> *', this.element ), .4, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: Power4.easeOut }, .08 )
						this.destroy()
					},
					offset: '86%'
				});
			})
		})

	}

	phone() {

		let $phone = $('.phone')

		if( $phone.length ) {

			new imagesLoaded( $(window), () => {

				TweenMax.fromTo( '.welcome .scroll-bottom > span', 1.4, { opacity: 0 }, { opacity: 1, ease: Power4.easeOut, delay: .5 } )
				TweenMax.fromTo( '.welcome .scroll-bottom > span', 1.4, { scale: .7 }, { scale: 1, ease: "elastic.out(1, 0.3)", delay: .5 } )
				// TweenMax.to( '.atf--2', 1.4, { delay: .5, opacity: 1 })
				// TweenMax.to( '.atf--1', 1.4, { delay: .7, opacity: 1 })

				gsap.fromTo( $phone, 1.4, { x: 100 }, { x: 0, ease: Power4.easeOut, onComplete: () => {
					this.phone_text()
				}});

			});

		}

	}

	phone_slider() {

		let $phone_slider = $('.phone-slider')
		let flkty

		if( $phone_slider.length ) {

			new imagesLoaded( $phone_slider, () => {

				this.$text = $('#slider-text')

				flkty = new flickity( '.phone-slider', {
		            contain: true,
		            wrapAround: true,
		            prevNextButtons: false,
		            pageDots: false,
		            autoPlay: 2000,
					pauseAutoPlayOnHover: false,
					on: {
						ready: () => {
							$('.phone-slider, .phone').css('opacity', 1)
							// this.phone_text()
						},
						change: ( index ) => {
							this.phone_text( index )
						}
					}
		        })

	        })

			$(window).on('resize', () => {
				flkty.resize(); $phone_slider.get(0).querySelector('.flickity-viewport').style.height = null
			})
		}

    }

	phone_text( index ) {

		index = index || 0
		let text = $('.phone-slider .slide').eq( index ).data('text')

		gsap.to( this.$text, .2, { opacity: 0, scale: .9, onComplete: () => {
			this.$text.html( text )
			gsap.to( this.$text, .2, { opacity: 1, scale: 1 });
		}});

	}

	bind() {

		$('.scroll-bottom').on('click', (e) => this.scroll_to(e))

	}

	scroll_to(e) {

		let $for = $( e.currentTarget );
		let scroll_for = $for.attr('for')
		let center = $for.data('for-center')

		gsap.to( $(window), .6, { scrollTo: { y: $( scroll_for ), offsetY: 0, autoKill: true } });

	}

	artefacts() {

		new imagesLoaded( $(window), () => {
			$('.artefact').each(( index, element ) => {
	            new Artefact( $( element ) )
	        });
		});

	}

}
