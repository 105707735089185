'use strict'

import debug from '../utils/debug'
import $ from 'jquery'

window.$ = window.jQuery

export default class Header {

	constructor() {

        this.$w = $(window)
        this.$ = $('body')
		this.$header = $('#header')

		if( this.$header.hasClass('has-sticky') ) {
			this.init()
		}

	}

	init() {

		// debug.client('Header init')
		this.scroll()
        this.sticky()

	}

	sticky() {

        this.$w.scroll(() => {
            this.scroll()
        });

	}

    scroll() {

        this.scroll_top = this.$w.scrollTop()
        this.$[ this.scroll_top ? 'addClass' : 'removeClass' ]('is-sticky');

    }

}
