<template>
      <div class="s-t-item s-t-item-pad" ref="hideMeOnSuccess">
          <div class="s-t-item-header">
              
              <div class="s-i-info">
                  <div class="s-t-image">
                      <img :src="getImage(product)" alt="">
                  </div>
                  <div class="s-t-i-content">
                      <div class="s-t-i-c-header">
                          <div class="s-t-i-title">{{ product.title }}</div>
                      </div>
                      <div class="s-t-i-footer">
                          <div class="s-t-i-category" v-if="product.category">
                              <span class="s-t-i-c-label">Категория:</span>
                              <span class="s-t-i-c-data">{{ product.category.name }}</span>
                          </div>
                          <div class="s-t-i-counter">{{ product.quantity }} бр.</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="s-t-item-data s-t-i-d-category">
              <div class="i-d-item-filler"></div>
              <div class="i-d-item i-d-i-info i-d-i-info-category">
                <div class="toggle-field toggle-field--import" v-for="(item,index) in current_categories">
                    <div class="toggle-field-left select-wrapper-button">
                        <div class="select-wrapper">
                            <select :id="`category-${index+1}`" :data-id='index' :name="`category-${index+1}`" 
                            v-model="p_cat[index]"
                            :tabindex="7+index" @change="getChildrens($event)">
                                <template v-for="c in item">
                                    <option :value="c.id" v-if="c.id == p_cat" selected>
                                        {{ c.name }}
                                    </option>
                                    <option :value="c.id" v-else>
                                        {{ c.name }}
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="select-remove-container">
                            <div class="select-remove" @click="destroy(index + 1)" v-if="index !== 0">
                                <i class="icon icon-close-circle-bold"></i>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="i-d-item-big--button-container">
                <div class="i-d-item i-d-item--icon i-d-item-big i-d-item-big--button" @click="setCategory">
                    <div class="i-d-item-label">
                        Задай категория
                    </div>
                </div>
              </div>
          </div>
      </div>
</template>

<script>
import moment from "moment";

export default {
  props: ['categories', 'shops','product'],
  data() {
    return {
      url: '/dashboard/import/setCategory',
      current_product:this.product,
      current_categories:[this.categories],
      final_category: false,
      p_cat: [],
    }
  },
  beforeMount() {
    // console.log(this.current_product)
    // console.log(this.current_categories)
  },
  methods: {
    getChildrens(event){
        axios.get(`/api/category/childrens`, {
            params: {
                category: this.p_cat,
                categories: this.current_categories,
                level: event.target.id.split('-')[1]
            }
        })
        .then((response) => {
            if(response && response.data){
                this.current_categories = response.data.categories
                if (response.data.level) {
                    this.p_cat = this.p_cat.slice(0, response.data.categories.length - 1);
                } else {
                    this.p_cat = this.p_cat.slice(0, response.data.categories.length);
                    this.final_category = this.p_cat.at(-1);
                }
            }
        })
        .catch((errors) => {
            this.errors = errors.response.data.errors
        })
    },
    checkCategories(product,event){
      product.categories.filter((item)=>{
        if(item.id == event.target.value){
          return true;
        }
      });
      return false;
    },
    getImage(product){
      let image = product.product_media[0];
      if (image) {
          var extension = image.name.split(".").pop();
          if (extension == 'mp4' || extension == 'mov') return '/images/default/video-thumb.jpg';

          return '/storage/media/' + moment(product.created_at).format('Y')
          + '/' + moment(product.created_at).format('MM')
          + '/' + product.id + '/' + image.name
      }else{
        return 'images/default/default.png';
      }
    },
    setCategory(){
      if(this.final_category){
        this.$snotify.async(`Задаване на категория за продукт ${this.current_product.title}`, 'Success async', () => new Promise((resolve, reject) => {
            let data = new FormData();
            data.append("product_id", this.current_product.id);
            data.append("category_id", this.final_category);
            data.append('is_sender_paying',this.is_sender_paying);
                axios.post(this.url, data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((response) => {
                    if(response.data){
                        if(response.data.type == 'success'){

                          this.$refs.hideMeOnSuccess.classList.add('hideMe');
                          setTimeout(()=>{
                            this.$refs.hideMeOnSuccess.classList.add('hiddenMe');
                          },300);

                          resolve({
                                title: 'Success',
                                body: response.data.message,
                                config: {
                                    timeout: 3000,
                                    closeOnClick: true
                                }
                          });

                          let done = document.querySelectorAll('.s-t-item:not(.hideMe)');

                          if(done.length == 0){
                            location.href = location.href;
                          }

                        }else if(response.data.type == 'error'){
                          // this.notifyError(response.data.message);
                          reject({
                                title: 'Error',
                                body: response.data.message,
                                config: {
                                    timeout: 10000,
                                    closeOnClick: true
                                }
                          });
                        }
                    }
                })
                .catch((errors) => {
                  // console.log('what eeerors')
                    // this.errors = errors.response.data.errors
                    // reject({
                    //         title: 'Error',
                    //         body: 'Възникна грешка. Моля опитайте отново.',
                    //         config: {
                    //             timeout: 10000,
                    //             closeOnClick: true
                    //         }
                    //   });
                })
            }));
      }else{
          this.notifyError('Моля изберете категория от последното ниво.');
      }
    },
    destroy(index){
          if(this.current_categories.length > index){
              for(let i = this.current_categories.length ; i > 0 ; i --){
                  if(i > index){
                      this.current_categories.pop();
                  }
              }
          }else{
              this.current_categories.splice(index, 1);
              
          }
          if(this.p_cat.length > index ){
              for(let i = this.p_cat.length ; i > -1 ; i --){
                  if(i  > index - 1){
                      this.p_cat.pop();
                  }
              }
          }else{
              if(index - 1 !== -1){
                  this.p_cat.splice(index -1, 1);
              }else{
                  this.p_cat.splice(index,1);
              }
          }
      }
  },
}
</script>

<style scoped>
.s-t-item-pad{
  padding:0 1rem;
}
.toggle-field--import select{
  width:100%;
  border:.2rem solid #ade8e2;
  margin-bottom: .3rem 
}
.toggle-field--import select{
  width:100%;
  padding:.3rem .6rem;
}
.toggle-field--import .select-wrapper-button{
  margin-bottom: 0;
}
.innner-item-container{
  display: flex;
  flex-direction: row;
}
  .i-d-i-info-category{
    grid-column: 1 / span 6;
    grid-row: span 2;
  }
  .s-t-i-d-category{
    margin-left: 0;
    width:100%;
  }
  .i-d-item-big--button-container{
    grid-row: 1 / span 2;
    grid-column: span 3;
    display: flex;
    width:100%;
  }
  .i-d-item-big--button-container .i-d-item-big{
    background-color:var(--clr-blue);
    color:#fff;
    width: 18rem;
    font-size: 1.4rem;
    padding: .75rem 1.5rem;
    margin: auto;
    text-align: center;
    border-radius: 5rem;
  }
  .hideMe{
    animation: slide_left .3s linear;
  }
  .hiddenMe{
    display: none;
  }
  .i-d-i-info-category{
    display: flex;
    flex-direction: column;
  }
  .i-d-i-info-category .toggle-field--import:first-child{
    margin-top: auto;
  }
  .i-d-i-info-category .toggle-field--import:last-child{
    margin-bottom: auto;
  }
  @keyframes slide_left{
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(100%);
    }
  }
</style>