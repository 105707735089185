<template>
    <section class="section-dashboard">
        <div class="container">

            <div class="grid grid-break-xl bg-white extend--office-side">
                <div class="col-12">

                        <div class="tab-sections">
                            <div class="tab-section" :class="{'active': !locatorTab}" data-id="tab-1">
                                <div class="grid mt-4">
                                    <template v-if="addresses.length < 1">
                                         <div class="col-4 col-md-12 mb-2 ">

                                        </div>
                                        <div class="col-4 col-md-12 mb-2 " :class="{'ab-no-address': address}" >
                                            <div class="address address-add flex flex-column">
                                                <div class="mt-auto">
                                                    <a href="#" class="address-add-icon open-modal" @click="addModalClick()"
                                                        data-modal-for="add-address"><i class="icon icon-plus"></i></a>
                                                </div>
                                                <div>
                                                    <p>Добави нов адрес,</p>
                                                    <p>от който да изпращаш</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else v-for="(address, index) in addresses">
                                    <div v-if="index == 0" class="col-4 col-md-12 mb-2 " :class="{'ab-no-address': address}" >
                                        <div class="address address-add flex flex-column">
                                            <div class="mt-auto">
                                                <a href="#" class="address-add-icon open-modal" @click="addModalClick()"
                                                    data-modal-for="add-address"><i class="icon icon-plus"></i></a>
                                            </div>
                                            <div>
                                                <p>Добави нов адрес,</p>
                                                <p>от който да изпращаш</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-12 mb-2">
                                        <!-- <a href="#" class="address" @click="choiceAddress(address)"
                                        :class="address.is_default == 1 ? 'active' : ''"> -->
                                        <a href="#" class="address" @click="choiceAddress(address)"
                                        :class="address.is_default == 1 || checkForDefault(address.id) ? 'active' : ''">
                                            <h3>{{ address.title }}</h3>
                                            <table>
                                                <tr>
                                                    <td>Населено място:</td>
                                                    <td>[{{ address.post_code}}] {{ address.city }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Адрес:</td>
                                                    <td>{{ address.full_address }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Имейл:</td>
                                                    <td>{{ address.email }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Тел:</td>
                                                    <td>{{ address.tel }}</td>
                                                </tr>

                                            </table>
                                            <span class="address-icon"><i class="icon icon-check"></i></span>
                                            <span style="margin-right:3rem !important;" class="address-edit open-modal mr-4" @click="editModalClick(address)"
                                                data-modal-for="add-address" title='Редактирай'>
                                                <!-- <i class="icon icon-edit"></i> -->
                                                <svg version="1.1"
                                                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                                                     x="0px" y="0px" width="199.8px" height="192.9px" viewBox="0 0 199.8 192.9" class="custom-edit-svg"
                                                     xml:space="preserve">
                                                <path d="M99.7,191.4c-39.7-2-70.2-19-88.9-54.9c-4.9-9.3-7.5-19.4-8.7-29.8c-4.4-38.4,15.8-73.9,47.9-92
                                                    C61.6,8,74,3.9,87.2,2.3C129.4-2.9,170.3,18.1,189,56c4.6,9.4,7.5,19.4,8.7,29.9c5.5,47.9-26.7,89.8-71.1,101.7
                                                    c-3.7,1-7.5,1.9-11.4,2.4C110.2,190.6,105,190.9,99.7,191.4z M98.9,181.1c4.2-0.3,7.4-0.5,10.6-0.8c13.2-1.2,25.6-5.1,36.8-12.2
                                                    c30.6-19.7,44.9-47.6,40.4-84.1c-2.1-17.2-9.8-32-21.8-44.5C146.1,20,123.1,11,96.1,11.9c-9.4,0.3-18.6,2.1-27.4,5.3
                                                    C35.4,29.3,10.4,61.7,12.3,99.9c0.5,11.3,2.9,22,8.1,32C37,164.2,64.3,179.4,98.9,181.1z"/>
                                                <path d="M128,47.1c4.8-0.1,8.6,1.6,11.9,4.8c2.1,2.1,4.3,4,6.4,6.1c6.4,6.4,6.5,15.9,0,22.2
                                                    c-19.9,19.2-39.8,38.4-59.8,57.6c-0.9,0.8-2.1,1.5-3.3,1.8c-10,2.8-19.9,5.5-29.9,8.2c-4.9,1.3-8.3-2.1-6.8-6.9
                                                    c2.8-9.5,5.6-19,8.5-28.4c0.4-1.3,1.3-2.7,2.3-3.7c19.7-19.1,39.5-38.2,59.3-57.2C119.7,48.6,123.6,47,128,47.1z M59.3,135.3
                                                    c0.7-0.1,1.2,0,1.6-0.1c5.9-1.6,11.8-3.2,17.8-4.9c0.7-0.2,1.4-0.7,2-1.2c14.7-14.2,29.4-28.3,44.2-42.5c1-1,1-1.6,0-2.6
                                                    c-4-3.8-8-7.6-11.9-11.4c-1.2-1.1-1.9-1.2-3.1,0c-14.6,14.1-29.2,28.2-43.8,42.3c-0.7,0.7-1.2,1.6-1.5,2.5
                                                    c-1.7,5.4-3.2,10.7-4.8,16.1C59.6,134,59.5,134.5,59.3,135.3z M118.9,63.8c5,4.8,9.8,9.5,14.7,14.2c1.3-1.2,2.3-2.1,3.3-3.1
                                                    c0.7-0.7,1.5-1.4,2.1-2.1c2-2.3,1.9-5.2-0.2-7.4c-2.2-2.3-4.6-4.5-7-6.6c-2-1.8-5-2-7-0.4C122.8,59.9,121,61.8,118.9,63.8z"/>
                                                </svg>
                                            </span>
                                            <span class="address-delete" @click="showDeleteModal = true;currentAddress = address;"
                                                data-modal-for="delete-address" title='Изтрий'><i class="icon icon-delete"></i></span>
                                        </a>
                                    </div>
                                    </template>

                                    <div v-if="addresses.length < 2" class="col-4 col-md-12 mb-2 " :class="{'ab-no-address': address}" >
                                        <!-- <div class="address address-add flex flex-column">
                                            <div class="mt-auto">
                                                <a href="#" class="address-add-icon open-modal" @click="addModalClick()"
                                                    data-modal-for="add-address"><i class="icon icon-plus"></i></a>
                                            </div>
                                            <div>
                                                <p>Добави нов адрес,</p>
                                                <p>от който да изпращаш</p>
                                            </div>
                                        </div> -->
                                    </div>

                                </div>
                            </div>

                            <div class="tab-section" :class="{'active': locatorTab}" data-id="tab-2">
                                <div class="grid tab-iframe">
                                    <div class="col-12 col-md-12">
                                        <!-- <locator v-show="locatorTab"></locator> -->
                                    </div>
                                </div>
                            </div>

                        </div>

                    <div class="flex" style="justify-content: flex-end;">
                        <div class="service--button-holder">
                            <button type="submit" @click.prevent="submit()" class="button">Запиши промените</button>
                        </div>
                    </div>

                <p class="mutted text-right mt-1 extend--faq">
                    <span class="vertical-middle">Често задавани въпроси</span>
                    <a href="/faq/questions" class="mr-0"><i
                            class="icon icon-questionmark-circle"></i></a>
                </p>
                </div>
            </div>
        </div>

        <modal v-if="showDeleteModal" @close="showDeleteModal=false">
            <h3>Изтриване</h3>
            <h4>Сигурен ли си, че искаш да изтриеш адреса?</h4>
            <p>Изтриването на адреса е перманентно и не може да бъде върнато.</p>
            <template v-slot:link>
                <a href="#" @click="destroy()" class="button small mint">Изтрий</a>
            </template>
        </modal>

        <address-modal-edit v-if="editModal" @close="editModal=false" @success="updateAddresses" :cities="cities" :address="address"></address-modal-edit>
        <address-modal-add v-if="addModal" @close="addModal=false" @success="addAddresses" :cities="cities"></address-modal-add>

    </section>
</template>

<script>
    import FormError from '../FormError.vue'
    import AddressModalEdit from '../link/AddressModalEdit.vue'
    import AddressModalAdd from '../link/AddressModalAdd.vue'
    import Modal from '../modal/Modal.vue';
    import $ from 'jquery';

    export default {
        props: ['addresses', 'cities'],
        components: {
            FormError,
            AddressModalEdit,
            AddressModalAdd,
            Modal,
        },
        data() {
            return {
                product: this.$store.state.createLink.product,
                locatorTab: false,
                errors: [],
                editModal: false,
                addModal: false,
                address: {},
                offices: [],
                default: '',
                showDeleteModal: '',
                currentAddress: '',
            }
        },
        created() {
            const defaultAddress = this.addresses.find(e => {
                return e.is_default == 1
            })
            if(defaultAddress == undefined && this.addresses[0] != undefined){
                this.default = this.addresses[0].id
            }else if(this.addresses[0] != undefined){
                this.default = defaultAddress.id
            }
        },
        methods: {
            modalOverview(){
                // console.log('called');
                setTimeout(function(){
                    $('.modal-overlay').click(function(e){
                        // console.log('clicked');
                        $('.modal').find('.close-modal').trigger('click');
                        // console.log($('.modal').find('.close-modal'));
                    });
                }, 500);
            },
            checkForDefault(id){
                if(this.default == id){
                    return true;
                }else{
                    return false;
                }
            },
            addModalClick(){
                this.addModal = true
                this.modalOverview()
            },
            editModalClick(address) {
                this.address = address
                this.editModal = true
                this.modalOverview()
            },
            updateAddresses(address) {
                var index = this.addresses.findIndex(item => item.id === address.id)
                if (index != -1) {
                    this.addresses.splice(index, 1, address)
                } else {
                    this.addresses.push(address)
                }
                this.modalOverview();
            },
            addAddresses(address) {
                this.addresses.push(address)
            },
            submit() {
                // axios.patch(`/address/update-default`, {id: this.default})
                //     .then((response) => {
                //         this.errors = []
                //         if (response.data.success) {
                //             window.location = response.data.redirect
                //         }
                //     })
                //     .catch((errors) => {
                //         this.$snotify.error('Нямате избран адрес по подразбиране');
                //         this.errors = errors.response.data.errors
                //     })
                this.updateDefaultAdress(this.default)
            },
            choiceAddress(address) {
                this.default = address.id
            },
            prev() {
                this.$store.state.createLink.step--;
            },
            tabOne() {
                this.locatorTab = false
                this.product.productAddress.full_address = null
                this.product.productAddress.is_office = 0
            },
            tabTwo() {
                this.locatorTab = true
                this.product.productAddress.full_address = null
                this.product.productAddress.is_office = 1
            },
            setCity(city) {
                this.product.productAddress.post_code = city.post_code
                this.product.productAddress.city = city.name
                this.product.productAddress.city_id = city.id

                if(this.locatorTab) {
                    this.product.productAddress.full_address = null
                }

                this.getOfficesById();
            },
            setFullAddress(address) {
                this.product.productAddress.full_address = address.full_address
                this.product.productAddress.office_code = address.code
                this.product.productAddress.office_id = address.id
            },
            getOfficesById() {
                axios.get(`/office/get`, {
                    params: {
                        city_id: this.product.productAddress.city_id,
                        for_sending: true,
                    }
                })
                .then((response) => {
                    this.offices = response.data.offices;
                });
            },
            destroy() {
                axios.delete(`/address/${this.currentAddress.id}`)
                    .then((response) => {
                        let indexAddress = this.addresses.findIndex(item => item.id === response.data.address.id)
                        this.addresses.splice(indexAddress,1)

                        this.gsapCloseModal()
                        this.showDeleteModal = false
                        this.updateDefaultAdress(this.addresses[0].id);
                    })
            },
            updateDefaultAdress(adress){
                axios.patch(`/address/update-default`, {id: adress})
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            window.location = response.data.redirect
                        }else{
                            this.$snotify.error(response.data.message);
                        }
                    })
                    .catch((errors) => {
                        this.$snotify.error('Нямате избран адрес по подразбиране');
                        this.errors = errors.response.data.errors
                    })
            }
        },
        mounted() {
            this.offices = [];
        }
    }

</script>
