'use strict'

import debug from './utils/debug'
import $ from 'jquery'
import gsap from 'gsap'

window.$ = window.jQuery

export default class Modal {

    constructor() {

        $(document).on('click', '[data-youtube-id]', e => { this.main(e) })
        $(document).on('click', '[data-popup-youtube-id]', e => { this.popup(e) })
        $(document).on('click', '[data-popup-video-url]', e => { this.popup_self_hosted(e) })
        $(document).on('click', '.popup-video .close', () => { this.close() })
        $(document).on('click', '.open-modal', e => { this.open_modal(e) })
        $(document).on('click', '.close-modal', () => { this.close_modal() })
        $(document).on('click', '.c-m', () => { this.close_modal() })
    }

    main(e) {

        let $icon = $(e.currentTarget)
        let $video = $icon.prev()
        let video_id = $icon.attr('data-youtube-id')

        if (video_id) {
            $video.html(`<iframe width="1113" height="835" src="https://www.youtube.com/embed/${video_id}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`)
        }

    }

    popup(e) {

        let $icon = $(e.currentTarget)
        let video_id = $icon.attr('data-popup-youtube-id')
        let video_ids = []
        let index = 0

        $icon.closest('.flickity-slider').find('.slide').each((index, element) => {
            video_ids.push($(element).find('.round-video').attr('data-popup-youtube-id'))
        })

        index = video_ids.indexOf(video_id)

        $(document).on('click.video-nav', '.popup-nav', (e) => {

            index += $(e.currentTarget).hasClass('popup-nav-next') ? +1 : -1

            if (index < 0) {
                index = video_ids.length - 1
            }

            if (index > video_ids.length - 1) {
                index = 0
            }

            $('.popup-video iframe').attr('src', `https://www.youtube.com/embed/${video_ids[index]}?autoplay=1`)

        })

        if (video_id) {
            $('body').append(`
                <div class="popup-overlay"></div>
                <div class="popup-video">
                    <a href="#" class="close">
                        <i class="icon icon-close-circle"></i>
                    </a>
                    <a href="#" class="popup-nav popup-nav-prev">
                        <i class="icon icon-arrow-left-circle"></i>
                    </a>
                    <a href="#" class="popup-nav popup-nav-next">
                        <i class="icon icon-arrow-right-circle"></i>
                    </a>
                    <div class="inner-video">
                        <iframe width="${video_id}" height="835" src="https://www.youtube.com/embed/${video_id}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            `)

            gsap.to($('.popup-overlay'), .3, { opacity: .9 })
            gsap.fromTo($('.popup-video'), .3, { opacity: 0, scale: .5 }, { opacity: 1, scale: 1 })

            if (video_ids.length > 1) {
                $('.popup-video').addClass('is-multiple')
            }

        }

    }

    popup_self_hosted(e) {

        let $icon = $(e.currentTarget)
        let video_url = $icon.attr('data-popup-video-url')

        if (video_url) {
            $('body').append(`
                <div class="popup-overlay"></div>
                <div class="popup-video">
                    <a href="#" class="close">
                        <i class="icon icon-close-circle"></i>
                    </a>
                    <div class="inner-video">
                        <video controls autoplay>
                            <source src="${video_url}" type="video/mp4">
                        </video>
                    </div>
                </div>
            `)

            gsap.to($('.popup-overlay'), .3, { opacity: .9 })
            gsap.fromTo($('.popup-video'), .3, { opacity: 0, scale: .5 }, { opacity: 1, scale: 1 })

        }

    }

    close() {

        $(document).off('click.video-nav', '.popup-nav')
        $('.popup-video, .popup-overlay').remove()

    }

    open_modal(e) {

        let $open_modal = $(e.currentTarget)
        let id = $open_modal.attr('data-modal-for')
        // let $overlay = $('<div class="modal-overlay" style="z-index:-1;"></div>')
        let $overlay = $('<div class="modal-overlay" style="z-index:999;"></div>')
        let $modal = $(`.modal[data-id=${id}]`)

        if($('.section-dashboard-info') && !$('.modal-choose-how-to-proceed')){
            // $modal.append($overlay)
            $modal.css('max-height','100vh');
            $modal.css('height','100%');
        }
        if(!$('.section-dashboard-preview')){
            $('body').append($overlay)
        }else{
            if($('.video-modal').length > 0){
                $('.video-modal').parent().append($overlay)
            }else{
                $('body').append($overlay)
            }
        }

        gsap.to($overlay, .3, { opacity: .9 })
        gsap.fromTo($modal, .3, { opacity: 0, visibility: 'visible' }, { opacity: 1 })

        $overlay.on('click', this.close_modal)

    }

    close_modal() {
        if(!$('.modal-overlay').parent().parent().hasClass('offer-container')){
            gsap.to($('.modal-overlay'), .2, {
                opacity: 0,
                onComplete: () => {
                    if(!$('.modal-overlay').parent().hasClass('modal-contact-container') || !$('.modal-overlay').parent().hasClass('modal-contact')){
                        $('.modal-overlay').remove()
                    }
                    if($('modal-overlay').parent("#body").length > 0){
                        $('.modal-overlay').remove()
                    }
                }
            })

            gsap.to($('.modal'), .2, {
                opacity: 0,
                onComplete: () => {
                    $('.modal').css('visibility', 'hidden')
                }
            })
        }
    }

}