<template>
    <div class="i-d-item i-d-item--icon i-d-item-med">
       <div class="i-d-item-icon" @click="showModal();">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 17.625" class="icon-closed">
             <path d="M21.3,64H2.2A2.2,2.2,0,0,0,0,66.2V79.422a2.2,2.2,0,0,0,2.2,2.2H21.3a2.2,2.2,0,0,0,2.2-2.2V66.2A2.2,2.2,0,0,0,21.3,64ZM2.2,65.469H21.3a.737.737,0,0,1,.734.734v1.9c-1.005.849-2.442,2.02-6.912,5.567-.776.615-2.3,2.1-3.369,2.079-1.065.018-2.6-1.464-3.369-2.079C3.911,70.123,2.474,68.952,1.469,68.1V66.2A.737.737,0,0,1,2.2,65.469ZM21.3,80.156H2.2a.737.737,0,0,1-.734-.734V70.013c1.046.858,2.7,2.185,6,4.806.941.753,2.6,2.41,4.282,2.4,1.671.014,3.318-1.629,4.282-2.4,3.3-2.621,4.952-3.947,6-4.806v9.409A.737.737,0,0,1,21.3,80.156Z" transform="translate(0 -64)"></path>
          </svg>
          <div class="i-d-item-badge" v-if="messageThread && seenCounter > 0">
             {{ seenCounter }}
          </div>
       </div>
       <div class="i-d-item-label" @click="showModal();">
          Изпрати съобщение
       </div>
       <MessageModal :order="currentOrder" :profile_id="profileID" v-if="showMessageModal" @close="showMessageModal=false;" @setCounterToNull="setCounterToNull" @updateCounter="updateCounter()"></MessageModal>
    </div>
</template>

<script>
import MessageModal from '../messages/MessageModal.vue';

export default {
    props: ['profile_id','order'],
    components: { 
        MessageModal,
    },
    data() {
        return {
            'messageThread' : this.order.thread ? this.order.thread : false,
            'currentOrder': this.order ? this.order : false,
            // 'receiverID': this.order.user_profile ? this.order.user_profile : false,
            'profileID' : this.profile_id,
            'showMessageModal': false,
            'seenCounter': 0,
        }
    },
    mounted(){
        // console.log(this.order)
        this.setSeenCounter();
        this.startListeningForNewMessages();
    },
    methods: {
        showModal(){
          this.showMessageModal = true;
          this.setSeenCounterToNull();
        },
        setSeenCounterToNull(){
          axios.post(`../../dashboard/message/resetMessageNotifications`, { 
              'order_id': this.order.id,
              }).then((response) => {
                  if (response.data.type == 'success') {
                     this.seenCounter = 0;
                  }else if(response.data.type == 'error'){
                      this.$snotify.error(response.data.message);
                  }
              })
              .catch((errors) => {
                  // this.errors = errors.response.data.errors
              })
        },
        startListeningForNewMessages() {
            const timer = setInterval(() => {
                if (window.Echo === undefined) {
                    return;
                }
                // if (this.activeMessageThread === null) {
                //     return;
                // }

                clearInterval(timer);


                let channel = window.Echo.channel(`messages.${this.profileID}`).listen('NewMessageEvent', (e) => {
                    // console.log('listening message')
                    // console.log(e.data);
                    if(this.order.id == e.data.messageThread.order_id){
                        // console.log('this is for me')
                        // console.log(this.order.id)
                        this.seenCounter += 1;
                    }

                    // if(activeChart.length == 0){
                    //     this.fetchThread(e.data.messageThread.id);
                    // }
                });

            }, 300);
        },
        setCounterToNull(){
            this.seenCounter = 0;
        },
        updateCounter(){
            // this.seenCounter += 1;
        },
        setSeenCounter(){
            if(this.messageThread){
              this.messageThread.profile_notifications.filter((item)=>{
                  if(item.seen == 0){
                      this.seenCounter +=1;
                  }
              })
            }
        }
    },
};
</script>