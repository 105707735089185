<template>
    <section class="section-dashboard">
        <div class="container">

            <div class="grid grid-break-xl bg-white extend--office-side">
                <div class="col-12">

                    <div class="tab-nav">
                        <ul>
                            <li @click="tabOne()" :class="{'active': !locatorTab}"><a href="#" data-for="tab-1" tabindex="7">Адрес</a></li>
                            <li @click="tabTwo()" :class="{'active': locatorTab}"><a href="#" data-for="tab-2" tabindex="8">Офис на Еконт</a></li>
                        </ul>
                    </div>

                    <div class="tabs mt-7">
                        <div class="tab-sections">
                            <div class="tab-section" :class="{'active': !locatorTab}" data-id="tab-1">
                                <div class="grid mt-4">
                                    <template v-for="(address, index) in addresses">
                                    <div class="col-4 col-md-12 mb-2">
                                        <a href="#" class="address" @click="choiceAddress(address)" :class="{ 'active': address.is_default }">
                                            <h3>{{ address.title }}</h3>
                                            <table>
                                                <tr>
                                                    <td>Населено място:</td>
                                                    <td>[{{ address.post_code}}] {{ address.city }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Адрес:</td>
                                                    <td>{{ address.full_address }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Имейл:</td>
                                                    <td>{{ address.email }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Тел:</td>
                                                    <td>{{ address.tel }}</td>
                                                </tr>

                                            </table>
                                            <span class="address-icon"><i class="icon icon-check"></i></span>
                                            <span class="address-edit open-modal" @click="editModalClick(address)"
                                                data-modal-for="add-address" title="Редактирай адрес"><i class="icon icon-edit"></i></span>
                                        </a>
                                    </div>

                                    <div v-if="index == 1" class="col-4 col-md-12 mb-2 " :class="{'ab-no-address': address}" >
                                        <div class="address address-add flex flex-column">
                                            <div class="mt-auto">
                                                <a href="#" class="address-add-icon open-modal" @click="addModal = true"
                                                    data-modal-for="add-address"><i class="icon icon-plus"></i></a>
                                            </div>
                                            <div>
                                                <p>Добави нов адрес,</p>
                                                <p>от който да изпращаш</p>
                                            </div>
                                        </div>
                                    </div>
                                    </template>

                                    <div v-if="addresses.length < 2" class="col-4 col-md-12 mb-2 " :class="{'ab-no-address': address}" >
                                        <div class="address address-add flex flex-column">
                                            <div class="mt-auto">
                                                <a href="#" class="address-add-icon open-modal" @click="addModal = true"
                                                    data-modal-for="add-address"><i class="icon icon-plus"></i></a>
                                            </div>
                                            <div>
                                                <p>Добави нов адрес,</p>
                                                <p>от който да изпращаш</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="tab-section" :class="{'active': locatorTab}" data-id="tab-2">
                                <p class="weight-500 mb-4 mt-2">Твоите данни</p>
                                <div class="grid">
                                    <div class="col-4 col-md-12">

                                        <div class="form-group" :class="errors['productAddress.tel'] ? 'is-error' : ''">
                                            <label for="">Телефон<sub class="is-required">*</sub></label>
                                            <input type="text" v-model="product.productAddress.tel"
                                                   @keyup="errors['productAddress.tel'] = null" tabindex="4">
                                            <form-error v-if="errors && errors['productAddress.tel']">
                                                <template v-for="tel in errors['productAddress.tel']">{{ tel }}</template>
                                            </form-error>
                                        </div>

                                        <div class="form-group" :class="errors['productAddress.email'] ? 'is-error' : ''">
                                            <label for="">E-mail<sub class="is-required">*</sub></label>
                                            <input type="text" v-model="product.productAddress.email"
                                                   @keyup="errors['productAddress.email'] = null" tabindex="3">
                                            <form-error v-if="errors && errors['productAddress.email']">
                                                <template v-for="email in errors['productAddress.email']">{{ email }}</template>
                                            </form-error>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-12">
                                        <infinite-scroll-select v-model="product.productAddress.city"
                                                                :options="cities"
                                                                :filter="citiesFilter"
                                                                :errors="errors && errors['productAddress.city']"
                                                                option-label-name="name"
                                                                @input="setCity"
                                                                @click="errors.sender_city = null"
                                                                field-label="Населено място"
                                                                :required="true">
                                            <template v-slot:option="{ option }">
                                                [{{ option.post_code }}] <b>{{ option.name }}</b>, <i>{{ option.region_name }}</i>
                                            </template>
                                            <template v-slot:selected-option>
                                                [{{ product.productAddress.post_code }}] <b>{{ product.productAddress.city }}</b>,
                                                <i>{{ product.productAddress.region_name }}</i>
                                            </template>
                                            <template v-slot:no-options>
                                                Няма такова населено място
                                            </template>
                                        </infinite-scroll-select>

                                        <div v-if="!locatorTab" class="form-group" :class="errors['productAddress.full_address'] ? 'is-error' : ''">
                                            <label for="">Адрес<sub class="is-required">*</sub></label>
                                            <input autocomplete="off" type="text" v-model="product.productAddress.full_address" @keyup="errors['productAddress.full_address'] = null" tabindex="6">

                                        </div>
                                        <div v-if="locatorTab" class="form-group"
                                             :class="errors['product.productAddress.full_address'] ? 'is-error' : ''"
                                             @click="errors.sender_city = null">
                                            <label for="">
                                                Избери офис<sub class="is-required">*</sub>
                                                <a href="#" class="toggle toggle-bottom-left"
                                                   data-text="Можеш да използваш Еконтомат като подателски адрес, само ако имаш сключено споразумение за изплащане и си го избрал в настройките."><i
                                                    class="icon icon-info"></i></a>
                                            </label>
                                            <div class="toggle-field" style="margin-top: -7px">
                                                <infinite-scroll-select v-model="selectedOffice"
                                                                        :options="offices"
                                                                        :filter="officesFilter"
                                                                        :errors="errors && errors.full_address"
                                                                        :option-is-selectable="(option) => !option.disabled"
                                                                        option-label-name="full_address"
                                                                        @input="setFullAddress"
                                                                        class="toggle-field-left">
                                                    <template v-slot:option="{option}">
                                                        <b>{{ option.name }}</b> <span>{{ option.full_address }}</span>
                                                    </template>
                                                    <template v-slot:selected-option>
                                                        {{ product.productAddress.full_address }}
                                                    </template>
                                                    <template v-slot:no-options>
                                                        Няма такъв офис на Еконт
                                                    </template>
                                                </infinite-scroll-select>
                                                <div class="toggle-field-right office-locator-button"
                                                     style="margin-top: 10px">
                                                    <office-locator-button @office-choice="onLocatorOfficeChange"
                                                                           :city="product.productAddress.city"
                                                                           :address="product.productAddress.full_address">
                                                    </office-locator-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="flex" style="justify-content: flex-end; margin-top: 1em;">
                                <div class="service--button-holder mr-1">
                                    <button type="submit" class="button" @click.prevent="prev()" :tabindex="addresses.length + 10">Назад</button>
                                </div>
                                <div class="service--button-holder">
                                    <button type="submit" @click.prevent="submit()" class="button" :tabindex="addresses.length + 11" :disabled="submitted">Напред</button>
                                </div>
                            </div>
                            <p class="mutted text-right mt-1 extend--faq">
                                <span class="vertical-middle">Често задавани въпроси</span>
                                <a href="/faq/questions" class="mr-0" target="_blank"><i
                                        class="icon icon-questionmark-circle"></i></a>
                            </p>

                        </div>
                    </div> <!-- END TABS -->

                </div>
            </div>
        </div>

        <address-modal-edit v-if="editModal" @close="editModal=false" @success="updateAddresses" :cities="cities" :address="address"></address-modal-edit>
        <address-modal-add v-if="addModal" @close="addModal=false" @success="addAddresses" :cities="cities"></address-modal-add>

    </section>
</template>

<script>
    import FormError from '../FormError.vue'
    import AddressModalEdit from './AddressModalEdit.vue'
    import AddressModalAdd from './AddressModalAdd.vue'
    import OfficeLocatorButton from "../OfficeLocatorButton";
    import InfiniteScrollSelect from "../InfiniteScrollSelect.vue";

    export default {
        props: ['addresses', 'cities', 'profile'],
        components: {
            FormError,
            AddressModalEdit,
            AddressModalAdd,
            OfficeLocatorButton,
            InfiniteScrollSelect,
        },
        data() {
            return {
                product: this.$store.state.createLink.product,
                locatorTab: false,
                errors: [],
                editModal: false,
                addModal: false,
                address: {},
                offices: [],
                submitted: false,
                selectedOffice: {},
            }
        },
        methods: {
            editModalClick(address) {
                this.address = address
                this.editModal = true
            },
            updateAddresses(address) {
                var index = this.addresses.findIndex(item => item.id === address.id)
                if (index != -1) {
                    this.addresses.splice(index, 1, address)
                } else {
                    this.addresses.push(address)
                }
                this.product.productAddress = {...address, is_office: 0, is_company: 0}
            },
            addAddresses(address) {
                this.addresses.forEach( (e) => {
                    e.is_default = 0
                })
                this.product.productAddress = {...address, is_office: 0, is_company: 0}
                this.product.productAddress.is_default = 1
                address.is_default = 1
                address.is_office = 0
                address.is_company = 0
                this.addresses.push(address)
            },
            submit() {
                if (this.product.productAddress.is_office) {
                    this.product.productAddress = {...this.product.productAddress }
                }
                this.submitted = true;
                axios.post(`/product`, this.product)
                    .then((response) => {
                        this.errors = []
                        if (response.data.success) {
                            gtag('event', 'Forms', {
                                'step': '2',
                                'delivery_type': this.product.productAddress.is_office ? 'Econt' : 'Address',
                                'office_id': this.product.productAddress.is_office ? this.product.productAddress.office_code : '',
                                'city_id': `${this.product.productAddress.city}, ${this.product.productAddress.post_code}`,
                            });
                            window.location = response.data.redirect
                        }else{
                            this.notifyError(response.data.message);
                        }
                    })
                    .catch((errors) => {
                        this.submitted = false
                        this.errors = errors.response.data.errors

                        if (this.errors.title || this.errors.description || this.errors.price || this.errors.media ||
                        this.errors.shop_id) {
                            this.notifyError('Не сте попълнили полета от стъпка едно')
                        }
                    })
            },
            choiceAddress(address) {
                this.product.productAddress = {...this.product.productAddress, ...address }
                this.addresses.forEach( (e) => {
                    e.is_default = 0
                    if (e.id == address.id) {
                        e.is_default = 1
                    }
                })
                this.product.productAddress.is_default = 1
            },
            prev() {
                this.$store.state.createLink.step--;
            },
            tabOne() {
                this.locatorTab = false

                const addressIndex = this.addresses.findIndex(e => e.is_default == 1)
                this.product.productAddress = {...this.addresses[addressIndex], is_office: 0, is_company: 0}
            },
            tabTwo() {
                this.locatorTab = true
                this.product.productAddress.is_office = 1
                this.product.productAddress.city = ''
                this.product.productAddress.full_address = ''
                this.selectedOffice = {};
            },
            setCity(city) {
                this.product.productAddress.post_code = city.post_code
                this.product.productAddress.city = city.name
                this.product.productAddress.city_id = city.id

                if(this.locatorTab) {
                    this.product.productAddress.full_address = null
                    this.selectedOffice = {};
                }

                return this.getOfficesById();
            },
            setFullAddress(address) {
                this.product.productAddress.full_address = address.full_address
                this.product.productAddress.office_code = address.code
                this.product.productAddress.office_id = address.id
                this.selectedOffice = address;
            },
            async onLocatorOfficeChange(office) {
                if (typeof office?.address !== 'object') {
                    return;
                }
                let localCityObj = this.cities.find((val) => {
                    return val.city_id === office.address.city.id;
                });
                await this.setCity(localCityObj);
                let localOfficeObj = this.offices.find((val) => {
                    return val.code === office.code;
                });
                this.setFullAddress(localOfficeObj);
            },
            getOfficesById() {
                return axios.get(`/office/get`, {
                    params: {
                        city_id: this.product.productAddress.city_id,
                        for_sending: true,
                    }
                })
                .then((response) => {
                    this.offices = response.data.offices;
                    if(!this.offices.length) {
                        this.notifyError('В избрания град няма офис на Еконт')
                    }
                });
            },
            setPreDefinedOfficeAddress() {
                this.product.productAddress = {
                    is_office: 1,
                    name: this.profile.name,
                    tel: this.profile.business_phone,
                    email: this.profile.business_email,
                }
                if (this.profile.client_type == 'commercial') {
                    this.product.productAddress.representative = this.profile.mol
                }
            },
            citiesFilter(city, search) {
                return city.name.toLowerCase().includes(search.toLowerCase());
            },
            officesFilter(office, search) {
                let searchLc = search.toLowerCase();

                return office.name.toLowerCase().includes(searchLc) ||
                    office.full_address.toLowerCase().includes(searchLc);
            },
        },
        created() {
            /*scroll to top*/
            window.scrollTo(0,0);
            let address = this.addresses.find(e => e.is_default == 1)
            this.product.productAddress = {...address, is_office: 0, is_company: 0}

            this.setPreDefinedOfficeAddress()
        },
        mounted() {
            this.offices = [];
        }
    }

</script>
