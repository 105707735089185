<template>
    <div class="modal modal-delete" :data-id="`delete-label-${order.id}`">
        <a href="#" class="close-modal" @click="$emit('close')"><i class="icon icon-close-circle"></i></a>
        <h3>Изтриване</h3>
        <h4>Сигурен ли си, че искаш да изтриеш товарителницата?1</h4>
        <!-- <p>Всички активни поръчки на този продукт ще бъдат изтрити. Не забравяй да уведомиш изчакващ купувач, че отказваш поръчката му.</p> -->
        <p v-if="order.payment_token" style="background-color: #913f98; color: white; padding: 8px;">
            Поръчката е платена електронно с EcontPay. Ако изтриеш тази товарителница, парите ще се възстановят по
            сметката на купувача. Ако решиш все пак да изпълниш поръчката с нова товарителница, ще трябва
            да активираш наложен платеж при създаването ѝ.
        </p>
        <p v-else>Ако изтриете товарителницата, ще трябва да създадете документа на ново преди да изпратите пратката.</p>
        <div class="text-right mt-3 flex">
            <div>
                <form :action="'/label/'+order.label.id" method="POST">
                    <input type="hidden" name="_method" value="DELETE">
                    <input type="hidden" name="_token" :value="token" />
                    <button type="submit" class="button small mint" tabindex="1">
                        Изтрий
                    </button>
                </form>
            </div>
            <a href="#" class="button small c-m" @click="$emit('close')" tabindex="2">Отказ</a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["order"],
    computed: {
        token() {
            return document.head.querySelector("[name=csrf-token]").content;
        }
    }
};
</script>
