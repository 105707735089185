<template>
    <div class="s-t-h-f-item m-l-item s-t-h-f-item-search">
        <div class="m-l-i-header">
            <div class="m-l-i-h-input">
                <input type="text" ref="search_product" name="search" placeholder="Търси продукт..." autocomplete="off" v-model="search">
            </div>
            <div class="m-l-i-h-icon m-l-i-h-button" @click="SearchWord();">
                <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 440.2 437.3" xml:space="preserve">
                <path d="M194,0C86.9,0,0,86.9,0,194s86.9,194,194,194s194-86.9,194-194S301.1,0,194,0z M193,359
                    c-91.7,0-166-74.3-166-166S101.3,27,193,27s166,74.3,166,166S284.7,359,193,359z"/>
                <rect x="362.6" y="308.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.2685 378.8583)" width="34.2" height="136.9"/>
                </svg> -->
                <span>Търси</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: '',
        }
    },
    created(){
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        // console.log(params.get("search"));
        this.search = params.get("product_search");
    },
    mounted() {
        this.$refs.search_product.addEventListener("keyup", (event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            this.SearchWord()
          }
        });
    },
    methods: {
        SearchWord(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            // console.log(params.get("search"));
            params.set("product_search",this.search);
            // console.log(this.search)
            // console.log(uri)
            let tempUri = uri.substr(uri.indexOf('product_search='),uri.length)
            let newUri = uri.replace(tempUri,'product_search=');
            // console.log('newUri');
            // console.log(newUri);
            window.location.search = newUri + this.search;
            // console.log(window.location)
            // console.log(this.search)
            // console.log(this.$route.query.search);
        }
    },
};
</script>