<template>
    <div :class="containerClasses">
        <label v-if="fieldLabel" for="infinite-scroll-select">
            {{ fieldLabel }}<sub v-if="required" class="is-required">*</sub>
        </label>
        <v-select :value="value"
                  :options="paginated"
                  :filterable="false"
                  :selectable="optionIsSelectable"
                  :label="optionLabelName"
                  @input="onInput"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  id="infinite-scroll-select">
            <template v-slot:option="option">
                <slot name="option" :option="option"></slot>
            </template>
            <template v-slot:selected-option="option">
                <slot name="selected-option" :option="option"></slot>
            </template>
            <template v-slot:no-options>
                <slot name="no-options"></slot>
            </template>
            <template v-slot:list-footer>
                <li v-show="hasNextPage" ref="load" class="loader">
                    Зарежда...
                </li>
            </template>
        </v-select>
        <slot name="after"></slot>
    </div>
</template>

<script>
export default {
    name: "InfiniteScrollSelect",
    props: {
        value: {},
        options: {
            type: Array,
            required: true,
        },
        filter: Function,
        optionIsSelectable: Function,
        fieldLabel: String,
        required: Boolean,
        optionLabelName: String,
        errors: false,
    },
    data: () => ({
        observer: null,
        limit: 10,
        search: '',
    }),
    computed: {
        filtered() {
            if (this.filter && this.search) {
                return this.options.filter(
                    (option) => this.filter(option, this.search)
                );
            } else {
                return this.options;
            }
        },
        paginated() {
            return this.filtered.slice(0, this.limit);
        },
        hasNextPage() {
            return this.paginated.length < this.filtered.length;
        },
        containerClasses() {
            return {
                'form-group': true,
                'is-error': this.errors,
            };
        },
    },
    methods: {
        onInput(value) {
            this.$emit('input', value);
        },
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick();
                this.observer.observe(this.$refs.load);
            }
        },
        onClose() {
            this.observer.disconnect();
        },
        async infiniteScroll([{isIntersecting, target}]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.limit += 10;
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },
    },
    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll);
    },
}
</script>

<style scoped>
.loader {
    text-align: center;
    color: #bbbbbb;
}
</style>
