<template>
    <div>
        <div class="container-list" v-if="chats.length > 0">
            <template v-for="chat in chats">
                <Message :thread="chat" :profile_id="profileId" @submitMessage="submitMessage" :key="chat.id" @updateUnseenMessage="updateUnseenMessage"></Message>
            </template>
        </div>
        <div class="container-list" v-else>
            В момента нямате съобщения.
        </div>
    </div>
</template>

<script>
import Message from './Message'


export default {

    props: ['profile_id','threads'],
    components: { 
        Message,
    },
    data() {
        return {
            'activeMessageThread': null,
            'profileId': this.profile_id,
            'chats' : this.threads.data,
            'newCounter': 0,
            'errors' : '',
        }
    },
    created(){
        this.getNewThreads();
        this.startListeningForNewMessages();

        // console.log('chats')
        // console.log(this.chats)
    },
    methods: {
        updateUnseenMessage(id){
            // console.log('unseen update')
            // console.log(id);
            this.chats.map((item)=>{
                if(item.id == id){
                    item.un_seen_profile_notifications = [];
                    // console.log('the chat is')
                    // console.log(item);
                }
                
            })
        },
        submitMessage(thread){
            // console.log('inside message index')
            // console.log('thread');
            // console.log(thread);
            this.chats.map((item)=>{
                if(item.id == thread.id){
                    item = thread;
                }
            });
        },
        startListeningForNewMessages() {
            const timer = setInterval(() => {
                if (window.Echo === undefined) {
                    return;
                }
                // if (this.activeMessageThread === null) {
                //     return;
                // }

                clearInterval(timer);


                let channel = window.Echo.channel(`messages.${this.profileId}`).listen('NewMessageEvent', (e) => {
                    // console.log('listening message')
                    // console.log(e.data);
                    let activeChart = this.chats.filter((item)=>{
                        if(item.id == e.data.messageThread.id){
                            // return item;
                            item.last_message.created_at = e.data.message.created_at;
                            item.last_message.id = e.data.message.id;
                            item.last_message.message = e.data.message.message;
                            item.last_message.message_thread_id = e.data.message.message_thread_id;
                            item.last_message.receiver_id = e.data.message.receiver_id;
                            item.last_message.sender_id = e.data.message.sender_id;
                            item.last_message.sender_id = e.data.message.sender_id;
                            item.last_message.sender_id = e.data.message.sender_id;
                            item.un_seen_profile_notifications = ['new message added'];
                            // console.log(item.messages)
                            item.messages = [...item.messages,e.data.message];
                        
                            item.unread = false;
                            // console.log('this is the thread')
                            // console.log(item);
                            return item;
                        }
                    });
                    if(activeChart.length == 0){
                        this.fetchThread(e.data.messageThread.id);
                    }
                });

            }, 300);
        },
        getNewThreads(){
            this.chats.map((item)=>{
                if(item.un_seen_profile_notifications.length > 0){
                    this.newCounter = this.newCounter + 1;
                }
            });
        },
        fetchThread(id){
            /*send message request here*/
            // console.log('fetching thread')
            axios.get(`message/${id}/getThreadByID`)
                .then((response) => {
                    if (response.data.type == 'success') {
                        /*Add message to Que*/
                        // this.chats = [...this.chats,response.data.thread];
                        // if(this.charts.length > 0){
                        //     this.chats = this.charts.unshift(response.data.thread);
                        // }else{
                            // this.chats = [...this.chats,response.data.thread];
                            this.chats = [response.data.thread,...this.chats];
                            // console.log('feteched chats')
                            // console.log(this.chats)
                            let counterElement = document.querySelector('.container-sub-header .c-s-h-data span');
                            let counter = parseInt(counterElement.textContent)
                            counterElement.textContent = counter + 1;
                            // console.log(counterElement)
                            // console.log(counter)

                        // }
                    }else if(response.data.type == 'error'){
                        this.$snotify.error(response.data.message);
                    }
                })
                .catch((errors) => {
                    // this.errors = errors.response.data.errors
                })
        }
    }
}
</script>