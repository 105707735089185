<template>
    <div class="modal modal-add-address" data-id="add-address" style="visibility: visible; opacity: 1;">
        <a href="#" class="close-modal" @click="$emit('close')" title="Затвори"><i class="icon icon-close-circle"></i></a>
        <h3>Добави нов адрес, от който да изпращаш</h3>
        <!-- <p class="weight-500">Вашите данни</p> -->
        <form class="form" @submit.prevent="submit">
            <div class="grid">
                <div class="col-8 col-md-12">

                    <div class="form-group" :class="errors && errors.title ? 'is-error' : ''">
                        <label for="">Добави име, с което по-лесно да разпознаваш адреса</label>
                        <div class="price-wrapper">
                            <input type="text" name="title" v-model="address.title" tabindex="1" ref="startPage">
                        </div>
                    </div>

                </div>
                <div class="col"></div>
                <div class="col-4 col-md-12">
                    <div class="form-group" :class="errors && errors.tel ? 'is-error' : ''">
                        <label for="">Телефон<sub class="is-required">*</sub></label>
                        <div class="price-wrapper">
                            <input type="text" name="tel" v-model="address.tel" tabindex="5">
                        </div>
                    </div>
                    <div class="form-group" :class="errors && errors.email ? 'is-error' : ''">
                        <label for="">E-mail<sub class="is-required">*</sub></label>
                        <div class="price-wrapper">
                            <input type="text" name="email" v-model="address.email" tabindex="4">
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-12">
                    <infinite-scroll-select v-model="address.city"
                                            :options="cities"
                                            :filter="citiesFilter"
                                            :errors="errors && errors.city"
                                            option-label-name="name"
                                            @input="setCity"
                                            field-label="Населено място"
                                            :required="true">
                        <template v-slot:option="{ option }">
                            [{{ option.post_code }}] <b>{{ option.name }}</b>, <i>{{ option.region_name }}</i>
                        </template>
                        <template v-slot:selected-option>
                            [{{ address.post_code }}] <b>{{ address.city }}</b>,
                            <i>{{ address.region_name }}</i>
                        </template>
                        <template v-slot:no-options>
                            Няма такова населено място
                        </template>
                    </infinite-scroll-select>
                    <div class="form-group" :class="errors && errors.full_address ? 'is-error' : ''">
                        <label for="">Адрес<sub class="is-required">*</sub></label>
                        <div class="price-wrapper">
                            <input type="text" name="full_address" v-model="address.full_address" tabindex="7">
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-auto text-right mt-3 mb-0">
                <button type="submit" class="button" tabindex="8">Добави адрес</button>
            </div>

        </form>
    </div>
</template>

<script>
    import FormError from '../FormError.vue'
    import InfiniteScrollSelect from "../InfiniteScrollSelect.vue";

    export default {
        props: ['cities'],
        components: {
            FormError,
            InfiniteScrollSelect,
        },
        data() {
            return {
                address: {},
                errors: [],
            }
        },
        methods: {
            focusInput(){
                this.$refs.startPage.focus();
            },
            submit() {
                axios.post(`/address`, this.address)
                    .then((response) => {
                        if (response.data.success) {
                            let address = response.data.address
                            this.notifySuccess('Адреса е добавен успешно')
                            this.$emit('success', address)
                            this.$emit('close')
                            document.querySelector('.modal-overlay').remove()
                        }
                    })
                    .catch((errors) => {
                        this.errors = errors.response.data.errors
                    })

            },
            setCity(city) {
                this.address.post_code = city.post_code;
                this.address.city = city.name;
            },
            citiesFilter(city, search) {
                return city.name.toLowerCase().includes(search.toLowerCase());
            },
        },
        mounted() {
            this.focusInput();
        }
    }

</script>
