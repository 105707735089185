'use strict'

import debug from './utils/debug'
import $ from 'jquery'

window.$ = window.jQuery

export default class Cookies {

	// constructor() {

	// 	this.cookie()

	// }
    run(){
		let cookieContainer = $("#cookies");
		let cookieButton = cookieContainer.find('.cookies-button');
		let cookieActive = 'show_cookie_message';

		this.cookieButtonClick(cookieButton, cookieContainer, cookieActive);

		var visit = this.getCookie("readMessage");
		
		if(visit == false){
			cookieContainer.addClass(cookieActive);
		}
	}
	getCookie(name){
  		var arg = name + "=";
	  	var alen = arg.length;
	  	var clen = document.cookie.length;
	  	var i=0;
		  while (i<clen) {
			var j = i+alen;
			if (document.cookie.substring(i,j)==arg)
			  return true;
			i=document.cookie.indexOf(" ",i)+1;
			if (i==0) break;
		  }
		  return false;
	}
	cookieButtonClick(element, container, active){
		element.click(function(e){
			e.preventDefault();
			container.removeClass(active);
			document.cookie = "readMessage=true";
		});
	}
}
