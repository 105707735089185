'use strict'

const debug = require('debug')('App')
const client = require('debug')('Client ||||||')
const dashboard = require('debug')('Dashboard |||')

client.enabled = dashboard.enabled = true

export default {
    client: client,
    dashboard: dashboard
}
