<template>
    <form @submit="post" class="form" method="post" enctype="multipart/form-data" autocomplete="off" action="/delivery">
        <input type="hidden" name="_token" :value="token" />
        <section class="section-dashboard">
            <div class="container">
                <div class="grid bg-white grid-settings">
                    <div class="col-12">

                        <div class="grid">
                            <div class="col-6 col-lg-12">
                                <div class="extend--dostavki">

                                    <div class="form-title">
                                        Допълнителни услуги:
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="no-select">
                                            <div class="grid">
                                                <div class="col-6 col-md-12">
                                                    <!-- <label class="md-checkbox">
                                                        <input type="checkbox" name="properties[cdAmount]" 
                                                        v-model="properties.cdAmount">
                                                        <span class='md-transition'></span><em>Наложен платеж</em>
                                                        <a href="#" class="toggle toggle-bottom-right toggle-large" data-text="Сумата, която получателят следва да плати при доставката. Ние събираме сумата и ти я изплащаме там, където е удобно за теб. По подразбиране е зададено изплащане на сумите от наложени платежи да се случва в брой. По подразбиране наложените платежи по пратки се изплащат на следващ работен ден след успешна доставка и прием от получател. Изплащането се извършва в офиса или на адреса, от който е тръгнала първоначално пратката. Можеш да избираш условията и мястото на изплащане според твоите нужди. Ако имаш предпочитан начин на изплащане, можеш да го настроиш по подразбиране за всички пратки чрез Споразумение. Създаването на Споразумение може да стане по два начина:"><i class="icon icon-info"></i></a>
                                                    </label> -->

                                                    <label class="md-checkbox">
                                                        <input type="checkbox" name="properties[smsNotification]" 
                                                        v-model="properties.smsNotification">
                                                        <span class='md-transition'></span><em>SMS известяване</em>
                                                        <a href="#" class="toggle toggle-bottom-right" data-text="Това е платена услуга. При активиране на опцията крайните потребители ще бъдат известявани, че имат пратка за получаване чрез SMS. Към сумата за доставка ще се добави допълнителна стойност за услугата SMS Известяване."><i class="icon icon-info"></i></a>
                                                    </label>

                                                    <label class="md-checkbox">
                                                        <input type="checkbox" name="properties[declaredValue]" v-model="properties.declaredValue">
                                                        <span class='md-transition'></span><em>Обявена стойност</em>
                                                        <a href="#" class="toggle toggle-bottom-right toggle-large" data-text="Това е платена услуга. Това е стойността на пратката, до която носим отговорност в случаите на повреда, загуба или кражба. Тя се посочва от подателя при изпращане на пратката. Лимит 10 000 лв. С маркирането на този чек бокс, се дава възможност да се настрои и праг за стойността на поръчката, от който услугата Обявена стойност да се активира. Ако не е въведен праг, но е маркиран чек бокса за услугата по всички пратки, ще се активира услуга Обявена стойност. Към сумата за доставка ще се добави допълнителна стойност за услугата Обявена стойност."><i class="icon icon-info"></i></a>
                                                    </label>

                                                    <input type="text" name="properties[declaredValueAmount]" 
                                                    v-model="properties.declaredValueAmount" :disabled="!properties.declaredValue"
                                                    style="max-width: 125px;">
                                                </div>
                                                <div class="col-6 col-md-12">
                                                    <!-- <label class="md-checkbox">
                                                        <input type="checkbox" name="properties[payOnline]" v-model="properties.payOnline" :checked="properties.payOnline">
                                                        <span class='md-transition'></span><em>Гарантирано от Еконт</em>
                                                        <a href="#" class="toggle toggle-bottom-right" data-text="Плащане с карта, при което се резервира сумата за поръчката и доставката.<br>
                                                    • Плащате, когато приемете пратката.<br>
                                                    • При отказ, стойността на стоката автоматично се освобождава от картата. Приспада се само доставката.<br>
                                                    Пазарувате с карта без притеснения дали ще получите средствата си обратно при връщане."><i class="icon icon-info"></i></a>
                                                    </label> -->
                                                    <label class="md-checkbox  mt-0">
                                                        <input @change="cPayAfterAccept" ref="payAfterAccept" type="checkbox" name="properties[payAfterAccept]" v-model="properties.payAfterAccept" :checked="properties.payAfterAccept">
                                                        <span class='md-transition'></span><em>Преглед</em>
                                                        <a href="#" class="toggle toggle-bottom-right" data-text="Това е безплатна услуга. При маркиране на чек бокса за услугата получателят ще има възможност да прегледа съдържанието на пратката в рамките на 15 минути и да заплати само ако приеме съдържанието на пратката."><i class="icon icon-info"></i></a>
                                                    </label>

                                                    <label class="md-checkbox mt-0">
                                                        <input @click="payAfter" type="checkbox" name="properties[payAfterTest]" v-model="properties.payAfterTest" :checked="properties.payAfterTest">
                                                        <span class='md-transition'></span><em>Тест</em>
                                                        <a href="#" class="toggle toggle-bottom-right" data-text="Това е безплатна услуга. Възможността за маркиране на чек бокса се отключва само ако преди това е маркиран чек бокса за Преглед. Услугата дава възможност на получателя да тества съдържанието на пратката след прегледа в рамките на 15 минути."><i class="icon icon-info"></i></a>
                                                    </label>

                                                    <label class="md-checkbox mt-0">
                                                        <input @click="payAfter" type="checkbox" name="properties[partialDelivery]" v-model="properties.partialDelivery" :checked="properties.partialDelivery">
                                                        <span class='md-transition'></span><em>Избор</em>
                                                        <a href="#" class="toggle toggle-bottom-right toggle-large" data-text="Това е безплатна услуга. Възможността за маркиране на чек бокса се отключва само ако преди това е маркиран чек бокса за Преглед. Услугата дава възможност на получателя да избере кой от артикулите да закупи след Преглед или Преглед и Тест. ВАЖНО! Дори да си маркирал услугата Избор, тя се активира само и единствено когато в поръчката има повече от 1 артикул. При маркиране на Избор системата автоматично създава опис към пратка, където стойността на артикулите в описа трябва да е равна на стойността на наложения платеж.Услугите Преглед, Тест и Избор могат да се активират накуп или да се изберат в комбинация, но задължително трябва да е маркирана услугата Преглед. Може да се активира Преглед и Тест, както и Преглед и Избор, но услугата Избор ще се активира само ако в пратката има повече от 1 артикул."><i class="icon icon-info"></i></a>
                                                    </label>

                                                    <label class="md-checkbox mt-5">
                                                        <input type="checkbox" name="properties[priorityTime]" 
                                                        v-model="properties.priorityTime">
                                                        <span class='md-transition'></span><em>Точен час за получаване
                                                            на пратка</em>
                                                        <a href="#" class="toggle toggle-bottom-right" data-text="Това е платена услуга. С маркиране на този чек бокс се дава възможност на получателя да избере точен час за доставка във формата за доставка, която се визуализира на чек аута. Тя се активира при доставка до адрес. Възможностите са След, В и Преди определен час. Към сумата за доставка ще се добави допълнителна стойност за услугата Точен час за получаване на пратка."><i class="icon icon-info"></i></a>
                                                    </label>
                                                    
                                                </div>
                                                <div class="col col-md-12 mt-5 mobile-margin">
                                                    <p class="mutted">ВАЖНО! Използването на допълнителни услуги (SMS известяване, Обявена стойност, Обратна разписка и Точен час се заплаща допълнително от теб или клиентите ти според условията, които си задал в настройките за плащане).</p>
                                                    <br>
                                                    <p class="mutted">
                                                        Плащане с карта, при което се резервира сумата за поръчката и доставката.<br>
                                                        • Клиента плаща, когато приеме пратката.<br>
                                                        • При отказ, стойността на стоката автоматично се освобождава от картата му. Приспада се само доставката.<br>
                                                        Клиента пазарува с карта без притеснения дали ще получи средствата си обратно при връщане, а вие сте сигурни за плащането на доставката.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 col-lg-12">
                                <div class="form-title mt-4" title="Тук избираш какви опции за доставка ще имат купувачите ти.">
                                    Платежен метод:
                                    <!-- <a href="#" class="toggle toggle-bottom-right" data-text="Тук избираш какви опции за плащане ще имат купувачите ти. "><i class="icon icon-info"></i></a> -->
                                </div>
                                <div class="grid">
                                    <div class="col-4 col-md-12">
                                        <label class="md-checkbox mt-0">
                                            <input @change="cdAmount" type="checkbox" name="properties[cdAmount]" 
                                            v-model="properties.cdAmount" :checked="properties.cdAmount">
                                            <span class='md-transition'></span><em>Наложен платеж</em>
                                            <a href="#" class="toggle toggle-bottom-right toggle-large" data-text="Сумата, която получателят следва да плати при доставката. Ние събираме сумата и ти я изплащаме там, където е удобно за теб. По подразбиране е зададено изплащане на сумите от наложени платежи да се случва в брой. По подразбиране наложените платежи по пратки се изплащат на следващ работен ден след успешна доставка и прием от получател. Изплащането се извършва в офиса или на адреса, от който е тръгнала първоначално пратката. Можеш да избираш условията и мястото на изплащане според твоите нужди. Ако имаш предпочитан начин на изплащане, можеш да го настроиш по подразбиране за всички пратки чрез Споразумение. Създаването на Споразумение може да стане по два начина:"><i class="icon icon-info"></i></a>
                                        </label>
                                    </div>
                                    <div class="col-6 col-md-12">
                                        <label class="md-checkbox mt-0">
                                            <input @change="payOnline" type="checkbox" name="properties[payOnline]" v-model="properties.payOnline" :checked="properties.payOnline">
                                            <span class='md-transition'></span><em>EcontPay</em>
                                            <a href="#" class="toggle toggle-bottom-right" data-text="Това е плащане с банкова карта, при което EcontPay резервира сума, равна на стойността на поръчаната стока + сумата за доставка.<br>
                                            Когато клиентът получи и приеме пратката, резервираната сума се тегли от банковата му карта.<br> 
                                            Ако клиентът върне или не потърси стоката в срок от 7 работни дни, задържаната сума се въстановява по сметката му, като от нея се приспада сумата на куриерските услуги за доставка и връщане на стоката към Вас."><i class="icon icon-info"></i></a>
                                        </label>
                                    </div>
                                </div>
                               <!--  <div class="form-title" title="Тук избираш какви опции за плащане ще имат купувачите ти.">
                                    Начини на плащане:
                                    <a href="#" class="toggle toggle-bottom-right" data-text="Тук избираш какви опции за плащане ще имат купувачите ти. "><i class="icon icon-info"></i></a>
                                </div>
                                <div class="grid">
                                    <div class="col-4 col-md-12">
                                        <label class="md-checkbox mt-0">
                                            <input type="checkbox" name="properties[payOnPlace]" v-model="properties.payOnPlace" :checked="properties.payOnPlace">
                                            <span class='md-transition'></span><em>Наложен платеж</em>
                                        </label>
                                    </div>
                                    <div class="col-4 col-md-12">
                                        
                                    </div>
                                </div> -->
                                <div class="form-title mt-4" title="Тук избираш какви опции за доставка ще имат купувачите ти.">
                                    Доставка до:
                                    <a href="#" class="toggle toggle-bottom-right" data-text="Тук избираш какви опции за доставка ще имат купувачите ти. "><i class="icon icon-info"></i></a>
                                </div>
                                <div class="grid">
                                    <div class="col-4 col-md-12">
                                        <label class="md-checkbox mt-0">
                                            <input @change="address" type="checkbox" name="properties[deliveryTo][address]" v-model="properties.deliveryTo.address" :checked="properties.deliveryTo.address">
                                            <span class='md-transition'></span><em>Адрес</em>
                                        </label>
                                    </div>
                                    <div class="col-4 col-md-12">
                                        <label class="md-checkbox mt-0">
                                            <input @change="office" type="checkbox" name="properties[deliveryTo][office]" v-model="properties.deliveryTo.office" :checked="properties.deliveryTo.office">
                                            <span class='md-transition'></span><em>Офис на Еконт</em>
                                        </label>
                                    </div>
                                </div>
                                <div class="grid mt-4">
                                    <div class="col-6 col-md-12">
                                        <div class="form-title">
                                            Доставка до държави:
                                        </div>
                                        <label class="md-checkbox mt-0">
                                            <input disabled type="checkbox" name="" value="1" checked="checked">
                                            <span class='md-transition'></span><em class="mutted">България</em>
                                        </label>
                                    </div>
                                    <div class="col-6 col-md-12">
                                        <div class="form-title">
                                            Формат на транспортен етикет:
                                        </div>
                                        <div class="radio-stack no-select">
                                            <div class="md-radio-fieldset">
                                                <label class="md-radio">
                                                    <input type="radio" name="properties[format]" 
                                                    v-model="properties.format"
                                                    value="A4">
                                                    <i class="md-transition"></i>
                                                    <span>A4</span>
                                                </label>
                                                <label class="md-radio">
                                                    <input type="radio" name="properties[format]" v-model="properties.format" value="10x9">
                                                    <i class="md-transition"></i>
                                                    <span>10x9 (Label)</span>
                                                </label>
                                                <label class="md-radio">
                                                    <input type="radio" name="properties[format]" v-model="properties.format" value="10x15">
                                                    <i class="md-transition"></i>
                                                    <span>10x15</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-title mt-4">
                                    Указания по пратка:
                                </div>
                                <div class="grid">
                                    <div class="col-6 col-md-12">

                                        <label class="md-checkbox mt-0">
                                            <input type="checkbox" name="properties[takeType]"
                                            v-model="properties.takeType"
                                            >
                                            <span class='md-transition'></span><em>Приемащ куриер</em>
                                        </label>

                                        <div class="form-group mt-1">
                                            <textarea v-model="properties.takeDescription" :disabled="!properties.takeType"
                                            class="textarea-short" name="properties[takeDescription]"></textarea>
                                        </div>

                                    </div>
                                    <div class="col-6 col-md-12">

                                        <label class="md-checkbox mt-0">
                                            <input type="checkbox" name="properties[giveType]"
                                            v-model="properties.giveType"
                                            >
                                            <span class='md-transition'></span><em>Предаващ куриер</em>
                                        </label>

                                        <div class="form-group mt-1">
                                            <textarea v-model="properties.giveDescription" :disabled="!properties.giveType"
                                            class="textarea-short" name="properties[giveDescription]"></textarea>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid justify-end align-center">
                            <div class="col col-md">
                                <label class="md-checkbox">
                                    <input type="checkbox" name="applyToExistingProducts">
                                    <span class='md-transition'></span><em>Приложи за съществуващи продукти</em>
                                    <a href="#" class="toggle toggle-top-left toggle-box" data-text="Настройките от този екран се прилагат автоматично за всеки нов продукт при създаването му. Ако искате да приложите актуалните настройки за всички съществуващи продукти, моля, включете тази опция."><i class="icon icon-info"></i></a>
                                </label>
                            </div>
                            <div class="col col-md">
                                <button type="submit" class="button">Запази промените</button>
                            </div>
                        </div>
                        <div class="grid justify-end mt-2">
                            <div class="col col-md">
                                <a href="/settings" class="button button-border">Назад</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
</template>

<script>
export default {
    props: ["settings"],
    data() {
        return {
            declared: this.settings.properties.declaredValue,
            take: this.settings.properties.take,
            give: this.settings.properties.give,
            properties: this.settings.properties
        };
    },
    computed: {
        token() {
            return document.head.querySelector("[name=csrf-token]").content;
        },
    },
    created() {
        // console.log(this.properties);
    },
    methods: {
        post() {
            this.afterAcceptDisabled = false;
        },
        payAfter($event) {
            if ($event.target.checked) {
                this.properties.payAfterAccept = true;
                this.$refs.payAfterAccept.checked = true;
            }
        },
        cPayAfterAccept($event) {
            if (
                this.properties.payAfterTest == true ||
                this.properties.payAfterTest == "on" ||
                this.properties.partialDelivery == true ||
                    this.properties.partialDelivery == "on"
            ) {
                this.properties.payAfterAccept = true;
                $event.target.checked = true;
            }
        },
        address($event) {
            if (!this.properties.deliveryTo.office) {
                this.properties.deliveryTo.address = true;
                $event.target.checked = true;
            }
        },
        office($event) {
            if (!this.properties.deliveryTo.address) {
                this.properties.deliveryTo.office = true;
                $event.target.checked = true;
            }
        },
        cdAmount($event) {
            if (!this.properties.payOnline) {
                this.properties.cdAmount = true;
                $event.target.checked = true;
            }
        },
        payOnline($event) {
            if (!this.properties.cdAmount) {
                this.properties.payOnline = true;
                $event.target.checked = true;
            }
        },
        payOnPlace($event){
            // console.log(this.properties.payOnPlace);
            this.properties.payOnPlace = !this.properties.payOnPlace;
        },
    }
};
</script>
