<!-- faq<template> -->
<template>
    <form class="form" method="post" enctype="multipart/form-data" autocomplete="off"
        action="/product/store" @submit.prevent="doUpdate">
        <section class="section-dashboard">
            <div class="container extend--upload">
                <div class="grid grid-break-xl bg-white">
                    <div class="col-6 col-lg-12">
                        <div class="field upload" :data-id="product.id" data-href="/product-media/edit-upload">
                            <label class="input-upload-label none" for="input-upload"></label>
                            <div class="upload-cover" for="input-upload">
                                
                                <div class="upload-cover-drag" id="upload" :class="errors && errors.upload ? 'is-error-media' : ''">
                                    <span v-if="product.product_media[0]" class="cover-background" :style="{ 'opacity': '1', 'background': `url('${getImage(product.product_media[0].name)}') 50% 50% / cover repeat scroll padding-box border-box rgba(0, 0, 0, 0)` } "></span>
                                    <span class="frame">
                                        <i></i>
                                        <i></i>
                                    </span>
                                    <span class="plus">
                                        <i class="icon icon-plus"></i>
                                    </span>
                                    <span class="preloader">
                                        <i class="fas fa-redo-alt fa-spin"></i>
                                    </span>
                                    <span class="drop-file-text">пусни файл тук или</span>
                                    <span class="button">Качи снимка или видео</span>
                                    <a href="#" class="toggle mr-0 toggle-top-right"
                                        data-text="Този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i
                                            class="icon icon-info"></i></a>
                                    <a href="#" class="upload-nav upload-nav-previous"><i
                                            class="icon icon-arrow-left-circle weight-800"></i></a>
                                    <a href="#" class="upload-nav upload-nav-next"><i
                                            class="icon icon-arrow-right-circle weight-800"></i></a>
                                </div>
                            </div>
                            <div class="none">
                                <input type="file" id="input-upload" multiple="multiple">
                            </div>
                            <div class="upload-thumbs">
                                <ul>

                                    <li class="ui-sortable-handle has-upload" v-for="media in product.product_media">
                                        <span class="upload-background ready" :data-id="media.name" :data-product-id="media.product_id"
                                        :style="{ 'background-image': `url(${getImage(media.name)})` }">
                                            <a href="#" class="edit-upload-remove">
                                                <i class="icon icon-close-circle-bold"></i>
                                            </a>
                                        </span>
                                        <i class="icon icon-image"></i>
                                    </li>

                                    <li v-if="Object.keys(product.product_media).length < 5" v-for="index in (5 - Object.keys(product.product_media).length)" :key="index">
                                        <span class="upload-background"><a href="#" class="edit-upload-remove"><i
                                                    class="icon icon-close-circle-bold"></i></a></span>
                                        <i class="icon icon-image"></i>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <p class="help-video-text">Помощно видео</p>
                        <input type="hidden" name="upload" class="input-ids" v-model="product.upload">
                    </div>
                    <div class="col-6 col-lg-12">

                        <div class="grid">
                            <div class="col-6 col-md-12">
                                <div class="form-group" :class="errors && errors.title ? 'is-error' : ''">
                                    <label for="">Име на продукт<sub class="is-required">*</sub></label>
                                    <input type="text" id="title" name="title" v-model="product.title" @keyup="errors.title = null">
                                    <form-error v-if="errors && errors.title">
                                        <template v-for="title in errors.title">{{ title }}</template>
                                    </form-error>
                                   
                                </div>
                                <div class="form-group" :class="errors && errors.description ? 'is-error' : ''">
                                    <label for="">Описание на продукт<sub class="is-required">*</sub></label>
                                    <textarea type="text" id="description" name="description" v-model="product.description" @keyup="errors.description = null"
                                        placeholder="Тук трябва да има текст, който да ти даде насоки как да напишеш по-вдъхновяващо описание."></textarea>
                                    <form-error v-if="errors && errors.description">
                                        <template v-for="description in errors.description">{{ description }}</template>
                                    </form-error>
                                </div>
                            </div>
                            <div class="col-6 col-md-12">
                                <div class="toggle-field">
                                    <div class="toggle-field-left">
                                        <div class="grid">
                                            <div class="col-4 col-sm-12">
                                                <div class="form-group" :class="errors && errors.price ? 'is-error' : ''">
                                                    <label for="">Цена<sub class="is-required">*</sub></label>
                                                    <div class="price-wrapper">
                                                        <input type="text" name="price" id="price" v-model="product.price"
                                                            value=""  @keyup="errors.price = null">
                                                        <span class="number-sub">лв.</span>
                                                    </div>
                                                    <form-error v-if="errors && errors.price">
                                                        <template v-for="price in errors.price">{{ price }}</template>
                                                    </form-error>
                                                </div>
                                            </div>
                                            <div class="col-4 col-sm-12">
                                                <div class="form-group" :class="errors && errors.quantity ? 'is-error' : ''">
                                                    <label for="">Брой<sub class="is-required">*</sub></label>
                                                    <div class="number-wrapper">
                                                        <input type="text" name="quantity" id="quantity" v-model="product.quantity" @keyup="errors.quantity = null">
                                                        <a href="#" class="number-arrow number-arrow-up"><i
                                                                class="icon icon-arrow-up"></i></a>
                                                        <a href="#" class="number-arrow number-arrow-down"><i
                                                                class="icon icon-arrow-down"></i></a>
                                                    </div>
                                                    <form-error v-if="errors && errors.quantity">
                                                        <template v-for="quantity in errors.quantity">{{ quantity }}</template>
                                                    </form-error>
                                                </div>
                                            </div>
                                            <div class="col-4 col-sm-12">
                                                <div class="form-group" :class="errors && errors.weight ? 'is-error' : ''">
                                                    <label for="">Тегло<sub class="is-required">*</sub></label>
                                                    <div class="price-wrapper">
                                                        <input type="text" name="weight" id="weight" v-model="product.weight" @keyup="errors.weight = null">
                                                        <span class="number-sub">кг.</span>
                                                    </div>
                                                    <form-error v-if="errors && errors.weight">
                                                        <template v-for="weight in errors.weight">{{ weight }}</template>
                                                    </form-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="toggle-field-right">
                                        <a href="#" class="toggle toggle-top ml-0 mr-0 toggle-bottom-left"
                                            data-text="Този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i
                                                class="icon icon-info"></i></a>
                                    </div>
                                </div>

                                <div class="form-group" :class="errors && errors.valid_to ? 'is-error' : ''">
                                    <label for="">Валидно до</label>
                                    <div class="toggle-field">
                                        <div class="toggle-field-left">
                                            <div class="calendar-wrapper">
                                                <input class="datepicker-product" type="text" name="valid_to" id="valid_to"
                                                    autocomplete="off" v-model="product.valid_to" @click="errors.valid_to = null">
                                                <i class="icon icon-calendar"></i>
                                            </div>
                                            <form-error v-if="errors && errors.valid_to">
                                                <template v-for="valid_to in errors.valid_to">{{ valid_to }}</template>
                                            </form-error>
                                        </div>
                                        <div class="toggle-field-right">
                                            <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                                data-text="Този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i
                                                    class="icon icon-info"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" :class="errors && errors.shop_id ? 'is-error' : ''">
                                    <label for="">Моите магазини</label>
                                    <div class="toggle-field">
                                        <div class="toggle-field-left">
                                            <div class="select-wrapper">
                                                <select id="shop_id" name="shop_id" v-model="product.shop_id">
                                                    <option v-for="shop in shops" :value="shop.id">{{ shop.title }}</option>
                                                </select>
                                            </div>
                                            <form-error v-if="errors && errors.shop_id">
                                                <template v-for="shop_id in errors.shop_id">{{ shop_id }}</template>
                                            </form-error>
                                        </div>

                                        <div class="toggle-field-right">
                                            <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                                data-text="Този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i
                                                    class="icon icon-info"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" :class="errors && errors.transport_pay ? 'is-error' : ''">
                                    <label for="">Куриерската услуга е за сметка на<sub class="is-required">*</sub></label>
                                    <div class="toggle-field">
                                        <div class="toggle-field-left">
                                            <div class="radio-wrapper">
                                                <div class="radio-stack no-select">
                                                    <div class="md-radio-fieldset">
                                                        <label class="md-radio">
                                                            <input type="radio" name="radio_format" value="0" :checked="!product.transport_pay" v-model="product.transport_pay">
                                                            <i class="md-transition"></i>
                                                            <span>Купувач</span>
                                                        </label>
                                                        <label class="md-radio">
                                                            <input type="radio" name="radio_format" value="1" :checked="product.transport_pay" v-model="product.transport_pay">
                                                            <i class="md-transition"></i>
                                                            <span>Продавач</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <form-error v-if="errors && errors.transport_pay">
                                                <template v-for="transport_pay in errors.transport_pay">{{ transport_pay }}</template>
                                            </form-error>
                                        </div>
                                        <div class="toggle-field-right">
                                            <a href="#" class="toggle ml-0 mr-0 toggle-bottom-left"
                                                data-text="Този продукт ще бъде валиден 40 дни. За да удължите валидността му, изберете нова дата на валидност от календара."><i
                                                    class="icon icon-info"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form--extend-butons form--extend-butons-1 justify-end mb-2">
                                    <div v-if="product.status == 0">
                                        <input type="submit" @click="submitDraft = 1" name="submit_draft" class="button button-light" value="Запази чернова">
                                    </div>
                                    <div>
                                        <input class="button" @click="submitDraft = 0" type="submit" value="Напред">
                                    </div>
                                </div>

                                <p class="mutted text-right">
                                    <span class="vertical-middle">Често задавани въпроси</span>
                                    <a href="/faq/questions" class="mr-0"><i
                                            class="icon icon-questionmark-circle"></i></a>
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </form>
</template>

<script>
    import FormError from '../FormError.vue'
    import moment from 'moment'

    export default {
        props: ['shops', 'product'],
        components: { FormError },

        data() {
            return {
                errors: [],
            }
        },
        created() {
            this.product.valid_to = moment().add(+40, 'days').format('DD/MM/Y');
        },
        methods: {
            doUpdate () {
                this.setImagesToInput();
                var self = this

                axios.post(`/product/update/${this.product.id}`, this.product)
                    .then(function(response) {
                        self.errors = []
                        if (response.data.success) {
                            window.location.href = response.data.redirectUrl
                        }
                    })
                    .catch(function(errors) {
                        self.errors = errors.response.data.errors
                    })
            },
            getImage(image) {
                return `/storage/media/${moment(this.product.created_at).format('Y')}/${moment(this.product.created_at).format('MM')}/${this.product.id}/${image}`;
            },
            setImagesToInput() {
                let input_ids = []
                let uplaod = document.querySelectorAll('.ready');     
                uplaod.forEach(el => {
                    input_ids.push(el.getAttribute('data-id'));
                });
                let input = document.querySelector('.input-ids');
                input.value = JSON.stringify(input_ids);

                this.product.upload = document.querySelector('.input-ids').value;
            }
        },
        mounted() {
            const $ = require('jquery')
            var self = this;
            $('.datepicker-product').datepicker({
                yearRange: '1920:2025',
                dateFormat : 'dd/mm/yy',
                setDate: +40,
                defaultDate: +40,
                onSelect:function(selectedDate, datePicker) {            
                    self.product.valid_to = selectedDate;
                }
            });
            $.datepicker.regional['bg']
        },

    }

</script>
